import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Star from './Star';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

const DashboardMyWageDetailedTableCustomToolbarSelect = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Star selectedRows={selectedRows} data={data} setData={setData} />
    </div>
  );
};

DashboardMyWageDetailedTableCustomToolbarSelect.propTypes = propTypes;

export default withRouter(DashboardMyWageDetailedTableCustomToolbarSelect);
