import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import {
  IssueStatus,
  getIssueStatus,
  getIssueStatusForClient,
  IssueSelectableStatus,
  IssueSelectableStatusWithWarning,
  IssueSelectableStatusForClient,
  IssueSelectableStatusForClientWithWarning,
  IssueState,
} from 'shared/constants/issues';
import { Select, Icon } from 'components';

import { SectionTitle } from '../Styles';
import { Status } from './Styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  isClient: PropTypes.bool,
  issue: PropTypes.shape(IssuePropTypes),
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};

const ProjectBoardIssueDetailsStatus = ({
  isClient,
  issue,
  setConfirmModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
}) => {
  const calculateNewState = (formerStatus, newStatus, formerState) => {
    if (formerState === IssueState.WARNING) {
      return IssueState.WARNING;
    } else if (
      formerStatus === IssueStatus.NEWTASK &&
      newStatus !== IssueStatus.CHECKREADY
    ) {
      return IssueState.WARNING;
    } else if (
      formerStatus === IssueStatus.CHECKREADY &&
      newStatus !== IssueStatus.DOCTORASSIGNED
    ) {
      return IssueState.WARNING;
    } else if (
      formerStatus === IssueStatus.DOCTORASSIGNED &&
      newStatus !== IssueStatus.REPORTREADY
    ) {
      return IssueState.WARNING;
    } else if (
      formerStatus === IssueStatus.REPORTREADY &&
      newStatus !== IssueStatus.SENDBACK
    ) {
      return IssueState.WARNING;
    } else if (
      formerStatus === IssueStatus.SENDBACK &&
      newStatus !== IssueStatus.DONE
    ) {
      return IssueState.WARNING;
    } else {
      return IssueState.NORMAL;
    }
  };

  const handleStatusChange = (status) => {
    const state = calculateNewState(issue.status, status, issue.state);
    if (status === IssueStatus.NEWTASK || status === IssueStatus.CHECKREADY) {
      setConfirmModalUpdateData({ status, state, userIds: [], users: [] });
    } else {
      setConfirmModalUpdateData({ status, state });
    }
    setConfirmModalInfo({ destination: status, issueState: state });
    setConfirmModalOpen(true);
  };

  return (
    <Fragment>
      <SectionTitle>{intl.get('issue_details_text_status')}</SectionTitle>
      <Select
        variant="empty"
        dropdownWidth={343}
        withClearValue={false}
        name="status"
        value={issue.status}
        options={(isClient
          ? issue.state === IssueState.WARNING
            ? IssueSelectableStatusForClientWithWarning[issue.status]
            : IssueSelectableStatusForClient[issue.status]
          : issue.state === IssueState.WARNING
          ? IssueSelectableStatusWithWarning[issue.status]
          : IssueSelectableStatus[issue.status]
        ).map((status) => ({
          value: status,
          label: isClient
            ? getIssueStatusForClient(status)
            : getIssueStatus(status),
        }))}
        onChange={(status) => handleStatusChange(status)}
        renderValue={({ value: status }) => (
          <Status isValue color={status}>
            <div>
              {isClient
                ? getIssueStatusForClient(status)
                : getIssueStatus(status)}
            </div>
            <Icon type="chevron-down" size={18} />
          </Status>
        )}
        renderOption={({ value: status }) => (
          <Status color={status}>
            {isClient
              ? getIssueStatusForClient(status)
              : getIssueStatus(status)}
          </Status>
        )}
      />
    </Fragment>
  );
};

ProjectBoardIssueDetailsStatus.propTypes = propTypes;

export default ProjectBoardIssueDetailsStatus;
