import React, { Fragment } from 'react';
import { Text } from '@react-pdf/renderer';
import { styles } from './styles';

const InvoiceFooter = () => {
  return (
    <Fragment>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Fragment>
  );
};

export default InvoiceFooter;
