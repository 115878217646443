// cardStyles.js
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  closeIconButton: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    // display: 'flex',
    // justifyContent: 'flex-end',
  },
  closeIcon: {
    fontSize: 'medium',
  },
  horizontalDivider: {
    borderTop: '2px solid #ccc', // Example line styling
    margin: '10px auto 0px',
    width: '90%',
  },
}));
