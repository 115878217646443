import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import KrillChat from 'krill';
import Welcome from 'pages/Welcome';
import PasswordRecovery from 'pages/PasswordRecovery';
import Dashboard from 'pages/Dashboard';
import IWGDashboard from 'pages/IWGDashboard';
import BaseApp from 'root/base';
import localData from 'shared/utils/localData';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const [currentUser, setCurrentUser] = useState('NotChecked');
  const [userGroups, setUserGroups] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (result) {
        setCurrentUser(result);
        const groups =
          result.signInUserSession.accessToken.payload['cognito:groups'];
        setUserGroups(groups);
      })
      .catch(function (error) {
        console.error(error);
        setCurrentUser('NotSignedIn');
      });

    const krillInit = async () => {
      try {
        await KrillChat.Config.init({
          DEFAULT_LANGUAGE: `en-US`,
          CURRENT_LANGUAGE: localData.get('userPreferredLanguage'),
          BROWSWER_STORAGE:
            process.env.REACT_APP_BROWSER_STORAGE === `localStorage`
              ? window.localStorage
              : window.sessionStorage,
          AXIOS_URL: process.env.REACT_APP_KRILL_CHAT_AXIOS_URL,
          AXIOS_TIMEOUT: 60000,
          ADMIN_URL: process.env.REACT_APP_KRILL_CHAT_ADMIN_URL,
          IM_URL: process.env.REACT_APP_KRILL_CHAT_IM_URL,
          CALL_SERVICE_HOST_HTTP_URL:
            process.env.REACT_APP_KRILL_CHAT_CALL_SERVICE_HOST_HTTP_URL,
          CALL_SERVICE_HOST_WS_URL:
            process.env.REACT_APP_KRILL_CHAT_CALL_SERVICE_HOST_WS_URL,
          CALL_SERVICE_TOKEN_SERVER_URL:
            process.env.REACT_APP_KRILL_CHAT_CALL_SERVICE_TOKEN_SERVER_URL,
        });
      } catch (err) {
        console.log(err);
      }
    };

    krillInit();
  }, []);

  if (currentUser === 'NotChecked') {
    return <div></div>; // temporary work-around;
  } else if (currentUser === 'NotSignedIn') {
    return (
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Switch>
          <Route exact path="/forgot-password">
            <PasswordRecovery />
          </Route>
          <Route path="/">
            <Welcome />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </SnackbarProvider>
    );
  } else if (userGroups && userGroups.includes('IWG')) {
    localData.set('username', 'IWG');
    return (
      <BaseApp>
        <IWGDashboard />
      </BaseApp>
    );
  } else {
    return (
      <BaseApp>
        <Dashboard currentUser={currentUser} />
      </BaseApp>
    );
  }
};

export default App;
