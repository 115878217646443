import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { Grid, Modal } from '@material-ui/core';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';

import useStyles from './styles';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from 'pages/parts/FormField';
import FormFieldHelperText from 'pages/parts/FormFieldHelperText';
import pxToRem from 'assets/theme/functions/pxToRem';
import { Auth } from 'aws-amplify';
import { QRCodeSVG } from 'qrcode.react';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { MFA_TYPE } from 'shared/constants/mfa';

const getPreferredMfaTypeOptions = () => {
  return [
    {
      label: intl.get('personal_settings_mfa_options_no_mfa'),
      value: MFA_TYPE.NOMFA,
    },
    {
      label: intl.get('personal_settings_mfa_options_totp'),
      value: MFA_TYPE.TOTP,
    },
    {
      label: intl.get('personal_settings_mfa_options_email'),
      value: MFA_TYPE.EMAIL,
    },
  ];
};

const DashboardPersonalSettingsMFA = () => {
  const classes = useStyles();
  const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState();
  const [showConfirmBeforeSend, setShowConfirmBeforeSend] = useState(false);
  const [userAttributesFormState, setUserAttributesFormState] = useState({
    preferredMfaType: MFA_TYPE.EMAIL,
  });
  const [secret, setSecret] = useState('');
  const [showTOTPSetting, setShowTOTPSetting] = useState(false);
  const [formState, setFormState] = useState({
    totpCode: '',
  });

  useEffect(() => {
    const fetchUser = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setCurrentAuthenticatedUser(user);
      const { attributes } = user;
      if (attributes['custom:preferredMfaType']) {
        setUserAttributesFormState({
          preferredMfaType: attributes['custom:preferredMfaType'],
        });
      }
    };
    fetchUser();
  }, []);

  const onOneTimePasswordChange = (key) => async (event) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  const changeMFAType = (key, newValue) => {
    setUserAttributesFormState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  const handleSetTOTPClick = async (event) => {
    try {
      const code = await Auth.setupTOTP(currentAuthenticatedUser);
      setSecret(code);
      setShowTOTPSetting(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateClick = async (event) => {
    setShowConfirmBeforeSend(true);
  };

  const handleConfirmClick = async (event) => {
    setShowConfirmBeforeSend(false);
    sendToServer();
  };

  const handleVerifyTotpCode = async () => {
    try {
      await Auth.verifyTotpToken(currentAuthenticatedUser, formState.totpCode);
      setShowTOTPSetting(false);
    } catch (error) {
      alert(intl.get('personal_settings_mfa_alert_text_wrong_totp_code'));
    }
  };

  const generateConfirmWindowContents = () => {
    const getPreferredMfaType = (type) => {
      switch (type) {
        case MFA_TYPE.NOMFA:
          return intl.get('personal_settings_mfa_options_no_mfa');
        case MFA_TYPE.TOTP:
          return intl.get('personal_settings_mfa_options_totp');
        case MFA_TYPE.EMAIL:
          return intl.get('personal_settings_mfa_options_email');
        default:
          return '';
      }
    };

    return (
      <Grid spacing={3} container style={{ marginTop: '15px' }}>
        <Grid xs={12} item>
          <p>
            {intl.get('personal_settings_mfa_label_preferred_mfa_type')}:{' '}
            {getPreferredMfaType(
              currentAuthenticatedUser.attributes['custom:preferredMfaType']
            ) +
              ' ==> ' +
              getPreferredMfaType(userAttributesFormState.preferredMfaType)}
          </p>
        </Grid>
      </Grid>
    );
  };

  const sendToServer = async () => {
    if (
      [MFA_TYPE.NOMFA, MFA_TYPE.EMAIL].includes(
        userAttributesFormState.preferredMfaType
      )
    ) {
      try {
        await Auth.setPreferredMFA(currentAuthenticatedUser, MFA_TYPE.NOMFA);
        await Auth.updateUserAttributes(currentAuthenticatedUser, {
          'custom:preferredMfaType': userAttributesFormState.preferredMfaType,
        });
      } catch (error) {
        console.log(error);
      }
    } else if (userAttributesFormState.preferredMfaType === MFA_TYPE.TOTP) {
      try {
        await Auth.setPreferredMFA(currentAuthenticatedUser, MFA_TYPE.TOTP);
        await Auth.updateUserAttributes(currentAuthenticatedUser, {
          'custom:preferredMfaType': userAttributesFormState.preferredMfaType,
        });
      } catch (error) {
        console.log(error);
        alert(intl.get('personal_settings_mfa_alert_text_set_totp'));
      }
    } else {
      alert(intl.get('personal_settings_mfa_alert_text_choose_mfa_type'));
    }
  };

  return (
    <MDBox>
      <Grid item xs={12}>
        <MDBox mt={8}>
          <Autocomplete
            id="select_preferred_mfa_type"
            value={
              getPreferredMfaTypeOptions().filter(
                (type) =>
                  type.value === userAttributesFormState.preferredMfaType
              )[0]
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.value)
                changeMFAType('preferredMfaType', newValue.value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={getPreferredMfaTypeOptions()}
            renderInput={(params) => (
              <FormField
                {...params}
                variant="outlined"
                label={intl.get(
                  'personal_settings_mfa_label_preferred_mfa_type'
                )}
                InputLabelProps={{ shrink: true }}
              />
            )}
            disableClearable
          />
          <FormFieldHelperText>
            {intl.get('personal_settings_mfa_helper_select_preferred_mfa_type')}
          </FormFieldHelperText>
        </MDBox>
      </Grid>

      <Grid item xs={12}>
        <MDButton
          variant="gradient"
          color="primary"
          fullWidth
          onClick={handleSetTOTPClick}
          style={{
            color: 'white',
            fontSize: pxToRem(18),
            marginTop: '25px',
            marginBottom: '15px',
            width: '100%',
            marginLeft: '0%',
          }}>
          {intl.get('personal_settings_mfa_button_set_totp')}
        </MDButton>
      </Grid>

      <Grid item xs={12}>
        <MDButton
          variant="gradient"
          color="info"
          fullWidth
          onClick={handleUpdateClick}
          style={{
            color: 'white',
            fontSize: pxToRem(18),
            marginTop: '25px',
            marginBottom: '15px',
            width: '50%',
            marginLeft: '25%',
          }}>
          {intl.get('personal_settings_mfa_button_update')}
        </MDButton>
      </Grid>

      <ConfirmModal
        isOpened={showConfirmBeforeSend}
        onCancel={() => setShowConfirmBeforeSend(false)}
        onConfirm={handleConfirmClick}
        title={intl.get('personal_settings_mfa_modal_text_confirm_title')}
        bodyText={intl.get('personal_settings_mfa_modal_text_confirm_hint')}
        confirmButtonText={intl.get(
          'personal_settings_mfa_modal_button_submit'
        )}
        cancelButtonText={intl.get(
          'personal_settings_mfa_modal_button_cancel'
        )}>
        <MDBox>
          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end">
            <Grid xs={12} item>
              {showConfirmBeforeSend && generateConfirmWindowContents()}
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>

      {/* Sort of Irregular Modal  */}
      <Modal
        open={showTOTPSetting}
        onClose={() => setShowTOTPSetting(false)}
        className={classes.modalModal}>
        <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
          <MDBox py={3} px={3} mt={3} mx={3}>
            <Grid
              spacing={3}
              container
              justifyContent="flex-end"
              alignItems="flex-end">
              <Grid xs={12} item>
                <MDTypography mb={1}>
                  {intl.get('personal_settings_mfa_text_scan_qr_code')}
                </MDTypography>
              </Grid>
              <Grid
                columns={{ xs: 12 }}
                container
                item
                justifyContent="center"
                alignItems="center">
                {currentAuthenticatedUser && secret && (
                  <QRCodeSVG
                    value={`otpauth://totp/DoCloud:${currentAuthenticatedUser.username}?secret=${secret}&issuer=DoCloud`}
                  />
                )}
              </Grid>
              <Grid xs={12} item>
                <MDTypography mb={1}>
                  {intl.get('personal_settings_mfa_text_input_qr_code')}
                </MDTypography>
              </Grid>
              <Grid
                columns={{ xs: 12 }}
                container
                item
                justifyContent="center"
                alignItems="center">
                <Grid xs={6} item>
                  <MDBox mt={0}>
                    <FormField
                      id="TOTP code"
                      name="TOTP code"
                      label={intl.get('personal_settings_mfa_label_totp_code')}
                      variant="outlined"
                      value={formState.totpCode}
                      inputProps={{ maxLength: 6 }}
                      onChange={onOneTimePasswordChange('totpCode')}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={handleVerifyTotpCode}>
                  {intl.get('personal_settings_mfa_modal_button_submit')}
                </MDButton>
              </Grid>

              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="error"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={() => {
                    setShowTOTPSetting(false);
                  }}>
                  {intl.get('personal_settings_mfa_modal_button_cancel')}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Modal>
    </MDBox>
  );
};

export default withRouter(DashboardPersonalSettingsMFA);
