import React, { Fragment } from 'react';
import { Text } from '@react-pdf/renderer';
import { styles } from './styles';

const InvoiceHeader = () => {
  return (
    <Fragment>
      <Text style={styles.headerIWG} fixed>
        ~ Powered By DoCloud ~
      </Text>
    </Fragment>
  );
};

export default InvoiceHeader;
