import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';

const ReportTitle = () => {
  return (
    <View style={styles.reportTitleContainer}>
      <Text style={styles.reportTitleText}>
        {intl.get('issue_details_report_preview_text_report_title')}
      </Text>
    </View>
  );
};

export default ReportTitle;
