import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  grid: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buttonLeft: {
    paddingRight: theme.spacing(1),
  },
  buttonRight: {
    paddingLeft: theme.spacing(1),
  },
}));
