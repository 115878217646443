import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { useStyles } from './Styles';
import { Grid, IconButton } from '@mui/material';

import Editor from '../Editor';
import Preview from '../Preview';
import Confirm from '../Confirm';
import { StyledConfirmModal } from 'components/ConfirmModal/Styles';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  reportContent: PropTypes.object.isRequired,
  setReportContent: PropTypes.func.isReqired,
  saveReportContent: PropTypes.func.isRequired,
  modalClose: PropTypes.func,
};

const PreviewMemo = React.memo(Preview);

const DashboardKanbanIssueDetailsReportsReportEditor = ({
  issue,
  reportContent,
  setReportContent,
  saveReportContent,
  modalClose,
}) => {
  const classes = useStyles();
  const [previewContent, setPreviewContent] = useState({
    ...reportContent,
  });

  return (
    <Fragment>
      <Grid>
        <Grid
          container
          className={classes.actionGrid}
          justifyContent="flex-end">
          <div>
            <IconButton onClick={modalClose}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </div>
        </Grid>
        <Grid
          container
          className={classes.reportEditorGrid}
          columns={{ xs: 12 }}>
          <Grid item className={classes.editorGrid} xs={6}>
            <Editor
              reportContent={reportContent}
              setReportContent={setReportContent}
              setPreviewContent={setPreviewContent}
              saveReportContent={saveReportContent}
            />
          </Grid>
          <Grid item className={classes.previewGrid} xs={6}>
            <PreviewMemo reportContent={previewContent} />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

DashboardKanbanIssueDetailsReportsReportEditor.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsReportEditor);
