import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const InvoiceRemarks = ({ invoiceContent }) => {
  return (
    <Fragment>
      <View style={styles.invoiceRemarks}>
        <Text style={styles.invoiceRemarksTitle}>
          {intl.get('billing_invoice_text_remarks')}
        </Text>
        <Text style={styles.invoiceRemarksText}>
          {invoiceContent.invoiceRemarks}
        </Text>
      </View>
    </Fragment>
  );
};

InvoiceRemarks.propTypes = propTypes;

export default InvoiceRemarks;
