import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { Grid, Modal } from '@material-ui/core';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { TextEditedContent } from 'components';
import pxToRem from 'assets/theme/functions/pxToRem';
import PropTypes from 'prop-types';
import { OrganizationProptypes } from 'shared/propTypes/organizationType';

import api from 'shared/utils/api';
import useApi from 'shared/hooks/api';
import { DOLPHIN_API } from 'shared/constants/apis';
import { PageLoader, PageError } from 'components';

import { NewTag } from './newTag';

const propTypes = {
  organizationInfo: PropTypes.shape(OrganizationProptypes),
  templateNewModalOpen: PropTypes.bool.isRequired,
  setTemplateNewModalOpen: PropTypes.func.isRequired,
};

const NewTemplate = ({
  organizationInfo,
  templateNewModalOpen,
  setTemplateNewModalOpen,
}) => {
  const [newTemplateJson, setNewTemplateJson] = useState({});

  const sendTemplateJsonToServer = async () => {
    await api
      .post(DOLPHIN_API.TEMPLATE_MANAGEMENT, {
        params: {
          organizationId: organizationInfo.id,
        },
        data: {
          templateJson: { ...newTemplateJson },
        },
      })
      .then((respond) => {
        // console.log(respond);
      });
  };

  return (
    <Fragment>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <NewTag
          organizationInfo={organizationInfo}
          newTemplateJson={newTemplateJson}
          setNewTemplateJson={setNewTemplateJson}
        />

        <Grid
          spacing={3}
          container
          alignItems="flex-start"
          justifyContent="center">
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '50px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={sendTemplateJsonToServer}>
              Submit
            </MDButton>
          </Grid>
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '50px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={() => setTemplateNewModalOpen(false)}>
              Cancel
            </MDButton>
          </Grid>
        </Grid>

        {/* Modal for warning */}
        <Modal
        //   open={incompleteWarningModalOpen}
        //   onClose={() => setIncompleteWarningModalOpen(false)}
        //   className={classes.modal}
        >
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Warning</MDTypography>
              <MDTypography>
                The following information is incomplete!
              </MDTypography>
              <br />
              {/* {incompleteFields &&
                incompleteFields.map((field, index) => (
                  <p style={{ color: 'red' }} key={index}>
                    {field}
                  </p>
                ))} */}
              <br />
            </MDBox>
          </Card>
        </Modal>
        {/* Modal for confirm */}
      </MDBox>
    </Fragment>
  );
};

NewTemplate.propTypes = propTypes;

export default withRouter(NewTemplate);
