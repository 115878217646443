import { Fragment } from 'react';
import intl from 'react-intl-universal';
import { htmlToText } from 'html-to-text';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Icon from '@mui/material/Icon';

// import pxToRem from 'assets/theme/functions/pxToRem';
// import MDBox from "components/MDBox";
import MDBadge from 'components/MDBadge';
// import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
// import MDProgress from "components/MDProgress";

import { formatBytes } from 'shared/utils/misc';
// import { useMaterialUIController } from "context";

import { listTags, showSelectedTagsNew } from 'pages/Dashboard/New/utils';
import { MISSION, STATUS } from 'shared/constants/uploadAttachments';

export const getBadge = (missionStatus, size) => {
  var color = 'info';
  var label;
  switch (missionStatus) {
    case STATUS.INIT:
      label = intl.get('upload_mission_menu_item_status_init');
      break;
    case STATUS.UPLOADING:
      label = intl.get('upload_mission_menu_item_status_uploading');
      break;
    case STATUS.ABORTING_UPLOAD:
      // label = intl.get('');
      label = 'Aborting';
      color = 'warning';
      break;
    case STATUS.UPLOAD_ABORTED:
      // label = intl.get('');
      color = 'warning';
      label = 'Aborted';
      break;
    case STATUS.UPLOAD_FAILED:
      color = 'error';
      label = intl.get('upload_mission_menu_item_status_upload_failed');
      break;
    case STATUS.PROCESSING:
      label = intl.get('upload_mission_menu_item_status_processing');
      break;
    case STATUS.PROCESS_FAILED:
      color = 'error';
      label = intl.get('upload_mission_menu_item_status_process_failed');
      break;
    case STATUS.PROCESS_COMPLETE:
      color = 'success';
      label = intl.get('upload_mission_menu_item_status_done');
      break;
    default:
      break;
  }
  return (
    <MDBadge size={size} color={color} badgeContent={label} container square />
  );
};

const createBasicInfo = (mission) => (
  <Fragment>
    <Grid xs={12} item>
      <Stack direction="row" spacing={1} alignItems="center">
        <MDTypography fontWeight="bold">{`Type: `}</MDTypography>
        <MDTypography>{getMissionTypeDisplayString(mission.type)}</MDTypography>
      </Stack>
    </Grid>
    <Grid xs={12} item>
      <Stack direction="row" spacing={1} alignItems="center">
        <MDTypography fontWeight="bold">
          {`${intl.get('upload_mission_menu_item_issue_title')}:`}
        </MDTypography>
        <MDTypography>{`${mission.issueIdentifierId}`}</MDTypography>
      </Stack>
    </Grid>
  </Fragment>
);

const createFormData = (mission) => {
  const formState = mission.formState;

  let fullGenderName;
  switch (formState.patientGender) {
    case 'F':
      fullGenderName = intl.get('new_option_patient_gender_female');
      break;
    case 'M':
      fullGenderName = intl.get('new_option_patient_gender_male');
      break;
    case 'X':
      fullGenderName = intl.get('new_option_patient_gender_others');
      break;
    default:
      break;
  }

  let emergencyName;
  switch (formState.emergency) {
    case 2:
      emergencyName = intl.get('new_option_emergency_no_emergency');
      break;
    case 5:
      emergencyName = intl.get('new_option_emergency_in_1_hour');
      break;
    case 4:
      emergencyName = intl.get('new_option_emergency_in_3_hours');
      break;
    case 3:
      emergencyName = intl.get('new_option_emergency_in_24_hours');
      break;
    default:
      break;
  }

  const formData = {
    new_label_patient_name: formState.patientName,
    new_label_patient_gender: fullGenderName,
    new_label_send_to: formState.dstOrgName,
    new_label_emergency: emergencyName,
    new_label_department: formState.department,
    new_label_doctor_in_charge: formState.doctorsInCharge.join(', '),
  };

  const res = [];
  for (const [key, value] of Object.entries(formData)) {
    res.push(
      <Grid xs={6} item key={key}>
        <MDTypography>
          <span style={{ fontWeight: 'bold' }}>{intl.get(key)}: </span>
          <span>{value}</span>
        </MDTypography>
      </Grid>
    );
  }
  res.push(
    <Grid xs={12} item key={'new_label_case_description_label'}>
      <MDTypography>
        <span style={{ fontWeight: 'bold' }}>
          {intl.get('new_label_case_description')}:
        </span>
      </MDTypography>
    </Grid>
  );
  res.push(
    <Grid xs={12} item key={'new_label_case_description_desc'}>
      <MDTypography variant="body2" mt={-2}>
        {htmlToText(formState.description)}
      </MDTypography>
    </Grid>
  );
  return res;
};

const getFilesList = (structuredAttachments) =>
  structuredAttachments.map((item, index) => {
    return (
      <Grid container spacing={2} key={index}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={4}
            justifyContent="flex-start"
            alignItems="center">
            <Icon fontSize="large" color="secondary">
              {item.type === 'directory' ? 'folder' : 'attachment'}
            </Icon>
            <Stack direction="column" spacing={1}>
              <MDTypography variant="body2" style={{ marginTop: '25px' }}>
                {intl.get('new_label_file_name')}: {item.name}
              </MDTypography>
              <MDTypography variant="body2">
                {intl.get('new_label_file_size')}: {formatBytes(item.size)}{' '}
              </MDTypography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    );
  });

export const generateMissionDetailsModalContents = (mission, darkMode) => {
  const structuredAttachments = mission.structuredAttachments;

  return (
    <Grid spacing={1} container style={{ marginTop: '5px', maxHeight: '80vh' }}>
      {createBasicInfo(mission)}
      {mission.type === MISSION.ISSUE && createFormData(mission)}
      {/* {mission.type === MISSION.ISSUE &&
        listTags(mission.checkboxForm, darkMode)} */}
      {mission.type === MISSION.ISSUE &&
        showSelectedTagsNew(
          mission.checkboxForm,
          mission.tagListJson,
          darkMode
        )}
      {getFilesList(structuredAttachments)}
    </Grid>
  );
};

export const getMissionTypeDisplayString = (missionType) => {
  let displayString;
  // TODO: user translator
  switch (missionType) {
    case MISSION.ISSUE:
      displayString = 'New Issue';
      break;
    case MISSION.NEW_ATTACHMENTS_ON_EXISTING_ISSUE:
      displayString = 'Additional Attachments for Issue';
      break;
    case MISSION.NEW_RESEARCH_CASE:
      displayString = 'New Research Case';
      break;
    case MISSION.NEW_ATTACHMENTS_ON_EXISTING_RESEARCH_CASE:
      displayString = 'Additional Attachments For Research Case';
      break;
    default:
      break;
  }

  return displayString;
};
