import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const propTypes = {
  onSearch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  tableTitle: PropTypes.string,
};

const defaultSearchStyles = (theme) => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class CustomSearchRender extends React.Component {
  handleTextChange = (event) => {
    this.props.onSearch(event.target.value);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  render() {
    const { classes, options, onHide, searchText, tableTitle } = this.props;

    return (
      <div className={classes.main} ref={(el) => (this.rootRef = el)}>
        <Typography variant="h6" style={{ marginTop: '8px' }}>
          {tableTitle}
        </Typography>
        <TextField
          style={{ marginTop: '8px', marginLeft: '20px' }}
          // placeholder={'Custom TableSearch without search icon'}
          autoFocus={true}
          className={classes.searchText}
          InputProps={{
            'aria-label': options.textLabels.toolbar.search,
          }}
          value={searchText || ''}
          onChange={this.handleTextChange}
          fullWidth={true}
          inputRef={(el) => (this.searchField = el)}
        />
        <IconButton className={classes.clearIcon} onClick={onHide}>
          <ClearIcon />
        </IconButton>
      </div>
    );
  }
}

CustomSearchRender.propTypes = propTypes;

export default withStyles(defaultSearchStyles, { name: 'CustomSearchRender' })(
  CustomSearchRender
);
