import PropTypes from 'prop-types';
import { OrganizationProptypes } from './organizationType';

export const AttachmentProptypes = {
  id: PropTypes.number,
  attachmentIdentifierId: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  fileType: PropTypes.any,
  filePath: PropTypes.any,
  webFilePath: PropTypes.string,
  dicomFilesInfo: PropTypes.shape({}),
  structureJson: PropTypes.shape({
    type: PropTypes.string,
    file: PropTypes.shape({
      path: PropTypes.string,
    }),
    name: PropTypes.string,
    fileFullPath: PropTypes.string,
    size: PropTypes.number,
    Bucket: PropTypes.string,
    Key: PropTypes.string,
  }),
  status: PropTypes.string,
  message: PropTypes.any,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  organization: PropTypes.shape(OrganizationProptypes),
  organizationId: PropTypes.number,
};
