import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const InvoiceTo = ({ invoiceContent }) => {
  return (
    <Fragment>
      <View style={styles.invoiceTo}>
        <Text style={styles.invoiceToText}>{invoiceContent.invoiceTo}</Text>
        {invoiceContent.invoiceTo.length > 0 && (
          <Text style={styles.invoiceToText2}>{invoiceContent.invoiceTo2}</Text>
        )}
      </View>
    </Fragment>
  );
};

InvoiceTo.propTypes = propTypes;

export default InvoiceTo;
