import styled, { css } from 'styled-components';

import { color, font, mixin } from 'shared/utils/styles';

export const User = styled.div`
  display: flex;
  align-items: center;
  ${mixin.clickable}
  ${(props) =>
    props.isSelectValue &&
    css`
      margin: 0 10px ${props.withBottomMargin ? 5 : 0}px 0;
      padding: 4px 8px;
      border-radius: 4px;
      background: ${color.backgroundLight};
      transition: background 0.1s;
      &:hover {
        background: ${color.backgroundMedium};
      }
    `}
`;

export const Username = styled.div`
  display: flex;
  padding: 0 3px 0 8px;
  flex-grow: 1;
  ${font.size(14.5)}
`;

export const Quota = styled.div`
  display: flex;
  ${font.size(14.5)}
`;

export const SectionTitle = styled.div`
  margin: 0 0 2px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(16.5)}
  ${font.bold}
`;
