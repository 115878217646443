import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import {
  InfoLabel,
  InfoValue,
  InfoContent,
  InfoContainer,
  InfoContainerTags,
} from './styles';
import { makeTagString } from 'pages/Dashboard/New/utils';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
};

const ProjectBoardIssueDetailsInfo = ({ issue }) => {
  const [CTTags, setCTTags] = useState([]);
  const [MRITags, setMRITags] = useState([]);
  const [PhyExamTags, setPhyExamTags] = useState([]);

  useEffect(() => {
    listTags(issue.tags);
  }, [issue.tags]);

  const listTags = (tags) => {
    const CTTagsArray = [];
    const MRITagsArray = [];
    const PhyExamTagsArray = [];

    // create the tagsObject to use the imported "makeTagString" function, in the form of {tagKeyName: true, ...};
    const tagsObject = {};
    for (var i = 0; i < tags.length; i++) {
      tagsObject[tags[i]] = true;
    }

    for (var j = 0; j < tags.length; j++) {
      const key = tags[j];
      const res = makeTagString(key, tagsObject);
      if (res) {
        if (key.startsWith('CT')) CTTagsArray.push(res);
        if (key.startsWith('MRI')) MRITagsArray.push(res);
        if (key.startsWith('PhyExam')) PhyExamTagsArray.push(res);
      }
    }

    setCTTags(CTTagsArray);
    setMRITags(MRITagsArray);
    setPhyExamTags(PhyExamTagsArray);
  };

  const renderTagsFixed = () => {
    return (
      <InfoContainerTags>
        <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
        <InfoValue>
          {intl.get('case_tag_value_section_1')}: {CTTags.join(', ')}
        </InfoValue>
        <InfoValue>
          {intl.get('case_tag_value_section_2')}: {MRITags.join(', ')}
        </InfoValue>
        <InfoValue>
          {intl.get('case_tag_value_section_3')}: {PhyExamTags.join(', ')}
        </InfoValue>
      </InfoContainerTags>
    );
  };

  const renderTagsCustomized = () => {
    return <Fragment></Fragment>;
  };

  const renderTagsNew = () => {
    return (
      <InfoContainerTags>
        <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
        {issue.tags.map((tagLabel, index) => {
          return <InfoValue key={index}>{tagLabel}</InfoValue>;
        })}
      </InfoContainerTags>
    );
  };

  const renderTags = () => {
    const tagSystem = process.env.REACT_APP_TAG_SYSTEM;
    if (tagSystem === 'customized') {
      return renderTagsFixed();
    } else if (tagSystem === 'fixed') {
      return renderTagsCustomized();
    } else {
      return renderTagsNew();
    }
  };

  return (
    <Fragment>
      <InfoContent>
        <InfoContainer>
          <InfoLabel>{intl.get('issue_details_info_label_from')}</InfoLabel>
          <InfoValue>{issue.srcOrgName}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_patient_name')}
          </InfoLabel>
          <InfoValue>{issue.patientName}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_patient_gender')}
          </InfoLabel>
          <InfoValue>{issue.patientGender}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_department')}
          </InfoLabel>
          <InfoValue>{issue.department}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_doctor_in_charge')}
          </InfoLabel>
          <InfoValue>{issue.doctorsInCharge.join(', ')}</InfoValue>
        </InfoContainer>
        {/* <InfoContainerTags>
          <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
          <InfoValue>
            {intl.get('case_tag_value_section_1')}: {CTTags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_2')}: {MRITags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_3')}: {PhyExamTags.join(', ')}
          </InfoValue>
        </InfoContainerTags> */}
        {renderTags()}
      </InfoContent>
    </Fragment>
  );
};

ProjectBoardIssueDetailsInfo.propTypes = propTypes;

export default ProjectBoardIssueDetailsInfo;
