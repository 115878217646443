import intl from 'react-intl-universal';
import { Button, Typography } from '@material-ui/core';
import { Operations, Operation } from './style';

export const getSearchedUserTableColumns = (
  openChatWithUser,
  sendApplication
) => [
  {
    name: 'show name',
    label: intl.get('friends_label_column_user_show_name'),
    operation: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'user id',
    label: intl.get('friends_label_column_user_id'),
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'Operation',
    label: intl.get('friends_label_column_operations'),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const userID = value[0];
        const isFriend = value[1];

        return (
          <Operations>
            <Operation>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  openChatWithUser(userID);
                }}>
                {intl.get('friends_button_send_message')}
              </Button>
            </Operation>
            <Operation>
              {!isFriend && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    sendApplication(userID);
                  }}>
                  {intl.get('friends_button_add_friend')}
                </Button>
              )}
              {isFriend && (
                <Button variant="contained" disabled color="primary">
                  {intl.get('friends_button_accepted')}
                </Button>
              )}
            </Operation>
          </Operations>
        );
      },
    },
  },
];
