import { styled } from '@mui/material/styles';

export const StateNormal = styled('span')({});

export const StateWarning = styled('span')({
  color: 'white',
  background: 'red',
  border: 'thick solid red',
  fontWeight: 'bold',
});

export const StateError = styled('span')({
  color: 'white',
  background: 'darkred',
  border: 'thick solid darkred',
  fontWeight: 'bold',
});
