import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import {
  // IssueStatus,
  getIssueStatus,
  getIssueStatusForClient,
  KenshinIssueSelectableStatus,
  KenshinIssueSelectableStatusForClient,
} from 'shared/constants/kenshin/issues';
import { Select, Icon } from 'components';

import { SectionTitle } from '../Styles';
import { Status } from './Styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  isClient: PropTypes.bool,
  issue: PropTypes.shape(IssuePropTypes),
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};

const ProjectBoardIssueDetailsStatus = ({
  isClient,
  issue,
  setConfirmModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
}) => {
  const handleStatusChange = (progress) => {
    setConfirmModalUpdateData({ progress });
    setConfirmModalInfo({ destination: progress });
    setConfirmModalOpen(true);
  };

  return (
    <Fragment>
      <SectionTitle>{intl.get('issue_details_text_status')}</SectionTitle>
      <Select
        variant="empty"
        dropdownWidth={200}
        withClearValue={false}
        name="progress"
        value={issue.progress}
        options={(isClient
          ? KenshinIssueSelectableStatusForClient[issue.progress]
          : KenshinIssueSelectableStatus[issue.progress]
        ).map((progress) => ({
          value: progress,
          label: isClient
            ? getIssueStatusForClient(progress)
            : getIssueStatus(progress),
        }))}
        onChange={(progress) => handleStatusChange(progress)}
        renderValue={({ value: progress }) => (
          <Status isValue color={progress}>
            <div>
              {isClient
                ? getIssueStatusForClient(progress)
                : getIssueStatus(progress)}
            </div>
            <Icon type="chevron-down" size={18} />
          </Status>
        )}
        renderOption={({ value: progress }) => (
          <Status color={progress}>
            {isClient
              ? getIssueStatusForClient(progress)
              : getIssueStatus(progress)}
          </Status>
        )}
      />
    </Fragment>
  );
};

ProjectBoardIssueDetailsStatus.propTypes = propTypes;

export default ProjectBoardIssueDetailsStatus;
