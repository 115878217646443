import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

export const customImageLoaderForBlobUrl = (blobUrl) => {
  const promise = new Promise((resolve, reject) => {
    getBlobFromBlobUrl(blobUrl)
      .then((blob) => {
        const wadoImageId =
          cornerstoneWADOImageLoader.wadouri.fileManager.add(blob);
        return cornerstone.loadAndCacheImage(wadoImageId);
      })
      .then((image) => {
        resolve(image);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return {
    promise,
  };
};

async function getBlobFromBlobUrl(blobUrl) {
  try {
    const response = await fetch(blobUrl); // Fetch the Blob from the Blob URL
    const blob = await response.blob(); // Convert the response to Blob
    return blob; // Return the ArrayBuffer
  } catch (error) {
    console.error('Error fetching the Blob:', error);
    throw error; // Rethrow the error for the caller to handle
  }
}
