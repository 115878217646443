import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { formatDeadline } from 'shared/utils/formatTime';
import { SectionTitle } from 'pages/Dashboard/Kanban/IssueDetails/Styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
};

const ProjectBoardIssueDetailsEstimateTracking = ({ issue }) => {
  return (
    <Fragment>
      <SectionTitle>{intl.get('issue_details_text_deadline')}</SectionTitle>
      <h4>{formatDeadline(issue.deadline)}</h4>
    </Fragment>
  );
};

ProjectBoardIssueDetailsEstimateTracking.propTypes = propTypes;

export default ProjectBoardIssueDetailsEstimateTracking;
