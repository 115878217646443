import React from 'react';

import NormalizeStyles from './NormalizedStyles';
import BaseStyles from './BaseStyles';
import './App.css';
import './fontStyles.css';

import { SnackbarProvider } from 'notistack';
import { PropTypes } from 'prop-types';

const BaseApp = ({ children }) => {
  return (
    <div>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <NormalizeStyles />
        <BaseStyles />
        {children}
      </SnackbarProvider>
    </div>
  );
};

BaseApp.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BaseApp;
