import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Stack from '@mui/material/Stack';

import { getUserFullName } from 'shared/utils/name';
import { SectionTitle } from './Styles';

import { KenshinCaseDiagnosis } from 'shared/constants/kenshin/case';

const propTypes = {
  firstCase: PropTypes.object,
  doctors: PropTypes.arrayOf(Object),
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};

const DoctorSelect = ({
  firstCase,
  doctors,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
}) => {
  const currentDoctor = doctors.filter(
    (item) => item.id === firstCase.doctorId
  );
  const currentDoctorIndex = doctors.findIndex(
    (item) => item.id === firstCase.doctorId
  );

  // const currentDoctorFullName = getUserFullName(
  //   currentDoctor.firstName,
  //   currentDoctor.lastName
  // );

  // const [selectedDoctorId, setSelectedDoctorId] = useState();

  const handleChange = (event) => {
    // console.log('event: ', event);
    const selectedId = event ? event.value : -1;
    let updatedFields;
    if (selectedId === -1) {
      updatedFields = {
        doctorId: null,
        doctorUsername: null,
        doctorFirstName: null,
        doctorLastName: null,
      };
    } else {
      updatedFields = {
        doctorId: event.doctorId,
        doctorUsername: event.doctoUsername,
        doctorFirstName: event.doctorFirstName,
        doctorLastName: event.doctorLastName,
        diagnosis: KenshinCaseDiagnosis.TO_BE_DIAGNOSED,
        diagnosticNote: null,
      };
    }
    setConfirmModalUpdateData(updatedFields);
    setConfirmModalOpen(true);
  };

  const doctorOptions = doctors.map((doctor) => ({
    value: doctor.id,
    label: getUserFullName(doctor.firstName, doctor.lastName),
    doctorId: doctor.id,
    doctorUsername: doctor.username,
    doctorFirstName: doctor.firstName,
    doctorLastName: doctor.lastName,
  }));

  return (
    <Fragment>
      <Stack direction="column" mt={2}>
        <SectionTitle>
          {intl.get('issue_details_text_doctor_in_charge')}
        </SectionTitle>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isClearable
          isSearchable
          defaultValue={doctorOptions[currentDoctorIndex]}
          // defaultValue={}
          // value={}
          name={intl.get(
            'kenshin_issue_details_case_batch_edit_modal_assign_title'
          )}
          placeholder={intl.get(
            'kenshin_issue_details_case_batch_edit_modal_assign_place_holder'
          )}
          options={doctorOptions}
          onChange={handleChange}
        />
      </Stack>
    </Fragment>
  );
};

DoctorSelect.propTypes = propTypes;
DoctorSelect.displayName = 'DoctorSelect';

export default DoctorSelect;
