import React, { useState } from 'react';

import { copyToClipboard } from 'shared/utils/browser';
import { Button } from 'components';

const CopyLinkButton = ({ ...buttonProps }) => {
  const [isLinkCopied, setLinkCopied] = useState(false);
  const { serviceType } = buttonProps;

  const handleLinkCopy = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    const origin = window.location.origin;
    const path = window.location.pathname;
    const pathList = path.split('/');
    const issueId = pathList[pathList.length - 1];
    // let link = origin + '/browse/issues/' + issueId;
    let link = `${origin}/browse/issues/${issueId}?serviceType=${serviceType}`;
    if (pathList[1] === 'research') {
      link = window.location.href;
    }
    // copyToClipboard(window.location.href);
    copyToClipboard(link);
  };

  return (
    <Button icon="link" onClick={handleLinkCopy} {...buttonProps}>
      {isLinkCopied ? 'Link Copied' : 'Copy link'}
    </Button>
  );
};

export default CopyLinkButton;
