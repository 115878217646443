/**
 * Iteratively filters an array of data objects based on a sequence of filter conditions.
 * Each filter condition is applied in order, with each subsequent filter being applied
 * to the result of the previous filter, potentially reducing the dataset size with each step.
 *
 * @param {Object[]} data - The initial array of data objects to be filtered.
 * @param {Object[]} filters - An array of filter conditions, where each condition
 *                             specifies the `id` (property name) to filter on and
 *                             the `value` to match against.
 * @returns {Object[]} A new array of data objects that meet all the filter conditions.
 *                     If a data object's property value includes the filter value
 *                     for every filter condition, it is included in the returned array.
 *
 * @example:
 * Filters data for entries where the diagnostic result is 'DIAGNOSED_HEALTHY',
 * the modality is 'DX', and the doctor in charge includes 'yamamoto'.
 * filterData(allData, [
 *   { id: "diagnosticResult", value: "DIAGNOSED_HEALTHY" },
 *   { id: "modality", value: "DX" },
 *   { id: "doctorInCharge", value: "yamamoto" }
 * ]);
 */
export const filterData = (data, filters) => {
  return filters.reduce((filteredData, filter) => {
    return filteredData.filter((item) => {
      // if (filter.id === 'index') {
      if (filter.id === 'displayIndex') {
        // value could be [ "50", "100" ], [ "50", undefined ], [ "50", "" ], ["", "100"], [undefined, "100"];
        // compare numbers
        const itemValue = item[filter.id];
        const filterValue = filter.value;
        const from = filterValue[0] === undefined ? 0 : Number(filterValue[0]);
        const to = filterValue[1] === undefined ? 0 : Number(filterValue[1]);

        if (from === 0) {
          // from is not a valid number
          if (to === 0) {
            // `from` is "" or undefined;  `to` is "" or undefined;
            return true; // return all data;
          } else {
            // `from` is "" or undefined;  `to` is a number;
            return itemValue <= to;
          }
        } else {
          // `from` is a valid number
          if (to === 0) {
            // to is not a valid number;
            return from <= itemValue;
          } else {
            // both `from` and `to` are valid numbers
            return from <= itemValue && itemValue <= to;
          }
        }
      } else if (filter.id === 'doctorId') {
        const itemValue = item[filter.id];
        const filterValue = filter.value;
        return itemValue === filterValue;
      } else {
        // compare strings
        const itemValue = String(item[filter.id]).toLowerCase();
        const filterValue = filter.value.toLowerCase();
        return itemValue.includes(filterValue);
      }
    });
  }, data);
};

/**
 * Creates an object with keys from 0 to n-1, all set to true.
 * Useful for initializing objects with boolean flags.
 *
 * @param {number} n - The number of keys to generate in the object. The generated
 *                     object will have keys from 0 to n-1.
 * @returns {Object} An object with keys ranging from 0 to n-1, where each key's
 *                   value is true.
 * @example
 * const result = generateTrueObject(5);
 * result: { 0: true, 1: true, 2: true, 3: true, 4: true }
 */
export const generateTrueObject = (n) =>
  Object.fromEntries(Array.from({ length: n }, (_, i) => [i, true]));

/**
 * Creates an object with keys based on the 'index' attribute of each item in the input array.
 * Each key is mapped to `true`.
 *
 * @param {Object[]} data - The array of objects to process.
 * @returns {Object} An object with keys from the 'index' attribute of each item, each set to true.
 */
export const generateRowSelectionObject = (data) => {
  return data.reduce((acc, { index }) => ({ ...acc, [index]: true }), {});
};
