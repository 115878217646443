export const STATUS = {
  INIT: 'INIT',
  REPLACE: 'REPLACE',
  IGNORE: 'IGNORE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  UPLOAD_ABORTED: 'UPLOAD_ABORTED',
  UPLOAD_FAILED: 'UPLOAD_FAILED',
  ERR_CANCELED: 'ERR_CANCELED',
  PROCESS_COMPLETE: 'PROCESS_COMPLETE',
  PROCESS_FAILED: 'PROCESS_FAILED',
};
export const IssueStatus = {
  COMPLETE: 'Complete',
  FAILED: 'Failed',
};

export const MISSION = {
  ISSUE: 'ISSUE',
  NEW_ATTACHMENTS_ON_EXISTING_ISSUE: 'NEW_ATTACHMENTS_ON_EXISTING_ISSUE',
  NEW_RESEARCH_CASE: 'NEW_RESEARCH_CASE',
  NEW_ATTACHMENTS_ON_EXISTING_RESEARCH_CASE:
    'NEW_ATTACHMENTS_ON_EXISTING_RESEARCH_CASE',
};
