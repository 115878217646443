import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {};

const InvoiceFooter = () => {
  return (
    <Fragment>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Fragment>
  );
};

InvoiceFooter.propTypes = propTypes;

export default InvoiceFooter;
