import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';

import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { PageLoader, PageError, Modal } from 'components';

import Header from '../components/Header';
import ItemCard from '../components/ItemCard';
import ProjectInfoCard from '../components/ProjectInfoCard';
import MemberList from '../components/MemberList';
import NewCase from '../components/NewCase';
import CaseDetails from '../components/CaseDetails';
// import memberListData from '../dummyData/memberListData';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
  projectId: PropTypes.number.isRequired,
};

const DashboardResearchProjectsDetails = ({ match, history, projectId }) => {
  const [newCaseModalOpen, setNewCaseModalOpen] = useState(false);
  const [{ data, error, setLocalData }] = useApi.get(
    `${DOLPHIN_API.RESEARCH_PROJECTS}/${projectId}`,
    {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      },
    }
  );
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { title, description, creator, affiliation, members, researchCases } =
    data.researchProject;

  const memberListData = members.map((member, index) => ({
    name: getUserFullName(member.firstName, member.lastName),
    description: member.email,
    action: {
      type: 'internal',
      route: '/pages/profile/profile-overview',
      userId: member.username,
      color: 'info',
      label: 'CHAT',
    },
  }));

  return (
    <Header title={title} subTitle={`${researchCases.length} cases in total`}>
      {/* <MDBox pt={2} px={2} lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium">
          Projects
        </MDTypography>
        <MDBox mb={1}>
          <MDTypography variant="button" color="text">
            Architects design houses
          </MDTypography>
        </MDBox>
      </MDBox> */}
      <MDBox p={2} mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={6} sx={{ display: 'flex' }}>
            {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
            <ProjectInfoCard
              title={title}
              description={description}
              info={{
                host: getUserFullName(creator.firstName, creator.lastName),
                mobile: '(81) 123 1234 123',
                email: creator.email,
                organization: affiliation.name,
              }}
              action={{ route: '', tooltip: 'Edit Profile' }}
              shadow={false}
            />
            {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
          </Grid>
          <Grid item xs={12} xl={6}>
            <MemberList
              title="Members"
              members={memberListData}
              shadow={false}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={2} px={2} lineHeight={1.25}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h6">Cases</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                Here is a list of all the cases
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={5} sx={{ textAlign: 'right' }}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setNewCaseModalOpen(true);
              }}>
              <Icon>add</Icon>&nbsp; Add New Case
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={6}>
          {researchCases.map((researchCase) => (
            <Grid key={researchCase.id} item xs={12} md={6} xl={3}>
              <ItemCard
                label={title}
                title={researchCase.title}
                description={researchCase.descriptionText}
                action={{
                  type: 'internal',
                  route: `${match.url}/${researchCase.id}`,
                  color: 'info',
                  label: 'view case',
                }}
                // authors={[
                //   { image: team1, name: 'Elena Morison' },
                //   { image: team2, name: 'Ryan Milly' },
                //   { image: team3, name: 'Nick Daniel' },
                //   { image: team4, name: 'Peterson' },
                // ]}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
      <Route
        path={`${match.path}/:researchCaseId`}
        render={(routeProps) => (
          <Modal
            isOpen
            testId="modal:case-details"
            width={1240}
            withCloseIcon={false}
            onClose={() => history.push(match.url)}
            renderContent={(modal) => (
              <CaseDetails
                researchCaseId={routeProps.match.params.researchCaseId}
                issueId={56}
                trigger={'byClick'}
                projectUsers={[]}
                updateLocaProjectIssues={(a, b) => {}}
                modalClose={modal.close}
              />
            )}
          />
        )}
      />
      {newCaseModalOpen && (
        <Modal
          isOpen
          testId="modal:new-project"
          width={1240}
          withCloseIcon={false}
          onClose={() => {
            setNewCaseModalOpen(false);
          }}
          renderContent={(modal) => (
            <NewCase researchProject={data.researchProject} />
          )}
        />
      )}
    </Header>
  );
};

DashboardResearchProjectsDetails.propTypes = propTypes;

export default withRouter(DashboardResearchProjectsDetails);
