import intl from 'react-intl-universal';

export const UserType = {
  DOCTOR: 'doctor',
  STAFF: 'staff',
  MANAGER: 'manager',
};

export const getUserType = (userType) => {
  if (userType === UserType.DOCTOR) {
    return intl.get('shared_value_user_type_doctor');
  } else if (userType === UserType.STAFF) {
    return intl.get('shared_value_user_type_staff');
  } else if (userType === UserType.MANAGER) {
    return intl.get('shared_value_user_type_manager');
  }
};

export const LANGUAGE = {
  ENGLISH: 'en-US',
  JAPANSESE: 'ja-JP',
  CHINESE: 'zh-CN',
};

export const TIMEZONE = {
  CHINA: 'CST',
  JAPAN: 'JST',
};

export const USER_DATA = {
  FIRST_NAME: 'userFirstName',
  LAST_NAME: 'userLastName',
  USER_TYPE: 'userType',
  USER_ID: 'userId',
  CURRENT_USER_ID: 'currentUserId',
  CURRENT_ORGANIZATION_ID: 'currentOrganizationId',
  USERNAME: 'username',
  AVATAR_URL: 'userAvatarUrl',
  PREFFERED_LANGUAGE: 'userPreferredLanguage',
  EMAIL: 'userEmail',
  CURRENT_ORGANIZATION: 'currentOrganization',
  TIMEZONE: 'preferredTimezone',
  IS_OWNER: 'isOwner',
  CURRENT_PRIVILEGE: 'currentPrivilege',
};
