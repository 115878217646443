import queryString from 'query-string';
import omit from 'lodash/omit';

export const queryStringToObject = (str, options = {}) => {
  return queryString.parse(str, {
    arrayFormat: 'bracket',
    ...options,
  });
};

export const objectToQueryString = (obj, options = {}) => {
  return queryString.stringify(obj, {
    arrayFormat: 'bracket',
    ...options,
  });
};

export const omitFromQueryString = (str, keys) => {
  return objectToQueryString(omit(queryStringToObject(str), keys));
};

export const addToQueryString = (str, fields) => {
  return objectToQueryString({
    ...queryStringToObject(str),
    ...fields,
  });
};
