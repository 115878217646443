export const imageExtension = new Set(['jpg', 'jpeg', 'png', 'gif']);

export const getFileExtension = (fileName) => {
  var fileExtension;
  fileExtension = fileName.replace(/^.*\./, '').toLowerCase();
  if (fileExtension === 'jpg') fileExtension = 'jpeg';
  return fileExtension;
};

export const isImage = (fileName) => {
  const extension = getFileExtension(fileName);
  return imageExtension.has(extension);
};

export const isPdf = (fileName) => {
  const extension = getFileExtension(fileName);
  return extension === 'pdf';
};
