import { styled } from '@mui/material/styles';

export const Emergency1Hour = styled('span')({
  color: 'white',
  background: 'darkred',
  border: 'thick solid darkred',
  fontWeight: 'bold',
});

export const Emergency3Hours = styled('span')({
  color: 'white',
  background: 'red',
  border: 'thick solid red',
  fontWeight: 'bold',
});

export const Emergency24Hours = styled('span')({
  color: 'white',
  background: 'gold',
  border: 'thick solid gold',
  fontWeight: 'bold',
});

export const EmergencyRegular = styled('span')({});
