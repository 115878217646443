import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';

import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { PageLoader, PageError, Modal } from 'components';
import Header from '../components/Header';
import ItemCard from '../components/ItemCard';
import NewProject from '../components/NewProject';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const DashboardResearchProjectsAll = ({ match, history }) => {
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [{ data, error, setLocalData }] = useApi.get(
    `${DOLPHIN_API.RESEARCH_PROJECTS}/all`,
    {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      },
    }
  );
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  return (
    <Header title={'All Projects'} subTitle="2 projects in total">
      <MDBox pt={2} px={2} lineHeight={1.25}>
        {/* <MDTypography variant="h6" fontWeight="medium">
          Projects
        </MDTypography>
        <MDBox mb={1}>
          <MDTypography variant="button" color="text">
            Architects design houses
          </MDTypography>
        </MDBox> */}
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                Some of Our Awesome Projects
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                This is the paragraph where you can write more details about
                your projects. Keep you user engaged by providing meaningful
                information.
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={5} sx={{ textAlign: 'right' }}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setNewProjectModalOpen(true);
              }}>
              <Icon>add</Icon>&nbsp; Add New Project
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={6}>
          {/* <Grid item xs={12} md={6} xl={3}>
            <ItemCard
              label="Project"
              title="Joint Research (Jikei) "
              description="This is a joint research on Medical Image Processing hosted by Jikei University"
              action={{
                type: 'internal',
                route: `${match.url}/1`,
                color: 'info',
                label: 'view project',
              }}
              // authors={[
              //   { image: team1, name: 'Elena Morison' },
              //   { image: team2, name: 'Ryan Milly' },
              //   { image: team3, name: 'Nick Daniel' },
              //   { image: team4, name: 'Peterson' },
              // ]}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <ItemCard
              label="Project"
              title="Joint Research (Todai)"
              description="This is a joint research on Literature hosted by The University of Tokyo"
              action={{
                type: 'internal',
                route: `${match.url}/2`,
                color: 'info',
                label: 'view project',
              }}
              // authors={[
              //   { image: team1, name: 'Elena Morison' },
              //   { image: team2, name: 'Ryan Milly' },
              //   { image: team3, name: 'Nick Daniel' },
              //   { image: team4, name: 'Peterson' },
              // ]}
            />
          </Grid> */}
          {data.researchProjects.map((project, index) => {
            return (
              <Grid key={index} item xs={12} md={6} xl={3}>
                <ItemCard
                  label="Project"
                  title={project.title}
                  description={project.description}
                  action={{
                    type: 'internal',
                    route: `${match.url}/${project.id}`,
                    color: 'info',
                    label: 'view project',
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
      {newProjectModalOpen && (
        <Modal
          isOpen
          testId="modal:new-project"
          width={1240}
          withCloseIcon={false}
          onClose={() => {
            setNewProjectModalOpen(false);
          }}
          renderContent={(modal) => <NewProject />}
        />
      )}
    </Header>
  );
};

DashboardResearchProjectsAll.propTypes = propTypes;

export default withRouter(DashboardResearchProjectsAll);
