import localData from './localData';
import { isWesternOrder, isEasternOrder } from './language';
import { USER_DATA } from 'shared/constants/users';

const getUserFullName = (...args) => {
  let firstName = localData.get(USER_DATA.FIRST_NAME);
  let lastName = localData.get(USER_DATA.LAST_NAME);
  let preferredLanguage = localData.get(USER_DATA.PREFFERED_LANGUAGE)
    ? localData.get(USER_DATA.PREFFERED_LANGUAGE)
    : 'en-US';
  if (args.length >= 2) {
    firstName = args[0];
    lastName = args[1];
  }
  if (args.length >= 3) {
    preferredLanguage = args[2];
  }
  return generateUserFullName(firstName, lastName, preferredLanguage);
};

const generateUserFullName = (firstName, lastName, preferredLanguage) => {
  if (!preferredLanguage) {
    preferredLanguage = localData.get(USER_DATA.PREFFERED_LANGUAGE)
      ? localData.get(USER_DATA.PREFFERED_LANGUAGE)
      : 'en-US';
  }

  if (isWesternOrder(preferredLanguage)) {
    return firstName + ' ' + lastName;
  }
  if (isEasternOrder(preferredLanguage)) {
    return lastName + ' ' + firstName;
  }
  return firstName + ' ' + lastName;
};

const getSavedName = () => {
  const firstName = localData.get(USER_DATA.FIRST_NAME);
  const lastName = localData.get(USER_DATA.LAST_NAME);
  return { firstName, lastName };
};

const setSavedName = (firstName, lastName) => {
  localData.set(USER_DATA.FIRST_NAME, firstName);
  localData.set(USER_DATA.LAST_NAME, lastName);
};

export { getUserFullName, generateUserFullName, getSavedName, setSavedName };
