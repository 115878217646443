import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoicePaymentMethod: PropTypes.shape({
    bank: PropTypes.string,
    branch: PropTypes.string,
    accountType: PropTypes.string,
    accountNo: PropTypes.string,
    accountName: PropTypes.string,
  }),
};

const InvoicePaymentMethod = ({ invoicePaymentMethod }) => {
  const { bank, branch, accountType, accountNo, accountName } =
    invoicePaymentMethod;

  return (
    <Fragment>
      <View style={styles.invoicePaymentMethod}>
        <Text style={styles.invoicePaymentMethodText}>
          {intl.get('billing_invoice_text_transfer_destination')}
        </Text>
        <Text style={styles.invoicePaymentMethodText}>
          {bank + '  ' + branch}
        </Text>
        <Text style={styles.invoicePaymentMethodText}>
          {accountType + '  ' + accountNo}
        </Text>
        <Text style={styles.invoicePaymentMethodText}>{accountName}</Text>
      </View>
    </Fragment>
  );
};
InvoicePaymentMethod.propTypes = propTypes;

export default InvoicePaymentMethod;
