import { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import List from '@mui/material/List';
// components for Sidenav
import SidenavCollapse from '../SidenavCollapse';
import SidenavList from '../SidenavList';
import SidenavItem from '../SidenavItem';
import PersonIcon from '@mui/icons-material/Person';

import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { UserProptypes } from 'shared/propTypes/userType';
import { USER_DATA } from 'shared/constants/users';

const propTypes = {
  color: PropTypes.string,
  userInfo: PropTypes.shape(UserProptypes),
  handleClickLogout: PropTypes.func,
};
function ProfileMenu({ color, userInfo, handleClickLogout }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const items = pathname.split('/').slice(1);
  const itemName = items[items.length - 1];
  const key = 'profile';
  const userFirstName = userInfo.firstName;
  const userLastName = userInfo.lastName;
  const preferredLanguage = userInfo.preferredLanguage;
  const userFullName = getUserFullName(
    userFirstName,
    userLastName,
    preferredLanguage
  );
  let OmittedUserFullName = userFullName;
  const byteSize = (str) => new Blob([str]).size;
  if (
    byteSize(OmittedUserFullName[0]) === 1 &&
    OmittedUserFullName.length > 15
  ) {
    OmittedUserFullName = userFullName.substring(0, 14) + '...';
  } else if (
    byteSize(OmittedUserFullName[0]) === 3 &&
    OmittedUserFullName.length > 9
  ) {
    OmittedUserFullName = userFullName.substring(0, 8) + '...';
  }

  const currentOrganization = localData.get(USER_DATA.CURRENT_ORGANIZATION);
  const organizations = userInfo.organizations;

  const changeCurrentOrganization = (newOrganization, newOrganizationId) => {
    localData.set(USER_DATA.CURRENT_ORGANIZATION, newOrganization);
    localData.set(USER_DATA.CURRENT_ORGANIZATION_ID, newOrganizationId);
    // TODO: set new value to IS_OWNER and CURRENT_PRIVILEGE
    const currentOrganization = userInfo.organizations.filter(
      (organization) => organization.id === newOrganizationId
    );
    localData.set(
      USER_DATA.IS_OWNER,
      currentOrganization.ownerId === userInfo.id
    );
    const currentEmployment = userInfo.employments.find(
      (employment) => employment.organizationId === newOrganizationId
    );
    localData.set(
      USER_DATA.CURRENT_PRIVILEGE,
      currentEmployment ? currentEmployment.privilege : {}
    );
    // reaload window for rerender sidebar
    window.location.reload();
  };

  return (
    <List>
      <SidenavCollapse
        key={key}
        name={OmittedUserFullName}
        icon={<PersonIcon fontSize="large" />}
        active={openCollapse}
        open={openCollapse}
        onClick={() =>
          openCollapse ? setOpenCollapse(false) : setOpenCollapse(true)
        }>
        <SidenavList key={'personal-settings'}>
          <NavLink
            to={'/personal-settings'}
            key={'personal-settings'}
            sx={{ textDecoration: 'none' }}>
            <SidenavItem
              color={color}
              name={intl.get('dashboard_button_personal_settings')}
              active={key === itemName}
            />
          </NavLink>
        </SidenavList>

        <SidenavList key={'organization'}>
          <SidenavItem
            key={'organization'}
            color={color}
            name={
              currentOrganization !== 'null'
                ? currentOrganization
                : intl.get('dashboard_button_no_organization')
            }
            active={openNestedCollapse}
            open={openNestedCollapse}
            onClick={({ currentTarget }) =>
              openNestedCollapse &&
              currentTarget.classList.contains('MuiListItem-root')
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(true)
            }>
            {organizations &&
              organizations.map((org, index) => (
                <SidenavItem
                  key={org.id}
                  name={org.name}
                  active={false}
                  nested
                  onClick={() => changeCurrentOrganization(org.name, org.id)}
                />
              ))}
          </SidenavItem>
        </SidenavList>

        <SidenavList key={'logout'}>
          <SidenavItem
            color={color}
            name={intl.get('dashboard_button_logout')}
            onClick={handleClickLogout}
          />
        </SidenavList>
      </SidenavCollapse>
    </List>
  );
}
ProfileMenu.propTypes = propTypes;
export default ProfileMenu;
