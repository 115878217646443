import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { IssueStatus } from 'shared/constants/issues';
import { sortByNewest } from 'shared/utils/javascript';

import Create from './Create';
import Comment from './Comment';
import { Comments, Title } from './Styles';

const propTypes = {
  researchCase: PropTypes.object.isRequired,
  fetchResearchCase: PropTypes.func.isRequired,
  updateLocalResearchCaseDetails: PropTypes.func,
  handleDownloadButtonClick: PropTypes.func,
  handlePreviewButtonClick: PropTypes.func,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    // Quill object
    current: PropTypes.object,
  }),
  isCommentFormOpen: PropTypes.bool,
  setIsCommentFormOpen: PropTypes.func,
  handleBlockQuoteTextClick: PropTypes.func,
  handleAddAttachmentButtonClick: PropTypes.func,
};

const DashboardResearchCaseDetailsComments = ({
  researchCase,
  fetchResearchCase,
  updateLocalResearchCaseDetails,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
  isCommentFormOpen,
  setIsCommentFormOpen,
  handleBlockQuoteTextClick,
  handleAddAttachmentButtonClick,
}) => (
  <Comments>
    <Title>{intl.get('issue_details_text_comments')}</Title>
    <Create
      researchProjectId={researchCase.researchProject.id}
      researchCaseId={researchCase.id}
      fetchResearchCase={fetchResearchCase}
      updateLocalResearchCaseDetails={updateLocalResearchCaseDetails}
      quoteAttachmentName={quoteAttachmentName}
      refreshQuote={refreshQuote}
      commentEditorRef={commentEditorRef}
      isCommentFormOpen={isCommentFormOpen}
      setIsCommentFormOpen={setIsCommentFormOpen}
      handleAddAttachmentButtonClick={handleAddAttachmentButtonClick}
    />

    {sortByNewest(researchCase.comments, 'createdAt').map((comment) => (
      <Comment
        key={comment.id}
        comment={comment}
        fetchResearchCase={fetchResearchCase}
        handleBlockQuoteTextClick={handleBlockQuoteTextClick}
      />
    ))}
  </Comments>
);

DashboardResearchCaseDetailsComments.propTypes = propTypes;

export default DashboardResearchCaseDetailsComments;
