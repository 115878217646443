import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Avatar, Select, Icon, Button } from 'components';
import { SectionTitle } from '../Styles';
// import { User, Username, Quota } from './Styles';
import { withRouter } from 'react-router-dom';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';

const propTypes = {
  prevIssueId: PropTypes.number.isRequired,
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const ProjectBoardIssueDetailsPreviousIssue = ({
  prevIssueId,
  match,
  history,
}) => {
  return (
    <Fragment>
      <SectionTitle>
        {intl.get('issue_details_prev_issue_text_previous_issue')}
      </SectionTitle>

      <Button
        variant="primary"
        icon="issues"
        iconSize={20}
        sx={{ justifyContent: 'start' }}
        onClick={() => {
          history.push(`${match.url}/previous-issue/${prevIssueId}`);
        }}>
        {/* {'Click to open previous issue'} */}
        {intl.get('issue_details_prev_issue_button_go_to_prev_issue')}
      </Button>
    </Fragment>
  );
};

ProjectBoardIssueDetailsPreviousIssue.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetailsPreviousIssue);
