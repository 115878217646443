import intl from 'react-intl-universal';
import { Button, Typography } from '@material-ui/core';
import { Operations, Operation } from './style';

export const getFriendsTableColumns = (
  openChatWithUser,
  setDeleteFriendConfirmModalOpen,
  setDeleteFriendUserID
) => [
  {
    name: 'show_name',
    label: intl.get('friends_label_column_user_nickname'),
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'userID',
    label: intl.get('friends_label_column_user_id'),
    options: {
      filter: true,
      filterType: 'textField',
    },
  },
  {
    name: 'online_status',
    label: intl.get('friends_label_column_online_status'),
    options: {
      filter: true,
      filterType: 'textField',
    },
  },
  {
    name: 'Operation',
    label: intl.get('friends_label_column_operations'),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updatedValue) => {
        const userID = value;
        return (
          <Operations>
            <Operation>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  openChatWithUser(userID);
                }}>
                {intl.get('friends_button_send_message')}
              </Button>
            </Operation>
            <Operation>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // deleteFriend(userID);
                  setDeleteFriendUserID(userID);
                  setDeleteFriendConfirmModalOpen(true);
                }}>
                {intl.get('friends_button_delete_friend')}
              </Button>
            </Operation>
          </Operations>
        );
      },
    },
  },
];
