import React, { Fragment, useState, useEffect, useCallback } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import CornerstoneViewerport from 'react-cornerstone-viewport';
import cornerstone from 'cornerstone-core';
import { withRouter } from 'react-router';

import { Modal } from '@mui/material';
import { PageLoader } from 'components';

import { useStyles } from './styles';

const checkIfLoaded = (imageIds, imageId) => {
  return imageIds.includes(imageId);
};

// TODO
const propTypes = {
  dicomFilePaths: PropTypes.arrayOf(
    PropTypes.shape({
      filePath: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const ViewerViewport = ({ dicomFilePaths }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  const imageIds = dicomFilePaths.map((filePath) => {
    return `dicomweb${filePath.substring(5)}`;
  });

  // TODO: Temporary
  const imageLoadedHandler = useCallback(
    (event) => {
      const ifLoaded = checkIfLoaded(imageIds, event.detail.image.imageId);
      if (ifLoaded) {
        setLoaded(true);
      }
    },
    [imageIds]
  );

  useEffect(() => {
    cornerstone.events.addEventListener(
      cornerstone.EVENTS.IMAGE_LOADED,
      imageLoadedHandler
    );

    return () => {
      cornerstone.events.removeEventListener(
        cornerstone.EVENTS.IMAGE_LOADED,
        imageLoadedHandler
      );
    };
  }, [imageLoadedHandler]);

  useEffect(() => {
    setLoaded(false);
    const cachedImages = cornerstone.imageCache.cachedImages;
    imageIds.forEach((imageId) => {
      cachedImages.forEach((image) => {
        // TODO:
        if (image.imageId === imageId && image.loaded) {
          setLoaded(true);
          return;
        }
      });
    });
    // setLoaded(false);
  }, [imageIds]);

  // TODO Might need translate or make it into a constant
  const tools = [
    {
      name: 'Wwwc',
      mode: 'active',
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: 'Zoom',
      mode: 'active',
      modeOptions: { mouseButtonMask: 2 },
    },
    {
      name: 'Pan',
      mode: 'active',
      modeOptions: { mouseButtonMask: 4 },
    },
    {
      name: 'StackScrollMouseWheel',
      mode: 'active',
    },
    {
      name: 'PanMultiTouch',
      mode: 'active',
    },
    {
      name: 'ZoomTouchPinch',
      mode: 'active',
    },
    {
      name: 'StackScrollMultiTouch',
      mode: 'active',
    },
  ];

  return (
    <Fragment>
      <CornerstoneViewerport imageIds={imageIds} />
      <Modal open={!loaded}>
        <PageLoader />
      </Modal>
    </Fragment>
  );
};

ViewerViewport.propTypes = propTypes;

export default withRouter(ViewerViewport);
