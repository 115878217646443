import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser'; // processNodes // convertNodeToElement,
import api from 'shared/utils/api';
import { formatDateTimeConversational } from 'shared/utils/dateTime';
import { ConfirmModal } from 'components';
import localData from 'shared/utils/localData';

import { UserContextMenu } from 'components/Menu';

import {
  Comment,
  UserAvatar,
  Content,
  Username,
  CreatedAt,
  HTMLParsedBody,
  DeleteLink,
} from './Styles';
import './styles.css';
import { getUserFullName } from 'shared/utils/name';
import { USER_DATA } from 'shared/constants/users';
import { LOCAL } from 'shared/constants/comment';
import { CommentProptypes } from 'shared/propTypes/commentType';
import { DOLPHIN_API } from 'shared/constants/apis';

const NODE_TYPE = {
  TAG: 'tag',
  SPAN: 'span',
  BLOCKQUOTE: 'blockquote',
};

const propTypes = {
  comment: PropTypes.shape(CommentProptypes).isRequired,
  fetchResearchCase: PropTypes.func.isRequired,
  handleBlockQuoteTextClick: PropTypes.func,
};

const DashboardResearchCaseDetailsComment = ({
  comment,
  fetchResearchCase,
  handleBlockQuoteTextClick,
}) => {
  const username = localData.get(USER_DATA.USERNAME);
  const currentOrganizationId = localData.get(
    USER_DATA.CURRENT_ORGANIZATION_ID
  );
  const commentUserFullName = getUserFullName(
    comment.user.firstName,
    comment.user.lastName
  );
  const commentUsername = comment.user.username;
  const isSelf = commentUsername === username;

  const handleCommentDelete = async () => {
    try {
      await api.delete(`${DOLPHIN_API.RESEARCH_COMMENTS}/${comment.id}`, {
        username: username,
        organizationId: currentOrganizationId,
        params: {
          organizationId: currentOrganizationId,
        },
      });
      await fetchResearchCase();
    } catch (error) {
      console.error(error);
    }
  };

  const onBlockquoteTextClick = (e) => {
    handleBlockQuoteTextClick(e.target.innerText);
  };

  const transform = (node, index) => {
    // return null to block certain elements.
    // e.g. don't allow <span> elements:
    if (node.type === NODE_TYPE.TAG && node.name === NODE_TYPE.SPAN) {
      return null;
    }
    if (node.type === NODE_TYPE.TAG && node.name === NODE_TYPE.BLOCKQUOTE) {
      return (
        <blockquote className="blockquote" key={index}>
          <div className="blockquoteText" onClick={onBlockquoteTextClick}>
            {node.children[0].data}
          </div>
        </blockquote>
      );
    }
  };

  const ReactHtmlParserOptions = {
    decodeEntities: true,
    transform,
  };
  return (
    <Comment data-testid="issue-comment">
      {/* <UserAvatar name={comment.user.username} avatarUrl={comment.user.avatarUrl} /> */}
      {isSelf && <UserAvatar name={commentUserFullName} />}
      {!isSelf && (
        <UserContextMenu
          username={commentUsername}
          userShowName={commentUserFullName}>
          <UserAvatar name={commentUserFullName} />
        </UserContextMenu>
      )}
      <Content>
        {isSelf && (
          <Username>
            {commentUserFullName +
              ' ' +
              (comment.domainType === LOCAL
                ? intl.get('issue_details_comment_label_published_locally')
                : intl.get('issue_details_comment_label_published_globally'))}
          </Username>
        )}
        {!isSelf && (
          <UserContextMenu
            username={commentUsername}
            userShowName={commentUserFullName}>
            <Username>
              {commentUserFullName +
                ' ' +
                (comment.domainType === LOCAL
                  ? intl.get('issue_details_comment_label_published_locally')
                  : intl.get('issue_details_comment_label_published_globally'))}
            </Username>
          </UserContextMenu>
        )}
        <CreatedAt>{formatDateTimeConversational(comment.createdAt)}</CreatedAt>

        <Fragment>
          <div>
            <HTMLParsedBody>
              {ReactHtmlParser(comment.body, ReactHtmlParserOptions)}
            </HTMLParsedBody>
          </div>
          {username === comment.user.username && (
            <ConfirmModal
              title={intl.get(
                'issue_details_confirm_modal_text_delete_comment_title'
              )}
              message={intl.get(
                'issue_details_confirm_modal_text_delete_comment_message'
              )}
              confirmText={intl.get(
                'issue_details_confirm_modal_button_delete_comment_confirm_text'
              )}
              cancelText={intl.get(
                'issue_details_confirm_modal_button_delete_comment_cancel_text'
              )}
              onConfirm={handleCommentDelete}
              renderLink={(modal) => (
                <DeleteLink onClick={modal.open}>Delete</DeleteLink>
              )}
            />
          )}
        </Fragment>
      </Content>
    </Comment>
  );
};

DashboardResearchCaseDetailsComment.propTypes = propTypes;

export default DashboardResearchCaseDetailsComment;
