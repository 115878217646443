import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const InvoiceDetailedTitle = ({ invoiceContent }) => {
  return (
    <Fragment>
      <View style={styles.invoiceDetailedTitle}>
        <Text style={styles.invoiceDetailedTitleText}>
          {intl.get('billing_invoice_text_detailed_title') +
            invoiceContent.invoiceDetailedTitle}
        </Text>
      </View>
    </Fragment>
  );
};

InvoiceDetailedTitle.propTypes = propTypes;

export default InvoiceDetailedTitle;
