import { makeStyles } from '@material-ui/core';
import { StyleSheet, Font } from '@react-pdf/renderer';

const publicUrl = process.env.PUBLIC_URL;

Font.register({
  family: 'NotoSansJP',
  fonts: [
    {
      src: `${publicUrl}/static/font/Noto_Sans_JP/NotoSansJP-Black.otf`,
      fontWeight: 'black',
    },
    {
      src: `${publicUrl}/static/font/Noto_Sans_JP/NotoSansJP-Bold.otf`,
      fontWeight: 'bold',
    },
    {
      src: `${publicUrl}/static/font/Noto_Sans_JP/NotoSansJP-Light.otf`,
      fontWeight: 'light',
    },
    {
      src: `${publicUrl}/static/font/Noto_Sans_JP/NotoSansJP-Medium.otf`,
      fontWeight: 'medium',
    },
    {
      src: `${publicUrl}/static/font/Noto_Sans_JP/NotoSansJP-Regular.otf`,
      fontWeight: 'normal',
    },
    {
      src: `${publicUrl}/static/font/Noto_Sans_JP/NotoSansJP-Thin.otf`,
      fontWeight: 'thin',
    },
  ],
});

Font.register({
  family: 'NotoSansRegular',
  src: `${publicUrl}/static/font/NotoSans-Regular.ttf`,
});

Font.register({
  family: 'NasuRegular',
  src: `${publicUrl}/static/font/Nasu-Regular.ttf`,
});

Font.register({
  family: 'Nasu',
  fonts: [
    {
      src: `${publicUrl}/static/font/Nasu/Nasu-Regular-20200227.ttf`,
      fontWeight: 'normal',
    },
    {
      src: `${publicUrl}/static/font/Nasu/Nasu-Bold-20200227.ttf`,
      fontWeight: 'bold',
    },
  ],
});

export const useStyles = makeStyles((theme) => ({
  pdfViewer: {
    //
  },
}));

export const styles = StyleSheet.create({
  invoiceHeader: {
    fontSize: 12,
    textAlign: 'center',
    color: 'grey',
  },
  invoiceFirstPage: {
    fontSize: 10,
    paddingTop: 45,
    paddingBottom: 60,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  invoiceTitleContainer: {
    marginTop: 10,
  },
  invoiceTitleText: {
    fontFamily: 'Nasu',
    fontWeight: 'bold',
    letterSpacing: 4,
    fontSize: 22,
    textAlign: 'center',
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'flex-end',
  },
  invoiceDateLabel: {
    font: 10,
    fontFamily: 'Nasu',
    fontWeight: 'normal',
  },
  invoiceDateText: {
    font: 12,
    fontFamily: 'Nasu',
  },
  invoiceTo: {
    // flexDirection: 'row',
    marginTop: 15,
    padding: 5,
  },
  invoiceToText: {
    fontSize: 15,
    fontFamily: 'Nasu',
    fontWeight: 'bold',
  },
  invoiceToText2: {
    fontSize: 12,
    fontFamily: 'Nasu',
  },
  invoiceFrom: {
    flexDirection: 'row',
    // marginTop: 15,
    justifyContent: 'flex-end',
  },
  invoiceFromText: {
    fontSize: 10,
    fontFamily: 'Nasu',
  },
  invoiceGreetings: {
    flexWrap: 'wrap',
    marginTop: 10,
  },
  invoiceGreetingsText: {
    fontFamily: 'Nasu',
  },
  invoiceDetailedTitle: {
    flexWrap: 'wrap',
    marginTop: 10,
  },
  invoiceDetailedTitleText: {
    fontFamily: 'Nasu',
    fontSize: 12,
    fontWeight: 'bold',
  },
  invoiceTotalAmount: {
    flexWrap: 'wrap',
    marginTop: 10,
  },
  invoiceTotalAmountText: {
    fontFamily: 'Nasu',
    fontSize: 12,
    fontWeight: 'bold',
  },
  invoiceDetailedAmount: {
    marginTop: 15,
  },
  invoiceDetailedAmountListTable: {
    flexWrap: 'wrap',
    marginTop: 10,
    borderWidth: 1,
    // borderColor: '#bff0fd',
  },
  invoiceDetailedAmountListHeader: {
    flexDirection: 'row',
    // borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    flexGrow: 1,
    fontFamily: 'Nasu',
  },
  invoiceDetailedAmountListNo: {
    width: '5%',
  },
  invoiceDetailedAmountListItem: {
    width: '55%',
  },
  invoiceDetailedAmountListItem1: {
    width: '30%',
  },
  invoiceDetailedAmountListItem2: {
    width: '25%',
  },
  invoiceDetailedAmountListCount: {
    width: '10%',
    textAlign: 'right',
    paddingRight: 8,
  },
  invoiceDetailedAmountListItemUnitPrice: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
  invoiceDetailedAmountListItemTotalPrice: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
  invoiceDetailedAmountListRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    fontFamily: 'Nasu',
  },
  invoiceDetailedAmountListRowBorderTop: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    fontFamily: 'Nasu',
    borderTopWidth: 1,
    // borderBottomColor: '#bff0fd',
  },
  invoiceDetailedAmountListRowBorderBottom: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    fontFamily: 'Nasu',
    borderBottomWidth: 1,
    // borderBottomColor: '#bff0fd',
  },
  invoiceDetailedAmountListFooter: {
    flexWrap: 'warp',
    paddingLeft: 2,
    borderWidth: 1,
  },
  invoiceDetailedAmountListFooterRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    fontFamily: 'Nasu',
    borderTopWidth: 1,
    // borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
  },
  invoiceDetailedAmountListFooterLabel: {
    width: '85%',
    // textAlign: 'left',
    paddingLeft: 8,
  },
  invoiceDetailedAmountListFooterText: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
  invoicePaymentMethod: {
    flexWrap: 'wrap',
    marginTop: 5,
    // borderWidth: 1,
  },
  invoicePaymentMethodTitle: {
    fontFamily: 'Nasu',
  },
  invoicePaymentMethodText: {
    fontFamily: 'Nasu',
    paddingLeft: 10,
  },
  invoiceRemarks: {
    // height: 40, // when height < 40, it's too small to show the text
    // flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    // borderWidth: 1,
    // borderBottomColor: '#bff0fd',
    // alignItems: 'center',
  },
  invoiceRemarksTitle: {
    fontFamily: 'Nasu',
  },
  invoiceRemarksText: {
    fontFamily: 'Nasu',
    paddingLeft: 10,
  },
  invoiceRemarksLeft: {
    width: '15%',
    fontSize: 15,
    fontFamily: 'Nasu',
    borderRightColor: '#bff0fd',
    padding: 5,
  },
  invoiceRemarksRight: {
    width: '85%',
    fontSize: 10,
    fontFamily: 'Nasu',
    padding: 5,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});
