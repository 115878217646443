import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'configs/azureAuthConfig';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

export const GetAzureB2CAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw new Error('user is not signed in;');
  }

  var request = {
    scopes: [
      'openid',
      'profile',
      'https://devda1.onmicrosoft.com/56f39849-19eb-49d6-a6eb-4333d62af12f/dolphin_api',
    ],
    account: activeAccount || accounts[0],
  };
  const tokenResponse = await msalInstance.acquireTokenSilent(request);
  return tokenResponse.accessToken;
};
