import styled from 'styled-components';

import { Button, Select, Input } from 'components';

export const Actions = styled.div`
  display: flex;
  padding-top: 10px;
`;

export const FormButton = styled(Button)`
  margin-right: 15px;
  width: 75px;
`;

export const FormSelect = styled(Select)`
  margin-top: 9px;
  margin-bottom: -9px;
  margin-right: 5px;
  width: 140px;
`;

export const FormFileInput = styled(Input)``;
