import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';
import localData from 'shared/utils/localData';
import api from 'shared/utils/api';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Badge from '@mui/material/Badge';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import KrillChat from 'krill';

const propTypes = {
  tableType: PropTypes.string,
  setTableType: PropTypes.func,
  recvFriendApplicationList: PropTypes.array,
  sentFriendApplicationList: PropTypes.array,
  setSearchedUserList: PropTypes.func.isRequired,
};

const DashboardContactsFriendsCustomizedTitle = ({
  tableType,
  setTableType,
  recvFriendApplicationList,
  sentFriendApplicationList,
  setSearchedUserList,
}) => {
  const [usernameToSearch, setUsernameToSearch] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const newCountForReceived = recvFriendApplicationList.filter(
    (application) => application.handleResult === 0
  ).length;
  const newCountForSent = 0; // TODO: Make a better way to calculate

  const handleSearchKeywordChange = async (event) => {
    setSearchKeyword((prevState) => {
      return event.target.value;
    });
  };

  const handleClickSearch = async () => {
    const apiVariables = {
      params: {
        organizationsId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        keyword: searchKeyword,
      },
    };
    const { users } = await api.get(
      `${DOLPHIN_API.SEARCH}/users`,
      apiVariables
    );
    setSearchedUserList(users);
  };

  return (
    <Grid spacing={2}>
      <ButtonGroup>
        <Button
          // color={tableType === 'friends' ? 'primary' : 'success'}
          variant={tableType === 'friends' ? 'contained' : 'outlined'}
          startIcon={<GroupIcon />}
          onClick={() => {
            setTableType('friends');
          }}>
          {intl.get('friends_button_friend_list')}
        </Button>
        <Button
          // color={tableType === 'receivedApplication' ? 'primary' : 'success'}
          variant={
            tableType === 'receivedApplication' ? 'contained' : 'outlined'
          }
          startIcon={
            <Badge badgeContent={newCountForReceived} color="success">
              <EmailIcon />
            </Badge>
          }
          onClick={() => {
            // setNewCountForReceived(0);
            setTableType('receivedApplication');
          }}>
          {intl.get('friends_button_received_application')}
        </Button>
        <Button
          // color={tableType === 'sentApplication' ? 'primary' : 'success'}
          variant={tableType === 'sentApplication' ? 'contained' : 'outlined'}
          startIcon={
            <Badge badgeContent={newCountForSent} color="success">
              <SendIcon />
            </Badge>
          }
          onClick={() => {
            // setNewCountForSent(0);
            setTableType('sentApplication');
          }}>
          {intl.get('friends_button_sent_application')}
        </Button>
        <Button
          variant={tableType === 'searchUsers' ? 'contained' : 'outlined'}
          startIcon={
            <Badge badgeContent={newCountForSent} color="success">
              <PersonSearchIcon />
            </Badge>
          }
          onClick={() => {
            setTableType('searchUsers');
          }}>
          {intl.get('friends_button_search_users')}
        </Button>
      </ButtonGroup>
      {tableType === 'searchUsers' && (
        <Fragment>
          <TextField
            variant="outlined"
            sx={{ marginLeft: 5, marginRight: 2, maxWidth: 200 }}
            value={searchKeyword}
            size="small"
            onChange={handleSearchKeywordChange}
          />
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleClickSearch}>
            {intl.get('friends_button_search')}
          </Button>
        </Fragment>
      )}
    </Grid>
  );
};

DashboardContactsFriendsCustomizedTitle.propTypes = propTypes;

export default DashboardContactsFriendsCustomizedTitle;
