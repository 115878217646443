import intl from 'react-intl-universal';
import { Button, Typography } from '@material-ui/core';

export const getReceivedApplicationTableColumns = (
  openChatWithUser,
  acceptApplication,
  rejectApplication
) => [
  {
    name: 'user name',
    label: intl.get('friends_label_column_user_nickname'),
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'user id',
    label: intl.get('friends_label_column_user_id'),
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'message',
    label: intl.get('friends_label_column_message'),
    options: {
      filter: true,
      filterType: 'textField',
    },
  },
  {
    name: 'Operation',
    label: intl.get('friends_label_column_operations'),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updatedValue) => {
        const userID = value[0];
        const handleResult = value[1];
        if (handleResult === 0) {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  openChatWithUser(userID);
                }}>
                {intl.get('friends_button_send_message')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  acceptApplication(userID);
                }}>
                {intl.get('friends_button_accept')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  rejectApplication(userID);
                }}>
                {intl.get('friends_button_reject')}
              </Button>
            </div>
          );
        } else if (handleResult === 1) {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  openChatWithUser(userID);
                }}>
                {intl.get('friends_button_send_message')}
              </Button>
              <Button variant="contained" disabled color="primary">
                {intl.get('friends_button_accepted')}
              </Button>
            </div>
          );
        } else if (handleResult === -1) {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  openChatWithUser(userID);
                }}>
                {intl.get('friends_button_send_message')}
              </Button>
              <Button variant="contained" disabled color="primary">
                {intl.get('friends_button_rejected')}
              </Button>
            </div>
          );
        }
      },
    },
  },
];
