import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';

import AllProjects from './AllProjects';
import ProjectDetails from './ProjectDetails';

import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const DashboardResearchProjects = ({ match, history }) => {
  return (
    <Fragment>
      <Route
        path={`${match.path}`}
        exact
        render={(routeProps) => <AllProjects />}
      />

      <Route
        path={`${match.path}/:projectId`}
        render={(routeProps) => (
          <ProjectDetails projectId={routeProps.match.params.projectId} />
        )}
      />
    </Fragment>
  );
};

DashboardResearchProjects.propTypes = propTypes;

export default withRouter(DashboardResearchProjects);
