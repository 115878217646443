import React, { useState, useEffect } from 'react';

// react-router-dom components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
// import MDAvatar from "components/MDAvatar";

const propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'light',
      'dark',
      'white',
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  members: PropTypes.arrayOf(PropTypes.object),
};

const DashboardResearchItemCard = ({
  label,
  title,
  description,
  action,
  members,
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible',
      }}>
      <MDBox
        mt={1}
        p={2}
        mx={0.5}
        position="relative"
        width="100.25%"
        shadow="xl"
        borderRadius="xl">
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize">
          {label}
        </MDTypography>
        <MDBox mb={1}>
          {action.type === 'internal' ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize">
              {title}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize">
              {title}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={3} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          {action.type === 'internal' ? (
            <MDButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="small"
              color={action.color}>
              {action.label}
            </MDButton>
          ) : (
            <MDButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="small"
              color={action.color}>
              {action.label}
            </MDButton>
          )}
          {/* <MDBox display="flex">{renderAuthors}</MDBox> */}
        </MDBox>
      </MDBox>
    </Card>
  );
};

DashboardResearchItemCard.propTypes = propTypes;

export default DashboardResearchItemCard;
