import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Tooltip, IconButton } from '@material-ui/core';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { ClassNames } from '@emotion/react';

import { updateIssue } from 'pages/Dashboard/Billing/shared/api';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        dataIndex: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

const DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelectFeeWaiver =
  ({ selectedRows, data, setData }) => {
    const classes = useStyles();

    const handleIconButtonClick = () => {
      const dataIndice = selectedRows.data.map((row) => {
        return row.dataIndex;
      });

      const newData = data;
      dataIndice.forEach((dataIndex) => {
        const { originalPrice, discount, difference } = newData[dataIndex][6];
        const waive = !newData[dataIndex][6].waive;
        newData[dataIndex][6].currentPrice = waive
          ? 0
          : originalPrice * discount + difference;
        const issueId = newData[dataIndex][7];
        updateIssue(issueId, { waive: waive });
      });

      setData([...newData]);
    };

    return (
      <Fragment>
        <Tooltip
          className={ClassNames.tooltip}
          title={intl.get(
            'billing_for_received_cases_toolbar_select_tooltip_fee_waiver'
          )}>
          <IconButton
            className={classes.iconButton}
            onClick={() => handleIconButtonClick()}>
            <MoneyOffIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  };

DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelectFeeWaiver.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelectFeeWaiver
);
