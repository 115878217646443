import React from 'react';
import PropTypes from 'prop-types';
import { IssueState, getIssueState } from 'shared/constants/issues';

import { StateNormal, StateWarning, StateError } from './styles';

const propTypes = {
  state: PropTypes.string,
};

const DataTableState = ({ state }) => {
  switch (state) {
    case IssueState.NORMAL:
      return <StateNormal>{getIssueState(state)}</StateNormal>;
    case IssueState.WARNING:
      return <StateWarning>{getIssueState(state)}</StateWarning>;
    case IssueState.ERROR:
      return <StateError>{getIssueState(state)}</StateError>;
    default:
      break;
  }
  return <div></div>;
};
DataTableState.propTypes = propTypes;
export default DataTableState;
