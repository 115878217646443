import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  block: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  modalModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 800,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalPaperNarrow: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 500,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalPaperWide: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 800,
    height: 320,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default useStyles;
