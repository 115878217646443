import PropTypes from 'prop-types';

import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import MDButton from 'components/MDButton';

import { withRouter } from 'react-router';

const propTypes = {
  newTemplateJson: PropTypes.object.isRequired,
  setNewTemplateJson: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};

export const TagInputs = ({ newTemplateJson, setNewTemplateJson, tag }) => {
  const [formData, setFormData] = useState({
    label: '',
    type: 'text',
    children_type: 'text',
    note: '',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setFormData({ ...formData, [name]: val });
  };

  const initFormData = () => {
    let temp = {
      label: '',
      type: 'text',
      children_type: 'text',
      note: '',
    };
    setFormData(temp);
  };

  const addNewTag = (formData, tag) => {
    tag.label = formData.label;
    tag.type = formData.type;
    tag.children_type = formData.children_type;
    tag.note = formData.note;
    // tag_list.push(newTag);
    setNewTemplateJson({ ...newTemplateJson });
    initFormData();
  };

  return (
    <>
      <Grid xs={4} item>
        <FormControl fullWidth>
          <TextField
            fullWidth
            required
            variant="outlined"
            label="Tag Name"
            name="label"
            size="small"
            value={formData.label}
            onChange={handleChange}
            // value={newTag.value}
          />
        </FormControl>
      </Grid>
      <Grid xs={4} item>
        <FormControl fullWidth size="medium">
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="type"
            name="type"
            value={formData.type}
            sx={{ height: '2.9em' }}
            onChange={handleChange}>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="input">Input</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={4} item>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Children Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="children_type"
            value={formData.children_type}
            label="Children Type"
            sx={{ height: '2.9em' }}
            onChange={handleChange}>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="multi">Multi selection</MenuItem>
            <MenuItem value="single">Single selection</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={10} item>
        <FormControl fullWidth>
          <TextField
            fullWidth
            variant="outlined"
            label="Note"
            size="small"
            onChange={handleChange}
            name="note"
            value={formData.note}
          />
        </FormControl>
      </Grid>
      <Grid xs={2} item>
        <MDButton
          variant="gradient"
          color="info"
          style={{
            color: 'white',
            marginTop: '5px',
            marginBottom: '45px',
            width: '100%',
          }}
          size="small"
          onClick={() => addNewTag({ ...formData }, tag)}>
          Add Tag
        </MDButton>
      </Grid>
    </>
  );
};

TagInputs.propTypes = propTypes;

export default withRouter(TagInputs);
