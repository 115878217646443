import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import ManualJaJP from './ManualJaJP';
import localData from 'shared/utils/localData';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';

const DashboardManual = () => {
  const preferredLanguage = localData.get(USER_DATA.PREFFERED_LANGUAGE);

  return (
    <Fragment>
      <Card
        sx={{
          margin: 4,
          marginTop: 3,
          overflow: 'visible',
          height: '92.8%',
          alignItems: 'center',
        }}>
        <MDBox
          py={3}
          px={3}
          mt={3}
          mx={3}
          sx={{ height: '100%', width: '100%' }}>
          {preferredLanguage === LANGUAGE.JAPANSESE && <ManualJaJP />}
          {preferredLanguage === LANGUAGE.ENGLISH && <ManualJaJP />}
          {preferredLanguage === LANGUAGE.CHINESE && <ManualJaJP />}
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default withRouter(DashboardManual);
