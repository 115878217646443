export const ROUTES = {
  HOME: 'home',
  PERSONAL_SETTINGS: 'personal-settings',
  NEW: 'new',
  RECEIVED: 'received',
  HISTORY: 'history',
  MY_TASK: 'my-task',
  SENT: 'sent',
  IN_CHARGE: 'in-charge',
  BILLING: 'billing',
  LOGS: 'logs',
  MANUAL: 'manual',
  RECEIVED_BILLING: 'received-billing',
  SENT_BILLING: 'sent-billing',
  SERVICE_TERMS: 'service-terms',
  SETTINGS: 'settings',
};

export const ROUTE_TITLE = {
  [ROUTES.HOME]: 'dashboard_button_home',
  [ROUTES.PERSONAL_SETTINGS]: 'dashboard_button_personal_settings',
  [ROUTES.NEW]: 'dashboard_button_new',
  [ROUTES.RECEIVED]: 'dashboard_button_received',
  [ROUTES.HISTORY]: 'dashboard_button_received_history',
  [ROUTES.MY_TASK]: 'dashboard_button_task_management',
  [ROUTES.SENT]: 'dashboard_button_sent',
  [ROUTES.IN_CHARGE]: 'dashboard_button_in_charge',
  [ROUTES.SETTINGS]: 'dashboard_button_organization_settings',
  [ROUTES.RECEIVED_BILLING]: 'dashboard_button_billing_received',
  [ROUTES.SENT_BILLING]: 'dashboard_button_billing_sent',
  [ROUTES.BILLING]: 'dashboard_button_billing',
  [ROUTES.LOGS]: 'dashboard_button_organization_logs',
  [ROUTES.MANUAL]: 'dashboard_button_manual',
  [ROUTES.SERVICE_TERMS]: 'dashboard_button_service_terms',
};
