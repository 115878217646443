import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import PropTypes from 'prop-types';
import {
  Title,
  Message,
  Actions,
  StyledButton,
} from 'components/ConfirmModal/Styles';
import CircularProgress from '@mui/material/CircularProgress';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { AttachmentProptypes } from 'shared/propTypes/attachmentType';
import { USER_COMMENT } from 'shared/constants/comment';
import { DOLPHIN_API } from 'shared/constants/apis';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  attachment: PropTypes.shape(AttachmentProptypes).isRequired,
  modalClose: PropTypes.func,
};

const ProjectBoardIssueDetailsDownload = ({
  issue,
  attachment,
  modalClose,
}) => {
  const [GetObjectPresignedURL, setGetObjectPresignedURL] = useState('');

  useEffect(() => {
    const config = {
      params: {
        organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
        attachmentId: attachment.id,
      },
    };

    api
      .get(`/api/download/${issue.id}`, config)
      .then(async (data) => {
        setGetObjectPresignedURL(data.GetObjectPresignedURL);
      })
      .catch((error) => {
        alert(error);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadButtonClick = () => {
    const organizationId = localData.get(USER_COMMENT.CURRENT_ORGID);
    const ifSent = issue.srcOrgId === organizationId;
    api.post(DOLPHIN_API.LOGS, {
      params: {
        organizationId: organizationId,
      },
      data: {
        type: 'download',
        contents: [issue.title],
        link: ifSent
          ? `/sent/issues/${issue.id}`
          : `/received/my-task/issues/${issue.id}`,
      },
    });

    modalClose();
  };

  const handleCancelButtonClick = () => {
    modalClose();
  };

  return (
    <Fragment>
      <Title>{attachment.title}</Title>
      <Message>{intl.get('kanban_modal_text_download_hint')}</Message>
      <Actions>
        <StyledButton
          variant={'primary'}
          disabled={!GetObjectPresignedURL}
          onClick={handleDownloadButtonClick}>
          {!GetObjectPresignedURL ? (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ marginRight: '8px' }}
            />
          ) : (
            <a
              href={GetObjectPresignedURL}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              {intl.get('kanban_modal_button_download')}
            </a>
          )}
        </StyledButton>
        <StyledButton hollow onClick={handleCancelButtonClick}>
          {intl.get('kanban_modal_button_download_cancel')}
        </StyledButton>
      </Actions>
      <br />
    </Fragment>
  );
};

ProjectBoardIssueDetailsDownload.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetailsDownload);
