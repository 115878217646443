import { Fragment } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from './Chip';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

import localData from 'shared/utils/localData';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { IconButton } from '@mui/material';
import api from 'shared/utils/api';
import { axiosForCloudStorageUrls } from 'shared/utils/axiosInstance';
// import { InvalidFileExtensions, InvalidFileTypeNames } from 'shared/constants/invalidFileTypes';
import { Tags } from 'shared/constants/tags';
import { formatBytes } from 'shared/utils/misc';
import { FILE_CHUNK_SIZE, uploadParts } from './S3MultipartUpload';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';
import {
  setUploadingFileNameContext,
  setUploadingProgressContext,
  setUploadedBytesContext,
  setUploadControllerContext,
} from 'context/NewIssueContext';
import parse from 'html-react-parser';
import crypto from 'crypto';

import {
  getStructuredFiles,
  flattenStructuredAttachmentsObject,
} from 'shared/utils/fileHandling';
import { FILE_TYPE } from 'shared/constants/file';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.label}`]: {
    fontSize: '1rem !important',
    fontWeight: '300 !important',
  },
}));

// Icons
// import FileImage from 'assets/images/icons/file.png';
//<a href="[https://www.flaticon.es/iconos-gratis/documento](https://www.flaticon.es/iconos-gratis/documento)" title="documento iconos">Documento iconos creados por Tomas Knop - Flaticon</a>
// import FolderImage from 'assets/images/icons/folder.png';
//<a href="[https://www.flaticon.es/iconos-gratis/carpeta](https://www.flaticon.es/iconos-gratis/carpeta)" title="carpeta iconos">Carpeta iconos creados por Freepik - Flaticon</a>

const consoleLogApiError = (error) => {
  if (error.response) {
    // The server responded with a status code out of the range of 2xx;
    console.log(`error.response: ${JSON.stringify(error.response)}`);
  } else if (error.request) {
    // The request was made but no response was received;
    // `error.request` is an XMLHttpRequest instance in the browser;
    console.log(
      `request made, no response received; error.request: ${JSON.stringify(
        error.request
      )}`
    );
    throw new Error('Network Failure');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Unknown Error', error.message);
  }
};

export const generateConfirmWindowContents = (
  formState,
  structuredAttachments,
  darkMode
) => {
  const getFilesList = () =>
    structuredAttachments.map((item, index) => {
      return (
        <Grid container spacing={3} key={index} style={{ padding: 10 }}>
          <Grid item>
            <Icon fontSize="large" color="secondary">
              {item.type === 'directory' ? 'folder' : 'attachment'}
            </Icon>
          </Grid>
          <Grid item>
            <Stack direction="column" spacing={2}>
              <MDTypography variant="body2">
                {intl.get('new_label_file_name')}: {item.name}
              </MDTypography>
              <MDTypography variant="body2">
                {intl.get('new_label_file_size')}: {formatBytes(item.size)}{' '}
              </MDTypography>
            </Stack>
          </Grid>
        </Grid>
      );
    });

  // TODO: add translation
  const formData = {
    new_label_title: formState.title,
    // new_label_doctor_in_charge: formState.doctorsInCharge.join(', '),
    new_label_members: formState.members.join(', '),
  };

  const createFormData = (formInfo) => {
    const formValues = [];
    for (const [key, value] of Object.entries(formInfo)) {
      formValues.push(
        <Grid xs={6} item key={key}>
          <MDTypography>
            <span style={{ fontWeight: 'bold' }}>{intl.get(key)}: </span>
            <span>{value}</span>
          </MDTypography>
        </Grid>
      );
    }
    formValues.push(
      <Grid xs={12} item key={'new_label_case_description_label'}>
        <MDTypography>
          <span style={{ fontWeight: 'bold' }}>
            {intl.get('new_label_case_description')}:
          </span>
        </MDTypography>
      </Grid>
    );
    formValues.push(
      <Grid xs={12} item key={'new_label_case_description_data'}>
        <MDTypography variant="body2" mt={-2}>
          {parse(formState.description)}
        </MDTypography>
      </Grid>
    );
    return formValues;
  };

  return (
    <Grid
      spacing={3}
      container
      style={{
        marginTop: '15px',
        overflowY: 'auto',
        overflowx: 'hidden',
        maxHeight: '40vh',
      }}>
      {createFormData(formData)}
      {getFilesList()}
    </Grid>
  );
};

export const generateFileList = (structuredAttachments, handleFileDelete) => {
  return structuredAttachments
    .filter((file) => file.type === FILE_TYPE.FILE)
    .map((item, index) => (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <IconButton onClick={handleFileDelete(item)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <MDTypography variant="body2">
              {item.name} - {formatBytes(item.size)}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    ));
};

export const generateDirectoryList = (
  structuredAttachments,
  handleFileDelete,
  darkMode
) => {
  return structuredAttachments
    .filter((directory) => directory.type === FILE_TYPE.DIRECTORY)
    .map((item, index) => (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <IconButton onClick={handleFileDelete(item)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {item.type === 'file' ? (
              <MDTypography variant="body2">
                {item.name} - {formatBytes(item.size)}
              </MDTypography>
            ) : (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#202940' : 'white',
                }}
                sx={{
                  maxheight: 400,
                  flexGrow: 1,
                  maxWidth: 500,
                  overflowY: 'auto',
                }}>
                {generateTreeView(item, index)}
              </TreeView>
            )}
          </Grid>
        </Grid>
      </MDBox>
    ));
};

const generateTreeView = (structureObject, index) => {
  const label = `${structureObject.name}  -  ${formatBytes(
    structureObject.size
  )}`;
  return (
    <StyledTreeItem
      key={index}
      nodeId={`${structureObject.name}-${generate8DigitsIdentifier()}`}
      label={label}>
      {structureObject.type === FILE_TYPE.DIRECTORY &&
        structureObject.children.map((item, index) =>
          generateTreeView(item, index)
        )}
    </StyledTreeItem>
  );
};

export const initNewResearchCase = async (formState, structuredAttachments) => {
  // POST to backend server to create a new Issue Entity;
  const currentOrganizationId = localData.get(
    USER_DATA.CURRENT_ORGANIZATION_ID
  );
  const formData = {};
  formData.title = formState.title;
  formData.description = formState.description;
  formData.members = formState.members;
  formData.numAttachments = structuredAttachments.length;
  formData.researchCaseProjectId = formState.researchProjectId;

  const apiVariables = {
    params: {
      organizationId: currentOrganizationId,
      researchProjectId: formState.researchProjectId,
    },
    data: formData,
  };

  let newResearchCaseInfo;
  try {
    newResearchCaseInfo = await api.post(
      `${DOLPHIN_API.RESEARCH_CASES}/new`,
      apiVariables
    );
  } catch (error) {
    console.log(`POST to /api/research/cases/new Failed`);
    consoleLogApiError(error);
    throw error;
  }

  return newResearchCaseInfo;
};

export const startIssuePeriodicStatusCheck = async (issueId) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        issueId: issueId,
      },
    };
    const res = await api.get(
      `${DOLPHIN_API.ISSUES}/periodic-status-check`,
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/periodic-status-check failed; Check network condition;`
    );
    consoleLogApiError(error);
  }

  return status;
};

export const startResearchCasePeriodicStatusCheck = async (researchCaseId) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        researchCaseId: researchCaseId,
      },
    };
    const res = await api.get(
      `${DOLPHIN_API.RESEARCH_CASES}/periodic-status-check`,
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/research/cases/periodic-status-check failed; Check network condition;`
    );
    consoleLogApiError(error);
  }

  return status;
};

/**
 *  Get dateTime string in given format (Japan)
 */
const getTimeStringInFormat = (issueCreatedAt) => {
  const format = 'YYYY-MM-DD-HH-mm';
  const dateTimeString = moment(issueCreatedAt).tz('Asia/Tokyo').format(format);
  return dateTimeString;
};

const notifyServerAboutAttachment = async ({
  researchCaseId,
  researchCaseIdentifierId,
  researchCaseDateTimeString,
  updatedAttachmentItem,
  attachmentIdentifierId,
}) => {
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        researchCaseId: researchCaseId,
      },
      data: {
        researchCaseId: researchCaseId,
        researchCaseIdentifierId: researchCaseIdentifierId,
        researchCaseDateTimeString: researchCaseDateTimeString,
        attachmentObj: updatedAttachmentItem,
        attachmentIdentifierId: attachmentIdentifierId,
      },
    };

    // console.log(`POSTMAN: localData.get('currentOrganizationId'): ${JSON.stringify(localData.get('currentOrganizationId'))}`);
    // console.log(`POSTMAN: issueId: ${JSON.stringify(issueId)}`);
    // console.log(`POSTMAN: issueIdentifierId: ${JSON.stringify(issueIdentifierId)}`);
    // console.log(`POSTMAN: issueDateTimeString: ${JSON.stringify(issueDateTimeString)}`);
    // console.log(`POSTMAN: updatedAttachmentItem: ${JSON.stringify(updatedAttachmentItem)}`);

    // TODO: check this request
    const res = await api.post(
      `${DOLPHIN_API.RESEARCH_CASES}/notify-attachment-upload-completion`,
      apiVariables
    );
    return res;
  } catch (error) {
    console.log(`POST to /api/attachment/notify-upload-completion Failed`);
    consoleLogApiError(error);
    throw error;
  }
};

// export const checkForAttachmentsCompletionOnNewIssue = async (issueId) => {
//   let status;
//   try {
//     const apiVariables = {
//       params: {
//         organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
//         issueId: issueId,
//       },
//     };
//     const res = await api.get(
//       `${DOLPHIN_API.ATTACHMENT}/check-for-attachments-completion-on-new-issue`,
//       apiVariables
//     );
//     status = res.status;
//   } catch (error) {
//     console.log(
//       `Request to /api/attachment/check-for-attachments-completion-on-new-issue failed; Check network condition;`
//     );
//     consoleLogApiError(error);
//   }

//   return status;
// };

export const checkForAttachmentsCompletionOnNewResearchCase = async (
  researchCaseId
) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        researchCaseId: researchCaseId,
      },
    };
    const res = await api.get(
      // `${DOLPHIN_API.ATTACHMENT}/check-for-attachments-completion-on-new-research-case`,
      `${DOLPHIN_API.RESEARCH_CASES}/check-for-attachments-completion-on-new-research-case`,
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/check-for-attachments-completion-on-new-research-case failed; Check network condition;`
    );
    consoleLogApiError(error);
  }

  return status;
};

export const uploadBatchAttachmentsToS3 = async ({
  missionId,
  researchCaseId,
  researchCaseIdentifierId,
  researchCaseCreatedAt,
  structuredAttachments,
  batchTotalBytes,
  controllerUploadMissions,
  dispatchUploadMissions,
}) => {
  const researchCaseDateTimeString = getTimeStringInFormat(
    researchCaseCreatedAt
  );

  const attachments = [];
  // For each attachment: upload and notify;
  for (var i = 0; i < structuredAttachments.length; i++) {
    const AttachmentItem = structuredAttachments[i];
    const attachmentIdentifierId = crypto.randomBytes(8).toString('hex'); // 16bits

    // 1. Upload attachment into S3 bucket; no need to be non-blocking / async;
    const updatedAttachmentItem = await uploadSingleAttachmentToS3({
      missionId,
      researchCaseIdentifierId,
      researchCaseCreatedAt,
      researchCaseDateTimeString,
      AttachmentItem,
      batchTotalBytes,
      controllerUploadMissions,
      dispatchUploadMissions,
      attachmentIdentifierId,
    });

    // 2. Notify backend that it is in s3, ready for processing;
    const res = await notifyServerAboutAttachment({
      researchCaseId,
      researchCaseIdentifierId,
      researchCaseDateTimeString,
      updatedAttachmentItem,
      attachmentIdentifierId,
    });
    attachments.push(res);
  }

  return attachments;
};

export const uploadSingleAttachmentToS3 = async ({
  missionId,
  researchCaseIdentifierId,
  researchCaseCreatedAt,
  researchCaseDateTimeString,
  AttachmentItem,
  batchTotalBytes,
  controllerUploadMissions,
  dispatchUploadMissions,
  attachmentIdentifierId,
}) => {
  // flatten the attachment object into an array of objects, each representing a single file;
  let arrayOfFileObjects = flattenStructuredAttachmentsObject(AttachmentItem);

  for (var i = 0; i < arrayOfFileObjects.length; i++) {
    const item = arrayOfFileObjects[i];
    setUploadingFileNameContext(dispatchUploadMissions, {
      missionId,
      value: item.fileFullPath,
    });
    const axiosUploadController = new AbortController();
    setUploadControllerContext(dispatchUploadMissions, {
      missionId,
      value: axiosUploadController,
    });
    const objectInfo = await uploadSingleFileToS3({
      missionId,
      researchCaseIdentifierId,
      researchCaseCreatedAt,
      researchCaseDateTimeString,
      batchTotalBytes,
      attachmentFileObj: item,
      controllerUploadMissions,
      dispatchUploadMissions,
      axiosUploadController,
      attachmentIdentifierId,
    });

    arrayOfFileObjects[i] = { ...item, ...objectInfo }; // add new info to each object
  }

  const arrayOfUpdatedStructuredAttachment =
    getStructuredFiles(arrayOfFileObjects);
  // TODO:  error-handling here: accessing an array
  const updatedStructuredAttachment = arrayOfUpdatedStructuredAttachment[0];
  return updatedStructuredAttachment;
};

/**
 *  Upload the file to S3 bucket through pre-signed URL
 */
export const uploadSingleFileToS3 = async ({
  missionId,
  researchCaseIdentifierId,
  researchCaseCreatedAt,
  researchCaseDateTimeString,
  batchTotalBytes,
  attachmentFileObj, // an object of one single file
  controllerUploadMissions,
  dispatchUploadMissions,
  axiosUploadController,
  attachmentIdentifierId,
}) => {
  const { file, fileFullPath } = attachmentFileObj;
  let bucketName;
  let objectKey;
  try {
    // use S3 Multipart Upload if file is larger than threshold;
    const multipart =
      attachmentFileObj.size > FILE_CHUNK_SIZE
        ? Math.ceil(attachmentFileObj.size / FILE_CHUNK_SIZE)
        : false;

    const apiVariables = {
      params: {
        multipart: multipart,
        organizationId: localData.get('currentOrganizationId'),
        filename: fileFullPath,
        researchCaseIdentifierId,
        researchCaseCreatedAt,
        researchCaseDateTimeString,
        attachmentIdentifierId,
      },
    };
    const res = await api.get(
      // '/api/issues/url-for-uploading-file',
      '/api/research/cases/url-for-uploading-file',
      apiVariables
    );
    const { presignedS3Url, uploadId } = res;
    bucketName = res.bucketName;
    objectKey = res.objectKey;

    if (multipart) {
      // Multipart Upload
      const parts = await uploadParts({
        file,
        presignedS3Url,
        batchTotalBytes,
        missionId,
        controllerUploadMissions,
        dispatchUploadMissions,
        axiosUploadController,
      });

      const apiVariables = {
        params: { organizationId: localData.get('currentOrganizationId') },
        data: {
          bucketname: bucketName,
          objectname: objectKey,
          uploadId: uploadId,
          parts: parts,
        },
      };

      // await api.post('/api/issues/complete-multipart-upload', apiVariables);
      await api.post(
        '/api/research/cases/complete-multipart-upload',
        apiVariables
      );
    } else {
      const uploadedBytesBase =
        controllerUploadMissions.missions[missionId].uploadedBytes;

      await axiosForCloudStorageUrls({
        method: 'PUT',
        url: presignedS3Url,
        data: file,
        signal: axiosUploadController.signal,
        onUploadProgress: (data) => {
          const gap = data.total - data.loaded;
          if (gap > 0 && Math.random() > 0.9) return;
          const newUploadedBytes = uploadedBytesBase + data.loaded;
          const uploadingProgress = Math.round(
            100 * (newUploadedBytes / batchTotalBytes)
          );

          setUploadedBytesContext(dispatchUploadMissions, {
            missionId,
            value: newUploadedBytes,
          });
          // setUploadingSpeedContext(dispatchUploadMissions, {missionId, value:bytesPerSecond});
          setUploadingProgressContext(dispatchUploadMissions, {
            missionId,
            value: uploadingProgress,
          });
        },
      });
    }
  } catch (error) {
    // if cancelled, error = {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
    console.log('ERROR when uploading file to S3 bucket: ', error);
    consoleLogApiError(error);
    throw error;
  }

  return { Bucket: bucketName, Key: objectKey };
};

// DO NOT DELETE
// const handleSingleFileOriginal = async (f) => {
//   let item;
//   // unzip and extract dicom tag information
//   const dataSet = await extractAndParseDicom(f);

//   if (dataSet != null ){  // this is a zip file containing dicom file(s)
//     // update original formState
//     const newFormState = { ...formState };
//     newFormState.zipFileName = f.name;
//     newFormState.file = f;
//     const attributes = {
//       patientName: 'x00100010',
//       patientGender: 'x00100040',
//       patientID: 'x00100020',
//       patientBirthdate: 'x00100030',
//       accessionNumber: 'x00080050',
//       // studyID: 'x00200010',
//       // StudyUID: 'x0020000d',
//       studyID: 'x0020000d',
//       studyDate: "x00080020",
//       studyTime: "x00080030",
//       // studyDescription: 'x00081030',
//       bodyPart: 'x00180015',
//       modality: 'x00080060',
//     };

//     Object.entries(attributes).map(([key, attr]) => {
//       var element = dataSet.elements[attr];
//       var text = "";
//       if (element !== undefined) {
//         var str = dataSet.string(attr);
//         if (str !== undefined) {
//           text = str;
//         }
//       }
//       if (text !== "") newFormState[key] = text;
//     })

//     setFormState({...newFormState});
//     setGenderValue(getGenderValue(newFormState.patientGender));

//     item = {
//       type: 'zippedDicoms',
//       modality: newFormState.modality,
//       bodyPart: newFormState.bodyPart,
//       file: f,
//       filename: f.name,
//       size: f.size,
//     };
//   } else {
//     item = {
//       type: 'other',
//       file: f,
//       filename: f.name,
//       size: f.size,
//     };
//   }

//   return item
// };
