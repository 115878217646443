import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import { TABLE_TYPE } from '../../constants';

const propTypes = {
  titleText: PropTypes.string.isRequired,
  setTableType: PropTypes.func.isRequired,
};

const DashboardPaidWageDetailedTableCustomTitle = ({
  titleText,
  setTableType,
}) => {
  return (
    <div>
      {titleText}
      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: '25px' }}
        onClick={() => {
          setTableType(TABLE_TYPE.SUMMARY);
        }}>
        {intl.get('paid_wage_button_go_to_summary')}
      </Button>
    </div>
  );
};

DashboardPaidWageDetailedTableCustomTitle.propTypes = propTypes;

export default withRouter(DashboardPaidWageDetailedTableCustomTitle);
