import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';

import InvoiceHeader from './InvoiceHeader';
import InvoiceTitle from './InvoiceTitle';
import InvoiceDate from './InvoiceDate';
import InvoiceTo from './InvoiceTo';
import InvoiceFrom from './InvoiceFrom';
import InvoiceGreetings from './InvoiceGreetings';
import InvoiceDetailedTitle from './InvoiceDetailedTitle';
import InvoiceTotalAmount from './InvoiceTotalAmount';
import InvoiceDetailedAmount from './InvoiceDetailedAmount';
import InvoicePaymentMethod from './InvoicePaymentMethod';
import InvoiceRemarks from './InvoiceRemarks';
import InvoiceFooter from './InvoiceFooter';
import { styles } from './styles';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const DashboardBillingForReceivedTasksInvoiceEditorPreview = ({
  invoiceContent,
}) => {
  const invoiceTitle = '';
  return (
    <PDFViewer width="100%" height="100%" fileName={invoiceTitle}>
      <Document title={invoiceTitle}>
        <Page size="A4" style={styles.invoiceFirstPage} wrap={true}>
          <InvoiceHeader />
          <InvoiceTitle />
          <InvoiceDate invoiceContent={invoiceContent} />
          <InvoiceTo invoiceContent={invoiceContent} />
          <InvoiceFrom invoiceContent={invoiceContent} />
          <InvoiceGreetings invoiceContent={invoiceContent} />
          <InvoiceDetailedTitle invoiceContent={invoiceContent} />
          <InvoiceTotalAmount invoiceContent={invoiceContent} />
          <InvoiceDetailedAmount invoiceContent={invoiceContent} />
          <InvoicePaymentMethod invoiceContent={invoiceContent} />
          <InvoiceRemarks invoiceContent={invoiceContent} />
          <InvoiceFooter />
        </Page>
      </Document>
    </PDFViewer>
  );
};

DashboardBillingForReceivedTasksInvoiceEditorPreview.propTypes = propTypes;

export default withRouter(DashboardBillingForReceivedTasksInvoiceEditorPreview);
