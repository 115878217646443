import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actionGrid: {
    background: '#525659',
  },
  invoiceEditorGrid: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
  },
  editorGrid: {
    background: 'green',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '85vh',
    overflowY: 'auto',
  },
  previewGrid: {
    background: 'orange',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '85vh',
  },
}));
