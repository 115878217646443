import PropTypes from 'prop-types';

export const OrganizationProptypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  organizationCode: PropTypes.string,
  ouid: PropTypes.string,
  contact: PropTypes.shape({
    name: PropTypes.string,
    zipCode: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    tel: PropTypes.string,
    fax: PropTypes.string,
  }),
  paymentMethod: PropTypes.shape({
    bank: PropTypes.string,
    branch: PropTypes.string,
    accountType: PropTypes.string,
    accountNo: PropTypes.string,
    accountName: PropTypes.string,
  }),
  userIds: PropTypes.arrayOf(PropTypes.number),
  settingsId: PropTypes.any,
  logIds: PropTypes.arrayOf(PropTypes.number),
  activityIds: PropTypes.arrayOf(PropTypes.number),
};
