import PropTypes from 'prop-types';
import { OrganizationProptypes } from './organizationType';
import { UserProptypes } from './userType';
import { QuotaProptypes } from './quotaType';

export const ProjectProptypes = {
  ...OrganizationProptypes,
  users: PropTypes.arrayOf(PropTypes.shape(UserProptypes)),
  quotaSrcOrgs: PropTypes.arrayOf(PropTypes.shape(QuotaProptypes)),
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      patientName: PropTypes.string,
      patientGender: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      state: PropTypes.string,
      priority: PropTypes.string,
      listPosition: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      userIds: PropTypes.array,
      srcOrgName: PropTypes.string,
      dstOrgName: PropTypes.string,
      studyIUID: PropTypes.any,
      tags: PropTypes.array,
      deadline: PropTypes.string,
      zipFileName: PropTypes.any,
      dicomFilePaths: PropTypes.shape({}),
      creationCompleted: PropTypes.bool,
      department: PropTypes.any,
      doctorsInCharge: PropTypes.array,
    })
  ),
  dateThres: PropTypes.string,
};
