// import { Fragment } from 'react';
import intl from 'react-intl-universal';
// import { htmlToText } from 'html-to-text';
import Grid from '@mui/material/Grid';
// import Stack from '@mui/material/Stack';
// import Icon from '@mui/material/Icon';
import { Button } from '@material-ui/core';
import { getIssueStatusForClient } from 'shared/constants/kenshin/issues';
import { getUserFullName } from 'shared/utils/name';
import { Status } from 'pages/Dashboard/ReceivedHistory/styles';
import State from 'components/DataTable/State';
// import pxToRem from 'assets/theme/functions/pxToRem';
// import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
// import { formatBytes } from 'shared/utils/misc';

export const getSelectedIssuesListTitle = () => {
  return (
    <Grid container spacing={2} key={0}>
      <Grid item xs={1}></Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {/* {intl.get('new_label_file_name')} */}
          Issue Title
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {intl.get('case_list_label_column_status')}
          {/* 依頼進捗 */}
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {intl.get('case_list_label_column_details')}
          {/* 詳細・その他 */}
        </MDTypography>
      </Grid>
    </Grid>
  );
};

const getDoctorNamesString = (doctors, issue) => {
  const doctorsInCharge = doctors.filter((user) =>
    issue.userIds.includes(user.id)
  );
  var doctorList = [];
  for (var index = 0; index < doctorsInCharge.length; index++) {
    const doctor = doctorsInCharge[index];
    doctorList.push(getUserFullName(doctor.firstName, doctor.lastName));
  }

  // given doctorList as a array of doctor name strings;
  let doctorNamesString = intl.get('case_list_value_doctor_unassigned');
  if (doctorList.length > 0) {
    doctorNamesString = doctorList[0];
    for (var idx = 1; idx < doctorList.length; idx++) {
      doctorNamesString += '; ' + doctorList[idx];
    }
  }
  return doctorNamesString;
};

export const getSelectedIssuesList = (
  doctors,
  selectedIssues,
  handleOpenIssueDetails
) =>
  selectedIssues.map((issue, index) => {
    return (
      <Grid container spacing={2} key={index + 1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <MDTypography variant="body2" style={{ marginTop: '25px' }}>
            {issue.title}
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          {/* 依頼進捗 */}
          <Status style={{ marginTop: '25px' }} color={issue.progress}>
            {getIssueStatusForClient(issue.progress)}
          </Status>
        </Grid>
        <Grid item xs={2}>
          <MDTypography
            variant="body2"
            style={{ marginTop: '25px', marginLeft: '20px' }}>
            {/* 詳細・その他 */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpenIssueDetails(issue.id);
              }}>
              {intl.get('case_list_button_view')}
            </Button>
          </MDTypography>
        </Grid>
      </Grid>
    );
  });
