import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
// import { getTextContentsFromHtmlString } from 'shared/utils/browser';
import { TextEditedContent } from 'components';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { InfoLabel } from '../Styles';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
};

const ProjectBoardIssueDetailsDescription = ({ issue }) => {
  return (
    <Fragment>
      <InfoLabel>{intl.get('issue_details_text_description')}</InfoLabel>
      <TextEditedContent content={issue.description} />
    </Fragment>
  );
};

ProjectBoardIssueDetailsDescription.propTypes = propTypes;

export default ProjectBoardIssueDetailsDescription;
