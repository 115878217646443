import PropTypes from 'prop-types';

export const HistoryProptypes = {
  length: PropTypes.number,
  action: PropTypes.string,
  push: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape({
      trigger: PropTypes.string,
    }),
    key: PropTypes.string,
  }),
};
