//generates random id;

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export const generate4DigitsIdentifier = () => {
  return s4();
};

export const generate8DigitsIdentifier = () => {
  return s4() + s4();
};
