import intl from 'react-intl-universal';
import Grid from '@mui/material/Grid';
import { Button } from '@material-ui/core';
import { getIssueStatus } from 'shared/constants/kenshin/issues';
import { Status } from 'pages/Dashboard/ReceivedHistory/styles';
import MDTypography from 'components/MDTypography';

import { readableJapaneseDateFormat } from 'shared/utils/dateTime';

export const getSelectedIssuesListTitle = () => {
  return (
    <Grid container spacing={2} key={0}>
      <Grid item xs={1}></Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {intl.get('dashboard_received_history_kenshin_table_column_issue_id')}
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {intl.get('case_list_label_column_status')}
          {/* 依頼進捗 */}
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {intl.get('case_list_label_column_order_time')}
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {intl.get('case_list_label_column_from')}
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="body2" style={{ marginTop: '25px' }}>
          {intl.get('case_list_label_column_details')}
          {/* 詳細・その他 */}
        </MDTypography>
      </Grid>
    </Grid>
  );
};

export const getSelectedIssuesList = (
  doctors,
  selectedIssues,
  handleOpenIssueDetails
) =>
  selectedIssues.map((issue, index) => {
    return (
      <Grid container spacing={2} key={index + 1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <MDTypography variant="body2" style={{ marginTop: '25px' }}>
            {issue.title}
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          {/* 依頼進捗 */}
          <Status style={{ marginTop: '25px' }} color={issue.progress}>
            {getIssueStatus(issue.progress)}
          </Status>
        </Grid>
        <Grid item xs={2}>
          {/* 受信時間 */}
          <MDTypography variant="subtitle2" style={{ marginTop: '25px' }}>
            {readableJapaneseDateFormat(issue.createdAt)}
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          {/* 依頼元 */}
          <MDTypography variant="subtitle2" style={{ marginTop: '25px' }}>
            {issue.srcOrgName}
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDTypography
            variant="body2"
            style={{ marginTop: '25px', marginLeft: '20px' }}>
            {/* 詳細・その他 */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpenIssueDetails(issue.id);
              }}>
              {intl.get('case_list_button_view')}
            </Button>
          </MDTypography>
        </Grid>
      </Grid>
    );
  });
