import { DOLPHIN_API, KENSHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';

export const updateMyWage = (wageId, updatedFields) => {
  api.put(`${DOLPHIN_API.MY_WAGE}/${wageId}`, {
    params: {},
    data: updatedFields,
  });
};

export const updateMyWageKenshin = (wageId, updatedFields) => {
  api.put(`${KENSHIN_API.MY_WAGE}/${wageId}`, {
    baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      userId: localData.get(USER_DATA.USER_ID),
    },
    data: updatedFields,
  });
};
