import React, { Component, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Grid, Paper } from '@material-ui/core';

// import api from 'shared/utils/api';
// import localData from 'shared/utils/localData';
// import useApi from 'shared/hooks/api';
// import { PageLoader, PageError } from 'components';

import ToolArea from './ToolArea';
import Info from './Info';
import Viewport from './Viewport';
import Diagnosis from './Diagnosis';
// import Comments from './Comments';

import useStyles from './styles';

import { withRouter } from 'react-router';
// import { convertToFalseColorImage } from 'cornerstone-core';

import { PageLoader } from 'components';

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  assignments: PropTypes.array.isRequired,
  updateLocalAssignments: PropTypes.func.isRequired,
  caseId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  viewports: PropTypes.object.isRequired,
  initViewer: PropTypes.func.isRequired,
  handleGoToPrevCase: PropTypes.func.isRequired,
  handleGoToNextCase: PropTypes.func.isRequired,
};

const Viewer = ({
  match,
  history,
  assignments,
  updateLocalAssignments,
  caseId,
  onClose,
  currentIndex,
  viewports,
  initViewer,
  handleGoToPrevCase,
  handleGoToNextCase,
}) => {
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [openPrevCase, setOpenPrevCase] = useState(false);
  const [diagnosticNote, setDiagnosticNote] = useState('');

  useEffect(() => {
    setLoaded(false);
    if (currentIndex === -1) {
      initViewer(caseId);
    } else {
      setDiagnosticNote(assignments[currentIndex].diagnosticNote);
      setLoaded(true);
    }
  }, [assignments, caseId, currentIndex, initViewer]);

  if (!loaded) {
    return <PageLoader />;
  }

  const case_ = assignments[currentIndex];

  const handleClickGoToPrev = () => {
    handleGoToPrevCase();
  };

  const handleClickGoToNext = () => {
    handleGoToNextCase();
  };

  if (!openPrevCase) {
    return (
      <Grid container className={classes.root}>
        <CssBaseline />
        <Grid container item xs={12}>
          <Grid container item xs={8} direction="column" justify="flex-start">
            <Grid container item className={classes.viewportContainer}>
              <Paper className={classes.paperViewport}>
                {/* <Viewport dicomFilePaths={case_.webUrl} /> */}
                {/* {currCase ? currCase.viewport : null} */}
                {viewports.currViewport}
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={4} direction="column" justify="flex-start">
            <Grid container item className={classes.toolAreaContainer}>
              <Paper className={classes.paper}>
                <ToolArea
                  case_={case_}
                  goToPrevCase={handleClickGoToPrev}
                  goToNextCase={handleClickGoToNext}
                  closeModal={onClose}
                />
              </Paper>
            </Grid>
            <Grid container item className={classes.caseInfoContainer}>
              <Paper className={classes.paper}>
                {/* <Info issue={issue} updateIssue={updateIssue} user_quotas={user_quotas}/> */}
                <Info
                  case_={case_}
                  hasPrevCase={
                    case_.prevCase !== null && case_.prevCase !== undefined
                  }
                  openPrevCase={() => {
                    setOpenPrevCase(true);
                  }}
                />
              </Paper>
            </Grid>
            <Grid container item className={classes.feedbackContainer}>
              <Paper className={classes.paper}>
                {/* <Comments isSrcOrg={isSrcOrg} issue={issue} fetchIssue={fetchIssue} /> */}
                {/* <div>Diagnosis</div> */}
                <Diagnosis
                  assignments={assignments}
                  diagnosis={null}
                  diagnosticNote={diagnosticNote}
                  setDiagnosticNote={setDiagnosticNote}
                  currentIndex={currentIndex}
                  updateLocalAssignments={updateLocalAssignments}
                  handleGoToNext={handleClickGoToNext}
                  readOnly={false}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.root}>
        <CssBaseline />
        <Grid container item xs={12}>
          <Grid container item xs={6} direction="column" justify="flex-start">
            <Grid container item className={classes.viewportContainerPrev}>
              <Paper className={classes.paperViewportPrev}>
                <Viewport dicomFilePaths={case_.prevCase.webUrl} />
                {/* {viewports.currViewport} */}
              </Paper>
            </Grid>
            <Grid container item className={classes.feedbackContainerPrev}>
              <Paper className={classes.paperFeedbackPrev}>
                <Diagnosis
                  assignments={assignments}
                  diagnosis={case_.prevCase.diagnosis}
                  diagnosticNote={case_.prevCase.diagnosticNote}
                  setDiagnosticNote={setDiagnosticNote}
                  currentIndex={currentIndex}
                  updateLocalAssignments={updateLocalAssignments}
                  handleGoToNext={handleClickGoToNext}
                  showPrevCase={true}
                  closePrevCase={() => {
                    setOpenPrevCase(false);
                  }}
                  readOnly={true}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={6} direction="column" justify="flex-start">
            <Grid container item className={classes.viewportContainerPrev}>
              <Paper className={classes.paperViewportPrev}>
                <Viewport dicomFilePaths={case_.webUrl} />
              </Paper>
            </Grid>
            <Grid container item className={classes.feedbackContainerPrev}>
              <Paper className={classes.paperFeedbackPrev}>
                <Diagnosis
                  assignments={assignments}
                  diagnosis={null}
                  diagnosticNote={diagnosticNote}
                  setDiagnosticNote={setDiagnosticNote}
                  currentIndex={currentIndex}
                  updateLocalAssignments={updateLocalAssignments}
                  handleGoToNext={handleClickGoToNext}
                  showPrevCase={true}
                  readOnly={false}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

Viewer.propTypes = propTypes;

export default withRouter(Viewer);
