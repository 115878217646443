import axios from 'axios';
import { getStoredAuthToken } from './authToken';
import { objectToQueryString } from './url';
import localData from 'shared/utils/localData';
import { USER_COMMENT } from 'shared/constants/comment';

const REQUEST_TYPES = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
  PUT: 'put',
};

const defaults = {
  baseURL: (variables) => {
    if (variables.baseURL) {
      return variables.baseURL;
    }
    return process.env.REACT_APP_API_URL;
  },
  headers: (variables) => ({
    'Content-Type': 'application/json',
    authorization: getStoredAuthToken(),
    ...variables.headers,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message:
      'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const api = (method, url, variables) => {
  return new Promise((resolve, reject) => {
    axios({
      // url: `${defaults.baseURL}${url}`,
      url: `${defaults.baseURL(variables)}${url}`,
      method,
      headers: defaults.headers(variables),
      params: variables.params,
      data: variables.data,
      paramsSerializer: objectToQueryString,
      onDownloadProgress: variables.onDownloadProgress,
    }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const optimisticUpdate = async (
  url,
  { updatedFields, currentFields, setLocalData },
  apiVariables = {}
) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, {
      params: {
        organizationId: localData.get('currentOrganizationId'),
      },
      data: updatedFields,
      ...apiVariables,
    });
  } catch (error) {
    console.log(`optimisticUpdate error: ${error}`);
    setLocalData(currentFields);
  }
};

// const optimisticUpdateKenshin = async (
//   url,
//   { updatedFields, currentFields, setLocalData },
//   apiVariables = {}
// ) => {
//   try {
//     setLocalData(updatedFields);
//     await api('put', url, {
//       params: {
//         organizationId: localData.get('currentOrganizationId'),
//         username: localData.get('username'),
//         userId: localData.get(USER_COMMENT.USER_ID),
//       },
//       baseURL: process.env.REACT_APP_KENSHIN_API_URL,
//       data: updatedFields,
//       ...apiVariables,
//     });
//   } catch (error) {
//     console.log(`optimisticUpdateKenshin error: ${error}`);
//     setLocalData(currentFields);
//   }
// };

const optimisticBatchUpdateIssues = async (
  url,
  { issues, updatedFields, setLocalData },
  apiVariables = {}
) => {
  try {
    for (const issue of issues) {
      setLocalData(issue.id, updatedFields);
    }
    const issueIds = issues.map((issue) => issue.id);
    await api('put', url, {
      params: {
        organizationId: localData.get('currentOrganizationId'),
      },
      data: { fields: updatedFields, issueIds: issueIds },
      ...apiVariables,
    });
  } catch (error) {
    console.log(`optimisticBatchUpdateIssues error: ${error}`);
    for (const issue of issues) {
      setLocalData(issue.id, issue);
    }
  }
};

// const optimisticBatchUpdateIssuesKenshin = async (
//   url,
//   { issues, updatedFields, setLocalData },
//   apiVariables = {}
// ) => {
//   try {
//     for (const issue of issues) {
//       setLocalData(issue.id, updatedFields);
//     }
//     const issueIds = issues.map((issue) => issue.id);
//     await api('put', url, {
//       params: {
//         organizationId: localData.get('currentOrganizationId'),
//         username: localData.get('username'),
//         userId: localData.get(USER_COMMENT.USER_ID),
//       },
//       data: { fields: updatedFields, issueIds: issueIds },
//       baseURL: process.env.REACT_APP_KENSHIN_API_URL,
//       ...apiVariables,
//     });
//   } catch (error) {
//     console.log(`optimisticBatchUpdateIssues error: ${error}`);
//     for (const issue of issues) {
//       setLocalData(issue.id, issue);
//     }
//   }
// };

const optimisticBatchUpdateKenshinCases = async (
  url,
  { selectedCases, updatedFields, setLocalData }
) => {
  try {
    for (const tmpCase of selectedCases) {
      setLocalData(tmpCase.id, updatedFields);
    }
    const kenshinCaseIds = selectedCases.map((c) => c.id);
    await api('put', url, {
      params: {
        organizationId: localData.get('currentOrganizationId'),
        username: localData.get('username'),
        userId: localData.get(USER_COMMENT.USER_ID),
      },
      data: { fields: updatedFields, kenshinCaseIds: kenshinCaseIds },
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    });
  } catch (error) {
    console.log(`optimisticBatchUpdateKenshinCases error: ${error}`);
    for (const tmpCase of selectedCases) {
      setLocalData(tmpCase.id, tmpCase);
    }
  }
};

const dolphinApi = {
  get: (...args) => api(REQUEST_TYPES.GET, ...args),
  post: (...args) => api(REQUEST_TYPES.POST, ...args),
  put: (...args) => api(REQUEST_TYPES.PUT, ...args),
  patch: (...args) => api(REQUEST_TYPES.PATCH, ...args),
  delete: (...args) => api(REQUEST_TYPES.DELETE, ...args),
  optimisticUpdate,
  optimisticBatchUpdateIssues,
  optimisticBatchUpdateKenshinCases,
};

export default dolphinApi;
