import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

// functions
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { updateArrayItemById } from 'shared/utils/javascript';
import { getFirstDayPreviousMonth } from 'shared/utils/dateTime';

// constants
import { KENSHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';
// import { ISSUE_LIST_TYPE } from 'shared/constants/issueList';

// components and parts
import { PageLoader, PageError, Modal, RefreshButton } from 'components';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import IssueDetails from 'pages/Dashboard/Kanban_Kenshin/IssueDetails';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { CustomDatePicker } from 'pages/parts/DatePicker';
import BatchEditModal from './BatchEditModal';

import Table from './table';
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { USER_COMMENT } from 'shared/constants/comment';
import { KenshinIssueStatusForClient } from 'shared/constants/kenshin/issues';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
  type: PropTypes.string,
};

const DashboardSentHistory = ({ match, history, type }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const currentDate = new Date();
  const firstDayPreviousMonth = getFirstDayPreviousMonth(currentDate);
  const [timeRangeStart, setTimeRangeStart] = useState(firstDayPreviousMonth);
  const [timeRangeEnd, setTimeRangeEnd] = useState(currentDate);
  const [batchEditModalIsOpen, setBatchEditModalIsOpen] = useState(false);
  const [selectedIssueIndexes, setSelectedIssueIndexes] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const currentUsername = localData.get('username');
  const userId = localData.get(USER_COMMENT.USER_ID);

  // TODO: [Privilege] different api for doctor or staff usage
  const [{ data, error, setLocalData }, fetchProject] = useApi.get(
    KENSHIN_API.ISSUES_SENT,
    {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        type: type,
        timeRangeStart: timeRangeStart,
        timeRangeEnd: timeRangeEnd,
        username: currentUsername,
        userId: userId,
      },
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    }
  );
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { sentIssues, doctors } = data;

  const updateLocalProjectIssues = (issueId, updatedFields) => {
    setLocalData((currentData) => ({
      sentIssues: updateArrayItemById(
        currentData.sentIssues,
        issueId,
        updatedFields
      ),
      doctors: doctors,
    }));
  };

  const handleOpenIssueDetails = (issueId) => {
    history.push(`${match.path}/issues/${issueId}`);
    // history.push(`/issues/${issueId}`);
  };

  const closeBatchEditModal = () => {
    setBatchEditModalIsOpen(false);
  };

  const handleRefreshClick = async () => {
    setIsRefreshing(true);
    await fetchProject();
    setIsRefreshing(false);
  };

  sentIssues.sort((issue1, issue2) => issue2.id - issue1.id);

  const tableAllData = sentIssues.map((issue, index) => {
    return {
      index: index,
      title: issue.title,
      progress: KenshinIssueStatusForClient[issue.progress],
      createdAt: issue.createdAt,
      dstOrgName: issue.dstOrgName,
      id: issue.id,
    };
  });

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <MDBox ml={4}>
              <MDTypography
                variant="h6"
                gutterBottom
                style={{
                  fontWeight: 'bold',
                  marginTop: '16px',
                  marginRight: '18px',
                  textAlign: 'right',
                  alignSelf: 'stretch',
                }}>
                {intl.get(
                  'dashboard_sent_history_kenshin_time_selection_title'
                )}
              </MDTypography>
            </MDBox>
            <ThemeProvider theme={getTablesTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeStart}
                setSelectedDate={setTimeRangeStart}
                labelText={intl.get(
                  'dashboard_sent_history_kenshin_time_selection_from'
                )}
              />
            </ThemeProvider>
            <ThemeProvider theme={getTablesTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeEnd}
                setSelectedDate={setTimeRangeEnd}
                labelText={intl.get(
                  'dashboard_sent_history_kenshin_time_selection_to'
                )}
                minDate={timeRangeStart}
              />
            </ThemeProvider>
            {/* <RefreshButton
              tooltipTitle={intl.get('dashboard_refresh_button_tooltip_title)} 
              handleRefreshClick={handleRefreshClick}
              isRefreshing={isRefreshing}
            /> */}
          </Stack>
        </Grid>
      </Grid>

      {/* <MDBox sx={{ marginBottom: 50 }}> */}
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table
              allData={tableAllData}
              setBatchEditModalIsOpen={setBatchEditModalIsOpen}
              handleOpenIssueDetails={handleOpenIssueDetails}
              setSelectedIssueIndexes={setSelectedIssueIndexes}
            />
          </LocalizationProvider>
        </MDBox>
      </Card>
      {/* </MDBox> */}
      <Route
        path={`${match.path}/issues/:issueId`}
        // path="/issues:issueId"
        render={(routeProps) => (
          // Irregular
          <Modal
            isOpen
            testid="modal:issue-details"
            width={1540}
            minWidth="800px"
            withCloseIcon={false}
            onClose={() => history.push(match.url)}
            renderContent={(modal) => (
              <IssueDetails
                issueId={routeProps.match.params.issueId}
                trigger={'byClick'}
                doctors={doctors}
                updateLocalProjectIssues={updateLocalProjectIssues}
                modalClose={modal.close}
              />
            )}
          />
        )}
      />

      {batchEditModalIsOpen && (
        <BatchEditModal
          isOpened={batchEditModalIsOpen}
          closeModal={closeBatchEditModal}
          issues={sentIssues}
          selectedIssueIndexes={selectedIssueIndexes}
          doctors={doctors}
          updateLocalProjectIssues={updateLocalProjectIssues}
          handleOpenIssueDetails={handleOpenIssueDetails}
          setBatchEditModalIsOpen={setBatchEditModalIsOpen}
        />
      )}
    </Fragment>
  );
};

DashboardSentHistory.propTypes = propTypes;

export default withRouter(DashboardSentHistory);
