/**
 * Iteratively filters an array of data objects based on a sequence of filter conditions.
 * Each filter condition is applied in order, with each subsequent filter being applied
 * to the result of the previous filter, potentially reducing the dataset size with each step.
 *
 * @param {Object[]} data - The initial array of data objects to be filtered.
 * @param {Object[]} filters - An array of filter conditions, where each condition
 *                             specifies the `id` (property name) to filter on and
 *                             the `value` to match against.
 * @returns {Object[]} A new array of data objects that meet all the filter conditions.
 *                     If a data object's property value includes the filter value
 *                     for every filter condition, it is included in the returned array.
 *
 * @example:
 * Filters data for entries where the diagnostic result is 'DIAGNOSED_HEALTHY',
 * the modality is 'DX', and the doctor in charge includes 'yamamoto'.
 * filterData(allData, [
 *   { id: "diagnosticResult", value: "DIAGNOSED_HEALTHY" },
 *   { id: "modality", value: "DX" },
 *   { id: "doctorInCharge", value: "yamamoto" }
 * ]);
 */
export const filterData = (data, filters) => {
  return filters.reduce((filteredData, filter) => {
    return filteredData.filter((item) => {
      // if (filter.id === 'index') {
      if (filter.id === 'displayIndex') {
        // value could be [ "50", "100" ], [ "50", undefined ], [ "50", "" ], ["", "100"], [undefined, "100"];
        // compare numbers
        const itemValue = item[filter.id];
        const filterValue = filter.value;
        const from = filterValue[0] === undefined ? 0 : Number(filterValue[0]);
        const to = filterValue[1] === undefined ? 0 : Number(filterValue[1]);

        if (from === 0) {
          // from is not a valid number
          if (to === 0) {
            // `from` is "" or undefined;  `to` is "" or undefined;
            return true; // return all data;
          } else {
            // `from` is "" or undefined;  `to` is a number;
            return itemValue <= to;
          }
        } else {
          // `from` is a valid number
          if (to === 0) {
            // to is not a valid number;
            return from <= itemValue;
          } else {
            // both `from` and `to` are valid numbers
            return from <= itemValue && itemValue <= to;
          }
        }
      } else if (filter.id === 'doctorId') {
        const itemValue = item[filter.id];
        const filterValue = filter.value;
        return itemValue === filterValue;
      } else {
        // compare strings
        const itemValue = String(item[filter.id]).toLowerCase();
        const filterValue = filter.value.toLowerCase();
        return itemValue.includes(filterValue);
      }
    });
  }, data);
};

export const getTableData = (data, filters, sorters) => {
  // 1. filtering
  const filteredData = filters.reduce((filteredData, filter) => {
    return filteredData.fitler((item) => {
      return false;
    });
  }, data);
  // 2. sort

  return filteredData;
};

export const getSelectedAfterFiltering = (
  assignments,
  filters
  // sorters
) => {
  // 1. filtering
  // const filteredAssignments = filters.reduce((filteredData, filter) => {
  //   return filteredData.filter((item) => {
  //     if (filter.id === 'index') {
  //       // TODO
  //       return false;
  //     } else if (filter.id === 'doctorId') {
  //       // TODO
  //       return false;
  //     } else {
  //       // compare strings
  //       const itemValue = String(item[filter.id]).toLowerCase();
  //       const filterValue = filter.value.toLowerCase();
  //       return itemValue.includes(filterValue);
  //     }
  //   });
  // }, assignments);
  let filteredAssignments = [...assignments];
  filters.forEach((filter) => {
    if (filter.id === 'index') {
      // return false;
    } else if (filter.id === 'doctorId') {
      // return false;
    } else {
      filteredAssignments = filteredAssignments.filter((assignment) => {
        const itemValue = String(assignment[filter.id]).toLowerCase();
        const filterValue = filter.value.toLowerCase();
        return itemValue.includes(filterValue);
      });
    }
  });

  // 2. sorting
  const sortedAssignments = filteredAssignments;

  // 3. Generated new selectedIndice
  const newSelectedAssignments = sortedAssignments;
  const newSelectedIndice = sortedAssignments.map((item) => {
    return item.index;
  });
  return { newSelectedAssignments, newSelectedIndice };
};

export const getSelectedIndiceAfterSorting = (data, sorters) => {
  // TODO
};
