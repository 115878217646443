import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { diagnosticResultTranslationKey } from 'shared/constants/kenshin/case';

const chipBaseStyle = {
  margin: '1px',
  color: 'black',
  border: '1px solid black',
};

const chipColorStyle = {
  TO_BE_DIAGNOSED: {
    ...chipBaseStyle,
    backgroundColor: 'lightgrey',
    fontWeight: 'bold',
  },
  DIAGNOSED_UNKNOWN: {
    ...chipBaseStyle,
    backgroundColor: 'orange',
    fontWeight: 'bold',
  },
  DIAGNOSED_SICK: {
    ...chipBaseStyle,
    backgroundColor: 'red',
    fontWeight: 'bold',
  },
  DIAGNOSED_HEALTHY: {
    ...chipBaseStyle,
    backgroundColor: 'green',
    fontWeight: 'bold',
    color: 'white',
  },
};

const propTypes = {
  result: PropTypes.string,
};

const DiagnosticResultChip = ({ result }) => {
  // Function to determine chip style based on result
  const getChipStyle = (chipResult) => {
    if (result === chipResult) {
      return chipColorStyle[chipResult];
    }
    return { ...chipBaseStyle, opacity: 0.2, backgroundColor: 'transparent' };
  };

  return (
    <Chip
      label={intl.get(diagnosticResultTranslationKey[result])}
      style={getChipStyle(result)}
    />
  );
};

DiagnosticResultChip.propTypes = propTypes;

export default DiagnosticResultChip;
