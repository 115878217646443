import { Fragment } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from './Chip';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

import localData from 'shared/utils/localData';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { IconButton } from '@mui/material';
import api from 'shared/utils/api';
import parse from 'html-react-parser';

import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.label}`]: {
    fontSize: '1rem !important',
    fontWeight: '300 !important',
  },
}));

// Icons
// import FileImage from 'assets/images/icons/file.png';
//<a href="[https://www.flaticon.es/iconos-gratis/documento](https://www.flaticon.es/iconos-gratis/documento)" title="documento iconos">Documento iconos creados por Tomas Knop - Flaticon</a>
// import FolderImage from 'assets/images/icons/folder.png';
//<a href="[https://www.flaticon.es/iconos-gratis/carpeta](https://www.flaticon.es/iconos-gratis/carpeta)" title="carpeta iconos">Carpeta iconos creados por Freepik - Flaticon</a>

const consoleLogApiError = (error) => {
  if (error.response) {
    // The server responded with a status code out of the range of 2xx;
    console.log(`error.response: ${JSON.stringify(error.response)}`);
  } else if (error.request) {
    // The request was made but no response was received;
    // `error.request` is an XMLHttpRequest instance in the browser;
    console.log(
      `request made, no response received; error.request: ${JSON.stringify(
        error.request
      )}`
    );
    throw new Error('Network Failure');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Unknown Error', error.message);
  }
};

export const generateConfirmWindowContents = (formState, darkMode) => {
  // TODO: add translation
  const formData = {
    new_label_title: formState.title,
    new_label_members: formState.members.join(', '),
  };

  const createFormData = (formInfo) => {
    const formValues = [];
    for (const [key, value] of Object.entries(formInfo)) {
      formValues.push(
        <Grid xs={6} item key={key}>
          <MDTypography>
            <span style={{ fontWeight: 'bold' }}>{intl.get(key)}: </span>
            <span>{value}</span>
          </MDTypography>
        </Grid>
      );
    }
    formValues.push(
      <Grid xs={12} item key={'new_label_case_description_label'}>
        <MDTypography>
          <span style={{ fontWeight: 'bold' }}>
            {intl.get('new_label_case_description')}:
          </span>
        </MDTypography>
      </Grid>
    );
    formValues.push(
      <Grid xs={12} item key={'new_label_case_description_data'}>
        <MDTypography variant="body2" mt={-2}>
          {parse(formState.description)}
        </MDTypography>
      </Grid>
    );
    return formValues;
  };

  return (
    <Grid
      spacing={3}
      container
      style={{
        marginTop: '15px',
        overflowY: 'auto',
        overflowx: 'hidden',
        maxHeight: '40vh',
      }}>
      {createFormData(formData)}
    </Grid>
  );
};

export const createNewResearchProject = async (formState) => {
  const currentOrganizationId = localData.get(
    USER_DATA.CURRENT_ORGANIZATION_ID
  );
  const formData = {};
  formData.title = formState.title;
  formData.description = formState.description;
  formData.members = formState.members;

  const apiVariables = {
    params: { organizationId: currentOrganizationId },
    data: formData,
  };

  let newResearchProjectInfo;
  try {
    newResearchProjectInfo = await api.post(
      `${DOLPHIN_API.RESEARCH_PROJECTS}/new`,
      apiVariables
    );
  } catch (error) {
    console.log(`POST to /api/issues/new Failed`);
    consoleLogApiError(error);
    throw error;
  }

  return newResearchProjectInfo;
};
