import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(20),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    width: '100%',
    maxHeight: '100vh',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  modalModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 800,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalPaperNarrow: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 500,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalPaperWide: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 800,
    // height: 320,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default useStyles;
