import styled from 'styled-components';

import { color, font } from 'shared/utils/styles';

export const InfoLeft = styled.div`
  width: 25%;
  padding-right: 50px;
`;

export const InfoContainer = styled.div`
  width: 25%;
  padding-right: 20px;
`;

export const InfoContainerTags = styled.div`
  width: 25%;
  padding-right: 20px;
`;

export const InfoRight = styled.div`
  width: 35%;
  padding-top: 5px;
`;

export const InfoContent = styled.div`
  display: flex;
`;

export const InfoLabel = styled.div`
  margin: 24px 0 8px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(12.5)}
  ${font.bold}
`;

export const InfoValue = styled.div`
  padding: 3px 0 6px;
  ${font.size(15)}
  ${font.medium}
`;

export const InfoValueSmall = styled.div`
  padding: 3px 0 6px;
  ${font.size(14)}
  ${font.medium}
`;

export const Dates = styled.div`
  margin-top: 11px;
  padding-top: 13px;
  line-height: 22px;
  border-top: 1px solid ${color.borderLightest};
  color: ${color.textMedium};
  ${font.size(13)}
`;
