import PropTypes from 'prop-types';
import { UserProptypes } from './userType';

export const CommentProptypes = {
  id: PropTypes.number,
  body: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  domainType: PropTypes.string,
  isReport: PropTypes.bool,
  user: PropTypes.shape(UserProptypes),
  attachments: PropTypes.array,
  userId: PropTypes.number,
  issueId: PropTypes.number,
};
