import { useState, useCallback } from 'react';
import isFunction from 'lodash/isFunction';

const useMergeState = (initialState) => {
  const [state, setState] = useState(initialState || {});

  const mergeState = useCallback((newState) => {
    if (isFunction(newState)) {
      setState((currentState) => {
        return { ...currentState, ...newState(currentState) };
      });
    } else {
      setState((currentState) => {
        return { ...currentState, ...newState };
      });
    }
  }, []);

  return [state, mergeState];
};

export default useMergeState;
