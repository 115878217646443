import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { TaskTimer } from 'tasktimer';
import {
  Grid,
  Modal,
  Checkbox,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import Chip from './Chip';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from 'pages/parts/FormField';
import FormFieldHelperText from 'pages/parts/FormFieldHelperText';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import DashboardNewDropZone from 'pages/parts/DropZone';
import { CustomCheckboxForm } from './customCheckboxForm';
import { PageLoader, PageError, TextEditor } from 'components';
import useStyles from './styles';
import { useMaterialUIController } from 'context';
import {
  useUploadMissionsContextController,
  addNewMission,
  setMissionStatusContext,
  contextUpdateUploadingSpeed,
  addNewResearchCaseMission,
} from 'context/NewIssueContext';
import {
  initNewResearchCase,
  // startIssuePeriodicStatusCheck,
  startResearchCasePeriodicStatusCheck,
  uploadBatchAttachmentsToS3,
  // checkForAttachmentsCompletionOnNewIssue,
  checkForAttachmentsCompletionOnNewResearchCase,
  generateConfirmWindowContents,
  generateFileList,
  generateDirectoryList,
} from './utils';

import {
  fileTypeIsValid,
  getStructuredFiles,
  calculateFolderSize,
  calcBatchTotalSize,
  findDuplicateAttachments,
} from 'shared/utils/fileHandling';

import { removeLeadingSlash } from 'shared/utils/misc';
import pxToRem from 'assets/theme/functions/pxToRem';
import { tagIntlValues, tagRelationships } from 'shared/constants/tagsValues';
import { formConstants } from './constants';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { getUserFullName } from 'shared/utils/name';
import { DOLPHIN_API } from 'shared/constants/apis';
import { isDeepEqual } from 'shared/utils/equality';

const AutocompleteGender = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 0.88em;
  }
`;

const propTypes = {
  // projectId: PropTypes.number.isRequired,
  researchProject: PropTypes.object.isRequired,
};

const DashboardResearchCaseNew = ({ researchProject }) => {
  const [controllerUploadMissions, dispatchUploadMissions] =
    useUploadMissionsContextController();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();
  const currentUsername = localData.get('username');
  const currentOrganization = localData.get('currentOrganization');
  const currentOrganizationId = localData.get('currentOrganizationId');

  // states specifically for resetting some Autocomplete inputs;
  const [toggleResetEmergency, setToggleResetEmergency] = useState(false);
  const [toggleResetDstOrgName, setToggleResetDstOrgName] = useState(false);
  const [toggleResetDescription, setToggleResetDescription] = useState(false);

  // states related to pre-sending;
  const [exceedUploadMissionsLimit, setExceedUploadMissionsLimit] =
    useState(false);
  const [warnFormIncomplete, setWarnFormIncomplete] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [showWarningBeforeSend, setShowWarningBeforeSend] = useState(false);
  const [sendContentsConfirmed, setSendContentsConfirmed] = useState(false);
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({});
  const [genderValue, setGenderValue] = useState('');
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [showWarnInvalidFileTypes, setShowWarnInvalidFileTypes] =
    useState(false);

  const [formState, setFormState] = useState({
    title: '',
    organizationId: '',
    members: [],
    description: '',
    file: '',
    dataSet: '',
    researchProjectId: researchProject.id,
  });

  const [structuredAttachments, setStructuredAttachments] = useState([]);
  const [newStructuredAttachments, setNewStructuredAttachments] = useState([]);
  const [duplicatedAttachments, setDuplicatedAttachments] = useState([]);
  // states related to procress of sending new issue;

  const [confirmNotCheck, setconfirmNotCheck] = useState(false);
  const [sendNewIssueFailed, setSendNewIssueFailed] = useState(false);
  const [doctorsInCharge, setDoctorsInCharge] = useState([]);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    // const id = controllerUploadMissions.newIssueIdentifierId;
    const id = controllerUploadMissions.newMissionIdentifierId;
    const status =
      id in controllerUploadMissions.missions
        ? controllerUploadMissions.missions[id].status
        : '';
    if (status === 'INIT') {
      resetForm();
      // const missionId = controllerUploadMissions.newIssueIdentifierId;
      const missionId = controllerUploadMissions.newMissionIdentifierId;
      const mission = controllerUploadMissions.missions[missionId];
      sendNewResearchCaseContinued(mission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newMissionIdentifierId]);

  const [{ data, error }] = useApi.get(DOLPHIN_API.ORGANIZATION_NEW, {
    params: { organizationId: currentOrganizationId },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { dstOrgs, CvicSuperUserOrgs, doctorsFromSrcOrg } = data;
  const doctorOptions = doctorsFromSrcOrg.map((doctor) => ({
    label: getUserFullName(doctor.firstName, doctor.lastName),
    username: doctor.username,
    id: doctor.id,
  }));
  const memberOptions = researchProject.members.map((member) => ({
    label: getUserFullName(member.firstName, member.lastName),
    username: member.username,
    id: member.id,
  }));

  // dstOrgs.sort((a, b) => {
  //   return a.name < b.name ? -1 : 1;
  // });
  const resetForm = () => {
    setFormState({
      title: '',
      organizationId: currentOrganizationId,
      members: [],
      description: '',
      file: '',
      dataSet: '',
      researchProjectId: researchProject.id,
    });
    setDoctorsInCharge([]);
    setMembers([]);
    setToggleResetDescription(!toggleResetDescription);

    setStructuredAttachments([]);
  };

  const handleChange = (key) => async (event) => {
    const newFormState = { ...formState, [key]: event.target.value };
    setFormState({ ...newFormState });
  };

  const handleChangeMembers = (newMembers) => {
    const members = newMembers.map((member) => member.username);
    setFormState((prevFormState) => ({
      ...prevFormState,
      members: members,
    }));
  };

  const handleChangeDoctors = (newDoctors) => {
    const doctorIds = newDoctors
      .filter((doctor) => doctor.id !== -1)
      .map((doctor) => doctor.id);
    const doctorNames = newDoctors.map((doctor) => doctor.label);
    setFormState((prevFormState) => ({
      ...prevFormState,
      doctorsInCharge: doctorNames,
      doctorIdsFromSrcOrg: doctorIds,
    }));
  };

  const handleDescriptionChange = (htmlValue) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      description: htmlValue,
    }));
  };

  // DO NOT DELETE!
  // const handleDicomZipFileInfoChange = (targetIndex, key) => (async (event) => {
  //   const newItems = attachments.map( (item, index) => {
  //     if (index == targetIndex) {
  //       return { ...item, [key]: event.target.value}
  //     }
  //     return item;
  //   });
  //   setAttachments(newItems);
  // });

  const handleFileDelete = (item) => async (event) => {
    setFileToDelete(item);
    setConfirmDeleteFile(true);
  };

  const handleDuplicateAttachments = async (action) => {
    switch (action) {
      case 'REPLACE': {
        const tmp = structuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...tmp, ...newStructuredAttachments]);
        break;
      }
      case 'IGNORE': {
        const nonDups = newStructuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...structuredAttachments, ...nonDups]);
        break;
      }
      case 'CANCEL':
        break;
      default:
        break;
    }
    setNewStructuredAttachments([]);
    setDuplicatedAttachments([]);
  };

  const handleFiles = async (files) => {
    const validAttachments = [];
    const invalidFiles = [];

    // filter out files with invalid formats;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (!fileTypeIsValid(file)) {
        await invalidFiles.push(file);
      } else {
        const item = await handleSingleFile(file);
        await validAttachments.push(item);
      }
    }
    if (invalidFiles.length > 0) {
      setInvalidFiles([...invalidFiles]);
      setShowWarnInvalidFileTypes(true);
    }

    const newItems = getStructuredFiles(validAttachments);
    newItems.forEach((item) => {
      calculateFolderSize(item);
    });
    const dups = findDuplicateAttachments(structuredAttachments, newItems);

    if (dups.length > 0) {
      setDuplicatedAttachments(dups);
      setNewStructuredAttachments(newItems);
    } else {
      setStructuredAttachments([...structuredAttachments, ...newItems]);
    }
  };

  const handleClosingWarnInvalidFileWindow = () => {
    setInvalidFiles([]);
    setShowWarnInvalidFileTypes(false);
  };

  const handleSingleFile = async (f) => {
    const item = {
      type: 'file',
      file: f,
      name: f.name,
      fileFullPath: removeLeadingSlash(f.path),
      size: f.size,
    };
    return item;
  };

  const checkIfFormCompleted = () => {
    if (!formState.title) {
      let incompleteFields = [!formState.title ? 'Project Title' : null]; // TODO: use translator
      incompleteFields = incompleteFields.filter(function (e) {
        return e;
      });
      setIncompleteFields(incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleConfirmContentsCheckbox = () => {
    if (sendContentsConfirmed) {
      setSendContentsConfirmed(false);
    } else {
      setSendContentsConfirmed(true);
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (checkIfFormCompleted()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShowWarningBeforeSend(true);
      return;
    } else {
      setWarnFormIncomplete(true);
      return;
    }
  };

  const handleShowWarningBeforeSendModalClose = () => {
    setSendContentsConfirmed(false);
    setShowWarningBeforeSend(false);
  };

  const handleConfirmNotCheckModalClose = () => {
    setconfirmNotCheck(false);
  };

  const handleNewIssueFailModalClose = () => {
    setSendNewIssueFailed(false);
  };

  const handleExceedUploadMissionsLimitClose = () => {
    setExceedUploadMissionsLimit(false);
  };

  const getNumOngoingUploads = () => {
    const ongoings = Object.values(controllerUploadMissions.missions).filter(
      (x) => x.status === 'UPLOADING' || x.status === 'PROCESSING'
    );
    return ongoings.length;
  };

  const sendNewResearchCase = async () => {
    if (!sendContentsConfirmed) {
      setconfirmNotCheck(true);
      return false;
    }
    setShowWarningBeforeSend(false);
    setSendContentsConfirmed(false);

    // Step 0: check if there are already 3 ongoing uploads missions;
    if (getNumOngoingUploads() >= 3) {
      setExceedUploadMissionsLimit(true);
      return;
    }

    // Step 1: Create the Issue Entity in the backend, and get the issue unique ID;
    // let issueInfo;
    let newResearchCaseInfo;
    try {
      newResearchCaseInfo = await initNewResearchCase(
        formState,
        structuredAttachments
      );
    } catch (error) {
      resetForm();
      setSendNewIssueFailed(true);
      return false;
    }

    const { researchCaseId, researchCaseIdentifierId, researchCaseCreatedAt } =
      newResearchCaseInfo;

    // Add the upload mission to Context;
    const batchTotalBytes = calcBatchTotalSize(structuredAttachments);

    // TODO: fix here
    // addNewMission(dispatchUploadMissions, {
    //   issueId,
    //   issueIdentifierId,
    //   issueCreatedAt,
    //   formState,
    //   checkboxForm,
    //   structuredAttachments,
    //   batchTotalBytes,
    // });
    addNewResearchCaseMission(dispatchUploadMissions, {
      researchCaseId,
      researchCaseIdentifierId,
      researchCaseCreatedAt,
      formState,
      structuredAttachments,
      batchTotalBytes,
    });
    // The rest of the steps are in function "sendNewIssueContinued"
    // It has to wait for addNewMission to finish updating the state stored in context;
    // Therefore It is placed inside useEffect hook
  };

  const sendNewResearchCaseContinued = async (mission) => {
    // This function is a continuation from the "sendNewIssue" function;
    // It is placed in useEffect hook above and only executed after context state is successfully updated;

    const {
      researchCaseId,
      researchCaseIdentifierId,
      researchCaseCreatedAt,
      missionId,
      formState,
      structuredAttachments,
      batchTotalBytes,
    } = mission;

    // Step 1: start a timer to track the upload speed of the upload mission that is about to start;
    const timer0 = new TaskTimer(1000); // milliseconds
    const task_uploadSpeed_checker = {
      id: 'heartbeat',
      tickInterval: 2, // number of ticks between 2 task runs;
      async callback(task) {
        // can also be an async function, returning a promise
        contextUpdateUploadingSpeed(dispatchUploadMissions, {
          missionId: missionId,
        });
        if (!['INIT', 'UPLOADING'].includes(mission.status)) {
          timer0.stop();
        }
      },
    };
    timer0.add(task_uploadSpeed_checker).start();

    // Step 2: Upload the attachments to S3 bucket, and notify the backend to process it;
    try {
      setMissionStatusContext(dispatchUploadMissions, {
        missionId: missionId,
        value: 'UPLOADING',
      });
      await uploadBatchAttachmentsToS3({
        missionId: missionId,
        researchCaseId,
        researchCaseIdentifierId,
        researchCaseCreatedAt,
        structuredAttachments,
        batchTotalBytes,
        controllerUploadMissions,
        dispatchUploadMissions,
      });
    } catch (error) {
      // if cancelled, error = {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
      if (error.code === 'ERR_CANCELED') {
        console.log(`Upload is aborted: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId: missionId,
          value: 'UPLOAD_ABORTED',
        });
      } else {
        console.log(`Failed to upload batch of attachments: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId: missionId,
          value: 'UPLOAD_FAILED',
        });
      }
      return false;
    }

    setMissionStatusContext(dispatchUploadMissions, {
      missionId: missionId,
      value: 'PROCESSING',
    });

    // Step 3: Notify the backend to start a periodic check on this issue;
    // await startIssuePeriodicStatusCheck(issueId);
    await startResearchCasePeriodicStatusCheck(researchCaseId);

    // Step 4: while waiting for backend to process, poll every a few seconds to check for new status;
    const timer = new TaskTimer(1000); // milliseconds
    const task1 = {
      id: 'heartbeat',
      tickInterval: 5, // number of ticks between 2 taskTuns; e.g. run every 5 sec;
      totalRuns: 1440, // times to run; max 2 hour before timeout;
      async callback(task) {
        // can also be an async function, returning a promise
        // const issueStatusRes = await checkForAttachmentsCompletionOnNewIssue(
        //   issueId
        // );
        const statusRes = await checkForAttachmentsCompletionOnNewResearchCase(
          researchCaseId
        );
        if (statusRes === 'Complete') {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId: missionId,
            value: 'PROCESS_COMPLETE',
          });
          timer.stop();
        }
        if (statusRes === 'Failed') {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId: missionId,
            value: 'PROCESS_FAILED',
          });
          timer.stop();
        }
      },
    };
    timer.add(task1).start();
  };

  return (
    <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">
            {/* {intl.get('new_text_new_task')} */}
            {'New Case'}
          </MDTypography>
        </MDBox>

        <Grid spacing={3} container>
          <Grid xs={12} item>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  // label={intl.get('new_label_department')}
                  label={'Case ID'}
                  onChange={handleChange(formConstants.RESEARCH_CASE_TITLE)}
                  value={formState.title}
                />
              </Grid>

              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <Autocomplete
                  multiple
                  id="doctor-in-charge"
                  options={memberOptions}
                  freeSolo
                  value={members}
                  onChange={(event, newValue) => {
                    if (newValue.length > members.length) {
                      const lastNewValue = newValue[newValue.length - 1];
                      if (typeof lastNewvalue !== 'string') {
                        let i = 0;
                        for (i = 0; i < newValue.length - 1; i++) {
                          if (
                            typeof newValue[i] !== 'string' &&
                            newValue[i].id === lastNewValue.id
                          ) {
                            break;
                          }
                        }
                        if (i !== newValue.length - 1) {
                          newValue.splice(newValue.length - 1);
                          newValue.splice(i, 1);
                        }
                      }
                    }
                    const newMembers = newValue.map((member) => {
                      if (typeof member === 'string') {
                        return {
                          label: member,
                          username: member,
                          id: -1,
                        };
                      }
                      return member;
                    });
                    // handleChangeDoctors(newDoctors);
                    // setDoctorsInCharge(newDoctors);
                    handleChangeMembers(newMembers);
                    setMembers(newMembers);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        labelcolor={darkMode ? 'white' : 'grey'}
                        deleteiconcolor={darkMode ? 'white' : 'grey'}
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      // label={intl.get('new_label_doctor_in_charge')}
                      label={'Add Members'}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid item xs={12} sm={12}>
              <MDTypography
                style={{
                  fontWeight: 'light',
                  fontSize: '12px',
                  color: 'light',
                  opacity: 0.7,
                  marginBottom: '10px',
                  marginTop: 0,
                }}>
                {/* {intl.get('new_label_additional_info')} */}
                {'Description'}
              </MDTypography>
              <TextEditor
                className={classes.description}
                placeholder={intl.get('new_label_case_description')}
                defaultValue={formState.description}
                onChange={handleDescriptionChange}
                key={toggleResetDescription}
              />
            </Grid>
          </Grid>
        </Grid>

        <br />

        {structuredAttachments.length > 0 && (
          <Grid spacing={5} container>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <MDBox
                  style={{
                    marginTop: '5px',
                    marginBottom: '25px',
                  }}>
                  <MDTypography>{intl.get('new_text_files')}</MDTypography>
                </MDBox>
              </Grid>
              {generateFileList(structuredAttachments, handleFileDelete)}
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <MDBox
                  style={{
                    marginTop: '5px',
                    marginBottom: '25px',
                  }}>
                  <MDTypography>
                    {intl.get('new_text_directories')}
                  </MDTypography>
                </MDBox>
              </Grid>
              {generateDirectoryList(
                structuredAttachments,
                handleFileDelete,
                darkMode
              )}
            </Grid>
          </Grid>
        )}

        <Grid spacing={3} container>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <DashboardNewDropZone handleFiles={handleFiles} />
          </Grid>
        </Grid>

        <Grid spacing={3} container alignItems="flex-end">
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              style={{ marginTop: '15px', marginBottom: '10px' }}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmitClick}
                style={{ color: 'white', fontSize: pxToRem(18) }}>
                {/* {intl.get('new_button_submit')} */}
                {'Submit'}
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>

      {/* Modals */}
      <ConfirmModal
        isOpened={confirmDeleteFile}
        title={intl.get('new_modal_to_be_removed')}
        onConfirm={() => {
          setStructuredAttachments(
            structuredAttachments.filter(
              (item, index) => !isDeepEqual(item, fileToDelete)
            )
          );
          setFileToDelete({});
          setConfirmDeleteFile(false);
        }}
        bodyText={`${intl.get('new_modal_confirm_to_be_removed_body')} 
        ${fileToDelete.name}`}
        confirmButtonText={intl.get('new_modal_confirm_to_be_removed')}
        onCancel={() => setConfirmDeleteFile(false)}
        cancelButtonText={intl.get('new_modal_cancel_to_be_removed')}
      />

      <ConfirmModal
        isOpened={sendNewIssueFailed}
        title={intl.get('new_modal_new_issue_failed_message_1')}
        onConfirm={handleNewIssueFailModalClose}
        confirmButtonText={intl.get('new_modal_new_issue_failed_message_4')}>
        {
          <div>
            <MDTypography mb={4}>
              {intl.get('new_modal_new_issue_failed_message_2')}
            </MDTypography>
            <MDTypography mb={1}>
              {intl.get('new_modal_new_issue_failed_message_3')}
            </MDTypography>
          </div>
        }
      </ConfirmModal>

      <ConfirmModal
        isOpened={confirmNotCheck}
        title={intl.get('confirm_not_check_message_1')}
        onConfirm={handleConfirmNotCheckModalClose}
        confirmButtonText={intl.get('confirm_not_check_message_4')}>
        {
          <div>
            <MDTypography mb={4}>
              {intl.get('confirm_not_check_message_2')}
            </MDTypography>
            <MDTypography mb={1}>
              {intl.get('confirm_not_check_message_3')}
            </MDTypography>
          </div>
        }
      </ConfirmModal>

      <ConfirmModal
        isOpened={exceedUploadMissionsLimit}
        title={intl.get('new_modal_exceed_upload_limit_message_1')}
        bodyText={`${intl.get('new_modal_exceed_upload_limit_message_2')}
  ${intl.get('new_modal_exceed_upload_limit_message_3')}`}
        onConfirm={handleExceedUploadMissionsLimitClose}
        confirmButtonText={intl.get(
          'new_modal_exceed_upload_limit_button_close'
        )}
      />

      <ConfirmModal
        isOpened={warnFormIncomplete}
        title={intl.get('new_modal_text_warning_title')}
        body={intl.get('new_modal_text_warning_text_empty')}
        onConfirm={() => setWarnFormIncomplete(false)}
        confirmButtonText={intl.get('new_modal_tag_confirm')}>
        <MDBox>
          <br />
          {incompleteFields &&
            incompleteFields.map((field, index) => (
              <p style={{ color: 'red' }} key={index}>
                {field}
              </p>
            ))}
          <br />
          <MDTypography mb={1}>
            {intl.get('new_modal_text_warning_text_fill')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      {/* Modal of confirmation sending info to the server */}
      <ConfirmModal
        isOpened={showWarningBeforeSend}
        title={intl.get('new_modal_text_confirm_title')}
        bodyText={`*${intl.get('new_modal_text_confirm_text_correct')}`}
        onConfirm={sendNewResearchCase}
        onCancel={handleShowWarningBeforeSendModalClose}
        confirmButtonText={intl.get('new_modal_button_submit')}
        cancelButtonText={intl.get('new_modal_button_cancel')}>
        <MDBox sx={{ overFlowY: 'auto' }}>
          {showWarningBeforeSend &&
            generateConfirmWindowContents(
              formState,
              structuredAttachments,
              darkMode
            )}

          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ marginTop: '20px' }}>
            <Grid xs={12} item>
              <FormControlLabel
                style={{ color: darkMode ? 'white' : 'black' }}
                control={
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon style={{ fill: '#66ccff' }} />
                    }
                    onChange={handleConfirmContentsCheckbox}
                  />
                }
                label={intl.get('new_modal_text_confirm_text_sure')}
              />
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>

      <ConfirmModal
        isOpened={showWarnInvalidFileTypes}
        title={intl.get('new_modal_invalid_files_title')}
        bodyText={intl.get('new_modal_invalid_files_message_1')}
        onConfirm={handleClosingWarnInvalidFileWindow}
        confirmButtonColor="info"
        confirmButtonText={intl.get(
          'new_modal_exceed_upload_limit_button_close'
        )}>
        <MDBox>
          {invalidFiles.map((file, index) => (
            <MDTypography style={{ color: 'red' }} key={index}>
              {file.path}
            </MDTypography>
          ))}
          <br />
          <MDTypography mb={1}>
            {intl.get('new_modal_invalid_files_message_2')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      {/* Changed into 2 buttons since the last button is useless*/}
      <ConfirmModal
        isOpened={duplicatedAttachments.length > 0}
        title={intl.get('new_modal_duplicate_files_message_1')}
        onConfirm={() => handleDuplicateAttachments('REPLACE')}
        onCancel={() => handleDuplicateAttachments('IGNORE')}
        confirmButtonColor="info"
        confirmButtonText={intl.get('new_modal_duplicate_files_replace')}
        cancelButtonColor="error"
        cancelButtonText={intl.get('new_modal_duplicate_files_ignore')}>
        <MDBox>
          {duplicatedAttachments.map((file, index) => (
            <MDTypography style={{ color: 'red' }} key={index}>
              {file.fileFullPath}
            </MDTypography>
          ))}
          <MDTypography>
            {intl.get('new_modal_duplicate_files_message_2')}
          </MDTypography>
          <br />
        </MDBox>
      </ConfirmModal>
    </Card>
  );
};

DashboardResearchCaseNew.propTypes = propTypes;

export default withRouter(DashboardResearchCaseNew);
