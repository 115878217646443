import useQuery from './query';
import useMutation from './mutation';

// don't omit the comment below
/* eslint-disable react-hooks/rules-of-hooks */
const hooksApi = {
  get: (...args) => useQuery(...args),
  post: (...args) => useMutation('post', ...args),
  put: (...args) => useMutation('put', ...args),
  patch: (...args) => useMutation('patch', ...args),
  delete: (...args) => useMutation('delete', ...args),
};

export default hooksApi;
