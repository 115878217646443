import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { getTextContentsFromHtmlString } from 'shared/utils/browser';
import { TextEditedContent } from 'components';

import { EmptyLabel } from './Styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { InfoLabel } from '../Styles';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
};

const ProjectBoardIssueDetailsDescription = ({ issue }) => {
  const isDescriptionEmpty =
    getTextContentsFromHtmlString(issue.description).trim().length === 0;

  return (
    <Fragment>
      <InfoLabel>{intl.get('issue_details_text_description')}</InfoLabel>
      {isDescriptionEmpty ? (
        <EmptyLabel>
          {intl.get('issue_details_placeholder_no_description')}
        </EmptyLabel>
      ) : (
        <TextEditedContent content={issue.description} />
      )}
    </Fragment>
  );
};

ProjectBoardIssueDetailsDescription.propTypes = propTypes;

export default ProjectBoardIssueDetailsDescription;
