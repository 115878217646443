import intl from 'react-intl-universal';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ConfirmModal } from 'shared/modals/confirmModal';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import {
  QuotaProptypes,
  ClientFormPropTypes,
} from 'shared/propTypes/quotaType';

const confirmWindowProps = {
  quotaSrcOrg: PropTypes.shape(QuotaProptypes),
  formState: PropTypes.shape(ClientFormPropTypes).isRequired,
  priceSrcOrgs: PropTypes.array,
  showConfirmBeforeSend: PropTypes.bool,
  handleClick: PropTypes.func,
};

const PRICE_STATE = {
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
};

export const ConfirmClientSettingsWindow = ({
  quotaSrcOrg,
  formState,
  priceSrcOrgs,
  showConfirmBeforeSend,
  handleClick,
}) => {
  const wasChanged = (formerValue, currentValue) =>
    !(formerValue.toString() === currentValue.toString());

  const generateConfirmWindowContents = () => {
    return (
      <Grid
        spacing={3}
        container
        style={{ marginTop: '15px', marginLeft: '8px' }}>
        {wasChanged(quotaSrcOrg.quota, formState.quota) && (
          <Grid columns={{ xs: 12 }}>
            <MDTypography>
              {intl.get('organization_settings_client_management_label_quota') +
                ': ' +
                quotaSrcOrg.quota +
                ' ==> ' +
                formState.quota}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          quotaSrcOrg.monthlyInvoiceDate,
          formState.monthlyInvoiceDate
        ) && (
          <Grid columns={{ xs: 12 }}>
            <MDTypography>
              {intl.get(
                'organization_settings_client_management_label_billing_date'
              ) +
                ': ' +
                quotaSrcOrg.monthlyInvoiceDate +
                ' ==> ' +
                formState.monthlyInvoiceDate}
            </MDTypography>
          </Grid>
        )}
        {formState.priceAndStates.map((priceAndState, index) => {
          if (priceAndState.state === PRICE_STATE.DELETE) {
            return (
              <Grid columns={{ xs: 12 }} key={index}>
                <MDTypography>
                  {intl.get(
                    'organization_settings_client_management_button_delete'
                  ) +
                    ': ' +
                    priceAndState.price.modality +
                    ' ' +
                    priceAndState.price.bodypart +
                    ': ￥' +
                    priceAndState.price.price}
                </MDTypography>
              </Grid>
            );
          } else if (priceAndState.state === PRICE_STATE.CREATE) {
            return (
              <Grid columns={{ xs: 12 }} key={index}>
                <MDTypography>
                  {intl.get(
                    'organization_settings_client_management_button_add'
                  ) +
                    ': ' +
                    priceAndState.price.modality +
                    ' ' +
                    priceAndState.price.bodypart +
                    ': ￥' +
                    priceAndState.price.price}
                </MDTypography>
              </Grid>
            );
          } else if (priceAndState.state === PRICE_STATE.UPDATE) {
            return (
              <Grid columns={{ xs: 12 }} key={index}>
                <MDTypography>
                  {intl.get(
                    'organization_settings_client_management_button_update'
                  ) +
                    ': ' +
                    priceAndState.price.modality +
                    ' ' +
                    priceAndState.price.bodypart +
                    ': ￥' +
                    priceSrcOrgs[priceAndState.formerIndex].price +
                    ' ==> ￥' +
                    priceAndState.price.price}
                </MDTypography>
              </Grid>
            );
          } else {
            return <div key={index}></div>;
          }
        })}
      </Grid>
    );
  };

  return (
    <ConfirmModal
      isOpened={showConfirmBeforeSend}
      onCancel={() => handleClick(false)}
      onConfirm={() => handleClick(false, true)}
      title={intl.get(
        'organization_settings_client_settings_modal_text_confirm_title'
      )}
      bodyText={intl.get(
        'organization_settings_client_settings_modal_text_confirm_hint'
      )}
      confirmButtonText={intl.get(
        'organization_settings_client_settings_modal_button_submit'
      )}
      cancelButtonText={intl.get(
        'organization_settings_client_settings_modal_button_cancel'
      )}>
      <MDBox>
        <Grid
          spacing={3}
          container
          justifyContent="flex-end"
          alignItems="flex-end">
          <Grid xs={12} item>
            {showConfirmBeforeSend && generateConfirmWindowContents()}
          </Grid>
        </Grid>
      </MDBox>
    </ConfirmModal>
  );
};

ConfirmClientSettingsWindow.propTypes = confirmWindowProps;
