export const Tags = {
  CT1: '冠動脈＋心エコー',
  CT2: '冠動脈',
  CT3: '大動脈',
  CT4: '胸部',
  CT5: '腹部',
  CT6: '肺',
  CT7: '造影',
  CT8: '非造影',
  CT9: '腎動脈',
  CT10: 'その他',
  MRI1: '心臓',
  MRI2: '非造影',
  MRI3: '造影',
  MRI4: '一任',
  MRI5: '※ 冠動脈MRAによる狭窄評価 要',
  MRI6: '※ 冠動脈MRAによる狭窄評価 不要',
  MRI7: '※ 冠動脈MRAによる狭窄評価 一任',
  MRI8: '脳 (MRAを含む)',
  MRI9: '大動脈',
  MRI10: '胸部',
  MRI11: '腹部',
  MRI12: '腎動脈',
  MRI13: '下肢動脈・静脈',
  MRI14: 'その他',
  PhyExam1: '心エコー',
  PhyExam2: '頸動脈エコー',
  PhyExam3: 'ABI',
  PhyExam4: 'ホルター心電図',
};
