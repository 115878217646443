import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { getTextContentsFromHtmlString } from 'shared/utils/browser';
import { TextEditor, TextEditedContent, Button } from 'components';

import { EmptyLabel, Actions } from './Styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { InfoLabel } from '../Styles';

const propTypes = {
  researchCase: PropTypes.object.isRequired, // TODO: Add ResearchCase Shape
  updateResearchCase: PropTypes.func.isRequired,
  isCreator: PropTypes.bool,
};

const ProjectBoardResearchCaseDetailsDescription = ({
  researchCase,
  updateResearchCase,
  isCreator,
}) => {
  const [description, setDescription] = useState(researchCase.description);
  const [isEditing, setEditing] = useState(false);

  const handleUpdate = () => {
    setEditing(false);
    updateResearchCase({ description });
  };

  const isDescriptionEmpty =
    getTextContentsFromHtmlString(description).trim().length === 0;

  return (
    <Fragment>
      <InfoLabel>{intl.get('issue_details_text_description')}</InfoLabel>
      {isCreator && isEditing ? (
        <Fragment>
          <TextEditor
            placeholder={intl.get(
              'issue_details_placeholder_describe_the_issue'
            )}
            defaultValue={description}
            onChange={setDescription}
          />
          <Actions>
            <Button variant="primary" onClick={handleUpdate}>
              {intl.get('issue_details_button_save')}
            </Button>
            <Button
              variant="empty"
              onClick={() => {
                setEditing(false);
                setDescription(researchCase.description);
              }}>
              {intl.get('issue_details_button_cancel')}
            </Button>
          </Actions>
        </Fragment>
      ) : (
        <Fragment>
          {isDescriptionEmpty ? (
            <EmptyLabel onClick={() => setEditing(true)}>
              {intl.get('issue_details_placeholder_add_a_description')}
            </EmptyLabel>
          ) : (
            <TextEditedContent
              content={description}
              onClick={isCreator ? () => setEditing(true) : () => {}}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ProjectBoardResearchCaseDetailsDescription.propTypes = propTypes;

export default ProjectBoardResearchCaseDetailsDescription;
