import intl from 'react-intl-universal';

const LANGUAGE_CODE = {
  ENGLISH: 'en-US',
  CHINESE: 'zh-CN',
  JAPANESE: 'ja-JP',
};

const queryLocalCode = (lang) => {
  if (lang) {
    if (lang.startsWith('en')) {
      return LANGUAGE_CODE.ENGLISH;
    }
    if (lang.startsWith('zh')) {
      return LANGUAGE_CODE.CHINESE;
    }
    if (lang.startsWith('ja')) {
      return LANGUAGE_CODE.JAPANESE;
    }
  }
  return LANGUAGE_CODE.ENGLISH;
};

const getTranslationData = (locale) => {
  try {
    return require(`locales/${locale}`);
  } catch (e) {
    return require('locales/en-US.json');
  }
};

const injectLocale = (locale) => {
  const data = getTranslationData(locale);
  intl.get = (key) => {
    return data[key];
  };
};

const initLocale = async (currentLocale) => {
  await intl.init({
    currentLocale,
    locales: {
      [LANGUAGE_CODE.ENGLISH]: require('locales/en-US.json'),
      [LANGUAGE_CODE.CHINESE]: require('locales/zh-CN.json'),
      [LANGUAGE_CODE.JAPANESE]: require('locales/ja-JP.json'),
    },
  });
};

export { injectLocale, queryLocalCode, initLocale, LANGUAGE_CODE };
