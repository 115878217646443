import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Modal } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import MUIDataTable from 'mui-datatables';
import InvoiceInfo from '../../shared/InvoiceInfo';
import Invoice from '../../shared/Invoice';
import useStyles from './styles';
import { getPrice, calculateTotalPrice } from '../../shared/utils';
import { triggerLogForInvoicePDF } from 'pages/Dashboard/Billing/shared/api';
import CustomSearchRender from 'components/CustomSearchRender';
import { TABLE_TYPE } from '../../shared/constants';
import { getIssuePriority } from 'shared/constants/issues';

import InvoiceEditor from './InvoiceEditor';

const propTypes = {
  selectedStartDate: PropTypes.any.isRequired,
  selectedEndDate: PropTypes.any.isRequired,
  billData: PropTypes.array.isRequired,
  billDataKenshin: PropTypes.array.isRequired,
  setSelectedSrcOrgId: PropTypes.func.isRequired,
  setSelectedSrcOrgName: PropTypes.func.isRequired,
  setTableType: PropTypes.func.isRequired,
  dstOrg: PropTypes.object.isRequired,
};

const DashboardBillingForReceivedTasksSummaryTable = ({
  selectedStartDate,
  selectedEndDate,
  billData,
  billDataKenshin,
  setSelectedSrcOrgId,
  setSelectedSrcOrgName,
  setTableType,
  dstOrg,
}) => {
  const classes = useStyles();

  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);
  const [modalInvoiceInfoOpen, setModalInvoiceInfoOpen] = useState(false);
  const [invoiceIssueDate, setInvoiceIssueDate] = useState(new Date());
  const [invoiceDueDate, setInvoiceDueDate] = useState(new Date());
  const [invoiceRemarksContent, setInvoiceRemarksContent] = useState('');
  const [invoiceIssuesData, setInvoiceIssuesData] = useState([]);
  // const [invoiceSrcOrgName, setInvoiceSrcOrgName] = useState('');
  // const [invoiceSrcOrgId, setInvoiceSrcOrgId] = useState(-1);
  const [invoiceVersion, setInvoiceVersion] = useState(0);

  // New code
  const [invoiceEditorModalOpen, setInvoiceEditorModalOpen] = useState(false);
  const [invoiceSrcOrgId, setInvoiceSrcOrgId] = useState(-1);
  const [invoiceSrcOrgName, setInvoiceSrcOrgName] = useState('');
  const [invoiceToOrgId, setInvoiceToOrgId] = useState(-1);
  const [invoiceToOrgName, setInvoiceToOrgName] = useState('');
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState(0);

  const getSummaryTableData = (billData, billDataKenshin) => {
    const map1 = new Map();
    billData.forEach((bill, index) => {
      const srcOrgId = bill.srcOrg.id;
      const dstOrgId = bill.dstOrg.id;
      const totalBill = map1.get(srcOrgId);
      if (!totalBill) {
        const newTotalBill = {
          srcOrgName: bill.srcOrg.name,
          srcOrgId: srcOrgId,
          billAmount: bill.currentFee ? bill.currentFee : bill.originalFee,
        };
        map1.set(srcOrgId, newTotalBill);
      } else {
        const newTotalBill = {
          srcOrgName: totalBill.srcOrgName,
          srcOrgId: srcOrgId,
          billAmount: bill.currentFee
            ? totalBill.billAmount + bill.currentFee
            : totalBill.billAmount + bill.originalFee,
        };
        map1.set(srcOrgId, newTotalBill);
      }
    });

    billDataKenshin.forEach((bill, index) => {
      const srcOrgId = bill.srcOrgId;
      const dstOrgId = bill.dstOrgId;
      const totalBill = map1.get(srcOrgId);
      if (!totalBill) {
        const newTotalBill = {
          srcOrgName: bill.srcOrgName,
          srcOrgId: srcOrgId,
          billAmount: bill.currentFee ? bill.currentFee : bill.originalFee,
        };
        map1.set(srcOrgId, newTotalBill);
      } else {
        const newTotalBill = {
          srcOrgName: totalBill.srcOrgName,
          srcOrgId: srcOrgId,
          billAmount: bill.currentFee
            ? totalBill.billAmount + bill.currentFee
            : totalBill.billAmount + bill.originalFee,
        };
        map1.set(srcOrgId, newTotalBill);
      }
    });

    const data = [];
    map1.forEach((value) => {
      const { srcOrgName, srcOrgId, billAmount } = value;
      data.push([
        srcOrgName,
        billAmount,
        {
          organizationName: srcOrgName,
          organizationId: srcOrgId,
        },
        {
          organizationName: srcOrgName,
          organizationId: srcOrgId,
        },
        {
          organizationName: srcOrgName,
          organizationId: srcOrgId,
          totalAmount: billAmount,
        },
      ]);
    });

    return data;
  };

  // const data = getSummaryTableData(issuesData, pricesDict, srcOrgNamesDict);
  const data = getSummaryTableData(billData, billDataKenshin);

  const exportToCSV = (organizationId, organizationName) => {
    const selectedBillData = billData.filter(
      (bill) => bill.srcOrg.id === organizationId
    );

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      '\uFEFF' +
      'Organization,Issue ID,Emergency,Check Item,Created At,Done At,Original Fee,Current Fee, Remark\n' +
      selectedBillData
        .map((bill) => {
          const organizationName = bill.srcOrg.name;
          const issueTitle = bill.issueTitle;
          // const emergencyText = bill.emergencyText;
          const emergencyText = getIssuePriority(bill.issuePriority);
          const checkItems = bill.issueCheckItems.join(';');
          const createdAt = bill.issueCreatedAt; // TODO: format time
          const doneAt = bill.createdAt;
          const originalFee = bill.originalFee;
          const currentFee =
            bill.currentFee === null ? bill.originalFee : bill.currentFee;
          // generate mark
          let remarks = '';
          const changeHistory = JSON.parse(bill.changeHistory);
          const tags = JSON.parse(bill.tags);
          changeHistory.forEach((history) => {
            const tagId = history.tagId;
            const tag = tags.filter((tag) => tag.id === tagId)[0];
            const tagLabelText = tag.labelText;
            const historyType = history.type;
            if (historyType === 'rule') {
              const remark = tagLabelText + '-' + history.remark + ';';
              remarks += remark;
            } else if (historyType === 'adjust') {
              const remark = `料金調整 ${history.feeChange}`;
              remarks += remark;
            }
          });

          const checkItems_ = checkItems.replace(/,/g, ' ');
          const remarks_ = remarks.replace(/,/g, ' ');
          return (
            organizationName +
            ',' +
            issueTitle +
            ',' +
            emergencyText +
            ',' +
            checkItems_ +
            ',' +
            createdAt +
            ',' +
            doneAt +
            ',' +
            originalFee +
            ',' +
            currentFee +
            ',' +
            remarks_
          );
        })
        .join('\n');

    const encodeUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodeUri);
    link.setAttribute('download', `normal-bills-to-${organizationName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToCSVKenshin = (organizationId, organizationName) => {
    const selectedBillData = billDataKenshin.filter(
      (bill) => bill.srcOrgId === organizationId
    );

    // eslint-disable-next-line no-useless-concat
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      '\uFEFF' +
      'Organization, Issue ID, Check Item, Created At, Done At, Original Fee, Current Fee, Remark\n' +
      selectedBillData
        .map((bill) => {
          const organizationName = bill.srcOrgName;
          const issueIdentifier =
            bill.issueIdentifier && bill.issueIdentifier.length > 0
              ? bill.issueIdentifier
              : bill.issueTitle;
          const createdAt = bill.issueCreatedAt;
          let checkItems = '';
          const tagCount = JSON.parse(bill.tagCount);
          for (let key in tagCount) {
            const tag = tagCount[key];
            checkItems += `${tag.labelText} * ${tag.count};`;
          }
          const doneAt = bill.createdAt;
          const originalFee = bill.originalFee;
          const currentFee =
            bill.currentFee === null ? bill.originalFee : bill.currentFee;
          // generate remark
          let remarks = '';
          const changeHistory = JSON.parse(bill.changeHistory);
          changeHistory.forEach((history) => {
            const historyType = history.type;
            if (historyType === 'adjust') {
              const remark = `料金調整 ${history.feeChange};`;
              remarks += remark;
            }
          });

          const checkItems_ = checkItems.replace(/./g, ' ');
          const remarks_ = remarks.replace(/./g, ' ');
          return (
            organizationName +
            ',' +
            issueIdentifier +
            ',' +
            checkItems_ +
            ',' +
            createdAt +
            ',' +
            doneAt +
            ',' +
            originalFee +
            ',' +
            currentFee +
            ',' +
            remarks
          );
        })
        .join('\n');

    const encodeUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodeUri);
    link.setAttribute('download', `kenshin-bills-to-${organizationName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      name: 'from_organization_name',
      label: intl.get('billing_for_received_cases_label_column_received_from'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'total_price',
      label: intl.get('billing_for_received_cases_label_column_total_price'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const totalFee = value;
          return (
            <div>
              <p>￥{totalFee}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'details',
      label: intl.get('billing_for_received_cases_label_column_details'),
      options: {
        customBodyRender: (value, talbeMeta, updateValue) => {
          const { organizationName, organizationId } = value;
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedSrcOrgName(organizationName);
                  setSelectedSrcOrgId(organizationId);
                  setTableType(TABLE_TYPE.DETAILED);
                }}>
                {/* {intl.get('billing_for_received_cases_button_go_to_details')} */}
                {intl.get(
                  'billing_for_received_cases_button_go_to_details_seimitsu'
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedSrcOrgName(organizationName);
                  setSelectedSrcOrgId(organizationId);
                  setTableType(TABLE_TYPE.DETAILED_KENSHIN);
                }}>
                {intl.get(
                  'billing_for_received_cases_button_go_to_details_kenshin'
                )}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'download',
      label: intl.get('billing_for_received_cases_label_column_download'),
      options: {
        customBodyRender: (value) => {
          const { organizationId, organizationName } = value;

          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  exportToCSV(organizationId, organizationName);
                }}>
                {/* {intl.get('billing_for_sent_cases_button_download_csv')} */}
                {intl.get(
                  'billing_for_received_cases_button_download_csv_seimitsu'
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  exportToCSVKenshin(organizationId, organizationName);
                }}>
                {intl.get(
                  'billing_for_received_cases_button_download_csv_kenshin'
                )}
              </Button>
            </div>
          );

          // return value === null ? (
          //   <div>
          //     <Button variant="contained" color="primary" disabled="true">
          //       {intl.get('billing_for_received_cases_button_pdf_download')}
          //     </Button>
          //   </div>
          // ) : (
          //   <div>
          //     <Button
          //       variant="contained"
          //       color="primary"
          //       onClick={() => {
          //         console.log('download');
          // const srcOrgId = parseInt(value);
          // const selectedIssuesData = issuesData.filter(
          //   (issue) => issue.swrcOrgId === srcOrgId
          // );

          // const _invoiceIssuesData = [];
          // selectedIssuesData.forEach((issue) => {
          //   const p = getPrice(issue, pricesDict);
          //   _invoiceIssuesData.push({
          //     title: issue.title,
          //     modality: issue.modality,
          //     bodypart: issue.bodypart,
          //     emergency: issue.emergency,
          //     currentPrice: issue.waive
          //       ? 0
          //       : p * issue.discount + issue.difference,
          //   });
          // });

          // triggerLogForInvoicePDF(srcOrgNamesDict[srcOrgId]);
          // setInvoiceIssuesData(_invoiceIssuesData);
          // setInvoiceSrcOrgName(srcOrgNamesDict[srcOrgId]);
          // setInvoiceSrcOrgId(srcOrgId);
          // setModalInvoiceInfoOpen(true);
          //       }}>
          //       {intl.get('billing_for_received_cases_button_pdf_download')}
          //     </Button>
          //   </div>
          // );
        },
      },
    },
    {
      name: 'invoice',
      label: intl.get('billing_for_received_cases_label_column_invoice'),
      // label: '請求書',
      options: {
        customBodyRender: (value) => {
          const { organizationId, organizationName, totalAmount } = value;

          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setInvoiceToOrgId(organizationId);
                  setInvoiceToOrgName(organizationName);
                  setInvoiceTotalAmount(totalAmount);
                  setInvoiceEditorModalOpen(true);
                }}>
                {intl.get(
                  'billing_for_received_cases_button_download_invoice_pdf'
                )}
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    filterType: 'textField',
    tableBodyMaxHeight: '950px',
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 25, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get(
            'billing_for_received_cases_text_received_cases'
          )}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      {/* TODO Add correct theming */}
      <ThemeProvider theme={createTheme()}>
        <MUIDataTable
          title={intl.get('billing_for_received_cases_text_received_cases')}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      {/* Irregular Modal */}
      <Modal
        open={modalInvoiceInfoOpen}
        onClose={() => {
          setModalInvoiceInfoOpen(false);
        }}
        className={classes.modalInvoiceInfo}>
        {/* <DialogContent> */}
        <InvoiceInfo
          setModalInvoiceInfoOpen={setModalInvoiceInfoOpen}
          setModalInvoiceOpen={setModalInvoiceOpen}
          setInvoiceIssueDate={setInvoiceIssueDate}
          setInvoiceDueDate={setInvoiceDueDate}
          setInvoiceRemarksContent={setInvoiceRemarksContent}
          invoiceSrcOrgId={invoiceSrcOrgId}
          // invoiceDstOrgId={dstOrg.id}
          // quotaSrcOrgsDict={quotaSrcOrgsDict}
          setInvoiceVersion={setInvoiceVersion}
        />
        {/* </DialogContent> */}
      </Modal>
      {/* Irregular Modal */}
      <Modal
        open={modalInvoiceOpen}
        onClose={() => {
          setModalInvoiceOpen(false);
        }}
        className={classes.modalInvoice}>
        {/* <DialogContent> */}
        <div className={classes.modalInvoicePaper}>
          <Invoice
            invoiceSrcOrgName={invoiceSrcOrgName}
            invoiceSrcOrgId={invoiceSrcOrgId}
            invoiceIssuesData={invoiceIssuesData}
            invoiceIssueDate={invoiceIssueDate}
            invoiceDueDate={invoiceDueDate}
            invoiceRemarksContent={invoiceRemarksContent}
            // invoiceDstOrg={dstOrg}
            invoiceVersion={invoiceVersion}
          />
        </div>
        {/* </DialogContent> */}
      </Modal>
      <Modal
        open={invoiceEditorModalOpen}
        onClose={() => setInvoiceEditorModalOpen(false)}
        className={classes.invoiceEditorModal}>
        <div className={classes.invoiceEditorModalPaper}>
          <InvoiceEditor
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            billData={billData}
            billDataKenshin={billDataKenshin}
            invoiceToOrgId={invoiceToOrgId}
            invoiceToOrgName={invoiceToOrgName}
            invoiceTotalAmount={invoiceTotalAmount}
            modalClose={() => setInvoiceEditorModalOpen(false)}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

DashboardBillingForReceivedTasksSummaryTable.propTypes = propTypes;

export default DashboardBillingForReceivedTasksSummaryTable;
