import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';

import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';

import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { PageError, PageLoader } from 'components';
import { DOLPHIN_API, KENSHIN_API } from 'shared/constants/apis';
import { ServiceType } from 'shared/constants/service';
import { USER_DATA } from 'shared/constants/users';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const DashboardBrowse = ({ match, history }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const query = useQuery();

  const currentOrganizationId = localData.get(
    USER_DATA.CURRENT_ORGANIZATION_ID
  );
  const currentUsername = localData.get(USER_DATA.USERNAME);
  const userId = localData.get(USER_DATA.USER_ID);
  const issueId = match.params.issueId;
  const serviceType = query.get('serviceType');

  let apiUrl = '';
  let apiVariables;
  const flag = true;

  if (serviceType === ServiceType.SEIMITSU) {
    apiUrl = `${DOLPHIN_API.ISSUES}/${issueId}`;
    apiVariables = {
      params: {
        organizationId: currentOrganizationId,
      },
    };
  } else if (serviceType === ServiceType.KENSHIN) {
    apiUrl = `${KENSHIN_API.ISSUES}/${issueId}`;
    apiVariables = {
      params: {
        organizationId: currentOrganizationId,
        username: currentUsername,
        userId: userId,
      },
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    };
  }

  const [{ data, error, setLocalData }, fetchIssue] = useApi.get(
    apiUrl,
    apiVariables
  );

  // const [{ data, error, setLocalData }, fetchIssue] = useApi.get(
  //   `/api/issues/${issueId}`,
  //   {
  //     params: { organizationId: currentOrganizationId },
  //   }
  // );

  if (error && error.status === 404) history.push('/home');
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { issue } = data;
  const srcOrgId = issue.srcOrgId.toString();
  const dstOrgId = issue.dstOrgId.toString();
  const isSender = srcOrgId === currentOrganizationId;
  const isReceiver = dstOrgId === currentOrganizationId;

  if (serviceType === ServiceType.SEIMITSU) {
    if (isSender) {
      history.push(`/history/sent/issues/${issueId}`);
    } else if (isReceiver) {
      history.push(`/history/received/issues/${issueId}`);
    }
  } else if (serviceType === ServiceType.KENSHIN) {
    if (isSender) {
      history.push(`/history/sent-kenshin/issues/${issueId}`);
    } else if (isReceiver) {
      history.push(`/history/received/issues/${issueId}`);
    }
  }

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <div>You Do Not Have Access to This Issue!</div>
          </ThemeProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardBrowse.propTypes = propTypes;

export default withRouter(DashboardBrowse);
