import React from 'react';
import PropTypes from 'prop-types';
import { formatDeadlineWithType } from 'shared/utils/formatTime';
import {
  DeadlinePassed,
  Deadline30Mins,
  Deadline1Hour,
  Deadline3Hours,
  Deadline24Hours,
  Deadline7Days,
} from './styles';

const propTypes = {
  deadline: PropTypes.string,
};

const DataTableDeadline = ({ deadline }) => {
  const { type, formatedDeadline } = formatDeadlineWithType(deadline);

  switch (type) {
    case 'passed':
      return <DeadlinePassed>{formatedDeadline}</DeadlinePassed>;
    case '30mins':
      return <Deadline30Mins>{formatedDeadline}</Deadline30Mins>;
    case '1hour':
      return <Deadline1Hour>{formatedDeadline}</Deadline1Hour>;
    case '3hours':
      return <Deadline3Hours>{formatedDeadline}</Deadline3Hours>;
    case '24hours':
      return <Deadline24Hours>{formatedDeadline}</Deadline24Hours>;
    case '7days':
      return <Deadline7Days>{formatedDeadline}</Deadline7Days>;
    default:
      break;
  }

  return <div></div>;
};
DataTableDeadline.propTypes = propTypes;
export default DataTableDeadline;
