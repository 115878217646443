import { LANGUAGE_CODE } from './intl';

const westernOrderLanguage = [LANGUAGE_CODE.ENGLISH];

const easternOrderLanguage = [LANGUAGE_CODE.JAPANESE, LANGUAGE_CODE.CHINESE];

const isWesternOrder = (language) => {
  return westernOrderLanguage.includes(language);
};

const isEasternOrder = (language) => {
  return easternOrderLanguage.includes(language);
};

export { isWesternOrder, isEasternOrder };
