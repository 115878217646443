import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const generateDetails = (invoiceDetailedAmount) => {
  const {
    mapForBillData,
    adjustmentAmount,
    mapForBillDataKenshin,
    adjustmentAmountKenshin,
  } = invoiceDetailedAmount;

  let billDetails = null;
  let billDetailsKenshin = null;
  let details = [];

  // 1. for common bill
  if (mapForBillData.size > 0) {
    // 1.1 prepare
    let totalCount = 0;
    let labelText = '精密検査 読影件数分';
    let contents = [];
    let itemCount = 0;

    // 1.2 for tag count
    mapForBillData.forEach((value, key) => {
      // prepare
      const { labelText, count, defaultFee, changeFromRule } = value;
      // default
      contents.push([
        `${itemCount + 1})`,
        labelText,
        '(普通)',
        count,
        `￥${defaultFee}`,
        `￥${defaultFee * count}`,
      ]);
      itemCount++;
      // rules
      Object.entries(changeFromRule).forEach(([key_, value_]) => {
        const { remark, feeChange, count } = value_;
        if (feeChange === 0) return;
        contents.push([
          '',
          '',
          `(加算/割引)${remark}`,
          count,
          `￥${feeChange}`,
          `￥${feeChange * count}`,
        ]);
      });
      // count
      totalCount += count;
    });

    // 1.3 for adjustment
    if (adjustmentAmount.count > 0) {
      contents.push([
        `${itemCount + 1})`,
        '料金調整',
        `合計：${adjustmentAmount.count}件`,
        '',
        '',
        `￥${adjustmentAmount.totalAmount}`,
      ]);
      itemCount += 1;
    }

    // TODO
    billDetails = {
      totalCount: totalCount,
      contents: contents,
      labelText: labelText,
    };
  }

  // 2. for kenshin bill
  if (mapForBillDataKenshin.size > 0) {
    // 2.1 prepare
    let totalCount = 0;
    let labelText = '健診 読影件数分';
    let contents = [];
    let itemCount = 0;

    // 2.2 for tag
    mapForBillDataKenshin.forEach((value, key) => {
      const { count, fee, labelText } = value;
      contents.push([
        `${itemCount + 1})`,
        labelText,
        '(普通)',
        count,
        `￥${fee}`,
        `￥${fee * count}`,
      ]);
      itemCount++;
      totalCount += count;
    });

    // 2.3 for adjustment
    if (adjustmentAmountKenshin.count > 0) {
      contents.push([
        `${itemCount + 1})`,
        '料金調整',
        `合計：${adjustmentAmountKenshin.count}件`,
        '',
        '',
        `￥${adjustmentAmountKenshin.totalAmount}`,
      ]);
      itemCount += 1;
    }

    // TODO
    billDetailsKenshin = {
      totalCount: totalCount,
      contents: contents,
      labelText: labelText,
    };
  }

  if (billDetails !== null) {
    details.push(billDetails);
  }
  if (billDetailsKenshin !== null) {
    details.push(billDetailsKenshin);
  }

  return details;
};

const generateDetailedBillStatics = (details) => {
  return (
    <Fragment>
      {details.map((detail, index) => {
        return (
          <Fragment key={index}>
            <View style={styles.invoiceDetailedAmountListRowBorderTop}>
              <Text style={styles.invoiceDetailedAmountListNo}>
                {index + 1}
              </Text>
              <Text style={styles.invoiceDetailedAmountListItem1}>
                {detail.labelText}
              </Text>
              <Text
                style={
                  styles.invoiceDetailedAmountListItem2
                }>{`合計：${detail.totalCount}件`}</Text>
            </View>
            {detail.contents.map((row, index_) => {
              return (
                <View
                  style={
                    row[0].length > 0
                      ? styles.invoiceDetailedAmountListRowBorderTop
                      : styles.invoiceDetailedAmountListRow
                  }
                  key={index_}>
                  <Text style={styles.invoiceDetailedAmountListNo}>
                    {row[0]}
                  </Text>
                  <Text style={styles.invoiceDetailedAmountListItem1}>
                    {row[1]}
                  </Text>
                  <Text style={styles.invoiceDetailedAmountListItem2}>
                    {row[2]}
                  </Text>
                  <Text style={styles.invoiceDetailedAmountListCount}>
                    {row[3]}
                  </Text>
                  <Text style={styles.invoiceDetailedAmountListItemUnitPrice}>
                    {`${row[4]}`}
                  </Text>
                  <Text style={styles.invoiceDetailedAmountListItemTotalPrice}>
                    {`${row[5]}`}
                  </Text>
                </View>
              );
            })}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const DetailedBillStaticsKenshin = () => {
  return (
    <Fragment>
      <View style={styles.invoiceDetaieldAmountListRow}></View>
    </Fragment>
  );
};

const InvoiceDetailedAmount = ({ invoiceContent }) => {
  const details = generateDetails(invoiceContent.invoiceDetailedAmount);

  return (
    <Fragment>
      <View style={styles.invoiceDetailedAmountListTable}>
        <View style={styles.invoiceDetailedAmountListHeader}>
          <Text style={styles.invoiceDetailedAmountListNo}>
            {intl.get('billing_invoice_amount_list_label_no')}
          </Text>
          <Text style={styles.invoiceDetailedAmountListItem}>
            {intl.get('billing_invoice_amount_list_label_item')}
          </Text>
          <Text style={styles.invoiceDetailedAmountListCount}>
            {intl.get('billing_invoice_amount_list_label_item_count')}
          </Text>
          <Text style={styles.invoiceDetailedAmountListItemUnitPrice}>
            {intl.get('billing_invoice_amount_list_label_item_unit_price')}
          </Text>
          <Text style={styles.invoiceDetailedAmountListItemTotalPrice}>
            {intl.get('billing_invoice_amount_list_label_item_total_price')}
          </Text>
        </View>
        {generateDetailedBillStatics(details)}
        <View style={styles.invoiceDetailedAmountListFooterRow}>
          <Text style={styles.invoiceDetailedAmountListFooterLabel}>
            {intl.get('billing_invoice_amount_list_label_total_price')}
          </Text>
          <Text style={styles.invoiceDetailedAmountListFooterText}>
            {`￥${invoiceContent.invoiceTotalAmount}`}
          </Text>
        </View>
        <View style={styles.invoiceDetailedAmountListFooterRow}>
          <Text style={styles.invoiceDetailedAmountListFooterLabel}>
            {intl.get('billing_invoice_amount_list_label_consumption_tax')}
          </Text>
          <Text style={styles.invoiceDetailedAmountListFooterText}>
            {`￥${
              (invoiceContent.invoiceTotalAmount *
                invoiceContent.invoiceTaxRate) /
              100
            }`}
          </Text>
        </View>
        <View style={styles.invoiceDetailedAmountListFooterRow}>
          <Text style={styles.invoiceDetailedAmountListFooterLabel}>
            {intl.get(
              'billing_invoice_amount_list_label_total_price_including_tax'
            )}
          </Text>
          <Text style={styles.invoiceDetailedAmountListFooterText}>
            {`￥${invoiceContent.invoiceTotalAmountIncludingTax}`}
          </Text>
        </View>
      </View>
    </Fragment>
  );
};

InvoiceDetailedAmount.propTypes = propTypes;

export default InvoiceDetailedAmount;
