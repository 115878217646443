import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const InvoicePaymentMethod = ({ invoiceContent }) => {
  return (
    <Fragment>
      <View style={styles.invoicePaymentMethod}>
        <Text style={styles.invoicePaymentMethodTitle}>
          {intl.get('billing_invoice_text_transfer_destination')}
        </Text>
        <Text style={styles.invoicePaymentMethodText}>
          {invoiceContent.invoicePaymentMethod}
        </Text>
      </View>
    </Fragment>
  );
};

InvoicePaymentMethod.propTypes = propTypes;

export default InvoicePaymentMethod;
