import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core';

import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';

import { CustomDatePicker as DatePicker } from 'pages/parts/DatePicker';
import SummaryTable from './SummaryTable';
import DetailedTable from './DetailedTable';
import DetailedTableKenshin from './DetailedTableKenshin';

import localData from 'shared/utils/localData';
import api from 'shared/utils/api';

// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { PageLoader, PageError } from 'components';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { TABLE_TYPE } from './constants';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API, KENSHIN_API } from 'shared/constants/apis';
import { getFirstDateOfTheSameMonth } from 'shared/utils/dateTime';

const DashboardMyWage = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const currentDate = new Date();
  const firstDate = getFirstDateOfTheSameMonth(currentDate);

  const [loaded, setLoaded] = useState(false);
  const [loadedKenshin, setLoadedKenshin] = useState(false);
  const [ifError, setIfError] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(firstDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const [tableType, setTableType] = useState(TABLE_TYPE.SUMMARY);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(-1);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState('');
  const [myWageData, setMyWageData] = useState([]);
  const [myWageDataKenshin, setMyWageDataKenshin] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const preferredLanguage = localData.get('userPreferredLanguage');
  const dstOrgId = localData.get(USER_DATA.CURRENT_ORGANIZATION_ID);

  useEffect(() => {
    setLoaded(false);

    const apiVariables = {
      params: {
        organizationId: parseInt(dstOrgId),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    };

    const kenshinApiVariables = {
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      params: {
        userId: localData.get(USER_DATA.USER_ID),
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    };

    api
      .get(DOLPHIN_API.MY_WAGE, apiVariables)
      .then(async (data) => {
        const { myWages, doctors } = data;
        const newMyWages = myWages.sort((wage1, wage2) =>
          wage1.id < wage2.id ? 1 : -1
        );
        setMyWageData(newMyWages);
        setDoctors(doctors);
        setLoaded(true);
      })
      .then((error) => {
        return <PageError />;
      });

    api
      .get(KENSHIN_API.MY_WAGE, kenshinApiVariables)
      .then(async (data) => {
        const { myWages } = data;
        const newMyWages = myWages.sort((wage1, wage2) =>
          wage1.id < wage2.id ? 1 : -1
        );
        setMyWageDataKenshin(newMyWages);
        setLoadedKenshin(true);
      })
      .then((error) => {
        return <PageError />;
      });
  }, [dstOrgId, selectedStartDate, selectedEndDate]);

  if (!(loaded && loadedKenshin)) {
    return <PageLoader />;
  }

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
        <Grid item>
          <MDBox ml={4}>
            <MDTypography
              variant="h6"
              gutterBottom
              style={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginRight: '18px',
                textAlign: 'right',
                alignSelf: 'stretch',
              }}>
              {intl.get('my_wage_text_select_period')}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={2}>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <DatePicker
              selectedDate={selectedStartDate}
              setSelectedDate={setSelectedStartDate}
              labelText={intl.get('my_wage_text_select_period_from')}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={2}>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <DatePicker
              selectedDate={selectedEndDate}
              setSelectedDate={setSelectedEndDate}
              labelText={intl.get('my_wage_text_select_period_to')}
              minDate={selectedStartDate}
            />
          </ThemeProvider>
        </Grid>
      </Grid>

      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            {tableType === TABLE_TYPE.SUMMARY && (
              <SummaryTable
                myWageData={myWageData}
                myWageDataKenshin={myWageDataKenshin}
                setSelectedOrganizationId={setSelectedOrganizationId}
                setSelectedOrganizationName={setSelectedOrganizationName}
                setTableType={setTableType}
              />
            )}
            {tableType === TABLE_TYPE.DETAILED && (
              <DetailedTable
                myWageData={myWageData}
                setMyWageData={setMyWageData}
                organizationName={selectedOrganizationName}
                doctors={doctors}
                selectedOrganizationId={selectedOrganizationId}
                setTableType={setTableType}
              />
            )}
            {tableType === TABLE_TYPE.DETAILED_KENSHIN && (
              <DetailedTableKenshin
                myWageData={myWageDataKenshin}
                setMyWageData={setMyWageDataKenshin}
                organizationName={selectedOrganizationName}
                selectedOrganizationId={selectedOrganizationId}
                setTableType={setTableType}
              />
            )}
          </ThemeProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default withRouter(DashboardMyWage);
