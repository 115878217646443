import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { Grid, Modal, TextField } from '@material-ui/core';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { TextEditedContent } from 'components';
import PropTypes from 'prop-types';
import api from 'shared/utils/api';
import pxToRem from 'assets/theme/functions/pxToRem';
import useStyles from './styles';
import { DOLPHIN_API } from 'shared/constants/apis';

//!!! This is the same as Issues/New/Index
const propTypes = {
  close: PropTypes.func,
  fetchUsers: PropTypes.func,
};

const IWGDashboardUsersNew = ({ close, fetchUsers }) => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    userType: '',
    preferredLanguage: 'ja-JP',
  });
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [incompleteWarningModalOpen, setIncompleteWarningModalOpen] =
    useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const keyToLabel = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
    username: 'Username',
    userType: 'User Type',
    preferredLanguage: 'Preferred Language',
  };

  const onValueChange = (key, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const sendToServer = async () => {
    const data = { ...formState };
    await api.post(DOLPHIN_API.ADMIN_USERS, {
      data: {
        createdData: [data],
      },
    });
    await fetchUsers();
    close();
  };

  const checkIfFormCompleted = () => {
    const _incompleteFields = [];

    for (const key in formState) {
      const value = formState[key];
      if (value.length === 0) {
        _incompleteFields.push(keyToLabel[key]);
      }
    }

    if (_incompleteFields.length > 0) {
      setIncompleteFields(_incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (!checkIfFormCompleted()) {
      setIncompleteWarningModalOpen(true);
    } else {
      setConfirmModalOpen(true);
    }
  };

  return (
    <Fragment>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <Grid
          spacing={3}
          container
          alignItems="flex-start"
          justifyContent="center">
          <Grid xs={12} item>
            <TextField
              id="firstName"
              name="firstName"
              fullWidth
              variant="outlined"
              label="First Name"
              onChange={(event) =>
                onValueChange('firstName', event.target.value)
              }
              value={formState.firstName}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="lastName"
              name="lastName"
              fullWidth
              variant="outlined"
              label="Last Name"
              onChange={(event) =>
                onValueChange('lastName', event.target.value)
              }
              value={formState.lastName}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="email"
              name="email"
              fullWidth
              variant="outlined"
              label="Email"
              onChange={(event) => onValueChange('email', event.target.value)}
              value={formState.email}
              color="secondary"
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="email"
              name="email"
              fullWidth
              variant="outlined"
              label="Username"
              onChange={(event) =>
                onValueChange('username', event.target.value)
              }
              value={formState.username}
              color="secondary"
            />
          </Grid>
          <Grid xs={12} item>
            <Autocomplete
              disablePortal
              id="userType"
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, newValue) => {
                if (newValue && newValue.value)
                  onValueChange('userType', newValue.value);
              }}
              options={[
                { label: 'Staff', value: 'staff' },
                { label: 'Doctor', value: 'doctor' },
                { label: 'Manager', value: 'manager' },
              ]}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="userType"
                    fullWidth
                    variant="outlined"
                    label="User Type"
                    color="secondary"
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <Autocomplete
              disablePortal
              id="preferredLanguage"
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, newValue) => {
                if (newValue && newValue.value)
                  onValueChange('preferredLanguage', newValue.value);
              }}
              options={[
                { label: 'Chinese', value: 'zh-CN' },
                { label: 'English', value: 'en-US' },
                { label: 'Japanese', value: 'ja-JP' },
              ]}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="preferredLanguage"
                    fullWidth
                    variant="outlined"
                    label="Preferred Language"
                    color="secondary"
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid
          spacing={3}
          container
          alignItems="flex-start"
          justifyContent="center">
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={handleSubmitClick}>
              Submit
            </MDButton>
          </Grid>
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={close}>
              Cancel
            </MDButton>
          </Grid>
        </Grid>

        {/* Modal for warning */}
        <Modal
          open={incompleteWarningModalOpen}
          onClose={() => setIncompleteWarningModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Warning</MDTypography>
              <MDTypography>
                The following information is incomplete!
              </MDTypography>
              <br />
              {incompleteFields &&
                incompleteFields.map((field, index) => (
                  <p style={{ color: 'red' }} key={index}>
                    {field}
                  </p>
                ))}
              <br />
            </MDBox>
          </Card>
        </Modal>
        {/* Modal for confirm */}
        <Modal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Confirm</MDTypography>
              <MDTypography>
                You are going to create a new user as below:
              </MDTypography>
              <TextEditedContent
                content={JSON.stringify(formState, undefined, 4)}
              />
              <Grid
                spacing={3}
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ marginTope: '20px' }}>
                <Grid xs={6} item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={sendToServer}>
                    Confirm
                  </MDButton>
                </Grid>
                <Grid xs={6} item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={() => {
                      setIncompleteWarningModalOpen(false);
                      setConfirmModalOpen(false);
                    }}>
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Modal>
      </MDBox>
    </Fragment>
  );
};

IWGDashboardUsersNew.propTypes = propTypes;

export default withRouter(IWGDashboardUsersNew);
