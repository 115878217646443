import { DOLPHIN_API, KENSHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';

export const updateIssue = (issueId, updatedFields) => {
  api.put(`${DOLPHIN_API.ISSUES}/${issueId}`, {
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
    },
    data: updatedFields,
  });
};

export const triggerLogForInvoicePDF = (srcOrgName) => {
  api.post(DOLPHIN_API.LOGS, {
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
    },
    data: {
      type: 'pdf',
      contents: [srcOrgName],
      link: '',
    },
  });
};

export const updateBillForReceivedTasks = (billId, updatedFields) => {
  api.put(`${DOLPHIN_API.BILLS_RECEIVED_TASKS}/${billId}`, {
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
    },
    data: updatedFields,
  });
};

export const updateBillForReceivedTasksKenshin = (billId, updatedFields) => {
  api.put(`${KENSHIN_API.BILLS_RECEIVED_ISSUES}/${billId}`, {
    baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      userId: localData.get(USER_DATA.USER_ID),
    },
    data: updatedFields,
  });
};

export const updateBillForSentTasks = (billId, updatedFields) => {
  api.put(`${DOLPHIN_API.BILLS_SENT_TASKS}/${billId}`, {
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
    },
    data: updatedFields,
  });
};

export const updateBillForSentTasksKenshin = (billId, updatedFields) => {
  api.put(`${KENSHIN_API.BILLS_SENT_ISSSUES}/${billId}`, {
    baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      userId: localData.get(USER_DATA.USER_ID),
    },
    data: updatedFields,
  });
};
