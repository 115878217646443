import axios from 'axios';

export const axiosForCloudStorageUrls = axios.create();
delete axiosForCloudStorageUrls.defaults.headers.common['authorization'];
delete axios.defaults.headers.put['Content-Type'];

if (process.env.REACT_APP_CLOUD_PROVIDER === 'azure') {
  // Add the 'x-ms-blob-type' header with a value of 'BlockBlob'
  axiosForCloudStorageUrls.defaults.headers.common['x-ms-blob-type'] =
    'BlockBlob';
}
