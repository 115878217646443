import React, { forwardRef, Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import {
  IssueStatus,
  getIssueStatus,
  getIssueStatusForClient,
  IssueSelectableStatus_BatchEditModal,
  IssueSelectableStatusWithWarning,
  IssueSelectableStatusForClient,
  IssueSelectableStatusForClientWithWarning,
  IssueState,
} from 'shared/constants/issues';
import { Select, Icon } from 'components';
import { Status } from './styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  issues: PropTypes.array.isRequired,
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
  setSelectDoctorModalOpen: PropTypes.func,
};
const BatchEditModalIssueStatus = forwardRef(
  (
    {
      issues,
      setConfirmModalInfo,
      setConfirmModalOpen,
      setConfirmModalUpdateData,
      setSelectDoctorModalOpen,
    },
    ref
  ) => {
    const firstIssue = issues[0];

    const calculateNewState = (formerStatus, newStatus, formerState) => {
      if (formerState === IssueState.WARNING) {
        return IssueState.WARNING;
      } else if (
        formerStatus === IssueStatus.NEWTASK &&
        newStatus !== IssueStatus.CHECKREADY
      ) {
        return IssueState.WARNING;
      } else if (
        formerStatus === IssueStatus.CHECKREADY &&
        newStatus !== IssueStatus.DOCTORASSIGNED
      ) {
        return IssueState.WARNING;
      } else if (
        formerStatus === IssueStatus.DOCTORASSIGNED &&
        newStatus !== IssueStatus.REPORTREADY
      ) {
        return IssueState.WARNING;
      } else if (
        formerStatus === IssueStatus.REPORTREADY &&
        newStatus !== IssueStatus.SENDBACK
      ) {
        return IssueState.WARNING;
      } else if (
        formerStatus === IssueStatus.SENDBACK &&
        newStatus !== IssueStatus.DONE
      ) {
        return IssueState.WARNING;
      } else {
        return IssueState.NORMAL;
      }
    };

    const handleStatusChange = (status) => {
      const state = calculateNewState(
        firstIssue.status,
        status,
        firstIssue.state
      );
      if (status === IssueStatus.NEWTASK || status === IssueStatus.CHECKREADY) {
        setConfirmModalUpdateData({ status, state, userIds: [], users: [] });
      } else {
        setConfirmModalUpdateData({ status, state });
      }

      // If moving from CHECKREADY to DOCTORASSIGNED, must ask user to select which doctor to assign;
      if (
        firstIssue.status === IssueStatus.CHECKREADY &&
        status === IssueStatus.DOCTORASSIGNED
      ) {
        setConfirmModalInfo({ destination: status, issueState: state });
        setSelectDoctorModalOpen(true);
      } else {
        setConfirmModalInfo({ destination: status, issueState: state });
        setConfirmModalOpen(true);
      }
    };

    return (
      <Fragment>
        <Select
          variant="empty"
          dropdownWidth={180}
          withClearValue={false}
          name="status"
          value={firstIssue.status}
          options={(firstIssue.state === IssueState.WARNING
            ? IssueSelectableStatusForClientWithWarning[firstIssue.status]
            : IssueSelectableStatusForClient[firstIssue.status]
          ).map((status) => ({
            value: status,
            label: getIssueStatusForClient(status),
          }))}
          onChange={(status) => handleStatusChange(status)}
          renderValue={({ value: status }) => (
            <Status isValue color={status}>
              <div>{getIssueStatusForClient(status)}</div>
              <Icon type="chevron-down" size={18} />
            </Status>
          )}
          renderOption={({ value: status }) => (
            <Status color={status}>{getIssueStatusForClient(status)}</Status>
          )}
        />
      </Fragment>
    );
  }
);

BatchEditModalIssueStatus.propTypes = propTypes;
BatchEditModalIssueStatus.displayName = 'BatchEditModalIssueStatus';

export default BatchEditModalIssueStatus;
