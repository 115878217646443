import PropTypes from 'prop-types';
import { UserProptypes } from './userType';
import { AttachmentProptypes } from './attachmentType';
import { CommentProptypes } from './commentType';
import { OrganizationProptypes } from './organizationType';

export const IssuePropTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  issueIdentifierId: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  state: PropTypes.string,
  priority: PropTypes.string,
  listPosition: PropTypes.number,
  description: PropTypes.string,
  descriptionText: PropTypes.string,
  estimate: PropTypes.any,
  timeSpent: PropTypes.any,
  timeRemaining: PropTypes.any,
  reporterId: PropTypes.any,
  uuid: PropTypes.string,
  ouid: PropTypes.any,
  studyIUID: PropTypes.any,
  accessionNumber: PropTypes.any,
  patientID: PropTypes.any,
  patientName: PropTypes.string,
  patientGender: PropTypes.string,
  patientBirthdate: PropTypes.any,
  seriesIUID: PropTypes.any,
  modality: PropTypes.any,
  bodypart: PropTypes.any,
  emergency: PropTypes.string,
  deadline: PropTypes.string,
  download: PropTypes.any,
  dicomFilePaths: PropTypes.shape({}),
  zipFileName: PropTypes.any,
  report: PropTypes.any,
  case_id: PropTypes.any,
  srcOrgName: PropTypes.string,
  dstOrgName: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  starredBySrc: PropTypes.any,
  starredByDst: PropTypes.any,
  question: PropTypes.any,
  waive: PropTypes.any,
  difference: PropTypes.number,
  discount: PropTypes.number,
  tags: PropTypes.array,
  creationCompleted: PropTypes.bool,
  department: PropTypes.any,
  doctorInCharge: PropTypes.any,
  doctorsInCharge: PropTypes.array,
  srcOrg: PropTypes.shape(OrganizationProptypes),
  dstOrg: PropTypes.shape(OrganizationProptypes),
  users: PropTypes.arrayOf(PropTypes.shape(UserProptypes)),
  attachments: PropTypes.arrayOf(PropTypes.shape(AttachmentProptypes)),
  comments: PropTypes.arrayOf(PropTypes.shape(CommentProptypes)),
  srcOrgId: PropTypes.number,
  dstOrgId: PropTypes.number,
  userIds: PropTypes.arrayOf(PropTypes.number),
  doctorIdsFromSrcOrg: PropTypes.array,
};
