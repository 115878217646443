import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actionGrid: {
    background: 'black',
  },
  viewportGrid: {
    height: '65vh',
  },
  studyListGrid: {
    height: '25vh',
  },
  studyListPaper: {
    background: 'dimgrey',
  },
  studyListTableContainer: {
    height: '25vh',
  },
  studyListTable: {
    // background: 'black',
  },
  studyListTableHead: {
    // background: 'black',
  },
  studyListTableHeadRow: {
    // background: 'black',
    // color: 'white',
  },
  studyListTableHeadCell: {
    backgroundColor: 'dimgrey',
    color: 'white',
  },
  studyListTableBody: {},
  studyListTableBodyRow: {},
  studyListTableBodyCellLight: {
    backgroundColor: 'darkgrey',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  studyListTableBodyCellDark: {
    backgroundColor: 'grey',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  studyListTableBodyCellSelected: {
    backgroundColor: 'royalblue',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
