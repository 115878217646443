export const diagnosticResultTranslationKey = {
  TO_BE_DIAGNOSED: 'kenshin_case_diagnostic_result_to_be_diagnosed',
  DIAGNOSED_HEALTHY: 'kenshin_case_diagnostic_result_diagnosed_healthy',
  DIAGNOSED_SICK: 'kenshin_case_diagnostic_result_diagnosed_sick',
  DIAGNOSED_UNKNOWN: 'kenshin_case_diagnostic_result_diagnosed_unknown',
};

export const KenshinCaseDiagnosis = {
  TO_BE_DIAGNOSED: 'TO_BE_DIAGNOSED',
  DIAGNOSED_HEALTHY: 'DIAGNOSED_HEALTHY',
  DIAGNOSED_SICK: 'DIAGNOSED_SICK',
  DIAGNOSED_UNKNOWN: 'DIAGNOSED_UNKNOWN',
};
