export const InvalidFileExtensions = new Set([
  'exe',
  'vbs',
  'pif',
  'sh',
  'com',
  'bat',
  'scr',
  'dll',
  'wsh',
  'cab',
  'inf',
  'ins',
  'hlp',
  'hta',
  'js',
  'vb',
  'lnk',
  'cmd',
  'chm',
  'cpl',
  'msi',
  'wsf',
  'reg',
  'vbe',
  'wsc',
]);

export const InvalidFileTypeNames = new Set(['text/x-sh']);
