export const LOCAL = 'local';

export const USER_COMMENT = {
  USERNAME: 'username',
  USER_ID: 'userId',
  USER_TYPE: 'userType',
  FIRSTNAME: 'userFirstName',
  LASTNAME: 'userLastName',
  AVATARURL: 'userAvatarUrl',
  COMMENT_BODY: 'commentBody',
  SUBMIT_TYPE: 'submitType',
  ATTACHMENT_UPLOADED: 'attachmentUploaded',
  FILENAME: 'fileName',
  ISSUE_ID: 'issueId',
  INFO: 'info',
  CURRENT_ORGID: 'currentOrganizationId',
};
