import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { useDropzone } from 'react-dropzone';
import { Container } from './styles';

const propTypes = {
  handleFiles: PropTypes.func.isRequired,
};

const DashboardNewDropZone = ({ handleFiles }) => {
  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   'application/zip': ['.zip', '.rar'],
    // },
    onDrop: (acceptedFiles) => {
      handleFiles(acceptedFiles);
    },
    // multiple: false, // Restrict to a single file
    multiple: true, // Restrict to a single file
    // This will allow folders to be dropped as well
    directory: true,
  });

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Container>
          <em>ファイルをここにドラッグするまたはファイルを選択</em>
          <em>選択できるのはファイルまたはフォルダのどちらか一つのみです</em>
        </Container>
      </div>
    </section>
  );
};

DashboardNewDropZone.propTypes = propTypes;

export default DashboardNewDropZone;
