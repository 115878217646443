import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';
import {
  IssueStatusKenshin,
  IssueStatusToKey,
} from 'shared/constants/kenshin/issues';
import api from 'shared/utils/api';
import { USER_COMMENT } from 'shared/constants/comment';
import { KENSHIN_API } from 'shared/constants/apis';
import localData from 'shared/utils/localData';

const propTypes = {
  project: PropTypes.object,
  issueId: PropTypes.string,
  modalClose: PropTypes.func.isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  confirmModalUpdateData: PropTypes.shape({
    issueId: PropTypes.number.isRequired,
    destination: PropTypes.shape({
      droppableId: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
    }),
    listPosition: PropTypes.number.isRequired,
    userIds: PropTypes.array,
    users: PropTypes.array,
  }),
  confirmModalInfo: PropTypes.shape({
    issueId: PropTypes.number.isRequired,
    destination: PropTypes.shape({
      droppableId: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
    }),
    source: PropTypes.shape({
      droppableId: PropTypes.string,
      index: PropTypes.number,
    }),
    listPosition: PropTypes.number.isRequired,
    userIds: PropTypes.array,
    users: PropTypes.array,
    CasesComplete: PropTypes.bool,
  }),
};

const DashboardKanbanConfirmPopUpWindow = ({
  project,
  modalClose,
  updateLocalProjectIssues,
  confirmModalUpdateData,
  confirmModalInfo,
}) => {
  const [isWorking] = useState(false);

  const updateIssue = (
    issueId,
    destination,
    project,
    listPosition,
    userIds,
    users
  ) => {
    api.optimisticUpdate(
      `${KENSHIN_API.ISSUES}/single/${issueId}`,
      {
        updatedFields: {
          progress: destination.droppableId,
          listPosition: listPosition,
        },
        currentFields: project.issues.find(({ id }) => id === issueId),
        setLocalData: (fields) => updateLocalProjectIssues(issueId, fields),
      },
      {
        params: {
          organizationId: localData.get('currentOrganizationId'),
          username: localData.get('username'),
          userId: localData.get(USER_COMMENT.USER_ID),
        },
        baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      }
    );
  };

  const handleYesButtonClick = () => {
    const { issueId, destination, listPosition } = confirmModalUpdateData;
    updateIssue(issueId, destination, project, listPosition);
    modalClose();
  };

  const specialMsg = (fieldName) => {
    return (
      '以下の変更を行いますか？' +
      '\n' +
      '\n' +
      fieldName +
      '\n' +
      '\n' +
      '変更がある場合は、確認が必要な状態になります。'
    );
  };

  const generateConfirmMessage = (confirmModalInfo) => {
    if (confirmModalInfo.destination === IssueStatusKenshin.DOCTORASSIGNED) {
      return intl.get('kanban_confirm_popup_window_text_doctor_assigned');
    } else if (
      confirmModalInfo.destination === IssueStatusKenshin.REPORTREADY
    ) {
      return intl.get('kanban_confirm_popup_window_text_report_ready');
    } else if (confirmModalInfo.destination === IssueStatusKenshin.SENDBACK) {
      if (confirmModalInfo.CasesComplete) {
        return intl.get('kanban_confirm_popup_window_text_send_back');
      } else {
        // warn that there are incomplete cases;
        return (
          'まだ医師に割り当てられていない、もしくは診断されていない画像があります。' +
          '\n' +
          '\n' +
          'この操作は元に戾せません。続行してもよろしいですか？'
        );
        // todo:dacao add styles
      }
    } else if (
      confirmModalInfo.source === IssueStatusKenshin.DOCTORASSIGNED &&
      confirmModalInfo.destination === IssueStatusKenshin.NEWTASK
    ) {
      const fieldName = intl.get(
        IssueStatusToKey[confirmModalInfo.destination]
      );
      return specialMsg(fieldName);
    }
    return '';
  };

  const confirmMessage = generateConfirmMessage(confirmModalInfo);

  return (
    <Fragment>
      <Message>{confirmMessage}</Message>
      <Actions>
        <StyledButton
          variant="primary"
          isWorking={isWorking}
          onClick={handleYesButtonClick}>
          {intl.get('kanban_confirm_popup_window_button_yes')}
        </StyledButton>
        <StyledButton hollow onClick={modalClose}>
          {intl.get('kanban_confirm_popup_window_button_no')}
        </StyledButton>
      </Actions>
    </Fragment>
  );
};
DashboardKanbanConfirmPopUpWindow.propTypes = propTypes;

export default DashboardKanbanConfirmPopUpWindow;
