import { useState, Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
// import Box from '@mui/material/Box';
// import ConstructionIcon from '@mui/icons-material/Construction';
import CloseIcon from '@mui/icons-material/Close';

// self defined
import api from 'shared/utils/api';

import { updateArrayItemsByIds } from 'shared/utils/javascript';

// import pxToRem from 'assets/theme/functions/pxToRem';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import { useMaterialUIController } from 'context';
import {
  isCurrentUserOrganizationManager,
  isCurrentUserTaskManager,
} from 'shared/utils/privilege';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';
import localData from 'shared/utils/localData';
import { USER_COMMENT } from 'shared/constants/comment';
import { KENSHIN_API } from 'shared/constants/apis';
import { IssueStatusKenshin } from 'shared/constants/kenshin/issues';
import { useStyles } from './styles';
import DoctorSelect from './AssigneesReporter';
import { zIndexValues } from 'shared/utils/styles';

const propTypes = {
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  selectedCases: PropTypes.array.isRequired,
  doctors: PropTypes.array.isRequired,
  updateLocalCase: PropTypes.func,
  setBatchEditModalIsOpen: PropTypes.func,
  updateLocalProjectIssues: PropTypes.func,
  issue: PropTypes.any,
  updateIssue: PropTypes.func,
};

const KenshinCaseBatchEditModal = ({
  isOpened,
  closeModal,
  selectedCases,
  doctors,
  updateLocalCase,
  setBatchEditModalIsOpen,
  updateLocalProjectIssues,
  issue,
  updateIssue,
}) => {
  const classes = useStyles();

  let allCases = issue.cases;

  const firstCase = selectedCases[0];
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();

  const batchUpdateCases = (updatedFields, selectedCases) => {
    api.optimisticBatchUpdateKenshinCases(`${KENSHIN_API.CASES_BATCH_UPDATE}`, {
      selectedCases,
      updatedFields,
      setLocalData: (caseId, fields) => {
        // this is the local action that is being executed
        updateLocalCase(caseId, fields);
      },
    });
  };

  const currentOrganizationId = localData.get(USER_COMMENT.CURRENT_ORGID);
  const srcOrgId = selectedCases[0].srcOrgId.toString();
  const isClient = parseInt(srcOrgId) === parseInt(currentOrganizationId);
  const isTaskManager = isCurrentUserTaskManager();

  const handleConfirmModalYesButtonClick = async () => {
    await batchUpdateCases(confirmModalUpdateData, selectedCases);

    // this code updates the case info of this issue in the `project` of kanban root;
    const ids = selectedCases.map((c) => c.id);
    const updatedCasesArray = updateArrayItemsByIds(
      allCases,
      ids,
      confirmModalUpdateData
    );

    updateLocalProjectIssues(issue.id, {
      cases: updatedCasesArray,
    });

    // If anything about the cases changes when issue is in NEWTASK,
    // we move the issue from NEWTASK to the next status;
    const newProgress =
      issue.progress === IssueStatusKenshin.NEWTASK
        ? IssueStatusKenshin.DOCTORASSIGNED
        : issue.progress;
    await updateIssue({
      progress: newProgress,
    });

    allCases = updatedCasesArray;
    setConfirmModalOpen(false);
    setBatchEditModalIsOpen(false);
  };

  const handleConfirmModalNoButtonClick = () => {
    setConfirmModalOpen(false);
  };

  const tempHeight = '20%';

  return (
    <Fragment>
      <Modal
        disableEscapeKeyDown
        open={isOpened}
        onClose={closeModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '50vw',
          zIndex: zIndexValues.modal2,
          overflow: 'visible',
        }}>
        <Card
          sx={{
            minHeight: '30%',
            maxHeight: '75%',
            width: '50%',
            height: tempHeight,
            margin: 'auto',
          }}>
          <Grid container justify="flex-end">
            <Grid xs={12} item display="flex" justifyContent="flex-end">
              <IconButton
                aria-label="delete"
                size="small"
                variant="empty"
                onClick={closeModal}
                sx={{ paddingRight: 1, paddingTop: 1 }}
                // className={classes.closeIconButton}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>

          <MDBox my={1} ml={4}>
            <MDTypography variant="h5" component="h2">
              {/* {intl.get(
                'kenshin_kanban_case_batch_edit_modal_text_already_selected',
                { arg1: `${selectedCases.length}` }
              )} */}
              {intl
                .get(
                  'kenshin_kanban_case_batch_edit_modal_text_already_selected'
                )
                .replace('{arg1}', `${selectedCases.length}`)}
            </MDTypography>
          </MDBox>
          <MDBox mt={1} mb={1} ml={4}>
            <MDTypography
              variant="h6"
              component="h2"
              style={{ marginTop: '1px' }}>
              {intl.get(
                'kenshin_kanban_case_batch_edit_modal_text_select_doctor_please'
              )}
            </MDTypography>
          </MDBox>

          <Grid container justify="center" alignItems="center">
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <DoctorSelect
                firstCase={firstCase}
                doctors={doctors}
                // setConfirmModalInfo={setConfirmModalInfo}
                setConfirmModalUpdateData={setConfirmModalUpdateData}
                setConfirmModalOpen={setConfirmModalOpen}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Card>
      </Modal>

      {confirmModalOpen && (
        <Modal
          disableEscapeKeyDown
          open={confirmModalOpen}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '50vw',
            zIndex: zIndexValues.modal3,
          }}>
          <Card
            sx={{
              minHeight: '30%',
              maxHeight: '75%',
              width: '50%',
              height: tempHeight,
              margin: 'auto',
            }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <Grid container justify="center" mb={5}>
                <Grid xs={12} item display="flex" justifyContent="flex-start">
                  <MDTypography variant="body1">
                    {intl
                      .get(
                        'kenshin_kanban_case_batch_edit_modal_text_hint_next'
                      )
                      .replace('{arg1}', `${selectedCases.length}`)}
                  </MDTypography>
                </Grid>
              </Grid>
              <Actions>
                <StyledButton
                  variant="primary"
                  onClick={handleConfirmModalYesButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_yes')}
                </StyledButton>
                <StyledButton hollow onClick={handleConfirmModalNoButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_no')}
                </StyledButton>
              </Actions>
            </MDBox>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};

KenshinCaseBatchEditModal.propTypes = propTypes;

export default KenshinCaseBatchEditModal;
