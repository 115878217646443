import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceTo: PropTypes.shape({
    orgName: PropTypes.string,
  }),
  invoiceFrom: PropTypes.shape({
    orgName: PropTypes.string,
    zipCode: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    tel: PropTypes.string,
    fax: PropTypes.string,
    whoInChargeEmail: PropTypes.string,
    whoInChargeName: PropTypes.string,
  }),
};

const InvoiceFromAndTo = ({ invoiceTo, invoiceFrom }) => {
  const { orgName: invoiceToOrgName } = invoiceTo;
  const {
    orgName: invoiceFromOrgName,
    zipCode: invoiceFromZipCode,
    addressLine1: invoiceFromAddressLine1,
    addressLine2: invoiceFromAddressLine2,
    tel: invoiceFromTel,
    fax: invoiceFromFax,
    whoInChargeEmail: invoiceFromWhoInChargeEmail,
    whoInChargeName: invoiceFromWhoInChargeName,
  } = invoiceFrom;

  return (
    <Fragment>
      <View style={styles.invoiceFromAndTo}>
        <View style={styles.invoiceFromAndToLeft}>
          <Text>
            {invoiceToOrgName + intl.get('billing_invoice_text_dear')}
          </Text>
        </View>
        <View style={styles.invoiceFromAndToRight}>
          <Text>{invoiceFromOrgName}</Text>
          <Text>{'〒' + invoiceFromZipCode}</Text>
          <Text>{invoiceFromAddressLine1}</Text>
          <Text>{invoiceFromAddressLine2}</Text>
          <Text>{'TEL：' + invoiceFromTel}</Text>
          <Text>{'FAX：' + invoiceFromFax}</Text>
          <Text>{'E-Mail：' + invoiceFromWhoInChargeEmail}</Text>
          <Text>
            {intl.get('billing_invoice_text_in_charge') +
              invoiceFromWhoInChargeName}
          </Text>
        </View>
      </View>
    </Fragment>
  );
};

InvoiceFromAndTo.propTypes = propTypes;
export default InvoiceFromAndTo;
