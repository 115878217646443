import localData from './localData';
import { USER_DATA } from 'shared/constants/users';
import { Privilege, PrivilegeStatus } from 'shared/constants/privilege';

export const isCurrentUserTaskManager = () => {
  const privilege = JSON.parse(localData.get(USER_DATA.CURRENT_PRIVILEGE));

  return (
    privilege[Privilege.ORGANIZATION_TASK_MANAGEMENT] === PrivilegeStatus.ON
  );
};

export const isCurrentUserOrganizationManager = () => {
  const privilege = JSON.parse(localData.get(USER_DATA.CURRENT_PRIVILEGE));

  return privilege[Privilege.ORGANIZATION_MANAGEMENT] === PrivilegeStatus.ON;
};

export const isCurrentUserPicAsDoctor = () => {
  const privilege = JSON.parse(localData.get(USER_DATA.CURRENT_PRIVILEGE));

  return privilege[Privilege.PERSON_IN_CHARGE_AS_DOCTOR] === PrivilegeStatus.ON;
};

export const getAllTaskMangers = () => {
  return [];
};

export const getAllOrganizationMangers = () => {
  return [];
};

export const getAllPicAsDoctors = () => {
  return [];
};
