import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { Paper, Grid, Typography } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Lists from './Lists';
import Header from './Header';
// import Filters from './Filters';
import IssueDetails from './IssueDetails';
import AlertWindow from './AlertPopupWindow';
import ConfirmPopUpWindow from './ConfirmPopUpWindow';
import { StyledConfirmModal } from 'components/ConfirmModal/Styles';

import useApi from 'shared/hooks/api';
import useMergeState from 'shared/hooks/mergeState';
import { PageLoader, PageError, Modal, RefreshButton } from 'components';
import localData from 'shared/utils/localData';
import { useStyles } from './styles';
import { updateArrayItemById } from 'shared/utils/javascript';

// components and parts
import MDBox from 'components/MDBox';
import { USER_COMMENT } from 'shared/constants/comment';
import { USER_DATA, UserType } from 'shared/constants/users';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { KENSHIN_API } from 'shared/constants/apis';
import { isCurrentUserTaskManager } from 'shared/utils/privilege';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const defaultFilters = {
  searchTerm: '',
  userIds: [],
  srcOrgNames: [],
  myOnly: false,
  recent: false,
};

const DashboardKanbanKenshin = ({ match, history }) => {
  const classes = useStyles();
  const isTaskManager = isCurrentUserTaskManager();

  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalInfo, setAlertModalInfo] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });

  const [filters, mergeFilters] = useMergeState(defaultFilters);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const currentUsername = localData.get('username');
  const userId = localData.get(USER_COMMENT.USER_ID);

  const [{ data, error, setLocalData }, fetchProject] = useApi.get(
    KENSHIN_API.PROJECT,
    {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        dateThres: sevenDaysAgo,
        username: currentUsername,
        userId: userId,
      },
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    }
  );

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { project } = data;

  const updateLocalProjectIssues = (issueId, updateFields) => {
    setLocalData((currentData) => ({
      project: {
        ...currentData.project,
        issues: updateArrayItemById(
          currentData.project.issues,
          issueId,
          updateFields
        ),
      },
    }));
  };

  const updateLocalProjectWithMoreDoneIssues = (updatedIssues) => {
    setLocalData((currentData) => ({
      project: {
        ...currentData.project,
        issues: updatedIssues,
      },
    }));
  };

  const handleRefreshClick = async () => {
    setIsRefreshing(true);
    await fetchProject();
    setIsRefreshing(false);
  };

  return isTaskManager === true ? (
    <Fragment>
      <MDBox py={3} px={3} mt={2} mx={3}>
        <Stack direction="row" spacing={2}>
          <Header organizationName={project.name} />
          <RefreshButton
            tooltipTitle={intl.get('dashboard_refresh_button_tooltip_title')}
            handleRefreshClick={handleRefreshClick}
            isRefreshing={isRefreshing}
          />
        </Stack>

        {/* // todo:dacao comment out for demo;  recover later; */}
        {/* <Grid container>
          <Grid item xs={12}>
            <Filters
              sourceOrganizations={project.quotaSrcOrgs}
              // projectUsers={project.users}
              doctors={project.doctors}
              defaultFilters={defaultFilters}
              filters={filters}
              mergeFilters={mergeFilters}
            />
          </Grid>

          <Grid item xs={2} container justifyContent="flex-end"></Grid>
        </Grid> */}

        <Lists
          project={project}
          fetchProject={fetchProject}
          filters={filters}
          updateLocalProjectIssues={updateLocalProjectIssues}
          setAlertModalOpen={setAlertModalOpen}
          setAlertModalInfo={setAlertModalInfo}
          setConfirmModalInfo={setConfirmModalInfo}
          setConfirmModalOpen={setConfirmModalOpen}
          setConfirmModalUpdateData={setConfirmModalUpdateData}
          updateLocalProjectWithMoreDoneIssues={
            updateLocalProjectWithMoreDoneIssues
          }
        />

        <Route
          path={`${match.path}/issues/:issueId`}
          render={(routeProps) => (
            <Modal
              isOpen
              testid="modal:issue-details"
              width={1540}
              minWidth="800px"
              withCloseIcon={false}
              onClose={() => history.push(match.url)}
              renderContent={(modal) => (
                <IssueDetails
                  issueId={routeProps.match.params.issueId}
                  trigger={
                    routeProps.location.state
                      ? routeProps.location.state.trigger
                      : 'byClick'
                  }
                  // projectUsers={projecte.users}
                  doctors={project.doctors}
                  fetchProject={fetchProject}
                  updateLocalProjectIssues={updateLocalProjectIssues}
                  modalClose={modal.close}
                />
              )}
            />
          )}
        />

        {alertModalOpen && (
          <StyledConfirmModal
            isOpen
            testid="modal:alert"
            width={740}
            withCloseIcon={false}
            onClose={() => {
              setAlertModalOpen(false);
            }}
            renderContent={(modal) => (
              <AlertWindow
                modalClose={modal.close}
                alertModalInfo={alertModalInfo}
              />
            )}
          />
        )}

        {confirmModalOpen && (
          <StyledConfirmModal
            isOpen
            testid="modal:confirm"
            width={740}
            withCloseIcon={false}
            onClose={() => {
              setConfirmModalOpen(false);
            }}
            renderContent={(modal) => (
              <ConfirmPopUpWindow
                project={project}
                modalClose={modal.close}
                updateLocalProjectIssues={updateLocalProjectIssues}
                confirmModalUpdateData={confirmModalUpdateData}
                confirmModalInfo={confirmModalInfo}
              />
            )}
          />
        )}
      </MDBox>
    </Fragment>
  ) : (
    <Fragment>
      <Paper className={classes.paper} elevation={10}>
        <Typography variant="h4" gutterBottom>
          {intl.get('kenshin_kanban_not_allowed_message')}
        </Typography>
        <br />
        <br />
      </Paper>
    </Fragment>
  );
};

DashboardKanbanKenshin.propTypes = propTypes;

export default withRouter(DashboardKanbanKenshin);
