import PropTypes from 'prop-types';

export const QuotaProptypes = {
  id: PropTypes.number,
  srcOrgName: PropTypes.string,
  dstOrgName: PropTypes.string,
  quota: PropTypes.number,
  used: PropTypes.number,
  monthlyInvoiceDate: PropTypes.number,
  lastInvoiceIssueDate: PropTypes.any,
  srcOrgId: PropTypes.number,
  dstOrgId: PropTypes.number,
};

export const ClientFormPropTypes = {
  quotaOrgId: PropTypes.number,
  srcOrgId: PropTypes.number,
  dstOrgId: PropTypes.number,
  quota: PropTypes.number,
  monthlyInvoiceDate: PropTypes.number,
  priceAndStates: PropTypes.array,
};
