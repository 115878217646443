import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';

import {
  Grid,
  Card,
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import FormField from 'pages/parts/FormField';
import { Button } from 'components';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
  setInvoiceContent: PropTypes.func.isRequired,
  setPreviewContent: PropTypes.func.isRequired,
};

const DashboardBillingForReceivedTasksInvoiceEditorEditor = ({
  invoiceContent,
  setInvoiceContent,
  setPreviewContent,
}) => {
  const handleTestClick = () => {
    setInvoiceContent((prevState) => {
      return {
        ...prevState,
        invoiceDate: Date.now().toLocaleString(),
      };
    });
  };

  const handleChangeContent = (key) => async (event) => {
    setInvoiceContent((prevState) => {
      return {
        ...prevState,
        [key]: event.target.value,
      };
    });
  };

  const handleRerenderButtonClick = () => {
    setPreviewContent((prevState) => ({
      ...invoiceContent,
    }));
  };

  return (
    <Fragment>
      <Card square={true} sx={{ overflow: 'visible' }}>
        <MDBox py={3} px={3} mt={1} mx={3}>
          <MDBox pl={1} mb={2} display="center" justifyContent="center">
            <MDTypography variant="h5">
              {intl.get('billing_invoice_editor_text_title')}
            </MDTypography>
          </MDBox>

          <Grid spcing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_editor_label_date')}
                  onChange={handleChangeContent('invoiceDate')}
                  value={invoiceContent.invoiceDate}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <Button
                  variant="primary"
                  sx={{ justifyContent: 'start', width: '60%' }}
                  onClick={handleRerenderButtonClick}>
                  Rerender
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_editor_label_to')}
                  onChange={handleChangeContent('invoiceTo')}
                  value={invoiceContent.invoiceTo}
                  multiline
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_editor_label_rep_name')}
                  onChange={handleChangeContent('invoiceTo2')}
                  value={invoiceContent.invoiceTo2}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_editor_label_from')}
                  onChange={handleChangeContent('invoiceFrom')}
                  value={invoiceContent.invoiceFrom}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_editor_label_bill_title')}
                  onChange={handleChangeContent('invoiceDetailedTitle')}
                  value={invoiceContent.invoiceDetailedTitle}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_editor_label_total_amount')}
                  onChange={handleChangeContent('invoiceTotalAmount')}
                  startAdornment={
                    <InputAdornment position="start">¥</InputAdornment>
                  }
                  value={invoiceContent.invoiceTotalAmount}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_editor_label_tax_rate')}
                  onChange={handleChangeContent('invoiceTaxRate')}
                  value={invoiceContent.invoiceTaxRate}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'billing_invoice_editor_label_total_amount_including_tax'
                  )}
                  onChange={handleChangeContent(
                    'invoiceTotalAmountIncludingTax'
                  )}
                  startAdornment={
                    <InputAdornment position="start">¥</InputAdornment>
                  }
                  value={invoiceContent.invoiceTotalAmountIncludingTax}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_text_transfer_destination')}
                  onChange={handleChangeContent('invoicePaymentMethod')}
                  value={invoiceContent.invoicePaymentMethod}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('billing_invoice_text_remarks')}
                  onChange={handleChangeContent('invoiceRemarks')}
                  value={invoiceContent.invoiceRemarks}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardBillingForReceivedTasksInvoiceEditorEditor.propTypes = propTypes;

export default withRouter(DashboardBillingForReceivedTasksInvoiceEditorEditor);
