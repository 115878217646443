import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import WageAdjustment from './WageAdjustment';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

const DashboardPaidWageDetailedTableCustomToolbarSelect = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WageAdjustment
        selectedRows={selectedRows}
        data={data}
        setData={setData}
      />
    </div>
  );
};

DashboardPaidWageDetailedTableCustomToolbarSelect.propTypes = propTypes;

export default withRouter(DashboardPaidWageDetailedTableCustomToolbarSelect);
