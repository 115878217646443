import localData from './localData';
import moment from 'moment-timezone';

const toLocalTime = (srcTimeRepresentation) => {
  const srcTime = new Date(srcTimeRepresentation);
  const tgtTime = srcTime.toString();
  return tgtTime;
};

export const formatDateTimeJa = (datetimeString) => {
  // Parse the input string with Moment.js, considering the timezone
  const deadline = moment(datetimeString).tz('Asia/Tokyo');

  // Format the date and time components in Japanese, excluding seconds
  return deadline.format('YYYY年MM月DD日 HH時mm分');
};

export const formatDateTimeZh = (datetimeString) => {
  const deadline = moment(datetimeString).tz('Asia/Beijing');
  return deadline.format('YYYY年MM月DD日 HH时mm分');
};

const formatDeadlineJa = (deadlineString) => {
  return formatDateTimeJa(deadlineString);
};

const formatDeadlineZh = (deadlineString) => {
  return formatDateTimeZh(deadlineString);
};

const formatDeadlineEn = (deadlineString) => {
  const deadline = new Date(deadlineString);

  if (deadline.getHours() < 9) {
    deadline.setDate(deadline.getDate() - 1);
  }
  const deadlineYear = deadline.getFullYear();
  const deadlineMonth = deadline.getMonth() + 1;
  const deadlineDate = deadline.getDate();
  const deadlineHour = deadline.getHours();
  var deadlineHourString = ' by';
  if (deadlineHour < 9) {
    deadlineHourString += ' 18:00'; // 18:00
  } else if (deadlineHour < 12) {
    deadlineHourString += ' 09:00'; // 09:00
  } else if (deadlineHour < 15) {
    deadlineHourString += ' 12:00'; // 12:00
  } else if (deadlineHour < 18) {
    deadlineHourString += ' 15:00'; // 15:00
  } else {
    deadlineHourString += ' 18:00'; // 18:00
  }
  const formatedDeadline =
    deadlineYear.toString() +
    '-' +
    deadlineMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    '-' +
    deadlineDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    deadlineHourString;
  return formatedDeadline;
};

const formatDeadline = (timeRep) => {
  const timeStringLocal = timeRep;

  const lang = localData.get('userPreferredLanguage');

  var formatedDeadline = '';
  if (lang === 'zh') {
    formatedDeadline = formatDeadlineZh(timeStringLocal);
  } else if (lang === 'ja-JP') {
    formatedDeadline = formatDeadlineJa(timeStringLocal);
  } else {
    formatedDeadline = formatDeadlineEn(timeStringLocal);
  }

  return formatedDeadline;
};

const formatDeadlineForEmergency = (timeRep) => {
  // timeRep is short for time representation
  const timeStringLocal = toLocalTime(timeRep);
  const deadline = new Date(timeStringLocal);
  const deadlineYear = deadline.getFullYear();
  const deadlineMonth = deadline.getMonth() + 1;
  const deadlineDate = deadline.getDate();
  const deadlineHour = deadline.getHours();
  const deadlineMinutes = deadline.getMinutes();

  const formatedDeadline =
    deadlineYear.toString() +
    '-' +
    deadlineMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    '-' +
    deadlineDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    ' by ' +
    deadlineHour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    ':' +
    deadlineMinutes.toLocaleString(undefined, { minimumIntegerDigits: 2 });

  return formatedDeadline;
};

const calculateDeadlineType = (timeRep) => {
  const deadline = new Date(timeRep);
  const now = new Date();

  const timeDiff = deadline - now;

  if (timeDiff < 0) {
    return 'passed';
  } else if (timeDiff < 1800000) {
    return '30mins';
  } else if (timeDiff < 3600000) {
    return '1hour';
  } else if (timeDiff < 10800000) {
    return '3hours';
  } else if (timeDiff < 86400000) {
    return '24hours';
  } else {
    return '7days';
  }
};

const formatDeadlineWithType = (timeRep) => {
  const type = calculateDeadlineType(timeRep);

  const formatedDeadline =
    type === '7days'
      ? formatDeadline(timeRep)
      : formatDeadlineForEmergency(timeRep);

  return { type, formatedDeadline };
};

// const formatTimeFromNowZh = (timeRep) => {
//   return '';
// };

// const formatTimeFromNowJa = (timeRep) => {
//   return '';
// };

// const formatTimeFromNowEn = (timeRep) => {
//   return '';
// };

// const formatTimeFromNow = (timeRep) => {
//   // timeRep is short for time representation
//   const timeStringLocal = toLocalTime(timeRep);

//   const lang = localData.get('userPreferredLanguage');

//   var formatedTimeFromNow = '';
//   if (lang === 'zh') {
//     formatedTimeFromNow = formatTimeFromNowZh(timeStringLocal);
//   } else if (lang === 'ja') {
//     formatedTimeFromNow = formatTimeFromNowJa(timeStringLocal);
//   } else {
//     // the default language is English
//     formatedTimeFromNow = formatTimeFromNowEn(timeStringLocal);
//   }
//   return formatedTimeFromNow;
// };

const formatTimeZh = (timeRep) => {
  const time = new Date(timeRep);

  const daysZh = ['日', '一', '二', '三', '四', '五', '六']; // Chinese days of the week.
  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1; // Adjust for zero-indexed months.
  const timeDate = time.getDate();
  const timeDayString = `星期${daysZh[time.getDay()]}`; // Use array to map day number to Chinese string.

  // Format the date string using template literals and padStart for zero-padding, with Chinese characters.
  return `${timeYear}年${String(timeMonth).padStart(2, '0')}月${String(
    timeDate
  ).padStart(2, '0')}日 ${timeDayString}`;
};

// const formatTimeJa = (timeRep) => {
//   const time = new Date(timeRep);
//   const timeYear = time.getFullYear();
//   const timeMonth = time.getMonth() + 1;
//   const timeDate = time.getDate();
//   const timeHours = time.getHours();
//   const timeMinutes = time.getMinutes();
//   const formatedTime =
//     timeYear.toString() +
//     '-' +
//     timeMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
//     '-' +
//     timeDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
//     ' ' +
//     timeHours.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
//     ':' +
//     timeMinutes.toLocaleString(undefined, { minimumIntegerDigits: 2 });
//   return formatedTime;
// };

const formatTimeJa = (timeRep) => {
  const time = new Date(timeRep);

  const daysJa = ['日', '月', '火', '水', '木', '金', '土']; // Japanese days of the week.
  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1; // Adjust for zero-indexed months.
  const timeDate = time.getDate();
  const timeDayString = daysJa[time.getDay()]; // Use array to map day number to Japanese string.

  // Format the date string using template literals and padStart for zero-padding, with Japanese characters.
  return `${timeYear}年${String(timeMonth).padStart(2, '0')}月${String(
    timeDate
  ).padStart(2, '0')}日 (${timeDayString})`;
};

// const formatTimeEn = (timeRep) => {
//   const time = new Date(timeRep);

//   const timeYear = time.getFullYear();
//   const timeMonth = time.getMonth() + 1;
//   const timeDate = time.getDate();
//   const timeDay = time.getDay();
//   var timeDayString = '';
//   switch (timeDay) {
//     case 0:
//       timeDayString = 'Sun';
//       break;
//     case 1:
//       timeDayString = 'Mon';
//       break;
//     case 2:
//       timeDayString = 'Tue';
//       break;
//     case 3:
//       timeDayString = 'Wed';
//       break;
//     case 4:
//       timeDayString = 'Thu';
//       break;
//     case 5:
//       timeDayString = 'Fri';
//       break;
//     case 6:
//       timeDayString = 'Sat';
//       break;
//     default:
//       console.log('Wrong Day!');
//       break;
//   }
//   const formatedTime =
//     timeYear.toString() +
//     '-' +
//     timeMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
//     '-' +
//     timeDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
//     ' ' +
//     timeDayString;
//   return formatedTime;
// };

const formatTimeEn = (timeRep) => {
  const time = new Date(timeRep);

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1; // Adjust for zero-indexed months.
  const timeDate = time.getDate();
  const timeDayString = days[time.getDay()]; // Use array to map day number to string.

  // Format the date string using template literals and padStart for zero-padding.
  return `${timeYear}-${String(timeMonth).padStart(2, '0')}-${String(
    timeDate
  ).padStart(2, '0')} ${timeDayString}`;
};

const formatTime = (timeRep) => {
  // timeRep is short for time representation
  const timeStringLocal = toLocalTime(timeRep);

  const lang = localData.get('userPreferredLanguage');

  var formatedTime = '';
  if (lang === 'zh-CN') {
    formatedTime = formatTimeZh(timeStringLocal);
  } else if (lang === 'ja-JP') {
    formatedTime = formatTimeJa(timeStringLocal);
  } else {
    // the default language is English
    formatedTime = formatTimeEn(timeStringLocal);
  }

  return formatedTime;
};

export {
  formatDeadline,
  formatDeadlineForEmergency,
  formatDeadlineWithType,
  // formatTimeFromNow,
  formatTime,
};
