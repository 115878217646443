import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const InvoiceTotalAmount = ({ invoiceContent }) => {
  return (
    <Fragment>
      <View style={styles.invoiceTotalAmount}>
        <Text style={styles.invoiceTotalAmountText}>
          {intl.get('billing_invoice_text_total_amount') +
            invoiceContent.invoiceTotalAmountIncludingTax}
        </Text>
      </View>
    </Fragment>
  );
};

InvoiceTotalAmount.propTypes = propTypes;

export default InvoiceTotalAmount;
