import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import api from 'shared/utils/api';
import {
  moveItemWithinArray,
  insertItemIntoArray,
} from 'shared/utils/javascript';
import { IssueStatus, IssueState } from 'shared/constants/issues';
import localData from 'shared/utils/localData';

import List from './List';
import { Lists } from './Styles';
import { ProjectProptypes } from 'shared/propTypes/projectType';
import { FilterProptypes } from 'shared/propTypes/filterType';
import { USER_COMMENT } from 'shared/constants/comment';
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';

const propTypes = {
  project: PropTypes.shape(ProjectProptypes).isRequired,
  filters: PropTypes.shape(FilterProptypes).isRequired,
  fetchProject: PropTypes.func.isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  setAlertModalOpen: PropTypes.func.isRequired,
  setAlertModalInfo: PropTypes.func.isRequired,
  setConfirmModalOpen: PropTypes.func.isRequired,
  setConfirmModalUpdateData: PropTypes.func.isRequired,
  setConfirmModalInfo: PropTypes.func.isRequired,
  updateLocalProjectWithMoreDoneIssues: PropTypes.func.isRequired,
};

const MODAL_INFO = {
  MOVE_FORWARD_WITH_WARNING: 'moveForwardWithWarning',
  INVALID_DESTINATION: 'invalidDestination',
};

const ProjectBoardLists = ({
  project,
  fetchProject,
  filters,
  updateLocalProjectIssues,
  setAlertModalOpen,
  setAlertModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
  setConfirmModalInfo,
  updateLocalProjectWithMoreDoneIssues,
}) => {
  const currentUserId = localData.get(USER_COMMENT.USER_ID);
  const match = useRouteMatch();
  const history = useHistory();

  const updateIssue = (issueId, destination, project, listPosition) => {
    api.optimisticUpdate(`${DOLPHIN_API.ISSUES}/${issueId}`, {
      updatedFields: {
        status: destination.droppableId,
        listPosition: listPosition,
      },
      currentFields: project.issues.find(({ id }) => id === issueId),
      setLocalData: (fields) => updateLocalProjectIssues(issueId, fields),
    });
  };

  const openConfirmModal = useCallback(
    (
      issueStateOverride,
      confirmModalInfoDefault,
      issueId,
      source,
      destination,
      listPosition,
      clearAssignedDoctors = false // default parameter added
    ) => {
      setConfirmModalInfo({
        ...confirmModalInfoDefault,
        issueState: issueStateOverride,
      });
      setConfirmModalOpen(true);
      setConfirmModalUpdateData({
        issueId,
        source,
        destination,
        listPosition,
        issueState: issueStateOverride,
        ...(clearAssignedDoctors && { userIds: [], users: [] }), // conditionally include properties
        // userIds: [],
        // users: [],
      });
    },
    [setConfirmModalInfo, setConfirmModalOpen, setConfirmModalUpdateData]
  );

  const openAlertModal = useCallback(
    (alertInfo) => {
      setAlertModalInfo(alertInfo);
      setAlertModalOpen(true);
    },
    [setAlertModalInfo, setAlertModalOpen]
  );

  const handleNewTaskDrag = useCallback(
    (
      issueState,
      destination,
      issueId,
      source,
      listPosition,
      confirmModalInfoDefault
    ) => {
      if (destination.droppableId === IssueStatus.CHECKREADY) {
        if (issueState === IssueState.NORMAL) {
          openConfirmModal(
            issueState,
            confirmModalInfoDefault,
            issueId,
            source,
            destination,
            listPosition
          );
        } else if (issueState === IssueState.WARNING) {
          openAlertModal(MODAL_INFO.MOVE_FORWARD_WITH_WARNING);
        }
      } else {
        openAlertModal(MODAL_INFO.INVALID_DESTINATION);
      }
    },
    [openConfirmModal, openAlertModal]
  );

  const handleCheckReadyDrag = useCallback(
    (
      issueState,
      destination,
      issueId,
      source,
      listPosition,
      confirmModalInfoDefault
    ) => {
      if (destination.droppableId === IssueStatus.DOCTORASSIGNED) {
        if (issueState === IssueState.NORMAL) {
          history.push({
            pathname: `${match.url}/issues/${issueId}`,
            state: { trigger: 'byDrop' },
          });
        } else if (issueState === IssueState.WARNING) {
          openAlertModal(MODAL_INFO.MOVE_FORWARD_WITH_WARNING);
        }
      } else if (destination.droppableId === IssueStatus.NEWTASK) {
        openConfirmModal(
          IssueState.WARNING,
          confirmModalInfoDefault,
          issueId,
          source,
          destination,
          listPosition
        );
      } else {
        openAlertModal(MODAL_INFO.INVALID_DESTINATION);
      }
    },
    [history, match.url, openConfirmModal, openAlertModal]
  );

  const handleDoctorAssignedDrag = useCallback(
    (
      issueState,
      destination,
      issueId,
      source,
      listPosition,
      confirmModalInfoDefault
    ) => {
      if (destination.droppableId === IssueStatus.REPORTREADY) {
        if (issueState === IssueState.NORMAL) {
          openConfirmModal(
            issueState,
            confirmModalInfoDefault,
            issueId,
            source,
            destination,
            listPosition
          );
        } else if (issueState === IssueState.WARNING) {
          openAlertModal(MODAL_INFO.MOVE_FORWARD_WITH_WARNING);
        }
      } else if (
        destination.droppableId === IssueStatus.NEWTASK ||
        destination.droppableId === IssueStatus.CHECKREADY
      ) {
        const clearAssignedDoctors = true;
        openConfirmModal(
          IssueState.WARNING,
          confirmModalInfoDefault,
          issueId,
          source,
          destination,
          listPosition,
          clearAssignedDoctors
        );
      } else {
        openAlertModal(MODAL_INFO.INVALID_DESTINATION);
      }
    },
    [openConfirmModal, openAlertModal]
  );

  const handleReportReadyDrag = useCallback(
    (
      issueState,
      destination,
      issueId,
      source,
      listPosition,
      confirmModalInfoDefault
    ) => {
      if (destination.droppableId === IssueStatus.SENDBACK) {
        if (issueState === IssueState.NORMAL) {
          openConfirmModal(
            issueState,
            confirmModalInfoDefault,
            issueId,
            source,
            destination,
            listPosition
          );
        } else if (issueState === IssueState.WARNING) {
          openAlertModal(MODAL_INFO.MOVE_FORWARD_WITH_WARNING);
        }
      } else if (
        destination.droppableId === IssueStatus.NEWTASK ||
        destination.droppableId === IssueStatus.CHECKREADY
      ) {
        const clearAssignedDoctors = true;
        openConfirmModal(
          IssueState.WARNING,
          confirmModalInfoDefault,
          issueId,
          source,
          destination,
          listPosition,
          clearAssignedDoctors
        );
      } else if (destination.droppableId !== IssueStatus.DONE) {
        openConfirmModal(
          IssueState.WARNING,
          confirmModalInfoDefault,
          issueId,
          source,
          destination,
          listPosition
        );
      } else {
        openAlertModal(MODAL_INFO.INVALID_DESTINATION);
      }
    },
    [openConfirmModal, openAlertModal]
  );

  const handleSendBackDrag = useCallback(
    (
      issueState,
      destination,
      issueId,
      source,
      listPosition,
      confirmModalInfoDefault
    ) => {
      if (
        destination.droppableId === IssueStatus.NEWTASK ||
        destination.droppableId === IssueStatus.CHECKREADY
      ) {
        const clearAssignedDoctors = true;
        openConfirmModal(
          IssueState.WARNING,
          confirmModalInfoDefault,
          issueId,
          source,
          destination,
          listPosition,
          clearAssignedDoctors
        );
      } else if (destination.droppableId !== IssueStatus.DONE) {
        openConfirmModal(
          IssueState.WARNING,
          confirmModalInfoDefault,
          issueId,
          source,
          destination,
          listPosition
        );
      } else {
        openAlertModal(MODAL_INFO.INVALID_DESTINATION);
      }
    },
    [openConfirmModal, openAlertModal]
  );

  const HandleIssueDrop = ({ draggableId, destination, source }) => {
    // Judge whether the drag from and the drop to the same position
    if (!isPositionChanged(destination, source)) return;

    const issueId = Number(draggableId);
    const issueState = project.issues.filter((issue) => issue.id === issueId)[0]
      .state;
    // const issue = project.issues.filter((issue) => issue.id === issueId);
    const listPosition = calculateIssueListPosition(
      project.issues,
      destination,
      source,
      issueId
    );
    const confirmModalInfoDefault = {
      source: source.droppableId,
      destination: destination.droppableId,
      issueState: issueState,
    };

    switch (source.droppableId) {
      case destination.droppableId:
        updateIssue(issueId, destination, source, project, listPosition);
        break;
      case IssueStatus.NEWTASK:
        handleNewTaskDrag(
          issueState,
          destination,
          issueId,
          source,
          listPosition,
          confirmModalInfoDefault
        );
        break;
      case IssueStatus.CHECKREADY:
        handleCheckReadyDrag(
          issueState,
          destination,
          issueId,
          source,
          listPosition,
          confirmModalInfoDefault
        );
        break;
      case IssueStatus.DOCTORASSIGNED:
        handleDoctorAssignedDrag(
          issueState,
          destination,
          issueId,
          source,
          listPosition,
          confirmModalInfoDefault
        );
        break;
      case IssueStatus.REPORTREADY:
        handleReportReadyDrag(
          issueState,
          destination,
          issueId,
          source,
          listPosition,
          confirmModalInfoDefault
        );
        break;
      case IssueStatus.SENDBACK:
        handleSendBackDrag(
          issueState,
          destination,
          issueId,
          source,
          listPosition,
          confirmModalInfoDefault
        );
        break;
      default:
        break;
    }
  };

  return (
    <DragDropContext onDragEnd={HandleIssueDrop}>
      <Lists>
        {Object.values(IssueStatus).map((status, index) => (
          <List
            key={index}
            status={status}
            project={project}
            fetchProject={fetchProject}
            filters={filters}
            currentUserId={currentUserId}
            updateLocalProjectWithMoreDoneIssues={
              updateLocalProjectWithMoreDoneIssues
            }
          />
        ))}
      </Lists>
    </DragDropContext>
  );
};

const isPositionChanged = (destination, source) => {
  if (!destination) return false;
  const isSameList = destination.droppableId === source.droppableId;
  const isSamePosition = destination.index === source.index;
  return !isSameList || !isSamePosition;
};

const calculateIssueListPosition = (...args) => {
  const { prevIssue, nextIssue } = getAfterDropPrevNextIssue(...args);
  let position;

  if (!prevIssue && !nextIssue) {
    position = 1;
  } else if (!prevIssue) {
    position = nextIssue.listPosition - 1;
  } else if (!nextIssue) {
    position = prevIssue.listPosition + 1;
  } else {
    position =
      prevIssue.listPosition +
      (nextIssue.listPosition - prevIssue.listPosition) / 2;
  }
  return position;
};

const getAfterDropPrevNextIssue = (
  allIssues,
  destination,
  source,
  droppedIssueId
) => {
  const beforeDropDestinationIssues = getSortedListIssues(
    allIssues,
    destination.droppableId
  );
  const droppedIssue = allIssues.find((issue) => issue.id === droppedIssueId);
  const isSameList = destination.droppableId === source.droppableId;

  const afterDropDestinationIssues = isSameList
    ? moveItemWithinArray(
        beforeDropDestinationIssues,
        droppedIssue,
        destination.index
      )
    : insertItemIntoArray(
        beforeDropDestinationIssues,
        droppedIssue,
        destination.index
      );

  return {
    prevIssue: afterDropDestinationIssues[destination.index - 1],
    nextIssue: afterDropDestinationIssues[destination.index + 1],
  };
};

const getSortedListIssues = (issues, status) =>
  issues
    .filter((issue) => issue.status === status)
    .sort((a, b) => a.listPosition - b.listPosition);

ProjectBoardLists.propTypes = propTypes;

export default ProjectBoardLists;
