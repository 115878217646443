import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import Header from '../components/Header';
import ItemCard from '../components/ItemCard';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const DashboardResearchAttachments = ({ match, history }) => {
  const attachmentIds = [1, 2, 3, 4, 5, 6, 7];
  return (
    <Header title={'All Attachments'} subTitle="7 cases in total">
      <MDBox pt={2} px={2} lineHeight={1.25}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                All of the Attachments I uploaded
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                This is the paragraph where you can write more details about
                your blablabla
              </MDTypography>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={5} sx={{ textAlign: 'right' }}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setNewProjectModalOpen(true);
              }}>
              <Icon>add</Icon>&nbsp; Add New Project
            </MDButton>
          </Grid> */}
        </Grid>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={6}>
          {attachmentIds.map((id) => (
            <Grid key={id} item xs={12} md={6} xl={3}>
              <ItemCard
                label={
                  id > 2
                    ? `Joint Research (Todai) - Case ${id}`
                    : `Joint Research (Jikei) - Case ${id}`
                }
                title={'Attachment Name'}
                description={'56.2 Mb'}
                action={{
                  type: 'internal',
                  route: `${match.url}/${id}`,
                  color: 'info',
                  label: 'download',
                }}
                // authors={[
                //   { image: team1, name: 'Elena Morison' },
                //   { image: team2, name: 'Ryan Milly' },
                //   { image: team3, name: 'Nick Daniel' },
                //   { image: team4, name: 'Peterson' },
                // ]}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </Header>
  );
};

DashboardResearchAttachments.propTypes = propTypes;

export default withRouter(DashboardResearchAttachments);
