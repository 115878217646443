/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import typography from 'assets/theme/base/typography';

// Material Dashboard 2 PRO React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';
import rgba from 'assets/theme/functions/rgba';

const { size, fontWeightMedium } = typography;

const stepLabel = {
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} !important`,
      fontWeight: fontWeightMedium,
      fontSize: size.sm,
      color: `${rgba('#206ca4', 0.4)}`,
      textTransform: 'none',

      '&.Mui-active': {
        fontWeight: `${fontWeightMedium}`,
        color: `${rgba('#206ca4', 1.0)}`,
      },

      '&.Mui-completed': {
        fontWeight: `${fontWeightMedium}`,
        color: `${rgba('#206ca4', 1.0)}`,
      },
    },
  },
};

export default stepLabel;
