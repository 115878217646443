export const DOLPHIN_API = {
  CLIENT_MANAGEMENT: '/api/organizations/client_management',
  CLIENT_MANAGEMENT_TEMPLATE_API:
    '/api/organizations/client_management/templates',
  ORGANIZATION_INFO: '/api/organizations/organization_info',
  ORGANIZATION_PREFERENCE: '/api/organizations/organization_preference',
  ORGANIZATION_NEW: '/api/organizations/new',
  TEMPLATE_MANAGEMENT: '/api/organizations/template_management',
  USER_MANAGEMENT: '/api/organizations/user_management',
  TODOS: '/api/organizations/todos',
  PROJECT: '/api/organizations/project',
  BILLING_RECEIVED_TASKS: '/api/billing_received_tasks',
  BILLING_SENT_TASKS: '/api/billing_sent_tasks',
  PERSONAL_INFO: '/api/user/personal_info',
  ISSUES_RECEIVED: '/api/issues/received',
  ISSUES_SENT: '/api/issues/sent',
  USERS: '/api/user',
  LOGS: '/api/logs',
  ISSUES: '/api/issues',
  ISSUES_BATCH_UPDATE: '/api/issues/batch-update',
  ISSUE_SEQUENCE_PREVIOUS_ISSUE_ID: '/api/issue-sequence/previous-issue-id',
  COMMENTS: '/api/comments',
  ATTACHMENT: '/api/attachment',
  DOWNLOAD: '/api/download',
  NOTIFICATIONS: '/api/notifications',
  ADMIN_CONTRACTS: '/api/admin/contracts',
  ADMIN_ORGS: '/api/admin/orgs',
  ADMIN_EMPLOYMENTS: '/api/admin/employments',
  ADMIN_USERS: '/api/admin/users',
  ADMIN_ISSUES: '/api/admin/issues',
  ADMIN_PRICES: '/api/admin/prices',
  USER_SERVICE: '/api/user/service-terms',
  RESEARCH_PROJECTS: '/api/research/projects',
  RESEARCH_CASES: '/api/research/cases',
  RESEARCH_COMMENTS: '/api/research/comments',
  RESEARCH_ATTACHMENT: '/api/research/attachment',
  FILL_DATA: '/api/admin/fillData',
  EMPLOYMENT_PRIVILEGE: '/api/employments/privilege',
  REPORTS: '/api/reports',
  PAID_WAGE: '/api/paid-wage',
  MY_WAGE: '/api/my-wage',
  BILLS_RECEIVED_TASKS: '/api/bills-received-tasks',
  BILLS_SENT_TASKS: '/api/bills-sent-tasks',
  CHECK_ITMES: '/api/checkItems',
  TRY_CREATE_BILL: '/api/triggers/tryCreateBill',
  SEARCH: '/api/search',
};

export const KENSHIN_API = {
  ASSIGNMENT_RECEIVED: '/kenshinApi/assignments/received',
  ADD_DIAGNOSIS: '/kenshinApi/assignments/add-diagnosis',
  ISSUES: '/kenshinApi/issues',
  ISSUES_RECEIVED: '/kenshinApi/issues/received',
  ISSUES_SENT: '/kenshinApi/issues/sent',
  ISSUES_BATCH_UPDATE: '/kenshinApi/issues/batch-update',
  ATTACHMENT: '/kenshinApi/attachment',
  PROJECT: '/kenshinApi/organizations/project',
  CASES_BATCH_UPDATE: '/kenshinApi/cases/batch-update',
  BILLS_RECEIVED_ISSUES: '/kenshinApi/bills/receivedIssues',
  BILLS_SENT_ISSSUES: '/kenshinApi/bills/sentIssues',
  PAID_WAGE: '/kenshinApi/wages/paid',
  MY_WAGE: '/kenshinApi/wages/my',
  DEV: '/kenshinApi/dev',
};

export const LOGOUT_URL = 'https://demo.docloud.iwg-inc.co.jp/';
