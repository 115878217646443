import PropTypes from 'prop-types';

export const UserProptypes = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  username: PropTypes.string,
  userId: PropTypes.any,
  uuid: PropTypes.string,
  userType: PropTypes.string,
  avatarUrl: PropTypes.any,
  avatarLargeUrl: PropTypes.any,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  preferredLanguage: PropTypes.string,
  preferredTimezone: PropTypes.any,
  agreedServiceTerms: PropTypes.bool,
  logIds: PropTypes.arrayOf(PropTypes.number),
  activityIds: PropTypes.arrayOf(PropTypes.number),
};
