import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';
import { PageLoader, PageError, Modal } from 'components';

// components and parts
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import Details from '../Details';
import Delete from '../Delete';
import New from './New';

import CustomSearchRender from 'components/CustomSearchRender/admin.js';
import { DOLPHIN_API } from 'shared/constants/apis';

const IWGDashboardOrganizations = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [orgDetailsModalOpen, setOrgDetailsModalOpen] = useState(false);
  const [orgIdSelected, setOrgIdSelected] = useState(-1);
  const [orgDataSelected, setOrgDataSelected] = useState({});
  const [orgNewModalOpen, setOrgNewModalOpen] = useState(false);
  const [orgDeleteModalOpen, setOrgDeleteModalOpen] = useState(false);

  const [{ data, error }, fetchOrgs] = useApi.get(DOLPHIN_API.ADMIN_ORGS, {});
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const getChangableOrgData = (org) => {
    return {
      name: org.name,
      organizationCode: org.organizationCode,
      paymentMethod: org.paymentMethod,
      contact: org.contact,
    };
  };

  const handleOpenOrgDetails = (org) => {
    setOrgIdSelected(org.id);
    setOrgDataSelected((prevState) => ({
      ...getChangableOrgData(org),
    }));
    setOrgDetailsModalOpen(true);
  };

  const handleUpdateOrg = async (orgId, updatedOrgJSON) => {
    const updatedOrgData = JSON.parse(updatedOrgJSON);
    await api.put(DOLPHIN_API.ADMIN_ORGS, {
      data: {
        ids: [orgId],
        updatedData: [updatedOrgData],
      },
    });
    await fetchOrgs();
  };

  const handleOpenDeleteOrg = (org) => {
    setOrgIdSelected(org.id);
    setOrgDataSelected((prevState) => ({
      ...getChangableOrgData(org),
    }));
    setOrgDeleteModalOpen(true);
  };

  const handleDeleteOrg = async (orgId) => {
    await api.delete(DOLPHIN_API.ADMIN_ORGS, {
      data: {
        ids: [orgId],
      },
    });

    await fetchOrgs();
  };

  data.sort((org1, org2) => {
    if (org1.id > org2.id) return 1;
    if (org1.id < org2.id) return -1;
    return 0;
  });

  const tableData = data.map((org) => {
    const owner = org.owner ? org.owner.username : 'null';
    return [org.id, org.name, org.organizationCode, owner, org, org];
  });

  const columns = [
    {
      name: 'id',
      label: 'Organization Id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'orgName',
      label: 'Organization Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'orgCode',
      label: 'Organization Code',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        filter: true,
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const org = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenOrgDetails(org);
                }}>
                Details
              </MDButton>
            </div>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const org = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDeleteOrg(org);
                  // handleDeleteUser(userId);
                }}>
                Delete
              </MDButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: true,
    download: true,
    filterType: 'textField',
    rowsPerPageOptions: [10, 20, 50, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={
            <Grid container item xs={4} columnSpacing={3}>
              <Grid item>
                <h2>Organizations</h2>
              </Grid>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOrgNewModalOpen(true);
                  }}>
                  New
                </MDButton>
              </Grid>
            </Grid>
          }
        />
      );
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                <Grid container columnSpacing={3} sx={{ marginTop: 3 }}>
                  <Grid item>
                    <h2>Organizations</h2>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setOrgNewModalOpen(true);
                      }}>
                      New
                    </MDButton>
                  </Grid>
                </Grid>
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>
      </Card>
      {orgNewModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-org-new"
          width={1040}
          withcloseIcon={true}
          onClose={() => setOrgNewModalOpen(false)}
          renderContent={(modal) => (
            <New close={modal.close} fetchOrgs={fetchOrgs} />
          )}
        />
      )}
      {orgDetailsModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-org-details"
          width={1040}
          withCloseIcon={true}
          onClose={() => setOrgDetailsModalOpen(false)}
          renderContent={() => (
            <Details
              dataType="Organization"
              dataId={orgIdSelected}
              data={orgDataSelected}
              updateData={handleUpdateOrg}
            />
          )}
        />
      )}
      {orgDeleteModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-org-delete"
          width={1040}
          withCloseIcon={true}
          onClose={() => setOrgDeleteModalOpen(false)}
          renderContent={() => (
            <Delete
              dataType="Organization"
              dataId={orgIdSelected}
              data={orgDataSelected}
              deleteData={handleDeleteOrg}
              close={setOrgDeleteModalOpen}
            />
          )}
        />
      )}
    </Fragment>
  );
};

export default withRouter(IWGDashboardOrganizations);
