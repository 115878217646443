// import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
// import KrillChat from 'krill';
import {
  setStoredAuthToken,
  setStoredAccessToken,
  getStoredAuthToken,
} from 'shared/utils/authToken';
import { StatusCodes } from 'http-status-codes';
import AppAWS from 'root/rootAWS';
import AppAzure from 'root/rootAzure';
import { GetAzureB2CAccessToken } from 'shared/utils/azureB2C';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 *  determine if failed http request should be retried;
 *  client side failure reasons that should have no retry:
 *    400 Bad Request: there is a problem with the request itself.
 *    403 Forbidden: Retrying without changing permissions or credentials will not succeed.
 *    404 Not Found: Retrying will not create the missing resource.
 *    405 Method Not Allowed: HTTP method not allowed; Retrying with the same method will not work.
 *    422 Unprocessable Entity: Server side understands the request but cannot process it due to semantic errors. Retrying is futile.
 *
 */
const isRetriable = (status) => {
  const nonRetriableStatusCodes = [400, 403, 404, 405, 422];
  return !nonRetriableStatusCodes.includes(status);
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && !isRetriable(error.response.status)) {
      return Promise.reject(error); // some failure reason should have no retry;
    }

    if (
      !error.response ||
      error.response.status === 0 ||
      error.response.status === StatusCodes.UNAUTHORIZED // 401: retry with renewed auth token;
    ) {
      if (process.env.REACT_APP_AUTH_SERVER === 'aws-cognito') {
        const currentSession = await Auth.currentSession();
        setStoredAuthToken(currentSession.idToken.jwtToken);
        setStoredAccessToken(currentSession.accessToken.jwtToken);
        originalRequest.headers.authorization = getStoredAuthToken();
      } else if (process.env.REACT_APP_AUTH_SERVER === 'azure-adb2c') {
        const newToken = await GetAzureB2CAccessToken();
        console.log('Just updated Azure B2C access token;');
        originalRequest.headers.authorization = newToken;
      }
    }

    if (originalRequest.retryCount) {
      originalRequest.retryCount++;
    } else {
      originalRequest.retryCount = 1;
    }
    if (originalRequest.retryCount > 3) {
      return Promise.reject(error);
    }

    await sleep(2 ** originalRequest.retryCount * 1000);
    return axios.request(originalRequest);
  }
);

let App;
switch (process.env.REACT_APP_SIGN_IN_PAGE) {
  case 'aws-custom':
    App = AppAWS;
    break;
  case 'azure-adb2c':
    App = AppAzure;
    break;
  default:
    break;
}

export default App;
