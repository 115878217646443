export const NotificationType = {
  NEW_ISSUE: 'NEW_ISSUE',
  NEW_COMMENT: 'NEW_COMMENT',
  ASSIGN_DOCTOR_IN_CHARGE: 'ASSIGN_DOCTOR_IN_CHARGE',
  ASSIGN_DOCTOR: 'ASSIGN_DOCTOR',
  RELEASE_DOCTOR: 'RELEASE_DOCTOR',
  STATE_CHANGE: 'STATE_CHANGE',
  STATUS_CHANGE: 'STATUS_CHANGE',
  BILLING_QUESTION: 'BILLING_QUESTION',
};
