import { KENSHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';

export const addDiagnosis = (assignmentId, diagnosis, diagnosticNote) => {
  api.put(`${KENSHIN_API.ADD_DIAGNOSIS}`, {
    baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    params: {
      userId: localData.get(USER_DATA.USER_ID),
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      username: localData.get(USER_DATA.USERNAME),
    },
    data: {
      assignmentId: assignmentId,
      diagnosis: diagnosis,
      diagnosticNote: diagnosticNote,
    },
  });
};
