// export const getStoredAuthToken = () => localStorage.getItem('authToken');
// export const storeAuthToken = token => localStorage.setItem('authToken', token);
// export const removeStoredAuthToken = () => localStorage.removeItem('authToken');

// export const getStoredAuthToken = () => {
//   const username = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
//   const idToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.idToken`;
//   const accessToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;
//   return localStorage.getItem(idToken);
// }

// export const getStoredAccessToken = () => {
//   const username = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
//   const accessToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;
//   return localStorage.getItem(accessToken);
// }

// DO NOT DELETE ABOVE CODE

// import { useMsal } from '@azure/msal-react';
// import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { browserStorage } from 'shared/utils/storage';

export const getStoredAuthToken = () => {
  let accessToken;
  if (process.env.REACT_APP_AUTH_SERVER === 'aws-cognito') {
    accessToken = getStoredAuthToken_AWS();
  } else if (process.env.REACT_APP_AUTH_SERVER === 'azure-adb2c') {
    accessToken = getStoredAuthToken_Azure();
  } else {
    console.error(
      `process.env.REACT_APP_AUTH_SERVER has no valid value: ${process.env.REACT_APP_AUTH_SERVER}`
    );
  }
  return accessToken;
};

export const getStoredAccessToken = () => {
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const accessToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;

  return browserStorage.getItem(accessToken);
};

export const setStoredAuthToken = (idTokenValue) => {
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const idTokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.idToken`;

  return browserStorage.setItem(idTokenKey, idTokenValue);
};

export const setStoredAccessToken = (accessTokenValue) => {
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const accessTokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;
  return browserStorage.setItem(accessTokenKey, accessTokenValue);
};

const getStoredAuthToken_Azure = () => {
  let accessToken;
  try {
    const tokenKeysString = browserStorage.getItem(
      `msal.token.keys.${process.env.REACT_APP_AZURE_B2C_FRONTEND_APP_ID}`
    );
    const tokenKeys = JSON.parse(tokenKeysString);
    const accessTokenKeyName = tokenKeys.accessToken[0]; // In theory there are can be more than 1 account in the array; In reality we don't have to deal with it;
    const cookieObj = JSON.parse(browserStorage.getItem(accessTokenKeyName));
    accessToken = cookieObj.secret;
  } catch (error) {
    console.error(`access token is not found: ${error}`);
  }
  return accessToken;
};

const getStoredAuthToken_AWS = () => {
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const idToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.idToken`;
  return browserStorage.getItem(idToken);
};
