import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { useStyles } from './Styles';
import { Grid, IconButton } from '@mui/material';

import Preview from '../Preview';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
  modalClose: PropTypes.func,
};

const DashboardKanbanIssueDetailsReportsReportPreview = ({
  reportContent,
  modalClose,
}) => {
  const classes = useStyles();

  return (
    <Grid>
      <Grid container className={classes.actionGrid} justifyContent="flex-end">
        <div>
          <IconButton onClick={modalClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </div>
      </Grid>
      <Grid
        container
        className={classes.reportPreviewGrid}
        columns={{ xs: 12 }}>
        <Grid item className={classes.previewGrid} xs={12}>
          <Preview reportContent={reportContent} />
        </Grid>
      </Grid>
    </Grid>
  );
};

DashboardKanbanIssueDetailsReportsReportPreview.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsReportPreview);
