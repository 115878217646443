import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Tooltip, IconButton } from '@material-ui/core';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { updateIssue } from 'pages/Dashboard/Billing/shared/api';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        dataIndex: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

const DashboardBillingForReceviedCasesDetailedTableCustomToolbarSelectStar = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  const setStarStatus = (status) => {
    const dataIndice = selectedRows.data.map((row) => row.dataIndex);
    const newData = data;
    dataIndice.forEach((dataIndex) => {
      newData[dataIndex][8].star = status;
      const issueId = newData[dataIndex][7];
      updateIssue(issueId, { starredByDst: status });
    });
    setData([...newData]);
  };

  return (
    <Fragment>
      <Tooltip
        className={classes.tooltip}
        title={intl.get(
          'billing_for_received_cases_toolbar_select_tooltip_star'
        )}>
        <IconButton
          className={classes.iconButton}
          onClick={() => setStarStatus(true)}>
          <StarIcon className={classes.starIcon} />
        </IconButton>
      </Tooltip>
      <Tooltip
        className={classes.tooltip}
        title={intl.get(
          'billing_for_received_cases_toolbar_select_tooltip_unstar'
        )}>
        <IconButton
          className={classes.iconButton}
          onClick={() => setStarStatus(false)}>
          <StarBorderIcon className={classes.starBorderIcon} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

DashboardBillingForReceviedCasesDetailedTableCustomToolbarSelectStar.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingForReceviedCasesDetailedTableCustomToolbarSelectStar
);
