import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.object.isRequired,
};

const InvoiceFrom = ({ invoiceContent }) => {
  return (
    <Fragment>
      <View style={styles.invoiceFrom}>
        <Text style={styles.invoiceFromText}>{invoiceContent.invoiceFrom}</Text>
      </View>
    </Fragment>
  );
};

InvoiceFrom.propTypes = propTypes;

export default InvoiceFrom;
