import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceNo: PropTypes.number.isRequired,
  invoiceDate: PropTypes.string.isRequired,
};

const InvoiceNoAndDate = ({ invoiceNo, invoiceDate }) => {
  return (
    <Fragment>
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.invoiceNoLabel}>
          {intl.get('billing_invoice_text_number')}
        </Text>
        <Text style={styles.invoiceNoText}>{invoiceNo}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.invoiceDateLabel}>
          {intl.get('billing_invoice_text_date')}
        </Text>
        <Text style={styles.invoiceDateText}>{invoiceDate}</Text>
      </View>
    </Fragment>
  );
};
InvoiceNoAndDate.propTypes = propTypes;
export default InvoiceNoAndDate;
