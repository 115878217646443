import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';

const propTypes = {
  case_: PropTypes.object.isRequired,
  goToPrevCase: PropTypes.func.isRequired,
  goToNextCase: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const ViewerToolArea = ({ case_, goToPrevCase, goToNextCase, closeModal }) => {
  return (
    <Fragment>
      <Grid container>
        <Grid container item xs={4} justifyContent="center">
          <Button variant="contained" color="primary" onClick={goToPrevCase}>
            {'  Prev  '}
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <Button variant="contained" color="primary" onClick={goToNextCase}>
            {'  Next  '}
          </Button>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <Button variant="contained" color="secondary" onClick={closeModal}>
            {'  close  '}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

ViewerToolArea.propTypes = propTypes;

export default ViewerToolArea;
