import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import BatchEdit from './BatchEdit';
import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  setBatchEditModalIsOpen: PropTypes.func.isRequired,
  setSelectedIssueIndexes: PropTypes.func.isRequired,
};

/**
 * @component
 * @param {Object} selectedRows An array with elements: {index: 5, dataIndex: 63}; `dataIndex` is the index in `issues` array;
 * @param {Object} displayData An array; Each element is an object representing one row, currently being displayed in the table;
 * @returns {ReactNode} Returns the Button component.
 */
const CustomToolbarSelect = ({
  selectedRows,
  setSelectedRows,
  setBatchEditModalIsOpen,
  setSelectedIssueIndexes,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BatchEdit
        selectedRows={selectedRows}
        setBatchEditModalIsOpen={setBatchEditModalIsOpen}
        setSelectedIssueIndexes={setSelectedIssueIndexes}
      />
    </div>
  );
};

CustomToolbarSelect.propTypes = propTypes;

export default withRouter(CustomToolbarSelect);
