import { useState } from 'react';
import { Auth } from 'aws-amplify';

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom';

// @mui material components
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// components for Sidenav
import SidenavCollapse from './SidenavCollapse';
import SidenavList from './SidenavList';
import SidenavItem from './SidenavItem';

// Custom styles for the Sidenav
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';

import PropTypes from 'prop-types';

// Images
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import WorkIcon from '@mui/icons-material/Work';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import localData from 'shared/utils/localData';

import { useMaterialUIController, setMiniSidenav } from 'context';
import { USER_DATA } from 'shared/constants/users';

const propTypes = {
  color: PropTypes.string,
  brand: PropTypes.string,
  brandName: PropTypes.string,
};

const IWGDashboardSidenav = ({ color, brand, brandName }) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const username = localData.get(USER_DATA.USERNAME);

  let textColor = 'white';
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const signOut = async () => {
    if (
      process.env.REACT_APP_AUTH_SERVER === 'aws-cognito' &&
      process.env.REACT_APP_SIGN_IN_PAGE === 'custom'
    ) {
      try {
        await Auth.signOut();
        window.location.reload();
      } catch (error) {
        alert('error sign out: ', error);
      }
    }
  };

  return (
    <SidenavRoot
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}>
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}>
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox component="img" src={brand} alt="Brand" width="3rem" />
          <MDBox
            width={'70%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MDTypography
              component="h1"
              variant="button"
              fontWeight="bold"
              color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />

      <SidenavCollapse
        key="profile"
        name={username}
        icon={<PersonIcon fontSize="large" />}
        active={openCollapse}
        open={openCollapse}
        onClick={() => {
          setOpenCollapse((prevState) => !prevState);
        }}>
        <SidenavList key={'logout'}>
          <SidenavItem color={color} name="LOGOUT" onClick={signOut} />
        </SidenavList>
      </SidenavCollapse>

      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />

      <NavLink to="/admin/users" key="users">
        <SidenavCollapse
          name="Users"
          noCollapse={true}
          icon={<GroupIcon fontSize="medium" />}
          active={pathname === '/admin/users'}
        />
      </NavLink>
      <NavLink to="/admin/orgs" key="organizations">
        <SidenavCollapse
          name="Organizations"
          noCollapse={true}
          icon={<CorporateFareIcon fontSize="medium" />}
          active={pathname === '/admin/orgs'}
        />
      </NavLink>
      <NavLink to="/admin/employments" key="employments">
        <SidenavCollapse
          name="Employments"
          noCollapse={true}
          icon={<WorkIcon fontSize="medium" />}
          active={pathname === '/admin/employments'}
        />
      </NavLink>
      <NavLink to="/admin/contracts" key="contracts">
        <SidenavCollapse
          name="Contracts"
          noCollapse={true}
          icon={<HistoryEduIcon fontSize="medium" />}
          active={pathname === '/admin/contracts'}
        />
      </NavLink>
      <NavLink to="/admin/fillData" key="contracts">
        <SidenavCollapse
          name="Fill Data"
          noCollapse={true}
          icon={<HistoryEduIcon fontSize="medium" />}
          active={pathname === '/admin/filledData'}
        />
      </NavLink>
    </SidenavRoot>
  );
};

IWGDashboardSidenav.propTypes = propTypes;

export default IWGDashboardSidenav;
