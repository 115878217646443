import React, { Fragment } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
};

const ReportKeyImages = ({ reportContent }) => {
  const keyImages = reportContent.keyImages;
  return (
    <Fragment>
      <View style={styles.reportSectionTitle}>
        <Text>{intl.get('issue_details_report_preview_text_key_images')}</Text>
      </View>
      <View style={styles.reportSectionContent}>
        {keyImages.map((item, index) => (
          <Image
            key={index}
            style={styles.keyImage}
            src={Buffer.from(item.fileBuffer)}
            // src={item.fileBuffer}
          />
        ))}
      </View>
    </Fragment>
  );
};

ReportKeyImages.propTypes = propTypes;

export default ReportKeyImages;
