import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';

const InvoiceTitle = () => {
  return (
    <View style={styles.invoiceTitleContainer}>
      <Text style={styles.invoiceTitleText}>
        {intl.get('billing_invoice_text_title')}
      </Text>
    </View>
  );
};

export default InvoiceTitle;
