import React from 'react';
import PropTypes from 'prop-types';
import localData from 'shared/utils/localData';

import {
  formatDateTimeConversational,
  readableJapaneseDateFormat,
} from 'shared/utils/dateTime';
import { Dates } from './Styles';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
};

// const ProjectBoardIssueDetailsDates = ({ issue }) => (
//   <Dates>
//     <div>Created at {formatDateTimeConversational(issue.createdAt)}</div>
//     <div>Updated at {formatDateTimeConversational(issue.updatedAt)}</div>
//   </Dates>
// );

const formDisplayString = (lang, datetimeString) => {
  var display = '';
  if (lang === 'ja-JP') {
    display = `${readableJapaneseDateFormat(datetimeString)}に作成`;
  } else {
    display = `Created at ${formatDateTimeConversational(datetimeString)}`;
  }
  return display;
};

const ProjectBoardIssueDetailsDates = ({ issue }) => {
  const lang = localData.get('userPreferredLanguage');
  const displayString = formDisplayString(lang, issue.createdAt);
  return (
    <Dates>
      <div>{displayString}</div>
    </Dates>
  );
};

ProjectBoardIssueDetailsDates.propTypes = propTypes;

export default ProjectBoardIssueDetailsDates;
