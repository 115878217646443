import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const chipBaseStyle = {
  margin: '1px',
  // color: 'black',
  // border: '1px solid black',
  // fontWeight: 'bold',
  borderRadius: 5,
  backgroundColor: 'lightgreen',
};

const chipColorStyle = {
  TO_BE_ASSIGNED: {
    ...chipBaseStyle,
    backgroundColor: '#ff6666', // Lighter shade of red
  },
  TO_BE_DIAGNOSED: {
    ...chipBaseStyle,
    backgroundColor: '#ff6666',
  },
  BASE: {
    ...chipBaseStyle,
  },
};

const propTypes = {
  issue: PropTypes.any,
};

const CaseStatsChips = ({ issue }) => {
  const caseCountTotal = issue.cases.length;
  const count_assigned = issue.cases.filter((item) => {
    return item.doctorId || item.doctorId === 0;
  }).length;

  const caseCountToBeAssigned = caseCountTotal - count_assigned;

  const caseCountToBeDiagnosed = issue.cases.filter(
    (item) =>
      typeof item.doctorId === 'number' && item.diagnosis === 'TO_BE_DIAGNOSED'
  ).length;

  const getChipStyle = (chipName, count) => {
    if (chipName === 'TO_BE_ASSIGNED' || chipName === 'TO_BE_DIAGNOSED') {
      if (count > 0) {
        return chipColorStyle[chipName];
      }
    }
    return chipColorStyle['BASE'];
  };

  return (
    <Stack spacing={1} alignItems="left">
      <Stack direction="row" spacing={0}>
        {issue.progress !== 'NEWTASK' && (
          <Chip
            size="small"
            variant="filled"
            label={`${intl.get(
              'kenshin_issue_ticket_label_case_count_to_be_assigned'
            )}: ${caseCountToBeAssigned}`}
            style={getChipStyle('TO_BE_ASSIGNED', caseCountToBeAssigned)}
          />
        )}
        {issue.progress !== 'NEWTASK' && (
          <Chip
            size="small"
            variant="filled"
            label={`${intl.get(
              'kenshin_issue_ticket_label_case_count_to_be_diagnosed'
            )}: ${caseCountToBeDiagnosed}`}
            style={getChipStyle('TO_BE_DIAGNOSED', caseCountToBeDiagnosed)}
          />
        )}
        <Chip
          size="small"
          variant="filled"
          label={`${intl.get(
            'kenshin_issue_ticket_label_case_total_count'
          )}: ${caseCountTotal}`}
          style={getChipStyle('TOTAL_COUNT', caseCountTotal)}
        />
      </Stack>
    </Stack>
  );
};

CaseStatsChips.propTypes = propTypes;

export default CaseStatsChips;
