import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalCard: {
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    maxHeight: '90vh',
    width: '80vw',
  },
}));

export default useStyles;
