import React, { Fragment, useState, useRef } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Typography, Modal, Grid } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';
import { PageError, Button, CopyLinkButton } from 'components';
import { IssueStatus, IssueState } from 'shared/constants/issues';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { StyledConfirmModal } from 'components/ConfirmModal/Styles';
import { StatusCodes } from 'http-status-codes';

import Loader from './Loader';
import Info from './Info';
import Description from './Description';
import Comments from './Comments';
import Status from './Status';
import AssigneesReporter from './AssigneesReporter';
import Priority from './Priority';
import EstimateTracking from './EstimateTracking';
import Dates from './Dates';
import ConfirmPopUpWindow from './ConfirmPopUpWindow';
import Download from './Download';
import Viewport from './Viewport';
import Preview from './Preview';
import { ModalForUploadingMoreAttachments } from './UploadMoreAttachmentsModal';
import {
  TopActions,
  TopActionsRight,
  Content,
  Left,
  Right,
  useStyles,
  InfoLabel,
} from './Styles';
import {
  isObject,
  generateAttachmentList,
  generateAttchmentList_OLD,
} from './utils';
import { useMaterialUIController } from 'context';
import ShareIssueButton from 'components/ShareIssueButton';
import { UserProptypes } from 'shared/propTypes/userType';
import { USER_COMMENT } from 'shared/constants/comment';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { DOLPHIN_API } from 'shared/constants/apis';
import { getUserFullName } from 'shared/utils/name';

const propTypes = {
  researchCaseId: PropTypes.string.isRequired,
  issueId: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
  projectUsers: PropTypes.arrayOf(PropTypes.shape(UserProptypes)).isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
};

const NOT_DOCTOR = ['staff', 'manager'];
const MODAL_TYPE = {
  RECEIVED: 'received',
  SENT: 'sent',
};

const ProjectBoardIssueDetails = ({
  researchCaseId,
  issueId,
  trigger,
  projectUsers,
  updateLocalProjectIssues,
  modalClose,
  match,
  history,
}) => {
  const classes = useStyles();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [viewportModalOpen, setViewModalOpen] = useState(false);
  const [viewerDicomFilePaths, setViewerDicomFilePaths] = useState([]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [attachmentObjToBeDownloaded, setAttachmentObjToBeDownloaded] =
    useState({});
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [attachmentObjToBePreviewed, setAttachmentObjToBePreviewed] = useState(
    {}
  );

  const [uploadMoreAttachmentsModalOpen, setUploadMoreAttachmentsModalOpen] =
    useState(false);

  const [
    issueStateChangeConfirmModalOpen,
    setIssueStateChangeConfirmModalOpen,
  ] = useState(false);

  const [issueShareConfirmModalOpen, setIssueShareConfirmModalOpen] =
    useState(false);
  const shareIssueConfirmModalBodyText = useRef(null);
  const shareIssueConfirmFunc = useRef(null);
  const shareIssueCancelFunc = useRef(null);

  // for comment functionalities
  const [refreshQuote, doRefreshQuote] = useState(0);
  const [quoteAttachmentName, setQuoteAttachmentName] = useState('');

  const commentEditorRef = useRef(null);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);

  const userId = localData.get('username');
  const currentOrganizationId = localData.get(USER_COMMENT.CURRENT_ORGID);

  const [{ data, error, setLocalData }, fetchResearchCase] = useApi.get(
    `${DOLPHIN_API.RESEARCH_CASES}/get`,
    {
      params: {
        organizationId: currentOrganizationId,
        researchCaseId: researchCaseId,
      },
    }
  );

  if (error && error.status === StatusCodes.NOT_FOUND)
    history.push('/research/cases');
  if (!data) return <Loader />;
  if (error) return <PageError />;

  const { researchCase } = data;
  const isCreator = userId === researchCase.creator.username;

  const updateLocalResearchCaseDetails = (fields) => {
    setLocalData((currentData) => ({
      researchCase: { ...currentData.researchCase, ...fields },
    }));
  };

  const updateResearchCase = (updatedFields) => {
    if (trigger === 'byDrop') {
      if (updatedFields.users) {
        updatedFields = {
          status: IssueStatus.DOCTORASSIGNED,
          ...updatedFields,
        };
      }
    }

    api.optimisticUpdate(`${DOLPHIN_API.ISSUES}/${issueId}`, {
      updatedFields,
      // currentFields: issue,
      setLocalData: (fields) => {
        updateLocalResearchCaseDetails(fields);
        // updateLocalProjectIssues(issue.id, fields);
      },
    });
  };

  const handleLinkButtonClick = (dicomFilePaths) => {
    setViewerDicomFilePaths(dicomFilePaths);
    setViewModalOpen(true);
  };

  const handleCommentButtonClick = (attachmentObj) => {
    setQuoteAttachmentName(attachmentObj.title);
    doRefreshQuote((prev) => prev + 1);
    setIsCommentFormOpen(true);
  };

  const handleDownloadButtonClick = (attachmentObj) => {
    setAttachmentObjToBeDownloaded(attachmentObj);
    setDownloadModalOpen(true);
  };

  const handlePreviewButtonClick = (attachmentObj) => {
    setAttachmentObjToBePreviewed(attachmentObj);
    setPreviewModalOpen(true);
  };

  const handleBlockQuoteTextClick = (attachmentName) => {
    const res = researchCase.attachments.filter(
      (item) => item.title === attachmentName
    );
    if (res && res.length > 0) {
      const item = res[0];
      const dicoms = res[0].dicomFilesInfo;
      if (isObject(dicoms) && Object.keys(dicoms).length > 0) {
        setViewerDicomFilePaths(dicoms);
        setViewModalOpen(true);
      } else {
        setAttachmentObjToBePreviewed(item);
        setPreviewModalOpen(true);
      }
    }
  };

  const handleBugButtonClick = (formerState) => {
    setIssueStateChangeConfirmModalOpen(true);
  };

  const handleAddAttachmentButtonClick = (state) => {
    setUploadMoreAttachmentsModalOpen(state);
  };

  return (
    <Fragment>
      <TopActions>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{
            fontWeight: 'bold',
            marginTop: '5px',
            marginLeft: '14px',
            textAlign: 'right',
            alignSelf: 'stretch',
          }}>
          {/* {issue.title} */}
          {'Case Template'}
        </Typography>

        <TopActionsRight>
          <ShareIssueButton
            setIssueShareConfirmModalOpen={setIssueShareConfirmModalOpen}
            shareIssueConfirmModalBodyText={shareIssueConfirmModalBodyText}
            shareIssueConfirmFunc={shareIssueConfirmFunc}
            shareIssueCancelFunc={shareIssueCancelFunc}
          />
          <CopyLinkButton researchCaseId={researchCaseId} />
          <Button
            icon="close"
            iconSize={24}
            variant="empty"
            onClick={modalClose}
          />
        </TopActionsRight>
      </TopActions>
      <Content>
        <Left>
          {/* <Info issue={issue} updateIssue={updateIssue} isClient={isClient} /> */}
          <Description
            researchCase={researchCase}
            updateResearchCase={updateResearchCase}
            isCreator={isCreator}
          />
          {/* Attachement List */}
          {researchCase.members.map((member, index) => {
            const username = member.username;
            const fullName = getUserFullName(member.firstName, member.lastName);
            return (
              <Fragment key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <InfoLabel
                      style={{
                        marginTop: '25px',
                        marginBottom: '20px',
                      }}>
                      {/* {intl.get('issue_details_sender_attachment_files')} */}
                      {`Files from ${fullName}`}
                    </InfoLabel>
                  </Grid>
                  {userId === username && (
                    <Grid item>
                      <Button
                        variant="primary"
                        icon="plus"
                        iconSize={20}
                        sx={{ justifyContent: 'start' }}
                        onClick={() => handleAddAttachmentButtonClick(true)}>
                        {intl.get('issue_details_add_more_attachment_files')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                {generateAttachmentList({
                  userId: username,
                  attachments: researchCase.attachments,
                  darkMode,
                  handleCommentButtonClick,
                  handleDownloadButtonClick,
                  handleLinkButtonClick,
                  handlePreviewButtonClick,
                })}
              </Fragment>
            );
          })}

          <Comments
            researchCase={researchCase}
            fetchResearchCase={fetchResearchCase}
            updateLocalResearchCaseDetails={updateLocalResearchCaseDetails}
            quoteAttachmentName={quoteAttachmentName}
            refreshQuote={refreshQuote}
            commentEditorRef={commentEditorRef}
            isCommentFormOpen={isCommentFormOpen}
            setIsCommentFormOpen={setIsCommentFormOpen}
            handleBlockQuoteTextClick={handleBlockQuoteTextClick}
            handleAddAttachmentButtonClick={() =>
              handleAddAttachmentButtonClick(true)
            }
          />
        </Left>
        {/* <Right>
          {NOT_DOCTOR.includes(userType) && (
            <Status
              isClient={isClient}
              issue={issue}
              updateLocalIssueDetails={updateLocalIssueDetails}
              setConfirmModalInfo={setConfirmModalInfo}
              setConfirmModalUpdateData={setConfirmModalUpdateData}
              setConfirmModalOpen={setConfirmModalOpen}
            />
          )}

          {NOT_DOCTOR.includes(userType) &&
            !isClient &&
            issue.status !== IssueStatus.NEWTASK && (
              <AssigneesReporter
                issue={issue}
                updateIssue={updateIssue}
                user_quotas={user_quotas}
                projectUsers={projectUsers}
                updateLocalIssueDetails={updateLocalIssueDetails}
                setConfirmModalInfo={setConfirmModalInfo}
                setConfirmModalUpdateData={setConfirmModalUpdateData}
                setConfirmModalOpen={setConfirmModalOpen}
              />
            )}
          <Priority issue={issue} updateIssue={updateIssue} />
          <EstimateTracking issue={issue} updateIssue={updateIssue} />
          <Dates issue={issue} />
        </Right> */}
      </Content>

      {/* Irregular */}
      <Modal
        open={viewportModalOpen}
        onClose={() => setViewModalOpen(false)}
        className={classes.viewportModal}>
        <div className={classes.viewportModalPaper}>
          <Viewport
            dicomFilePaths={viewerDicomFilePaths}
            close={() => setViewModalOpen(false)}
          />
        </div>
      </Modal>

      <ConfirmModal
        isOpened={issueShareConfirmModalOpen}
        title={intl.get('issue_details_confirm_modal_title_share_issue')}
        bodyText={shareIssueConfirmModalBodyText.current}
        onConfirm={() => {
          shareIssueConfirmFunc.current();
        }}
        onCancel={() => {
          shareIssueCancelFunc.current();
        }}
        confirmButtonColor="info"
        confirmButtonText={intl.get(
          'issue_details_confirm_modal_button_share_issue_confirm'
        )}
        cancelButtonColor="error"
        cancelButtonText={intl.get(
          'issue_details_confirm_modal_button_share_issue_cancel'
        )}></ConfirmModal>

      {confirmModalOpen && (
        <StyledConfirmModal
          isOpen
          testid="modal:confirm"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
          renderContent={(modal) => (
            <ConfirmPopUpWindow
              modalClose={modal.close}
              updateIssue={updateResearchCase}
              confirmModalUpdateData={confirmModalUpdateData}
              confirmModalInfo={confirmModalInfo}
            />
          )}
        />
      )}

      {downloadModalOpen && (
        <StyledConfirmModal
          isOpen
          testId="modal:zipfile-download"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setDownloadModalOpen(false);
          }}
          renderContent={(modal) => (
            <Download
              // issue={issue}
              researchCaseId={researchCaseId}
              attachment={attachmentObjToBeDownloaded}
              modalClose={modal.close}
            />
          )}
        />
      )}

      {/* Irregular */}
      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        className={classes.previewModal}>
        <div className={classes.previewModalPaper}>
          <Preview
            researchCaseId={researchCaseId}
            attachment={attachmentObjToBePreviewed}
            modalClose={() => setPreviewModalOpen(false)}
          />
        </div>
      </Modal>

      <ModalForUploadingMoreAttachments
        // issue={issue}
        researchCase={researchCase}
        fetchResearchCase={fetchResearchCase}
        isOpen={uploadMoreAttachmentsModalOpen}
        handleModalClose={() => handleAddAttachmentButtonClick(false)}
        darkMode={darkMode}
      />
    </Fragment>
  );
};

ProjectBoardIssueDetails.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetails);
