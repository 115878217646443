import { Fragment } from 'react';
import intl from 'react-intl-universal';

import { Grid } from '@material-ui/core';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import localData from 'shared/utils/localData';
import api from 'shared/utils/api';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { formatBytes } from 'shared/utils/misc';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';
import { FILE_TYPE } from 'shared/constants/file';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';

export const startPeriodicStatusCheckForBatchAttachments = async (
  attachmentIds
) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        attachmentIds: attachmentIds,
      },
    };
    const res = await api.get(
      `${DOLPHIN_API.ATTACHMENT}/periodic-status-check-attachments`,
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/periodic-status-check-attachments failed; Check network condition;`
    );
  }

  return status;
};

export const checkForBatchAttachmentsCompletion = async (
  issueId,
  attachmentIds
) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        issueId: issueId,
        attachmentIds: attachmentIds,
      },
    };
    const res = await api.get(
      `${DOLPHIN_API.ATTACHMENT}/check-for-attachments-completion-on-existing-issue`,
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/check-for-attachments-completion-on-existing-issue failed; Check network condition;`
    );
  }

  return status;
};

export const generateFileList = (structuredAttachments, handleFileDelete) => {
  const res = structuredAttachments.map((item, index) => {
    if (item.type === FILE_TYPE.DIRECTORY) return <></>;
    return (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <MDTypography variant="body2" sx={{ wordBreak: 'break-word' }}>
              {item.name} - {formatBytes(item.size)}
            </MDTypography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <MDButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleFileDelete(index)}>
              {intl.get('issue_details_button_delete')}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    );
  });

  return res;
};

export const generateDirectoryList = (
  structuredAttachments,
  handleFileDelete,
  darkMode
) => {
  const res = structuredAttachments.map((item, index) => {
    if (item.type === FILE_TYPE.FILE) return <></>;
    return (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            {item.type === FILE_TYPE.FILE ? (
              <MDTypography variant="body2" sx={{ wordBreak: 'break-word' }}>
                {item.name} - {formatBytes(item.size)}
              </MDTypography>
            ) : (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#202940' : 'white',
                }}
                sx={{
                  maxheight: 400,
                  flexGrow: 1,
                  maxWidth: 500,
                  overflowY: 'auto',
                }}>
                {generateTreeView(item)}
              </TreeView>
            )}
          </Grid>
          <Grid item xs={2}>
            <MDButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleFileDelete(index)}>
              {intl.get('issue_details_button_delete')}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    );
  });

  return res;
};

const generateTreeView = (structureObject) => {
  const label = `${structureObject.name}  -  ${formatBytes(
    structureObject.size
  )}`;
  return (
    <TreeItem
      nodeId={`${structureObject.name}-${generate8DigitsIdentifier()}`}
      label={label}>
      {structureObject.type === FILE_TYPE.DIRECTORY &&
        structureObject.children.map((item) => generateTreeView(item))}
    </TreeItem>
  );
};
