export const CVIC_USERS = new Set([
  'cvic_super',
  'tima',
  'tida',
  'tidb',
  'tisa',
  'tisb',
  'tmma',
  'tmda',
  'tmdb',
  'tmsa',
  'tmsb',
  'cvic_iidabashi_staff_001',
  'cvic_iidabashi_staff_002',
  'cvic_iidabashi_doctor_001',
  'cvic_iidabashi_doctor_002',
  'cvic_iidabashi_admin_001',
  'cvic_mitaka_staff_001',
  'cvic_mitaka_staff_002',
  'cvic_mitaka_doctor_001',
  'cvic_mitaka_doctor_002',
  'cvic_mitaka_admin_001',
  'cvic_oimachi_staff_001',
  'cvic_oimachi_doctor_001',
  'cvic_oimachi_admin_001',
  'cvic_sengawa_staff_001',
  'cvic_sengawa_doctor_001',
  'cvic_sengawa_admin_001',
  'cvic_china_staff_001',
  'cvic_china_doctor_001',
  'cvic_china_admin_001',
]);
