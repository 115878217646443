import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import {
  Grid,
  Tooltip,
  IconButton,
  Popover,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
  TextField,
} from '@material-ui/core';
import MoneyIcon from '@mui/icons-material/Money';
import CloseIcon from '@mui/icons-material/Close';

import ExposureIcon from '@mui/icons-material/Exposure';

import { batchUpdatePaidWage, updatePaidWageKenshin } from '../../api';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

const DashboardPaidWageDetailedTableCustomToolbarSelectWageAdjustment = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  const [newWage, setNewWage] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleIconButtonClick = (event) => {
    anchorEl.current = event.currentTarget;
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const handleChange = (event) => {
    setNewWage(Number(event.target.value));
  };

  const handleApply = () => {
    const dataIndice = selectedRows.data.map((row) => {
      return row.dataIndex;
    });

    const newData = data;
    dataIndice.forEach((dataIndex) => {
      const wageId = data[dataIndex][5].wageId;
      newData[dataIndex][4].currentWage = newWage;
      updatePaidWageKenshin(wageId, { newWage: newWage });
    });
    setData([...newData]);
  };

  return (
    <Fragment>
      <Tooltip
        className={classes.tooltip}
        title={intl.get('paid_wage_toolbar_select_tooltip_wage_adjustment')}>
        <IconButton
          className={classes.iconButton}
          onClick={(event) => {
            handleIconButtonClick(event);
          }}>
          <MoneyIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Popover
        elevation={2}
        open={popoverOpen}
        // TransitionProps={{ onExited: {} }}
        onClose={handlePopoverClose}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <IconButton
          aria-label="Close"
          onClick={handlePopoverClose}
          className={classes.closeIconButton}>
          <CloseIcon />
        </IconButton>
        <Grid container className={classes.popoverContentRoot}>
          <Grid
            container
            item
            className={classes.popoverContent}
            xs={12}
            spacing={1}>
            <Grid item xs={9} className={classes.input}>
              <TextField
                label={intl.get(
                  'paid_wage_toolbar_select_input_label_new_wage'
                )}
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                  },
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={handleApply}>
                {intl.get('paid_wage_toolbar_select_button_adjust_wage')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </Fragment>
  );
};

DashboardPaidWageDetailedTableCustomToolbarSelectWageAdjustment.propTypes =
  propTypes;

export default DashboardPaidWageDetailedTableCustomToolbarSelectWageAdjustment;
