import styled, { css } from 'styled-components';

import { color, font, mixin } from 'shared/utils/styles';

export const Operations = styled.div`
  display: flex;
  justify-content: start;
`;

export const Operation = styled.div`
  margin-right: 10px;
`;
