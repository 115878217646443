import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
// import { Select } from 'components';
import Select from 'react-select';
import MDTypography from 'components/MDTypography';
import { Filters, SearchInput, User, Username } from './Styles';
import { getUserFullName } from 'shared/utils/name';
import { UserProptypes } from 'shared/propTypes/userType';
// import { UserType } from 'shared/constants/users';
import { FilterProptypes } from 'shared/propTypes/filterType';
import { QuotaProptypes } from 'shared/propTypes/quotaType';

const propTypes = {
  sourceOrganizations: PropTypes.arrayOf(PropTypes.shape(QuotaProptypes)),
  // projectUsers: PropTypes.arrayOf(PropTypes.shape(UserProptypes)),
  doctors: PropTypes.arrayOf(PropTypes.shape(UserProptypes)),
  filters: PropTypes.shape(FilterProptypes),
  mergeFilters: PropTypes.func.isRequired,
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '40px', // Custom height
    height: '40px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '40px',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '-1px',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '40px',
  }),

  multiValue: (provided, state) => ({
    ...provided,
    height: '30px', // Set the height of the chip
    display: 'flex',
    alignItems: 'center', // This ensures vertical centering of the content
    justifyContent: 'center', // This centers the content horizontally (if needed)
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: state.isSelected ? 'bold' : 'normal',
  }),
};

// const renderSelection = (selection) => (
//   <User isSelectValue>
//     <Username>
//       {selection.srcOrgName
//         ? `${selection.srcOrgName}`
//         : `${selection.lastName} ${selection.firstName}`}
//     </Username>
//   </User>
// );

// const renderOptions = (label) => (
//   <div style={{ display: 'flex', alignItems: 'center' }}>{label}</div>
// );

const ProjectBoardFilters = ({
  sourceOrganizations,
  doctors,
  filters,
  mergeFilters,
}) => {
  const { searchTerm } = filters;
  const doctorsOptions = doctors.map((u) => ({
    value: u.id,
    label: getUserFullName(u.firstName, u.lastName),
  }));

  const onSelectDoctor = (options) => {
    const inputUserIds = options.map((o) => o.value);
    mergeFilters({ userIds: inputUserIds });
  };

  const sourceOrgOptions = sourceOrganizations.map((item) => ({
    value: item.srcOrgId,
    label: item.srcOrgName,
  }));

  const onSelectOrganization = (options) => {
    const inputOrgIds = options.map((o) => o.value);
    mergeFilters({ srcOrgIds: inputOrgIds });
  };

  return (
    <Filters data-testid="board-filters">
      <Stack direction="row" alignItems="center" spacing={3}>
        <SearchInput
          icon="search"
          value={searchTerm}
          onChange={(value) => mergeFilters({ searchTerm: value })}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <MDTypography variant="h6" marginLeft="20px">
            {intl.get('kanban_filter_text_filter_by_doctor')}
          </MDTypography>
          <Select
            // className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            isMulti
            placeholder={intl.get('kanban_filter_text_click_to_select')}
            options={doctorsOptions}
            onChange={onSelectDoctor}
            styles={customStyles}
          />
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <MDTypography variant="h6" marginLeft="20px">
            {intl.get('kanban_filter_text_filter_by_organization')}
          </MDTypography>
          <Select
            // className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable
            isMulti
            placeholder={intl.get('kanban_filter_text_click_to_select')}
            options={sourceOrgOptions}
            onChange={onSelectOrganization}
            styles={customStyles}
          />
        </Stack>
      </Stack>
    </Filters>
  );
};

ProjectBoardFilters.propTypes = propTypes;

export default ProjectBoardFilters;
