import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '24px',
    marginRight: '24px',
  },
}));

export default useStyles;
