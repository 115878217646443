import { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';

import iconNewInCharge from 'assets/images/icons/icon-homepage-new-in-charge.svg';
import iconNewAssigned from 'assets/images/icons/icon-homepage-new-assigned.svg';
import iconNewSendback from 'assets/images/icons/icon-homepage-new-sendback.svg';
import iconNewReceived from 'assets/images/icons/icon-homepage-new-received.svg';
import { Grid } from '@mui/material';

const propTypes = {
  isTaskManager: PropTypes.bool.isRequired,
  numNewReceived: PropTypes.number,
  numNewSendback: PropTypes.number,
  isPicAsDoctor: PropTypes.bool.isRequired,
  numNewInCharge: PropTypes.number,
  numNewAssigned: PropTypes.number,
};

const DashboardHomeGeneral = ({
  isTaskManager,
  numNewReceived,
  numNewSendback,
  isPicAsDoctor,
  numNewInCharge,
  numNewAssigned,
}) => {
  return (
    <Grid container spacing={4}>
      {isTaskManager === true && (
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <MDBox
              display="flex"
              flexDirection={{ xs: 'column' }}
              justifyContent="center"
              alignItems="center"
              px={1.5}
              component={NavLink}
              to="/task-management/task-management-regular">
              <MDBadge
                badgeContent={numNewReceived}
                color="error"
                size="lg"
                circular>
                <MDBox
                  component="img"
                  src={iconNewReceived}
                  alt="new_received"
                  width="10rem"
                  mr={1}
                  pb={1}
                />
              </MDBadge>
              <MDTypography variant="h3">
                {intl.get('home_link_new_received_issues')}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox
              display="flex"
              flexDirection={{ xs: 'column' }}
              justifyContent="center"
              alignItems="center"
              px={1.5}
              component={NavLink}
              to="/history/sent">
              <MDBadge
                badgeContent={numNewSendback}
                color="error"
                size="lg"
                circular>
                <MDBox
                  component="img"
                  src={iconNewSendback}
                  alt="new_sendback"
                  width="10rem"
                  mr={1}
                  pb={1}
                />
              </MDBadge>
              <MDTypography variant="h3">
                {intl.get('home_link_new_sendback_issues')}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      )}

      {isPicAsDoctor === true && (
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <MDBox
              display="flex"
              flexDirection={{ xs: 'column' }}
              justifyContent="center"
              alignItems="center"
              px={1.5}
              component={NavLink}
              to={'/assignment/received'}>
              <MDBadge
                badgeContent={numNewAssigned}
                color="error"
                size="lg"
                circular>
                <MDBox
                  component="img"
                  src={iconNewAssigned}
                  alt="new_assigned"
                  width="10rem"
                  mr={1}
                  pb={1}
                />
              </MDBadge>
              <MDTypography variant="h3">
                {intl.get('home_link_new_assigned_issues')}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={6}>
            <MDBox
              display="flex"
              flexDirection={{ xs: 'column' }}
              justifyContent="center"
              alignItems="center"
              px={1.5}
              component={NavLink}
              to="/assignment/sent">
              <MDBadge
                badgeContent={numNewInCharge}
                color="error"
                size="lg"
                circular>
                <MDBox
                  component="img"
                  src={iconNewInCharge}
                  alt="new_assigned"
                  width="10rem"
                  mr={1}
                  pb={1}
                />
              </MDBadge>
              <MDTypography variant="h3">
                {intl.get('home_link_new_in_charge_issues')}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DashboardHomeGeneral.propTypes = propTypes;

export default DashboardHomeGeneral;
