import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';

import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

import api from 'shared/utils/api';
import { DOLPHIN_API } from 'shared/constants/apis';

const IWGDashboardFillData = () => {
  const handleFillData = async () => {
    const data = await api.post(DOLPHIN_API.FILL_DATA, {});
  };

  return (
    <Card sx={{ margin: 3, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">{'Fill Data'}</MDTypography>
        </MDBox>
        <br />
        <br />
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDBox
            px={3}
            mb={5}
            width="80%"
            display="flex"
            justifyContent="space-around"
            alignItems="center">
            <MDButton
              variant="contained"
              color="primary"
              onClick={handleFillData}>
              Fill Data
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default withRouter(IWGDashboardFillData);
