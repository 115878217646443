export const MenuOptionsAll = {
  NEW: 'New',
  NEW_KENSHIN: 'New_Kenshin',
  TASKMANAGEMENT: 'TaskManagement',
  TASKMANAGEMENT_KENSHIN: 'TaskManagement_Kenshin',
  HISTORY: 'History',
  HISTORY_KENSHIN: 'History_Kenshin',
  ASSIGNMENT: 'Assignment',
  RESEARCH: 'Research',
  CONTACTS: 'Contacts',
  BILLING: 'Billing',
  PAIDWAGE: 'PaidWage',
  MYWAGE: 'MyWage',
  ORGSETTINGS: 'OrganizationSettings',
  ORGLOGS: 'OrganizationLogs',
  MANUAL: 'Manual',
  SERVICETERMS: 'ServiceTerms',
};

export const MenuOptionsForManager = [
  MenuOptionsAll.NEW,
  MenuOptionsAll.TASKMANAGEMENT,
  MenuOptionsAll.HISTORY,
  MenuOptionsAll.RESEARCH,
  MenuOptionsAll.CONTACTS,
  MenuOptionsAll.BILLING,
  MenuOptionsAll.ORGSETTINGS,
  MenuOptionsAll.ORGLOGS,
  MenuOptionsAll.MANUAL,
  MenuOptionsAll.SERVICETERMS,
];

export const MenuOptionsForStaff = [
  MenuOptionsAll.NEW,
  MenuOptionsAll.TASKMANAGEMENT,
  MenuOptionsAll.HISTORY,
  MenuOptionsAll.CONTACTS,
  MenuOptionsAll.BILLING,
  MenuOptionsAll.MANUAL,
  MenuOptionsAll.SERVICETERMS,
];

export const MenuOptionsForDoctor = [
  MenuOptionsAll.ASSIGNMENT,
  MenuOptionsAll.RESEARCH,
  MenuOptionsAll.CONTACTS,
  MenuOptionsAll.MANUAL,
  MenuOptionsAll.SERVICETERMS,
];

export const MenuOptionsDefault = [
  MenuOptionsAll.MANUAL,
  MenuOptionsAll.SERVICETERMS,
];
