import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import { IssuePriorityCopy } from 'shared/constants/issues';
import PropTypes from 'prop-types';

const propTypes = {
  pageData: PropTypes.array.isRequired,
};

const InvoiceDetails = ({ pageData }) => {
  return (
    <Fragment>
      <View style={styles.invoiceDetailsTable}>
        <View style={styles.invoiceDetailsTableHeader}>
          <Text style={styles.invoiceDetailsTableIndex}>
            {intl.get('billing_invoice_details_table_label_index')}
          </Text>
          <Text style={styles.invoiceDetailsTableTitle}>
            {intl.get('billing_invoice_details_table_label_title')}
          </Text>
          <Text style={styles.invoiceDetailsTableModality}>
            {intl.get('billing_invoice_details_table_label_modality')}
          </Text>
          <Text style={styles.invoiceDetailsTableBodypart}>
            {intl.get('billing_invoice_details_table_label_bodypart')}
          </Text>
          <Text style={styles.invoiceDetailsTableEmergency}>
            {intl.get('billing_invoice_details_table_label_emergency')}
          </Text>
          <Text style={styles.invoiceDetailsTableCurrentPrice}>
            {intl.get('billing_invoice_details_table_label_price')}
          </Text>
        </View>
        {pageData.map((item, index) => {
          return (
            <View style={styles.invoiceDetailsTableRow} key={index}>
              <Text style={styles.invoiceDetailsTableIndex}>{item.index}</Text>
              <Text style={styles.invoiceDetailsTableTitle}>{item.title}</Text>
              <Text style={styles.invoiceDetailsTableModality}>
                {item.modality ? item.modality : ''}
              </Text>
              <Text style={styles.invoiceDetailsTableBodypart}>
                {item.bodypart ? item.modality : ''}
              </Text>
              <Text style={styles.invoiceDetailsTableEmergency}>
                {IssuePriorityCopy[item.emergency]}
              </Text>
              <Text style={styles.invoiceDetailsTableCurrentPrice}>
                {item.currentPrice ? item.currentPrice : 0}
              </Text>
            </View>
          );
        })}
      </View>
    </Fragment>
  );
};
InvoiceDetails.propTypes = propTypes;
export default InvoiceDetails;
