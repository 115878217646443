import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';

import { Grid, Card, TextField, Autocomplete, IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import FormField from 'pages/parts/FormField';
import DropZone from 'pages/parts/DropZone';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from 'components';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
  setReportContent: PropTypes.func.isRequired,
  setPreviewContent: PropTypes.func.isRequired,
  saveReportContent: PropTypes.func.isRequired,
};

const DashboardKanbanIssueDetailsReportsEditor = ({
  reportContent,
  setReportContent,
  setPreviewContent,
  saveReportContent,
}) => {
  const handleTestClick = () => {
    setReportContent((prevState) => {
      return {
        ...prevState,
        reportDate: Date.now().toLocaleString(),
      };
    });
  };

  const handleChange = (key) => async (event) => {
    setReportContent((prevState) => {
      return {
        ...prevState,
        [key]: event.target.value,
      };
    });
  };

  const handleImages = async (files) => {
    let newKeyImages = [...reportContent.keyImages];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      const fileName = file.name;
      const fileBuffer = await file.arrayBuffer();
      newKeyImages.push({
        fileName: fileName,
        fileBuffer: Buffer.from(fileBuffer),
      });
    }
    setReportContent((prevState) => {
      return {
        ...prevState,
        keyImages: newKeyImages,
      };
    });
  };

  const handleKeyImageDelete = (item, index) => {
    let newKeyImages = reportContent.keyImages;
    newKeyImages.splice(index, 1);
    setReportContent((prevState) => {
      return {
        ...prevState,
        keyImages: newKeyImages,
      };
    });
  };

  const handleRerenderButtonClick = () => {
    setPreviewContent((prevState) => ({
      ...reportContent,
    }));
  };

  const handleSaveButtonClick = () => {
    // TODO: add confirm modal
    saveReportContent();
  };

  return (
    <Fragment>
      <Card square={true} sx={{ overflow: 'visible' }}>
        <MDBox py={3} px={3} mt={1} mx={3}>
          <MDBox pl={1} mb={2} display="center" justifyContent="center">
            <MDTypography variant="h5">
              {intl.get('issue_details_report_preview_text_report_title')}
            </MDTypography>
          </MDBox>

          <Grid spacing={0} container>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_report_filename'
                  )}
                  onChange={handleChange('fileName')}
                  value={reportContent.fileName}
                />
              </Grid>
              <Grid item xs={3} sm={3} style={{ marginTop: 15 }}>
                {/* <Button variant="contained">Save</Button> */}
                <Button
                  variant="primary"
                  sx={{ justifyContent: 'start', width: '60%' }}
                  onClick={handleRerenderButtonClick}>
                  Rerender
                </Button>
              </Grid>
              <Grid item xs={3} sm={3} style={{ marginTop: 15 }}>
                {/* <Button variant="contained">Refresh</Button> */}
                <Button
                  variant="primary"
                  sx={{ justifyContent: 'start', width: '60%' }}
                  onClick={handleSaveButtonClick}>
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_issue_title'
                  )}
                  onChange={handleChange('issueTitle')}
                  value={reportContent.issueTitle}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_issue_date'
                  )}
                  onChange={handleChange('issueDate')}
                  value={reportContent.issueDate}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_patient_name'
                  )}
                  onChange={handleChange('patientName')}
                  value={reportContent.patientName}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_src_org_name'
                  )}
                  onChange={handleChange('srcOrgName')}
                  value={reportContent.srcOrgName}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_patient_gender'
                  )}
                  onChange={handleChange('patientGender')}
                  value={reportContent.patientGender}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_department'
                  )}
                  onChange={handleChange('department')}
                  value={reportContent.department}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_patient_birthday'
                  )}
                  onChange={handleChange('patientBirthdayDate')}
                  value={reportContent.patientBirthdayDate}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_doctors_from_src_org'
                  )}
                  onChange={handleChange('doctorsFromSrcOrg')}
                  value={reportContent.doctorsFromSrcOrg}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('issue_details_report_preview_text_tags')}
                  onChange={handleChange('tags')}
                  value={reportContent.tags}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_description'
                  )}
                  onChange={handleChange('description')}
                  value={reportContent.description}
                  multiline
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get('issue_details_report_preview_text_measures')}
                  onChange={handleChange('measures')}
                  value={reportContent.measures}
                  multiline
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_diagnosis'
                  )}
                  onChange={handleChange('diagnosis')}
                  value={reportContent.diagnosis}
                  multiline
                />
              </Grid>
            </Grid>

            {/* {reportContent.keyImages.map((keyImage, index) => {
              return (
                <Grid key={index} container spacing={3}>
                  <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                    <MDTypography px={2} variant="h6">
                      {keyImage.fileName}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                    <Button variant="contained">Delete</Button>
                  </Grid>
                </Grid>
              );
            })} */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <MDBox>
                  <MDTypography>
                    {intl.get('issue_details_report_preview_text_key_images')}
                  </MDTypography>
                </MDBox>
              </Grid>
              {reportContent.keyImages.map((item, index) => {
                return (
                  <MDBox
                    style={{ marginLeft: '15px', marginBottom: '15px' }}
                    key={index}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={3} alignItems="center">
                        <IconButton
                          onClick={() => {
                            handleKeyImageDelete(item, index);
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={9} alignItems="center">
                        <MDTypography variant="body2">
                          {item.fileName}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                );
              })}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                <DropZone handleFiles={handleImages} />
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardKanbanIssueDetailsReportsEditor.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsEditor);
