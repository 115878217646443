import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
};

const ReportMeasure = ({ reportContent }) => {
  return (
    <Fragment>
      <View style={styles.reportSectionTitle}>
        <Text>{intl.get('issue_details_report_preview_text_measures')}</Text>
      </View>
      <View style={styles.reportSectionContent}>
        <Text>{reportContent.measures}</Text>
      </View>
    </Fragment>
  );
};

ReportMeasure.propTypes = propTypes;

export default ReportMeasure;
