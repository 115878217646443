import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceContent: PropTypes.string.isRequired,
};

const InvoiceDate = ({ invoiceContent }) => {
  return (
    <Fragment>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.invoiceDateLabel}>
          {intl.get('billing_invoice_text_date')}
        </Text>
        <Text style={styles.invoiceDateText}>{invoiceContent.invoiceDate}</Text>
      </View>
    </Fragment>
  );
};

InvoiceDate.propTypes = propTypes;

export default InvoiceDate;
