import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

import { color, font } from 'shared/utils/styles';

export const Content = styled.div`
  display: flex;
  padding: 0 30px 60px;
`;

export const Left = styled.div`
  width: 65%;
  padding-right: 50px;

  // make the left side scrollable
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  min-height: 500px;
  max-height: 800px;
  width: 80%;
  border-radius: 3px;
  overflow-y: auto;
`;

export const Right = styled.div`
  width: 35%;
  padding-top: 5px;
`;

export const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 18px 0;
`;

export const TopActionsRight = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 4px;
  }
`;

export const SectionTitle = styled.div`
  margin: 24px 0 5px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(12.5)}
  ${font.bold}
`;

export const InfoLabel = styled.div`
  margin: 24px 0 8px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(13.5)}
  ${font.bold}
`;

export const useStyles = makeStyles((theme) => ({
  viewportModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewportModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '85vw',
    height: '95vh',
  },
  previewModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '85vw',
    maxHeight: '95vh',
  },
}));
