import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  & > button {
    visibility: hidden;
    margin-right: 3px;
    margin-left: 3px;
  }
  &:hover > button {
    margin-right: 3px;
    margin-left: 3px;
    visibility: visible;
  }
`;

export const FirstAction = styled.div`
  display: flex;
  & > button {
    margin-right: 3px;
    margin-left: 3px;
  }
`;
