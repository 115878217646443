import intl from 'react-intl-universal';
import { Button, Typography } from '@material-ui/core';

export const getSentApplicationTableColumns = (openChatWithUser) => [
  {
    name: 'user name',
    label: intl.get('friends_label_column_user_nickname'),
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'user id',
    label: intl.get('friends_label_column_user_id'),
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'message',
    label: intl.get('friends_label_column_message'),
    options: {
      filter: true,
      filterType: 'textField',
    },
  },
  {
    name: 'Operation',
    label: intl.get('friends_label_column_operations'),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updatedValue) => {
        const userID = value[0];
        const handleResult = value[1];
        let hint = intl.get('friends_button_in_application');
        if (handleResult === 1) {
          hint = intl.get('friends_button_accepted');
        } else if (handleResult === 2) {
          hint = intl.get('friends_button_rejected');
        }
        return (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                openChatWithUser(userID);
              }}>
              {intl.get('friends_button_send_message')}
            </Button>
            <Button variant="contained" disabled color="primary">
              {hint}
            </Button>
          </div>
        );
      },
    },
  },
];
