// Keep the value consistent with that in api
export const Privilege = {
  NEW_TASK: 'NEW_TASK',
  ORGANIZATION_TASK_MANAGEMENT: 'ORGANIZATION_TASK_MANAGEMENT',
  ORGANIZATION_TASK_LIST: 'ORGANIZATION_TASK_LIST',
  ORGANIZATION_RECEIVED_LIST: 'ORGANIZATION_RECEIVED_LIST',
  ORGANIZATION_SENT_LIST: 'ORGANIZATION_SENT_LIST',
  BILLING: 'BILLING',
  BILLING_RECEIVED_TASKS: 'BILLING_RECEIVED_TASK',
  BILLING_SENT_TASKS: 'BILLING_SENT_TASK',
  ORGANIZATION_MANAGEMENT: 'ORGANIZATION_MANAGEMENT',
  ORGANIZATION_SETTINGS: 'ORGANIZATION_SETTINGS',
  CONTRACT_MANAGEMENT: 'CONTRACT_MANAGEMENT',
  EMPLOYMENT_MANAGEMENT: 'EMPLOYMENT_MANAGEMENT',
  TAG_TEMPLATE_MANAGEMENT: 'TAG_TEMPLATE_MANAGEMENT',
  ACTIVITIES_LOG_MANAGEMENT: 'ACTIVITIES_LOG_MANAGEMENT',
  PERSON_IN_CHARGE_AS_STAFF: 'PERSON_IN_CHARGE_AS_STAFF',
  PERSON_IN_CHARGE_AS_DOCTOR: 'PERSON_IN_CHARGE_AS_DOCTOR',
};

export const SwitchLabelForOwner = {
  NEW_TASK: Privilege.NEW_TASK,
  ORGANIZATION_TASK_MANAGEMENT: Privilege.ORGANIZATION_TASK_MANAGEMENT,
  BILLING: Privilege.BILLING,
  ORGANIZATION_MANAGEMENT: Privilege.ORGANIZATION_MANAGEMENT,
  PERSON_IN_CHARGE_AS_STAFF: Privilege.PERSON_IN_CHARGE_AS_STAFF,
  PERSON_IN_CHARGE_AS_DOCTOR: Privilege.PERSON_IN_CHARGE_AS_DOCTOR,
};

export const SwitchLabel = {
  NEW_TASK: Privilege.NEW_TASK,
  ORGANIZATION_TASK_MANAGEMENT: Privilege.ORGANIZATION_TASK_MANAGEMENT,
  BILLING: Privilege.BILLING,
  PERSON_IN_CHARGE_AS_STAFF: Privilege.PERSON_IN_CHARGE_AS_STAFF,
  PERSON_IN_CHARGE_AS_DOCTOR: Privilege.PERSON_IN_CHARGE_AS_DOCTOR,
};

export const SwitchLabelToPrivilege = {
  [SwitchLabel.NEW_TASK]: [Privilege.NEW_TASK],
  [SwitchLabel.ORGANIZATION_TASK_MANAGEMENT]: [
    Privilege.ORGANIZATION_TASK_MANAGEMENT,
    Privilege.ORGANIZATION_TASK_LIST,
    Privilege.ORGANIZATION_RECEIVED_LIST,
    Privilege.ORGANIZATION_SENT_LIST,
  ],
  [SwitchLabel.BILLING]: [
    Privilege.BILLING,
    Privilege.BILLING_RECEIVED_TASKS,
    Privilege.BILLING_SENT_TASKS,
  ],
  [SwitchLabelForOwner.ORGANIZATION_MANAGEMENT]: [
    Privilege.ORGANIZATION_MANAGEMENT,
    Privilege.ORGANIZATION_SETTINGS,
    Privilege.CONTRACT_MANAGEMENT,
    Privilege.EMPLOYMENT_MANAGEMENT,
    Privilege.TAG_TEMPLATE_MANAGEMENT,
    Privilege.ACTIVITIES_LOG_MANAGEMENT,
  ],
  [SwitchLabel.PERSON_IN_CHARGE_AS_STAFF]: [
    Privilege.PERSON_IN_CHARGE_AS_STAFF,
  ],
  [SwitchLabel.PERSON_IN_CHARGE_AS_DOCTOR]: [
    Privilege.PERSON_IN_CHARGE_AS_DOCTOR,
  ],
};

export const SwitchLableToTranslationKey = {
  [SwitchLabel.NEW_TASK]:
    'organization_settings_user_management_modal_label_new_task',
  [SwitchLabel.ORGANIZATION_TASK_MANAGEMENT]:
    'organization_settings_user_management_modal_label_organization_task_management',
  [SwitchLabel.BILLING]:
    'organization_settings_user_management_modal_label_billing',
  [SwitchLabelForOwner.ORGANIZATION_MANAGEMENT]:
    'organization_settings_user_management_modal_label_organization_management',
  [SwitchLabel.PERSON_IN_CHARGE_AS_STAFF]:
    'organization_settings_user_management_modal_label_pic_as_staff',
  [SwitchLabel.PERSON_IN_CHARGE_AS_DOCTOR]:
    'organization_settings_user_management_modal_label_pic_as_doctor',
};

export const PrivilegeStatus = {
  ON: 'ON',
  OFF: 'OFF',
};
