import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { TaskTimer } from 'tasktimer';
import {
  Grid,
  Modal,
  Checkbox,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import Chip from './Chip';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from 'pages/parts/FormField';
import FormFieldHelperText from 'pages/parts/FormFieldHelperText';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import DashboardNewDropZone from 'pages/parts/DropZone';
import { CustomCheckboxForm } from './customCheckboxForm';
import { PageLoader, PageError, TextEditor } from 'components';
import useStyles from './styles';
import { useMaterialUIController } from 'context';
import {
  generateConfirmWindowContents,
  createNewResearchProject,
} from './utils';

import { removeLeadingSlash } from 'shared/utils/misc';
import pxToRem from 'assets/theme/functions/pxToRem';
import { tagIntlValues, tagRelationships } from 'shared/constants/tagsValues';
import { formConstants } from './constants';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { getUserFullName } from 'shared/utils/name';
import { DOLPHIN_API } from 'shared/constants/apis';
import { isDeepEqual } from 'shared/utils/equality';

const AutocompleteGender = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 0.88em;
  }
`;

const propTypes = {};

const DashboardResearchProjectNew = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();
  const currentUsername = localData.get('username');
  const currentOrganization = localData.get('currentOrganization');
  const currentOrganizationId = localData.get('currentOrganizationId');

  // states specifically for resetting some Autocomplete inputs;
  const [toggleResetDescription, setToggleResetDescription] = useState(false);

  // states related to pre-sending;
  const [warnFormIncomplete, setWarnFormIncomplete] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [showWarningBeforeSend, setShowWarningBeforeSend] = useState(false);
  const [sendContentsConfirmed, setSendContentsConfirmed] = useState(false);
  const [formState, setFormState] = useState({
    title: '',
    organizationId: '',
    members: [],
    description: '',
  });

  const [confirmNotCheck, setconfirmNotCheck] = useState(false);
  const [sendNewIssueFailed, setSendNewIssueFailed] = useState(false);
  const [doctorsInCharge, setDoctorsInCharge] = useState([]);
  const [members, setMembers] = useState([]);

  const [{ data, error }] = useApi.get(DOLPHIN_API.ORGANIZATION_NEW, {
    params: { organizationId: currentOrganizationId },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { dstOrgs, CvicSuperUserOrgs, doctorsFromSrcOrg } = data;
  const doctorOptions = doctorsFromSrcOrg.map((doctor) => ({
    label: getUserFullName(doctor.firstName, doctor.lastName),
    username: doctor.username,
    id: doctor.id,
  }));

  dstOrgs.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
  const resetForm = () => {
    setFormState({
      title: '',
      members: [],
      description: '',
      currentOrganizationId: currentOrganizationId,
    });
    setMembers([]);
    setToggleResetDescription(!toggleResetDescription);
  };

  const handleChange = (key) => async (event) => {
    const newFormState = { ...formState, [key]: event.target.value };
    setFormState({ ...newFormState });
  };

  const handleChangeMembers = (newMembers) => {
    const members = newMembers.map((member) => member.username);
    setFormState((prevFormState) => ({
      ...prevFormState,
      members: members,
    }));
  };

  const handleDescriptionChange = (htmlValue) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      description: htmlValue,
    }));
  };

  const checkIfFormCompleted = () => {
    if (!formState.title) {
      let incompleteFields = [!formState.title ? 'Project Title' : null]; // TODO: use translator
      incompleteFields = incompleteFields.filter(function (e) {
        return e;
      });
      setIncompleteFields(incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleConfirmContentsCheckbox = () => {
    if (sendContentsConfirmed) {
      setSendContentsConfirmed(false);
    } else {
      setSendContentsConfirmed(true);
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (checkIfFormCompleted()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShowWarningBeforeSend(true);
      return;
    } else {
      setWarnFormIncomplete(true);
      return;
    }
  };

  const handleShowWarningBeforeSendModalClose = () => {
    setSendContentsConfirmed(false);
    setShowWarningBeforeSend(false);
  };

  const handleConfirmNotCheckModalClose = () => {
    setconfirmNotCheck(false);
  };

  const handleNewIssueFailModalClose = () => {
    setSendNewIssueFailed(false);
  };

  const sendNewResearchProject = async () => {
    if (!sendContentsConfirmed) {
      setconfirmNotCheck(true);
      return false;
    }
    setShowWarningBeforeSend(false);
    setSendContentsConfirmed(false);

    try {
      const sendNewResearchProject = await createNewResearchProject(formState);
      window.location.reload();
    } catch (error) {
      resetForm();
      setSendNewIssueFailed(true);
      return false;
    }

    // The rest of the steps are in function "sendNewIssueContinued"
    // It has to wait for addNewMission to finish updating the state stored in context;
    // Therefore It is placed inside useEffect hook
  };

  return (
    <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">
            {/* {intl.get('new_text_new_task')} */}
            {'New Project'}
          </MDTypography>
        </MDBox>

        <Grid spacing={3} container>
          <Grid xs={12} item>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <FormField
                  variant="outlined"
                  // label={intl.get('new_label_department')}
                  label={'Project Title'}
                  onChange={handleChange(formConstants.RESEARCH_PROJECT_TITLE)}
                  value={formState.title}
                />
              </Grid>

              <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                <Autocomplete
                  multiple
                  id="doctor-in-charge"
                  options={doctorOptions}
                  freeSolo
                  value={members}
                  onChange={(event, newValue) => {
                    if (newValue.length > members.length) {
                      const lastNewValue = newValue[newValue.length - 1];
                      if (typeof lastNewvalue !== 'string') {
                        let i = 0;
                        for (i = 0; i < newValue.length - 1; i++) {
                          if (
                            typeof newValue[i] !== 'string' &&
                            newValue[i].id === lastNewValue.id
                          ) {
                            break;
                          }
                        }
                        if (i !== newValue.length - 1) {
                          newValue.splice(newValue.length - 1);
                          newValue.splice(i, 1);
                        }
                      }
                    }
                    const newMembers = newValue.map((member) => {
                      if (typeof member === 'string') {
                        return {
                          label: member,
                          username: member,
                          id: -1,
                        };
                      }
                      return member;
                    });
                    handleChangeMembers(newMembers);
                    setMembers(newMembers);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        labelcolor={darkMode ? 'white' : 'grey'}
                        deleteiconcolor={darkMode ? 'white' : 'grey'}
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      // label={intl.get('new_label_doctor_in_charge')}
                      label={'Add Members'}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid item xs={12} sm={12}>
              <MDTypography
                style={{
                  fontWeight: 'light',
                  fontSize: '12px',
                  color: 'light',
                  opacity: 0.7,
                  marginBottom: '10px',
                  marginTop: 0,
                }}>
                {/* {intl.get('new_label_additional_info')} */}
                {'Description'}
              </MDTypography>
              <TextEditor
                className={classes.description}
                placeholder={intl.get('new_label_case_description')}
                defaultValue={formState.description}
                onChange={handleDescriptionChange}
                key={toggleResetDescription}
              />
            </Grid>
          </Grid>
        </Grid>

        <br />

        <Grid spacing={3} container alignItems="flex-end">
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              style={{ marginTop: '15px', marginBottom: '10px' }}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmitClick}
                style={{ color: 'white', fontSize: pxToRem(18) }}>
                {/* {intl.get('new_button_submit')} */}
                {'Submit'}
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>

      <ConfirmModal
        isOpened={sendNewIssueFailed}
        title={intl.get('new_modal_new_issue_failed_message_1')}
        onConfirm={handleNewIssueFailModalClose}
        confirmButtonText={intl.get('new_modal_new_issue_failed_message_4')}>
        {
          <div>
            <MDTypography mb={4}>
              {intl.get('new_modal_new_issue_failed_message_2')}
            </MDTypography>
            <MDTypography mb={1}>
              {intl.get('new_modal_new_issue_failed_message_3')}
            </MDTypography>
          </div>
        }
      </ConfirmModal>

      <ConfirmModal
        isOpened={confirmNotCheck}
        title={intl.get('confirm_not_check_message_1')}
        onConfirm={handleConfirmNotCheckModalClose}
        confirmButtonText={intl.get('confirm_not_check_message_4')}>
        {
          <div>
            <MDTypography mb={4}>
              {intl.get('confirm_not_check_message_2')}
            </MDTypography>
            <MDTypography mb={1}>
              {intl.get('confirm_not_check_message_3')}
            </MDTypography>
          </div>
        }
      </ConfirmModal>

      <ConfirmModal
        isOpened={warnFormIncomplete}
        title={intl.get('new_modal_text_warning_title')}
        body={intl.get('new_modal_text_warning_text_empty')}
        onConfirm={() => setWarnFormIncomplete(false)}
        confirmButtonText={intl.get('new_modal_tag_confirm')}>
        <MDBox>
          <br />
          {incompleteFields &&
            incompleteFields.map((field, index) => (
              <p style={{ color: 'red' }} key={index}>
                {field}
              </p>
            ))}
          <br />
          <MDTypography mb={1}>
            {intl.get('new_modal_text_warning_text_fill')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      {/* Modal of confirmation sending info to the server */}
      <ConfirmModal
        isOpened={showWarningBeforeSend}
        title={intl.get('new_modal_text_confirm_title')}
        bodyText={`*${intl.get('new_modal_text_confirm_text_correct')}`}
        onConfirm={sendNewResearchProject}
        onCancel={handleShowWarningBeforeSendModalClose}
        confirmButtonText={intl.get('new_modal_button_submit')}
        cancelButtonText={intl.get('new_modal_button_cancel')}>
        <MDBox sx={{ overFlowY: 'auto' }}>
          {showWarningBeforeSend &&
            generateConfirmWindowContents(formState, darkMode)}
          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ marginTop: '20px' }}>
            <Grid xs={12} item>
              <FormControlLabel
                style={{ color: darkMode ? 'white' : 'black' }}
                control={
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon style={{ fill: '#66ccff' }} />
                    }
                    onChange={handleConfirmContentsCheckbox}
                  />
                }
                label={intl.get('new_modal_text_confirm_text_sure')}
              />
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>
    </Card>
  );
};

DashboardResearchProjectNew.propTypes = propTypes;

export default withRouter(DashboardResearchProjectNew);
