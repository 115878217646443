import styled from 'styled-components';
import { color, font, mixin } from 'shared/utils/styles';

export const Content = styled.div`
  // display: flex;
  padding: 0 6px 30px;
`;

export const Title = styled.div`
  padding: 10px 0 6px;
  width: 90%;
  ${font.size(18)};
  ${font.medium};
`;

export const Line = styled.div`
  padding: 5px 0 0 10px;
  display: flex;
  width: 100%;
`;

export const Key = styled.div`
  font-weight: bold;
  width: 25%;
  ${font.size(15)};
  ${font.medium};
`;

export const CloseButton = styled.button`
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  font-size: 16px; /* Font size */
  padding: 2px 12px; /* Padding around the text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for background color */

  &:hover {
    background-color: #45a049; /* Darker shade of green when hovered */
  }
`;

export const Value = styled.div`
  width: 25%;
  ${font.size(15)};
  ${font.medium};
`;

export const ValueFill = styled.div`
  max-width: 100%;
  ${font.size(15)};
  ${font.medium};
`;
