/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React base styles
import typography from 'assets/theme/base/typography';
// import brandWhite from "assets/images/logos/iwg/iwg-white-logo-cropped.png";
import brandWhite from 'assets/images/logos/iwg/iwg-inc-logo-white-2.png';

function Footer({ loginMode, light }) {
  const { size } = typography;

  let bgColor;
  if (loginMode === 1) {
    bgColor = { backgroundColor: 'rgba(52, 52, 52, 0.9)' };
  }

  return (
    <MDBox position="absolute" width="100%" bottom={0} py={4} sx={bgColor}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column' }}
          justifyContent="center"
          alignItems="center"
          px={1.5}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? 'white' : 'text'}
            fontSize={size.lg}>
            <MDBox component={Link} href="https://www.iwg-inc.co.jp/">
              <MDBox
                component="img"
                src={brandWhite}
                alt="Brand"
                width="16rem"
                mr={1}
                mt={0}
              />
            </MDBox>
            {'© '}
            {new Date().getFullYear()}
            {' Powered by I.W.G Inc. All rights reserved.'}
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  loginMode: PropTypes.number.isRequired,
  light: PropTypes.bool,
};

export default Footer;
