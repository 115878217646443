import React from 'react';
import PropTypes from 'prop-types';
import { IssuePriority, getIssuePriority } from 'shared/constants/issues';

import {
  EmergencyRegular,
  Emergency1Hour,
  Emergency3Hours,
  Emergency24Hours,
} from './styles';

const propTypes = {
  priority: PropTypes.string,
};

const DataTableEmergency = ({ priority }) => {
  switch (priority) {
    case IssuePriority.HIGHEST:
      return <Emergency1Hour>{getIssuePriority(priority)}</Emergency1Hour>;
    case IssuePriority.HIGH:
      return <Emergency3Hours>{getIssuePriority(priority)}</Emergency3Hours>;
    case IssuePriority.MEDIUM:
      return <Emergency24Hours>{getIssuePriority(priority)}</Emergency24Hours>;
    case IssuePriority.LOW:
      return <EmergencyRegular>{getIssuePriority(priority)}</EmergencyRegular>;
    default:
      break;
  }
  return <div></div>;
};
DataTableEmergency.propTypes = propTypes;

export default DataTableEmergency;
