import Icon from '@mui/material/Icon';
import intl from 'react-intl-universal';

// import CreateIcon from '@mui/icons-material/Create';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScienceIcon from '@mui/icons-material/Science';
import PaidIcon from '@mui/icons-material/Paid';

// page components
import New from './New';
import ReceivedHistory from './ReceivedHistory';
import SentHistory from './SentHistory';
import BillingForReceivedTasks from './Billing/BillingForReceivedTasks';
import BillingForSentCases from './Billing/BillingForSentTasks';
import Kanban from './Kanban';
import OrganizationSettings from './OrganizationSettings';
import OrganizationLogs from './OrganizationLogs';
import Manual from './Manual';
import ServiceTerms from './ServiceTerms';
import Friends from './Contacts/Friends';
import ResearchProjects from './Research/Projects';
import ResearchCases from './Research/Cases';
import ResearchAttachments from './Research/Attachments';
// Kenshin page components
import NewKenshin from 'pages/Dashboard/New_Kenshin';
import KanbanKenshin from 'pages/Dashboard/Kanban_Kenshin';
import ReceivedHistoryKenshin from 'pages/Dashboard/ReceivedHistory_Kenshin';
import SentHistoryKenshin from 'pages/Dashboard/SentHistory_Kenshin';
import ReceivedAssignmentKenshin from 'pages/Dashboard/Assignment/ReceivedAssignmentKenshin';

import { MenuOptionsAll } from 'shared/constants/menuOptions';
import {
  ROUTES,
  // ROUTE_TITLE
} from 'shared/constants/routes';
import {
  USER_DATA,
  // UserType
} from 'shared/constants/users';
import localData from 'shared/utils/localData';
import { Privilege, PrivilegeStatus } from 'shared/constants/privilege';
import { ISSUE_LIST_TYPE } from 'shared/constants/issueList';
import PaidWage from './PaidWage';
import MyWage from './MyWage';

export const getStaticMenuContents = (userInfo) => {
  const hasAffiliation = !!userInfo.organizations;

  if (!hasAffiliation) {
    return [
      {
        type: 'collapse',
        name: intl.get('dashboard_button_service_terms'),
        key: ROUTES.SERVICE_TERMS,
        icon: <Icon fontSize="medium">handshake</Icon>,
        route: '/service-terms',
        component: <ServiceTerms />,
        noCollapse: true,
      },
    ];
  }

  const menuContentForNew_both = {
    type: 'collapse',
    name: intl.get('dashboard_button_new'),
    key: 'new',
    icon: <Icon fontSize="medium">create</Icon>,
    collapse: [
      {
        name: intl.get('dashboard_button_new'),
        key: 'new-regular',
        route: '/new/new-regular',
        component: <New />,
      },
      {
        name: intl.get('dashboard_button_new_kenshin'),
        key: 'new-kenshin',
        route: '/new/new-kenshin',
        component: <NewKenshin />,
      },
    ],
  };

  const menuContentForTaskMangement_both = {
    type: 'collapse',
    name: intl.get('dashboard_button_task_management'),
    key: 'task-management',
    icon: <ViewKanbanIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_task_management'),
        key: 'task-management-regular',
        route: '/task-management/task-management-regular',
        component: <Kanban />,
      },
      {
        name: intl.get('dashboard_button_task_management_kenshin'),
        key: 'task-management-kenshin',
        route: '/task-management/task-management-kenshin',
        component: <KanbanKenshin />,
      },
    ],
  };

  const menuContentForHistory = {
    type: 'collapse',
    name: intl.get('dashboard_button_history'),
    key: 'history',
    icon: <AssignmentIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_history_received'),
        key: 'received',
        route: '/history/received',
        component: <ReceivedHistory type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
      {
        name: intl.get('dashboard_button_history_sent'),
        key: 'sent',
        route: '/history/sent',
        component: <SentHistory type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
      {
        name: intl.get('dashboard_button_history_received_kenshin'),
        key: 'received-kenshin',
        route: '/history/received-kenshin',
        component: <ReceivedHistoryKenshin type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
      {
        name: intl.get('dashboard_button_history_sent_kenshin'),
        key: 'sent-kenshin',
        route: '/history/sent-kenshin',
        component: <SentHistoryKenshin type={ISSUE_LIST_TYPE.TASK_LIST} />,
      },
    ],
  };

  const menuContentForResearch = {
    type: 'collapse',
    name: intl.get('dashboard_button_research'),
    key: 'research',
    icon: <ScienceIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_research_projects'),
        key: 'projects',
        route: '/research/projects',
        component: <ResearchProjects />,
      },
      {
        name: intl.get('dashboard_button_research_cases'),
        key: 'cases',
        route: '/research/cases',
        component: <ResearchCases />,
      },
      {
        name: intl.get('dashboard_button_research_attachments'),
        key: 'attachments',
        route: '/research/attachments',
        component: <ResearchAttachments />,
      },
    ],
  };

  const menuContentForAssignment = {
    type: 'collapse',
    name: intl.get('dashboard_button_assignment'),
    key: 'assignment',
    icon: <AssignmentIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_assignment_received'),
        key: 'received',
        route: '/assignment/received',
        component: <ReceivedHistory type={ISSUE_LIST_TYPE.ASSIGNMNET} />,
      },
      {
        name: intl.get('dashboard_button_assignment_sent'),
        key: 'sent',
        route: '/assignment/sent',
        component: <SentHistory type={ISSUE_LIST_TYPE.ASSIGNMNET} />,
      },
      {
        name: intl.get('dashboard_button_assignement_received_kenshin'),
        key: 'received-kenshin',
        route: '/assignment/received-kenshin',
        component: <ReceivedAssignmentKenshin />,
      },
    ],
  };

  const menuContentForContacts = {
    type: 'collapse',
    name: intl.get('dashboard_button_contacts'),
    key: 'contacts',
    icon: <GroupIcon />,
    collapse: [
      {
        name: intl.get('dashboard_button_friends'),
        key: 'friends',
        route: '/contacts/friends',
        component: <Friends />,
      },
    ],
  };

  const menuContentForBilling = {
    type: 'collapse',
    name: intl.get('dashboard_button_billing'),
    key: 'billing',
    icon: <Icon fontSize="medium">currency_yen</Icon>,
    collapse: [
      {
        name: intl.get('dashboard_button_billing_received'),
        key: 'received-billing',
        route: '/billing/received-billing',
        component: <BillingForReceivedTasks />,
      },
      {
        name: intl.get('dashboard_button_billing_sent'),
        key: 'sent-billing',
        route: '/billing/sent-billing',
        component: <BillingForSentCases />,
      },
    ],
  };

  const menuContentForPaidWage = {
    type: 'collapse',
    name: intl.get('dashboard_button_paid_wage'),
    key: 'paid-wage',
    icon: <PaidIcon />,
    route: '/paid-wage',
    component: <PaidWage />,
    noCollapse: true,
  };

  const menuContentForMyWage = {
    type: 'collapse',
    name: intl.get('dashboard_button_my_wage'),
    key: 'my-wage',
    icon: <PaidIcon />,
    route: '/my-wage',
    component: <MyWage />,
    noCollapse: true,
  };

  const menuContentForOrganizationSettings = {
    type: 'collapse',
    name: intl.get('dashboard_button_organization_settings'),
    key: 'settings',
    icon: <Icon fontSize="medium">settings</Icon>,
    route: '/settings',
    component: <OrganizationSettings />,
    noCollapse: true,
  };

  const menuContentForOrganizationLogs = {
    type: 'collapse',
    name: intl.get('dashboard_button_organization_logs'),
    key: 'logs',
    icon: <Icon fontSize="medium">assignment</Icon>,
    route: '/logs',
    component: <OrganizationLogs />,
    noCollapse: true,
  };

  const menuContentForManual = {
    type: 'collapse',
    name: intl.get('dashboard_button_manual'),
    key: 'manual',
    icon: <Icon fontSize="medium">lightbulb</Icon>,
    route: '/manual',
    component: <Manual />,
    noCollapse: true,
  };

  const menuContentForServiceTerms = {
    type: 'collapse',
    name: intl.get('dashboard_button_service_terms'),
    key: 'service-terms',
    icon: <Icon fontSize="medium">handshake</Icon>,
    route: '/service-terms',
    component: <ServiceTerms />,
    noCollapse: true,
  };

  const menuOptionsToContent = {
    [MenuOptionsAll.NEW]: menuContentForNew_both,
    [MenuOptionsAll.TASKMANAGEMENT]: menuContentForTaskMangement_both,
    [MenuOptionsAll.HISTORY]: menuContentForHistory,
    [MenuOptionsAll.ASSIGNMENT]: menuContentForAssignment,
    [MenuOptionsAll.CONTACTS]: menuContentForContacts,
    [MenuOptionsAll.RESEARCH]: menuContentForResearch,
    [MenuOptionsAll.BILLING]: menuContentForBilling,
    [MenuOptionsAll.PAIDWAGE]: menuContentForPaidWage,
    [MenuOptionsAll.MYWAGE]: menuContentForMyWage,
    [MenuOptionsAll.ORGSETTINGS]: menuContentForOrganizationSettings,
    [MenuOptionsAll.ORGLOGS]: menuContentForOrganizationLogs,
    [MenuOptionsAll.MANUAL]: menuContentForManual,
    [MenuOptionsAll.SERVICETERMS]: menuContentForServiceTerms,
  };

  const getMenuOptionsByPrivilege = () => {
    const privilege = JSON.parse(localData.get(USER_DATA.CURRENT_PRIVILEGE));
    const menuOptions = [];

    // new task
    if (privilege[Privilege.NEW_TASK] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.NEW);
    }

    // task management, aka, kanban
    if (
      privilege[Privilege.ORGANIZATION_TASK_MANAGEMENT] === PrivilegeStatus.ON
    ) {
      menuOptions.push(MenuOptionsAll.TASKMANAGEMENT);
    }

    // task list, including received and sent
    if (privilege[Privilege.ORGANIZATION_TASK_LIST] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.HISTORY);
    }

    // billing
    if (privilege[Privilege.BILLING] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.BILLING);
    }

    // payment
    if (privilege[Privilege.BILLING] === PrivilegeStatus.ON) {
      // TODO: ADD NEW PRIVILEGE FOR PAY
      menuOptions.push(MenuOptionsAll.PAIDWAGE);
    }

    if (
      privilege[Privilege.PERSON_IN_CHARGE_AS_DOCTOR] === PrivilegeStatus.ON
    ) {
      menuOptions.push(MenuOptionsAll.MYWAGE);
    }

    // assignment for doctors
    if (
      privilege[Privilege.PERSON_IN_CHARGE_AS_DOCTOR] === PrivilegeStatus.ON
    ) {
      menuOptions.push(MenuOptionsAll.ASSIGNMENT);
    }

    // organization management
    if (privilege[Privilege.ORGANIZATION_MANAGEMENT] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.ORGSETTINGS);
    }

    // logs
    if (privilege[Privilege.ACTIVITIES_LOG_MANAGEMENT] === PrivilegeStatus.ON) {
      menuOptions.push(MenuOptionsAll.ORGLOGS);
    }

    // default
    menuOptions.push(MenuOptionsAll.RESEARCH);
    menuOptions.push(MenuOptionsAll.CONTACTS);
    // menuOptions.push(MenuOptionsAll.MANUAL);
    menuOptions.push(MenuOptionsAll.SERVICETERMS);

    return menuOptions;
  };

  const getMenuContents = (menuOptions) => {
    const menuContents = [];
    menuOptions.forEach((option) => {
      menuContents.push(menuOptionsToContent[option]);
    });
    return menuContents;
  };

  const menuOptions = getMenuOptionsByPrivilege();
  const menuContents = getMenuContents(menuOptions);

  return menuContents;
};
