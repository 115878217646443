// import { useRouteMatch, useHistory } from 'react-router-dom';
import { IssueStatusKenshin } from 'shared/constants/kenshin/issues';
import {
  moveItemWithinArray,
  insertItemIntoArray,
} from 'shared/utils/javascript';

// Temp for dev
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import { KENSHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';

const triggerEventForIssue = (issueId, event_) => {
  api
    .post(`${KENSHIN_API.DEV}`, {
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        username: localData.get(USER_DATA.USERNAME),
        userId: localData.get(USER_DATA.USER_ID),
      },
      data: {
        type: 'trigger',
        issueId: issueId,
        event: event_,
      },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      console.error(error);
    });
};

const isPositionChanged = (destination, source) => {
  if (!destination) return false;
  const isSameList = destination.droppableId === source.droppableId;
  const isSamePosition = destination.index === source.index;
  return !isSameList || !isSamePosition;
};

const getSortedListIssues = (issues, status) =>
  issues
    .filter((issue) => issue.status === status)
    .sort((a, b) => a.listPosition - b.listPosition);

const getAfterDropPrevNextIssue = (
  allIssues,
  destination,
  source,
  droppedIssueId
) => {
  const beforeDropDestinationIssues = getSortedListIssues(
    allIssues,
    destination.droppableId
  );
  const droppedIssue = allIssues.find((issue) => issue.id === droppedIssueId);
  const isSameList = destination.droppableId === source.droppableId;

  const afterDropDestinationIssues = isSameList
    ? moveItemWithinArray(
        beforeDropDestinationIssues,
        droppedIssue,
        destination.index
      )
    : insertItemIntoArray(
        beforeDropDestinationIssues,
        droppedIssue,
        destination.index
      );

  return {
    prevIssue: afterDropDestinationIssues[destination.index - 1],
    nextIssue: afterDropDestinationIssues[destination.index + 1],
  };
};

const calculateIssueListPosition = (...args) => {
  const { prevIssue, nextIssue } = getAfterDropPrevNextIssue(...args);
  let position;

  if (!prevIssue && !nextIssue) {
    position = 1;
  } else if (!prevIssue) {
    position = nextIssue.listPosition - 1;
  } else if (!nextIssue) {
    position = prevIssue.listPosition + 1;
  } else {
    position =
      prevIssue.listPosition +
      (nextIssue.listPosition - prevIssue.listPosition) / 2;
  }
  return position;
};

export const HandleIssueDrop = (
  draggableId,
  destination,
  source,
  updateIssue,
  project,
  goToIssueDetails,
  showInvalidDestinationAlert,
  setConfirmModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData
) => {
  // Judge whether the drag from and the drop to the same position
  if (!isPositionChanged(destination, source)) return;

  const issueId = Number(draggableId);
  const listPosition = calculateIssueListPosition(
    project.issues,
    destination,
    source,
    issueId
  );
  const confirmModalInfoDefault = {
    source: source.droppableId,
    destination: destination.droppableId,
    issueId: issueId,
  };

  if (source.droppableId === destination.droppableId) {
    // only update the list position;
    updateIssue(issueId, destination, project, listPosition);
  } else if (source.droppableId === IssueStatusKenshin.NEWTASK) {
    if (destination.droppableId === IssueStatusKenshin.DOCTORASSIGNED) {
      // allow the issue status (progress) to change directly into DOCTORASSIGNED for Kenshin;
      updateIssue(issueId, destination, project, listPosition);
      goToIssueDetails(issueId);
    } else {
      showInvalidDestinationAlert();
    }
  } else if (source.droppableId === IssueStatusKenshin.DOCTORASSIGNED) {
    if (destination.droppableId === IssueStatusKenshin.REPORTREADY) {
      updateIssue(issueId, destination, project, listPosition);
    } else {
      showInvalidDestinationAlert();
    }
  } else if (source.droppableId === IssueStatusKenshin.REPORTREADY) {
    if (destination.droppableId === IssueStatusKenshin.SENDBACK) {
      // get issue; check its stats numbers, if there are unfinished cases; warn;
      const issue = project.issues.find((item) => item.id === issueId);
      const { caseCountToBeAssigned, caseCountToBeDiagnosed } =
        calcCaseStatsForIssue(issue);

      setConfirmModalInfo({
        ...confirmModalInfoDefault,
        CasesComplete: caseCountToBeAssigned + caseCountToBeDiagnosed === 0,
      });
      setConfirmModalOpen(true);
      setConfirmModalUpdateData({
        issueId,
        source,
        destination,
        listPosition,
      });
    } else if (destination.droppableId === IssueStatusKenshin.DOCTORASSIGNED) {
      updateIssue(issueId, destination, project, listPosition);
    } else {
      showInvalidDestinationAlert();
    }
  } else if (source.droppableId === IssueStatusKenshin.SENDBACK) {
    // Temp for dev
    triggerEventForIssue(issueId, 'trigger-generate-bill-and-wages');
    showInvalidDestinationAlert();
  } else if (source.droppableId === IssueStatusKenshin.DONE) {
    showInvalidDestinationAlert();
  }
};

const calcCaseStatsForIssue = (issue) => {
  const caseCountTotal = issue.cases.length;
  const count_assigned = issue.cases.filter((item) => {
    return item.doctorId || item.doctorId === 0;
  }).length;

  const caseCountToBeAssigned = caseCountTotal - count_assigned;

  const caseCountToBeDiagnosed = issue.cases.filter(
    (item) =>
      typeof item.doctorId === 'number' && item.diagnosis === 'TO_BE_DIAGNOSED'
  ).length;

  return { caseCountToBeAssigned, caseCountToBeDiagnosed };
};
