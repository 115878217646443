import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
// import intersection from 'lodash/intersection';

import localData from 'shared/utils/localData';
// import { Tags } from 'shared/constants/tags';
import { IssueStatus, getIssueStatus } from 'shared/constants/kenshin/issues';
import { Button } from 'components';

import Issue from './Issue';
import { List, Title, IssuesCount, Issues } from './Styles';
import { ProjectProptypes } from 'shared/propTypes/projectType';
import { FilterProptypes } from 'shared/propTypes/filterType';
import { USER_COMMENT } from 'shared/constants/comment';

const propTypes = {
  progress: PropTypes.string.isRequired,
  project: PropTypes.shape(ProjectProptypes).isRequired,
  filters: PropTypes.shape(FilterProptypes).isRequired,
  fetchProject: PropTypes.func.isRequired,
  currentUserId: PropTypes.string,
};

const defaultProps = {
  currentUserId: null,
};

const ProjectBoardList = ({
  progress,
  project,
  fetchProject,
  filters,
  currentUserId,
}) => {
  const getMoreIssuesStatusDone = async () => {
    fetchProject({
      params: {
        organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
        dateThres: project.dateThres,
        fetchMoreDoneIssues: true,
      },
    });
  };

  const filteredListIssues = getSortedListIssues(project.issues, progress);
  const allListIssues = getSortedListIssues(project.issues, progress);

  return (
    <Droppable key={progress} droppableId={progress}>
      {(provided) => (
        <List>
          <Title>
            {`${getIssueStatus(progress)} `}
            <IssuesCount>
              {formatIssuesCount(allListIssues, filteredListIssues)}
            </IssuesCount>
          </Title>
          <Issues
            {...provided.droppableProps}
            ref={provided.innerRef}
            data-testid={`board-list:${progress}`}>
            {filteredListIssues.map((issue, index) => (
              <Issue
                key={issue.id}
                projectUsers={project.users}
                issue={issue}
                index={index}
              />
            ))}
            {provided.placeholder}

            {progress === IssueStatus.DONE && (
              <Fragment>
                <div
                  style={{
                    marginTop: '20px',
                    width: '50%',
                    marginLeft: '25%',
                    fontSize: '14px',
                  }}>
                  Showing items from{' '}
                  {
                    new Date(project.dateThres).toLocaleDateString('ja-JP')
                    // todo: intl
                  }
                </div>

                <Button
                  iconSize={27}
                  variant="empty"
                  style={{
                    marginTop: '15px',
                    marginBottom: '45px',
                    width: '60%',
                    marginLeft: '25%',
                  }}
                  onClick={getMoreIssuesStatusDone}>
                  {intl.get('kanban_button_load_more')}
                </Button>
              </Fragment>
            )}
          </Issues>
        </List>
      )}
    </Droppable>
  );
};

const getSortedListIssues = (issues, progress) => {
  if (progress === IssueStatus.DONE) {
    return issues
      .filter((issue) => issue.progress === progress)
      .sort((a, b) => a.id - b.id);
  } else {
    return issues
      .filter((issue) => issue.progress === progress)
      .sort(function (a, b) {
        if (a.priority === b.priority) {
          return a.listPosition - b.listPosition;
        }
        return a.priority < b.priority ? 1 : -1;
      });
  }
};

const formatIssuesCount = (allListIssues, filteredListIssues) => {
  if (allListIssues.length !== filteredListIssues.length) {
    return `${filteredListIssues.length} of ${allListIssues.length}`;
  }
  return allListIssues.length;
};

ProjectBoardList.propTypes = propTypes;
ProjectBoardList.defaultProps = defaultProps;

export default ProjectBoardList;
