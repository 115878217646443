import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';
import { IssueStatus, IssueStatusToKey } from 'shared/constants/issues';

const propTypes = {
  modalClose: PropTypes.func,
  updateIssue: PropTypes.func,
  confirmModalUpdateData: PropTypes.shape({
    status: PropTypes.string,
    state: PropTypes.string,
    userIds: PropTypes.array,
    users: PropTypes.array,
  }),
  confirmModalInfo: PropTypes.shape({
    destination: PropTypes.string,
    issueState: PropTypes.string,
    // ?? Is this used
    AssignType: PropTypes.string,
  }),
};

const DashboardKanbanIssueDetailsConfirmPopUpWindow = ({
  modalClose,
  updateIssue,
  confirmModalUpdateData,
  confirmModalInfo,
}) => {
  const [isWorking] = useState(false);

  const handleYesButtonClick = () => {
    updateIssue(confirmModalUpdateData);
    modalClose();
  };

  const generateConfirmMessage = (confirmModalInfo) => {
    if (confirmModalInfo.AssignType === 'assign') {
      return intl.get('kanban_confirm_popup_window_text_assign_doctor');
    } else if (confirmModalInfo.AssignType === 'unassign') {
      return intl.get('kanban_confirm_popup_window_text_unassign_doctor');
    }

    if (confirmModalInfo.destination === IssueStatus.DOCTORASSIGNED) {
      return intl.get('kanban_confirm_popup_window_text_doctor_assigned');
    } else if (confirmModalInfo.destination === IssueStatus.REPORTREADY) {
      return intl.get('kanban_confirm_popup_window_text_report_ready');
    } else if (confirmModalInfo.destination === IssueStatus.SENDBACK) {
      return intl.get('kanban_confirm_popup_window_text_send_back');
    } else if (confirmModalInfo.destination === IssueStatus.DONE) {
      return intl.get('kanban_confirm_popup_window_text_done');
    }

    return 'undefined message';
  };
  const confirmMessage = generateConfirmMessage(confirmModalInfo);

  return (
    <Fragment>
      <Message>{confirmMessage}</Message>
      <Actions>
        <StyledButton
          variant="primary"
          isWorking={isWorking}
          onClick={handleYesButtonClick}>
          {intl.get('kanban_confirm_popup_window_button_yes')}
        </StyledButton>
        <StyledButton hollow onClick={modalClose}>
          {intl.get('kanban_confirm_popup_window_button_no')}
        </StyledButton>
      </Actions>
    </Fragment>
  );
};

DashboardKanbanIssueDetailsConfirmPopUpWindow.propTypes = propTypes;

export default DashboardKanbanIssueDetailsConfirmPopUpWindow;
