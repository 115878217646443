import intl from 'react-intl-universal';

export const tagIntlValues = {
  CT1: intl.get('case_tag_value_ct_1'),
  CT2: intl.get('case_tag_value_ct_2'),
  CT3: intl.get('case_tag_value_ct_3'),
  CT4: intl.get('case_tag_value_ct_4'),
  CT5: intl.get('case_tag_value_ct_5'),
  CT6: intl.get('case_tag_value_ct_6'),
  CT7: intl.get('case_tag_value_ct_7'),
  CT8: intl.get('case_tag_value_ct_8'),
  CT9: intl.get('case_tag_value_ct_9'),
  CT10: intl.get('case_tag_value_ct_10'),
  MRI1: intl.get('case_tag_value_mri_1'),
  MRI2: intl.get('case_tag_value_mri_2'),
  MRI3: intl.get('case_tag_value_mri_3'),
  MRI4: intl.get('case_tag_value_mri_4'),
  MRI5: intl.get('case_tag_value_mri_5'),
  MRI6: intl.get('case_tag_value_mri_6'),
  MRI7: intl.get('case_tag_value_mri_7'),
  MRI8: intl.get('case_tag_value_mri_8'),
  MRI9: intl.get('case_tag_value_mri_9'),
  MRI10: intl.get('case_tag_value_mri_10'),
  MRI11: intl.get('case_tag_vaule_mri_11'),
  MRI12: intl.get('case_tag_value_mri_12'),
  MRI13: intl.get('case_tag_value_mri_13'),
  MRI14: intl.get('case_tag_value_mri_14'),
  PhyExam1: intl.get('case_tag_value_phy_exam_1'),
  PhyExam2: intl.get('case_tag_value_phy_exam_2'),
  PhyExam3: intl.get('case_tag_value_phy_exam_3'),
  PhyExam4: intl.get('case_tag_value_phy_exam_4'),
  MRICor: intl.get('case_tag_value_mri_cor'),
};

// These dictionary will contain the relationships for the tags to work
export const tagRelationships = {
  CT3: 'CTGroup1',
  CT4: 'CTGroup1',
  CT5: 'CTGroup1',
  CT6: 'CTGroup2',
  CT7: 'CTGroup2',
  CT8: 'CTGroup2',
  MRI1: 'MRIGroup1',
  MRI2: 'MRIGroup1',
  MRI3: 'MRIGroup1',
  MRI4: 'MRIGroup1',
  MRI5: 'MRIGroup2',
  MRI6: 'MRIGroup2',
  MRI7: 'MRIGroup2',
  MRI9: 'MRIGroup3',
  MRI10: 'MRIGroup3',
  MRI11: 'MRIGroup3',
};
