import React from 'react';
import PropTypes from 'prop-types';
import MaterialChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const Chip = (props) => {
  const StyledChip = withStyles({
    root: {
      color: 'white',
      backgroundColor: `${props.labelcolor} !important`,
      '&:hover': {
        backgroundColor: props.labelcolor,
        filter: 'brightness(120%)',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: props.labelcolor,
        borderColor: props.labelcolor,
      },
    },
    outlined: {
      color: `${props.labelcolor} !important`,
      border: `1px solid ${props.labelcolor}`,
      backgroundColor: `transparent !important`,
      '& .MuiChip-deleteIcon': {
        color: `${props.deleteiconcolor} !important`,
      },
    },
  })(MaterialChip);
  return <StyledChip {...props} />;
};

Chip.propTypes = {
  labelcolor: PropTypes.string,
  deleteiconcolor: PropTypes.string,
};

export default Chip;
