import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';

const InvoiceHeader = () => {
  return (
    <Text style={styles.invoiceHeader} fixed>
      ~ Powered By DoCloud ~
    </Text>
  );
};

export default InvoiceHeader;
