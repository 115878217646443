import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';

import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { PageLoader, PageError, Modal } from 'components';

import Header from '../components/Header';
import ItemCard from '../components/ItemCard';
import CaseDetails from '../components/CaseDetails';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const DashboardResearchCases = ({ match, history }) => {
  const caseIds = [1, 2, 3, 4, 5];
  const [{ data, error, setLocalData }] = useApi.get(
    `${DOLPHIN_API.RESEARCH_CASES}/all`,
    {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      },
    }
  );
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { researchCases } = data;

  return (
    <Header title={'All Cases'} subTitle="5 cases in total">
      <MDBox pt={2} px={2} lineHeight={1.25}>
        {/* <MDTypography variant="h6" fontWeight="medium">
          Projects
        </MDTypography>
        <MDBox mb={1}>
          <MDTypography variant="button" color="text">
            Architects design houses
          </MDTypography>
        </MDBox> */}
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                All of the Cases I got involved
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                This is the paragraph where you can write more details about
                your blablabla
              </MDTypography>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={5} sx={{ textAlign: 'right' }}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setNewProjectModalOpen(true);
              }}>
              <Icon>add</Icon>&nbsp; Add New Project
            </MDButton>
          </Grid> */}
        </Grid>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={6}>
          {researchCases.map((researchCase) => (
            <Grid key={researchCase.id} item xs={12} md={6} xl={3}>
              <ItemCard
                label={researchCase.researchProject.title}
                title={researchCase.title}
                description={researchCase.descriptionText}
                action={{
                  type: 'internal',
                  route: `${match.url}/${researchCase.id}`,
                  color: 'info',
                  label: 'view case',
                }}
                // authors={[
                //   { image: team1, name: 'Elena Morison' },
                //   { image: team2, name: 'Ryan Milly' },
                //   { image: team3, name: 'Nick Daniel' },
                //   { image: team4, name: 'Peterson' },
                // ]}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
      <Route
        path={`${match.path}/:researchCaseId`}
        render={(routeProps) => (
          <Modal
            isOpen
            testId="modal:case-details"
            width={1240}
            withCloseIcon={false}
            onClose={() => history.push(match.url)}
            renderContent={(modal) => (
              <CaseDetails
                researchCaseId={routeProps.match.params.researchCaseId}
                trigger={'byClick'}
                projectUsers={[]}
                updateLocaProjectIssues={(a, b) => {}}
                modalClose={modal.close}
              />
            )}
          />
        )}
      />
    </Header>
  );
};

DashboardResearchCases.propTypes = propTypes;

export default withRouter(DashboardResearchCases);
