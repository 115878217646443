import React, { Fragment, useRef } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import CommentTextEditor from './CommentTextEditor';
import { Actions, FormButton, FormSelect } from './Styles';
import { SUBMIT_TYPE } from './constants';

// TODO Update PropTypes
const propTypes = {
  isWorking: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  allowReport: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    // The Quill Object
    current: PropTypes.object,
  }),
};

const ProjectBoardIssueDetailsCommentsBodyForm = ({
  isClient,
  allowReport,
  isWorking,
  formState,
  setFormState,
  onSubmit,
  onCancel,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
}) => {
  const $textareaRef = useRef();

  const handleChange = (key) => (val) => {
    // const newFormState = { ...formState, [key]: val };
    // setFormState({ ...newFormState });
    setFormState((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const renderSubmitType = (submitType) => {
    if (submitType === SUBMIT_TYPE.GLOBAL) {
      return <div>{intl.get('issue_details_option_comment_type_global')}</div>;
    } else if (submitType === SUBMIT_TYPE.LOCAL) {
      return <div>{intl.get('issue_details_option_comment_type_local')}</div>;
    } else if (submitType === SUBMIT_TYPE.REPORT) {
      return <div>{intl.get('issue_details_option_comment_type_report')}</div>;
    }
    return <div>{''}</div>;
  };

  const formOptions = (allowReport) => {
    const options = [
      {
        value: SUBMIT_TYPE.GLOBAL,
        label: intl.get('issue_details_option_comment_type_local'),
      },
      {
        value: SUBMIT_TYPE.LOCAL,
        label: intl.get('issue_details_option_comment_type_global'),
      },
    ];
    if (allowReport) {
      options.push({
        value: SUBMIT_TYPE.REPORT,
        label: intl.get('issue_details_option_comment_type_report'),
      });
    }
    return options;
  };

  return (
    <Fragment>
      <CommentTextEditor
        autoFocus
        placeholder={
          formState.commentBody && formState.commentBody !== ''
            ? formState.commentBody
            : intl.get('issue_details_placeholder_add_a_comment')
        }
        value={formState.commentBody}
        onChange={handleChange('commentBody')}
        innerRef={$textareaRef}
        quoteAttachmentName={quoteAttachmentName}
        refreshQuote={refreshQuote}
        commentEditorRef={commentEditorRef}
      />

      <Actions>
        {!isClient && (
          <FormSelect
            variant="empty"
            withClearValue={false}
            dropdownWidth={140}
            name="submitType"
            value={formState.submitType}
            options={formOptions(allowReport)}
            onChange={handleChange('submitType')}
            renderValue={({ value: submitType }) =>
              renderSubmitType(submitType)
            }
            renderOption={({ value: submitType }) =>
              renderSubmitType(submitType)
            }
          />
        )}
        <FormButton
          variant="primary"
          isWorking={isWorking}
          onClick={handleSubmit}>
          {intl.get('issue_details_button_save')}
        </FormButton>
        <FormButton variant="empty" onClick={onCancel}>
          {intl.get('issue_details_button_cancel')}
        </FormButton>
      </Actions>
    </Fragment>
  );
};

ProjectBoardIssueDetailsCommentsBodyForm.propTypes = propTypes;

export default ProjectBoardIssueDetailsCommentsBodyForm;
