import React, { Fragment, useRef } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import CommentTextEditor from './CommentTextEditor';
import { Actions, FormButton, FormSelect } from './Styles';
import { SUBMIT_TYPE } from './constants';

// TODO Update PropTypes
const propTypes = {
  isWorking: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    // The Quill Object
    current: PropTypes.object,
  }),
};

const ProjectBoardIssueDetailsCommentsBodyForm = ({
  isWorking,
  formState,
  setFormState,
  onSubmit,
  onCancel,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
}) => {
  const $textareaRef = useRef();
  // const attachmentRef = useRef();  // do not delete

  const handleChange = (key) => (val) => {
    const newFormState = { ...formState, [key]: val };
    setFormState({ ...newFormState });
  };

  const handleSubmit = () => {
    onSubmit();
  };

  // do not delete
  // const selectAttachments = (event) => {
  //   const file = event.target.files[0];
  //   const newFormState = { ...formState };
  //   newFormState.file = file;
  //   newFormState.fileName = file.name;
  //   newFormState.progressInfos = { val: [] };
  //   setFormState({ ...newFormState });
  // };

  return (
    <Fragment>
      <CommentTextEditor
        autoFocus
        placeholder={
          formState.commentBody && formState.commentBody !== ''
            ? formState.commentBody
            : intl.get('issue_details_placeholder_add_a_comment')
        }
        value={formState.commentBody}
        onChange={handleChange('commentBody')}
        innerRef={$textareaRef}
        quoteAttachmentName={quoteAttachmentName}
        refreshQuote={refreshQuote}
        commentEditorRef={commentEditorRef}
      />

      {/* // DO NOT DELETE */}
      {/* <input type="file" ref={attachmentRef} onChange={selectAttachments} /> */}

      <Actions>
        <FormButton
          variant="primary"
          isWorking={isWorking}
          onClick={handleSubmit}>
          {intl.get('issue_details_button_save')}
        </FormButton>
        <FormButton variant="empty" onClick={onCancel}>
          {intl.get('issue_details_button_cancel')}
        </FormButton>
      </Actions>
    </Fragment>
  );
};

ProjectBoardIssueDetailsCommentsBodyForm.propTypes = propTypes;

export default ProjectBoardIssueDetailsCommentsBodyForm;
