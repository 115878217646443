import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import intersection from 'lodash/intersection';

import localData from 'shared/utils/localData';
import { Tags } from 'shared/constants/tags';
import {
  IssueStatus,
  getIssueStatus,
  IssueState,
} from 'shared/constants/issues';
import { Button } from 'components';

import Issue from './Issue';
import { List, Title, IssuesCount, Issues } from './Styles';
import { ProjectProptypes } from 'shared/propTypes/projectType';
import { FilterProptypes } from 'shared/propTypes/filterType';
import { USER_COMMENT } from 'shared/constants/comment';

const propTypes = {
  status: PropTypes.string.isRequired,
  project: PropTypes.shape(ProjectProptypes).isRequired,
  filters: PropTypes.shape(FilterProptypes).isRequired,
  fetchProject: PropTypes.func.isRequired,
  currentUserId: PropTypes.string,
};

const defaultProps = {
  currentUserId: null,
};

const ProjectBoardList = ({
  status,
  project,
  fetchProject,
  filters,
  currentUserId,
}) => {
  const getMoreIssuesStatusDone = async () => {
    fetchProject({
      params: {
        organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
        dateThres: project.dateThres,
        fetchMoreDoneIssues: true,
      },
    });
  };

  const filteredIssues = filterIssues(project.issues, filters, currentUserId);
  const filteredListIssues = getSortedListIssues(filteredIssues, status);
  const allListIssues = getSortedListIssues(project.issues, status);

  return (
    <Droppable key={status} droppableId={status}>
      {(provided) => (
        <List>
          <Title>
            {`${getIssueStatus(status)} `}
            <IssuesCount>
              {formatIssuesCount(allListIssues, filteredListIssues)}
            </IssuesCount>
          </Title>
          <Issues
            {...provided.droppableProps}
            ref={provided.innerRef}
            data-testid={`board-list:${status}`}>
            {filteredListIssues.map((issue, index) => (
              <Issue
                key={issue.id}
                projectUsers={project.users}
                issue={issue}
                index={index}
              />
            ))}
            {provided.placeholder}

            {status === IssueStatus.DONE && (
              <Fragment>
                <div
                  style={{
                    marginTop: '20px',
                    width: '50%',
                    marginLeft: '25%',
                    fontSize: '14px',
                  }}>
                  Showing items from{' '}
                  {new Date(project.dateThres).toLocaleDateString('ja-JP')}
                </div>

                <Button
                  iconSize={27}
                  variant="empty"
                  style={{
                    marginTop: '15px',
                    marginBottom: '45px',
                    width: '60%',
                    marginLeft: '25%',
                  }}
                  onClick={getMoreIssuesStatusDone}>
                  {intl.get('kanban_button_load_more')}
                </Button>
              </Fragment>
            )}
          </Issues>
        </List>
      )}
    </Droppable>
  );
};

const tagsContainString = (issue, searchTerm) => {
  if ('tags' in issue) {
    const tagsString = issue.tags.map((t) => Tags[t]).join(`, `);
    return tagsString.toLowerCase().includes(searchTerm.toLowerCase());
  }
  return false;
};

const filterIssues = (projectIssues, filters) => {
  const { searchTerm, userIds, srcOrgNames, srcOrgIds } = filters;
  let issues = projectIssues;

  if (searchTerm) {
    searchTerm.trim().split(/\s+/);
    issues = issues.filter(
      (issue) =>
        issue.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        issue.patientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        issue.srcOrgName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tagsContainString(issue, searchTerm)
    );
  }
  if (userIds.length > 0) {
    issues = issues.filter(
      (issue) => intersection(issue.userIds, userIds).length > 0
    );
  }
  if (srcOrgIds.length > 0) {
    issues = issues.filter((issue) => srcOrgIds.includes(issue.srcOrgId));
  }
  return issues;
};

const getSortedListIssues = (issues, status) => {
  if (status === IssueStatus.DONE) {
    return issues
      .filter((issue) => issue.status === status)
      .sort((a, b) => a.id - b.id);
  } else {
    return issues
      .filter((issue) => issue.status === status)
      .sort(function (a, b) {
        if (a.state === b.state) {
          if (a.priority === b.priority) {
            return a.listPosition - b.listPosition;
          }
          return a.priority < b.priority ? 1 : -1;
        }
        return a.state === IssueState.NORMAL ? 1 : -1;
      });
  }
};

const formatIssuesCount = (allListIssues, filteredListIssues) => {
  if (allListIssues.length !== filteredListIssues.length) {
    return `${filteredListIssues.length} of ${allListIssues.length}`;
  }
  return allListIssues.length;
};

ProjectBoardList.propTypes = propTypes;
ProjectBoardList.defaultProps = defaultProps;

export default ProjectBoardList;
