import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
};

const ReportInfo = ({ reportContent }) => {
  return (
    <Fragment>
      <View style={styles.reportInfoTable}>
        <View style={styles.reportInfoRow}>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_issue_title')}
          </Text>
          <Text style={styles.reportInfoValue}>{reportContent.issueTitle}</Text>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_issue_date')}
          </Text>
          <Text style={styles.reportInfoValue}>{reportContent.issueDate}</Text>
        </View>
        <View style={styles.reportInfoRow}>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_patient_name')}
          </Text>
          <Text style={styles.reportInfoValue}>
            {reportContent.patientName}
          </Text>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_src_org_name')}
          </Text>
          <Text style={styles.reportInfoValue}>{reportContent.srcOrgName}</Text>
        </View>
        <View style={styles.reportInfoRow}>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_patient_gender')}
          </Text>
          <Text style={styles.reportInfoValue}>
            {reportContent.patientGender}
          </Text>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_department')}
          </Text>
          <Text style={styles.reportInfoValue}>{reportContent.department}</Text>
        </View>
        <View style={styles.reportInfoRow}>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_patient_birthday')}
          </Text>
          <Text style={styles.reportInfoValue}>
            {reportContent.patientBirthdayDate}
          </Text>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_doctors_from_src_org')}
          </Text>
          <Text style={styles.reportInfoValue}>
            {reportContent.doctorsFromSrcOrg}
          </Text>
        </View>
        <View style={styles.reportInfoRow}>
          <Text style={styles.reportInfoKey}>
            {intl.get('issue_details_report_preview_text_tags')}
          </Text>
          <Text style={styles.reportInfoValue2}>{reportContent.tags}</Text>
        </View>
      </View>
    </Fragment>
  );
};

ReportInfo.propTypes = propTypes;

export default ReportInfo;
