import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Tooltip, IconButton } from '@material-ui/core';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { updateBillForSentTasksKenshin } from 'pages/Dashboard/Billing/shared/api';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object,
  data: PropTypes.array,
  setData: PropTypes.func,
};

const DashboardBillingForSentCasesDetailedTableCustomToolbarSelectStar = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  const setStarStatus = (status) => {
    const dataIndice = selectedRows.data.map((row) => row.dataIndex);
    const newData = data;
    dataIndice.forEach((dataIndex) => {
      newData[dataIndex][6] = status;
      const billId = newData[dataIndex][5].billId;
      updateBillForSentTasksKenshin(billId, { starred: status });
    });
    setData([...newData]);
  };

  return (
    <Fragment>
      <Tooltip
        className={classes.tooltip}
        title={intl.get('billing_for_sent_cases_toolbar_select_tooltip_star')}>
        <IconButton
          className={classes.iconButton}
          onClick={() => setStarStatus(true)}>
          <StarIcon className={classes.starIcon} />
        </IconButton>
      </Tooltip>
      <Tooltip
        className={classes.tooltip}
        title={intl.get(
          'billing_for_sent_cases_toolbar_select_tooltip_unstar'
        )}>
        <IconButton
          className={classes.iconButton}
          onClick={() => setStarStatus(false)}>
          <StarBorderIcon className={classes.starBorderIcon} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

DashboardBillingForSentCasesDetailedTableCustomToolbarSelectStar.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingForSentCasesDetailedTableCustomToolbarSelectStar
);
