import { getIssueStatus, getIssueState } from 'shared/constants/issues';
import localData from 'shared/utils/localData';

const generateLogContentsInEnglish = (type, contents) => {
  var result = '';
  if (type === 'activity') {
    result = `Log in.`;
  } else if (type === 'download') {
    result = `Download attachements from Issue ${contents[2]}`;
    if (contents.length > 3) {
      result = result + `/'s comment ${contents[3]}`;
    }
    result = result + '.';
  } else if (type === 'new') {
    result = `Sent Issue ${contents[2]} to ${contents[3]}.`;
  } else if (type === 'comment') {
    result = `Submit a comment to Issue ${contents[2]}`;
    if (contents.length > 3) {
      result = result + `with an attachment ${contents[3]}`;
    }
    result = result + '.';
  } else if (type === 'report') {
    result = `Submit a report to Issue ${contents[2]}`;
    if (contents.length > 3) {
      result = result + `with an attachment ${contents[3]}`;
    }
    result = result + '.';
  } else if (type === 'assign') {
    result = `Assign Issue ${contents[2]} to Doctor ${contents[3]} ${contents[4]}.`;
  } else if (type === 'unassign') {
    result = `Remove Doctor ${contents[3]} ${contents[4]} from Issue ${contents[2]}.`;
  } else if (type === 'status') {
    result = `Changed the status of Issue ${contents[2]} to ${getIssueStatus(
      contents[3]
    )}.`;
  } else if (type === 'state') {
    result = `Changed the status of Issue ${contents[2]} to ${getIssueState(
      contents[3]
    )}.`;
  } else if (type === 'star') {
    result = `Starred Issue ${contents[2]}.`;
  } else if (type === 'question') {
    result = `Questioned Issue ${contents[2]}.`;
  } else if (type === 'waive') {
    result = `Waived Issue ${contents[2]}/'s fee.`;
  } else if (type === 'adjustment') {
    result = `Adjustment Issue ${contents[2]}/'s fee.`;
  } else if (type === 'pdf') {
    result = `Download the PDF invoice for ${contents[2]}.`;
  }

  return result;
};

const generateLogContentsInJapanese = (type, contents) => {
  var result = '';
  if (type === 'activity') {
    result = `ログイン`;
  } else if (type === 'download') {
    result = `依頼${contents[2]}`;
    if (contents.length > 3) {
      result = result + 'のコメント';
    }
    result = result + `の添付ファイルをダウンロード`;
  } else if (type === 'new') {
    result = `${contents[3]}へ新規依頼${contents[2]}を送信`;
  } else if (type === 'comment') {
    result = `依頼${contents[2]}に`;
    if (contents.length > 3) {
      result = result + `ファイル（${contents[3]}）付き`;
    }
    result = result + `コメントを投稿`;
  } else if (type === 'report') {
    result = `依頼${contents[2]}に`;
    if (contents.length > 3) {
      result = result + `ファイル（${contents[3]}）付き`;
    }
    result = result + `レポートを投稿`;
  } else if (type === 'assign') {
    result = `依頼${contents[2]}を${contents[4]} ${contents[3]}先生に割り当て`;
  } else if (type === 'unassign') {
    result = `先生${contents[4]} ${contents[3]}を依頼${contents[2]}の割り当てをリムーブ`;
  } else if (type === 'status') {
    result = `依頼${contents[2]}のステータスを${getIssueStatus(
      contents[3]
    )}に変更`;
  } else if (type === 'state') {
    result = `依頼${contents[2]}の状態を${getIssueState(contents[3])}に変更`;
  } else if (type === 'star') {
    result = `依頼${contents[2]}にスターを付ける`;
  } else if (type === 'question') {
    result = `依頼${contents[2]}に問題マークを付ける`;
  } else if (type === 'waive') {
    result = `依頼${contents[2]}の料金を免除`;
  } else if (type === 'adjustment') {
    result = `依頼${contents[2]}の料金を調整`;
  } else if (type === 'pdf') {
    result = `依頼${contents[2]}の請求書を出力`;
  }

  return result;
};

export const generateLogContents = (type, contents) => {
  if (localData.get('userPreferredLanguage') === 'en-US') {
    return generateLogContentsInEnglish(type, contents);
  } else if (localData.get('userPreferredLanguage') === 'ja-JP') {
    return generateLogContentsInJapanese(type, contents);
  }

  return generateLogContentsInEnglish(type, contents);
};
