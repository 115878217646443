import React, { forwardRef, Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Avatar, Select, Icon } from 'components';
import { User, Username, Quota, SectionTitle } from './Styles';

import { UserContextMenu } from 'components/Menu';

import { IssueState, IssueStatus } from 'shared/constants/issues';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { LANGUAGE, UserType } from 'shared/constants/users';
import { UserProptypes } from 'shared/propTypes/userType';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  doctors: PropTypes.arrayOf(Object),
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};

const BatchEditModalAssignees = forwardRef(
  (
    {
      issue,
      doctors,
      setConfirmModalInfo,
      setConfirmModalOpen,
      setConfirmModalUpdateData,
    },
    ref
  ) => {
    const getUserById = (userId) => doctors.find((user) => user.id === userId);
    const handleAssignDoctor = (issue, userIds) => {
      const users = userIds.map(getUserById);
      const updatedFields = {
        userIds,
        users: users,
        status: issue.status,
        state: issue.state,
      };
      // TODO: [Privilege-Check-Again] change to filter doctors by privilege: has pic_as_doctor
      // const doctorUsers = issue.users.filter(
      //   (user) => user.userType === UserType.DOCTOR
      // );
      const doctorUsers = issue.userIds;
      if (
        doctorUsers.length === 0 &&
        issue.state === IssueState.NORMAL &&
        issue.status === IssueStatus.CHECKREADY
      ) {
        updatedFields.status = IssueStatus.DOCTORASSIGNED;
      }
      if (
        users.length === 0 &&
        [
          IssueStatus.DOCTORASSIGNED,
          IssueStatus.CHECKREADY,
          IssueStatus.REPORTREADY,
          IssueStatus.SENDBACK,
          IssueStatus.DONE,
        ].includes(issue.status)
      ) {
        updatedFields.state = IssueState.WARNING;
      }
      setConfirmModalInfo({
        destination: updatedFields.status,
        issueState: updatedFields.state,
        AssignType: doctorUsers.length < users.length ? 'assign' : 'unassign',
      });
      setConfirmModalUpdateData(updatedFields);
      setConfirmModalOpen(true);
    };

    const userOptions = doctors.map((user) => ({
      value: user.id,
      label: user.username,
    }));

    return (
      <Fragment>
        <SectionTitle>
          {intl.get('issue_details_text_doctor_in_charge')}
        </SectionTitle>
        <Select
          isMulti
          variant="empty"
          dropdownWidth={240}
          placeholder={intl.get('issue_details_unassigned')}
          name="assignees"
          value={issue.userIds}
          options={userOptions}
          onChange={(userIds) => {
            handleAssignDoctor(issue, userIds);
          }}
          renderValue={({ value: userId, removeOptionValue }) =>
            renderUser(getUserById(userId), true, removeOptionValue)
          }
          renderOption={({ value: userId }) =>
            renderUserWithoutQuota(getUserById(userId), false)
          }
        />
      </Fragment>
    );
  }
);

const renderUser = (user, isSelectValue, removeOptionValue) => {
  const preferredLanguage = localData.get('userPreferredLanguage');

  return (
    <UserContextMenu
      username={user.username}
      userShowName={getUserFullName(user.firstName, user.lastName)}>
      <User
        key={user.id}
        isSelectValue={isSelectValue}
        withBottomMargin={!!removeOptionValue}
        onClick={() => removeOptionValue && removeOptionValue()}>
        <Avatar name={user.firstName} size={24} />
        <Username>
          {preferredLanguage === LANGUAGE.ENGLISH
            ? user.firstName + ' ' + user.lastName
            : user.lastName + ' ' + user.firstName}
        </Username>
        {removeOptionValue && <Icon type="close" top={1} />}
      </User>
    </UserContextMenu>
  );
};

const renderUserWithoutQuota = (user, isSelectValue) => {
  return (
    <User
      key={user.id}
      isSelectValue={isSelectValue}
      withBottomMargin={false}
      onClick={() => {}}>
      {/* <Avatar avatarUrl={user.avatarUrl} name={user.username} size={24} /> */}
      <Username style={{}}>
        {' '}
        {getUserFullName(user.firstName, user.lastName)}{' '}
      </Username>
    </User>
  );
};

BatchEditModalAssignees.propTypes = propTypes;
BatchEditModalAssignees.displayName = 'BatchEditModalAssignees';

export default BatchEditModalAssignees;
