import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actionGrid: {
    background: '#525659',
  },
  imageGrid: {
    // background: 'black',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  pdfGrid: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fileViewerGrid: {
    background: '#525659',
    width: '800px',
    height: '800px',
    maxWidth: '100%',
    maxHeight: '100%',
    overflowY: 'hidden',
    '& .pg-viewer-wrapper': {
      'overflow-y': 'auto !important',
    },
  },
}));
