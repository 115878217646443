/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useMemo } from 'react';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
//Material UI Imports
import {
  Box,
  Button,
  // ListItemIcon,
  // MenuItem,
  // Typography,
  lighten,
} from '@mui/material';

//Icons Imports
// import { AccountCircle, Send } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
//Date Picker Imports - these should just be in your Context Provider
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import intl from 'react-intl-universal';
// import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { readableJapaneseDateFormat } from 'shared/utils/dateTime';

import { getIssueStatusForClient } from 'shared/constants/kenshin/issues';
import { Status } from './styles';
import { filterData, generateRowSelectionObject } from './utils';
import localData from 'shared/utils/localData';

const propTypes = {
  allData: PropTypes.any,
  handleOpenIssueDetails: PropTypes.func,
  setBatchEditModalIsOpen: PropTypes.func,
  setSelectedIssueIndexes: PropTypes.func,
};

const Table = ({
  allData,
  handleOpenIssueDetails,
  setBatchEditModalIsOpen,
  setSelectedIssueIndexes,
}) => {
  // const theme = useTheme();

  const statusList = [
    {
      label: intl.get('shared_value_status_new_task'),
      value: 'NEWTASK',
    },
    {
      label: intl.get('shared_value_status_in_progress'),
      value: 'INPROGRESS',
    },
    {
      label: intl.get('shared_value_status_send_back_sender'),
      value: 'SENDBACK',
    },
    {
      label: intl.get('shared_value_status_done_sender'),
      value: 'DONE',
    },
  ];

  const columns = useMemo(() => [
    {
      header: intl.get('kenshin_sent_history_column_label_title'), // display name
      accessorKey: 'title', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      size: 50,
      enableClickToCopy: true,
      enableColumnFilter: true,
      filterFn: 'fuzzy',
      filterVariant: 'text',
      enableColumnActions: false,
    },
    {
      header: intl.get('kenshin_sent_history_column_label_progress'),
      accessorKey: 'progress',
      size: 70,
      filterVariant: 'select',
      filterSelectOptions: statusList,
      enableColumnFilter: true,
      enableColumnActions: false,
      //custom conditional format and styling
      Cell: ({ cell }) => {
        const status = cell.getValue();
        return (
          <Status color={status}>{getIssueStatusForClient(status)}</Status>
        );
      },
    },
    {
      header: intl.get('kenshin_sent_history_column_label_created_at'),
      accessorKey: 'createdAt',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const createdAt = cell.getValue();
        return readableJapaneseDateFormat(createdAt);
      },
    },
    {
      header: intl.get('kenshin_sent_history_column_label_to'),
      accessorKey: 'dstOrgName',
      size: 100,
      enableClickToCopy: true,
      enableColumnFilter: true,
      filterFn: 'fuzzy',
      filterVariant: 'text',
      enableColumnActions: false,
    },
    {
      header: intl.get('kenshin_sent_history_column_label_others'),
      accessorKey: 'id',
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      //custom conditional format and styling
      Cell: ({ cell }) => {
        const issueId = cell.getValue();
        return (
          <Button
            variant="contained"
            color="primary"
            style={{ color: 'white' }} // make the text color to be white; refine code;
            onClick={() => {
              handleOpenIssueDetails(issueId);
            }}>
            {intl.get('case_list_button_view')}
          </Button>
        );
      },
    },
  ]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectingAll, setSelectingAll] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [data, setData] = useState(allData); // This is the data shown in the table; after going through filters;

  useEffect(() => {
    const filteredData = filterData(allData, columnFilters);
    setData([...filteredData]);
  }, [columnFilters, allData]);

  const preferredLanguage = localData.get('userPreferredLanguage');
  let MRT_Localization = null;
  if (preferredLanguage === 'ja-JP') {
    MRT_Localization = MRT_Localization_JA;
  } else {
    MRT_Localization = MRT_Localization_EN;
  }

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization: {
      ...MRT_Localization,
      toggleSelectAll: intl.get('share_mrt2_text_label_body_toggle_select_all'),
      noRecordsToDisplay: intl.get('share_muitable_text_label_body_no_match'),
    },
    enableStickyHeader: true,
    enableRowActions: false,
    enableRowSelection: true,
    getRowId: (row) => row.index,
    enableSelectAll: true,
    selectAllMode: 'page', // 'all'
    onRowSelectionChange: setRowSelection,

    state: {
      rowSelection,
      density: 'compact',
      showColumnFilters: true,
    },
    onColumnFiltersChange: setColumnFilters, //hoist internal columnFilters state to your state
    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 15, 20, 25, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    muiSelectCheckboxProps: { color: 'secondary' },
    muiTableContainerProps: { sx: { maxHeight: '60vh' } }, // set the max height of the table to be 60% of the viewport height;
    muiTablePaperProps: { style: { zIndex: '1' } },
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontSize: '15px',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        border: 1,
      },
    },
    muiTableBodyCellProps: {
      size: 'small',
      sx: {
        fontSize: '14px',
        // '& .MuiTableCell-sizeSmall': {
        //   fontSize: '12px',
        // },
        //use the `&` syntax to target hover state
        '&:hover': {
          fontWeight: 'bold',
        },
      },
    },
    muiFilterTextFieldProps: {
      sx: { width: '100%', p: '1px 0 1px' },
    },
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 10, //adjust the size of the row select column
        grow: false, //new in v2.8 (default is false for this column)
      },
      // 'mrt-row-numbers': {
      //   size: 20,
      //   grow: true, //new in v2.8 (allow this column to grow to fill in remaining space)
      // },
    },

    // muiTableBodyProps: {
    //   sx: (theme) => ({
    //     '& tr:not([data-selected="true"]):not([data-pinned="true"]) > td': {
    //       backgroundColor: darken(baseBackgroundColor, 0.1),
    //     },
    //     '& tr:not([data-selected="true"]):not([data-pinned="true"]):hover > td':
    //       {
    //         backgroundColor: darken(baseBackgroundColor, 0.2),
    //       },
    //   }),
    // },
    // mrtTheme: (theme) => ({
    //   baseBackgroundColor: baseBackgroundColor,
    //   // draggingBorderColor: theme.palette.secondary.main,
    // }),

    renderTopToolbar: ({ table }) => {
      const handleSelectAllButtonClick = () => {
        if (selectingAll) {
          table.getState().rowSelection = {};
          setRowSelection({});
          setSelectingAll(false);
        } else {
          const rowSelection = generateRowSelectionObject(data);
          table.getState().rowSelection = rowSelection;
          setRowSelection(rowSelection);
          setSelectingAll(true);
        }
      };

      const handleBatchEditClick = () => {
        // console.log(` === rowSelection: `, rowSelection);
        // console.log(` === table.getState().rowSelection: `, table.getState().rowSelection);
        // console.log(' === table.getSelectedRowModel().rows: ', table.getSelectedRowModel().rows)
        // const selectedIndexes = table
        //   .getSelectedRowModel()
        //   .rows.map((item) => item.index);
        // console.log(' ==== selectedIndexes: ', selectedIndexes)

        // Filter out objects from the data array based on the selected indexes
        // const selectedIssues = selectedIndexes
        //   .map((index) => data[index])
        //   .filter(Boolean);
        const selectedIndexes = Object.keys(rowSelection).map((key) =>
          parseInt(key)
        );
        setSelectedIssueIndexes(selectedIndexes);
        setBatchEditModalIsOpen(true);
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            {/* <MRT_GlobalFilterTextField table={table} /> */}
            {/* <MRT_ToggleFiltersButton table={table} /> */}
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                color="info"
                disabled={data.length === 0}
                onClick={handleSelectAllButtonClick}
                variant="contained">
                {selectingAll
                  ? intl.get(
                      'kenshin_sent_history_case_batch_edit_modal_button_clear_all'
                    )
                  : intl.get(
                      'kenshin_sent_history_case_batch_edit_modal_button_select_all'
                    )}
              </Button>
              <Button
                color="info"
                onClick={handleBatchEditClick}
                variant="contained"
                startIcon={<EditIcon />}>
                編集
              </Button>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return <MaterialReactTable table={table} />;
};

Table.propTypes = propTypes;

export default Table;
