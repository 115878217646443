export const RECOVERY_STATES = {
  SUBMIT_EMAIL: 'submitEmail',
  CODE_SENT: 'codeSent',
  CODE_DELIVERY_FAILED: 'codeDeliverFailed',
  CLICK_PASSWORD_RESET_BUTTON: 'clickPasswordResetButton',
  INITIALIZE_RESET_FORM: 'initializeResetForm',
  INVALID_RESET_FORM: 'invalidResetForm',
  PASSWORD_RESET_SUCCESS: 'passwordResetSuccess',
  PASSWORD_RESET_FAIL: 'passwordResetFail',
  GO_BACK_TO_PAGE1: 'goBackToPage1',
};
