/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';
import { NavLink } from 'react-router-dom';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { analyzeRouteForBreadcrumbs } from './utils';

function Breadcrumbs({ icon, route, light }) {
  const translatedRoute = analyzeRouteForBreadcrumbs(route);
  let title = translatedRoute[translatedRoute.length - 1]; // title = 'd'
  let routes = translatedRoute.slice(0, -1); // routes = ['a', 'b', 'c']

  if (routes.length === 0 && !title) {
    title = 'undefined';
  }

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}>
        <MDTypography
          component="span"
          variant="div"
          color={light ? 'white' : 'dark'}
          opacity={light ? 0.8 : 0.5}
          sx={{ lineHeight: 0 }}
          to="/home">
          <NavLink to="/home">
            <Icon>{icon}</Icon>
          </NavLink>
        </MDTypography>

        {routes.map((el, index) => (
          <MDTypography
            key={index}
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="none"
            color={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}>
            {el}
          </MDTypography>
        ))}

        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="none"
          color={light ? 'white' : 'dark'}
          sx={{ lineHeight: 0 }}>
          {title.replace('-', ' ')}
        </MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
