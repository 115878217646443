import FormHelperText from '@mui/material/FormHelperText';
import { useMaterialUIController } from 'context';
import PropTypes from 'prop-types';

/**
 * FormFieldHelperText
 * @param {String} children
 * @returns FormHelperText element
 */
function FormFieldHelperText({ children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const color = darkMode ? 'white' : 'grey';
  return <FormHelperText style={{ color: color }}>{children}</FormHelperText>;
}

FormFieldHelperText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FormFieldHelperText;
