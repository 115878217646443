import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import intl from 'react-intl-universal';
import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import KrillChat from 'krill';
import { useLatest } from 'ahooks';
import { useSnackbar } from 'notistack';

import { getUserFullName } from 'shared/utils/name';

import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import { getFriendsTableColumns } from './FriendsTableColumns';
import { getReceivedApplicationTableColumns } from './ReceivedApplicationTableColumns';
import { getSentApplicationTableColumns } from './SentApplicationTableColumns';
import CustomSearchRender from 'components/CustomSearchRender';
import CustomizedTitle from './CustomizedTitle';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { getSearchedUserTableColumns } from './SearchedUserTableColumns';

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const DashboardContactsFriends = ({ match, history }) => {
  const [controller] = useMaterialUIController();
  const context = KrillChat.Context.useKrillChatContext();
  const { friendList, recvFriendApplicationList, sentFriendApplicationList } =
    context;
  const { darkMode } = controller;
  const [tableType, setTableType] = useState('friends'); // friends | receivedApplication | sentApplication | searchUsers
  const [newCountForReceived, setNewCountForReceived] = useState(0);
  const [newCountForSent, setNewCountForSent] = useState(0);
  const [friendsOnlineStatus, setFriendsOnlineStatus] = useState([]);
  const [searchedUserList, setSearchedUserList] = useState([]);

  const [deleteFriendConfirmModalOpen, setDeleteFriendConfirmModalOpen] =
    useState(false);
  const [deleteFriendUserID, setDeleteFriendUserID] = useState(null);
  const latestDeleteFriendUserID = useLatest(deleteFriendUserID);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getFriendsOnlineStatus = async () => {
      const friendUserIDs = friendList.map((friend) => {
        return friend.userID;
      });
      const onlineStatus = await KrillChat.API.getUsersOnlineStatus(
        friendUserIDs
      );
      setFriendsOnlineStatus(onlineStatus);
    };
    getFriendsOnlineStatus();
  }, [friendList]);

  useEffect(() => {
    const unHandledApplication = recvFriendApplicationList.filter(
      (application) => application.handleResult === 0
    );
    setNewCountForReceived(unHandledApplication.length);
  }, []);

  const openChatWithUser = async (userID) => {
    const sourceID = userID;
    const sessionType = 1;
    KrillChat.UI.Widget.Events.emit(
      KrillChat.Events.TOASSIGNCVE,
      sourceID,
      sessionType
    );
  };

  const deleteFriend = async (userID) => {
    KrillChat.API.deleteFriend(userID)
      .then(() => {
        enqueueSnackbar(
          intl.get('friends_snackbar_text_delete_friend_success'),
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(intl.get('friends_snackbar_text_delete_friend_error'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  const sendApplication = async (userID) => {
    KrillChat.API.addFriend(userID)
      .then(() => {
        enqueueSnackbar(
          intl.get('friends_snackbar_text_send_application_success'),
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          intl.get('friends_snackbar_text_send_application_error'),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
      });
  };

  const acceptApplication = async (userID) => {
    KrillChat.API.acceptFriendApplication(userID)
      .then(() => {
        enqueueSnackbar(
          intl.get('friends_snackbar_text_accept_application_success'),
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          intl.get('friends_snackbar_text_accept_application_error'),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
      });
  };

  const rejectApplication = async (userID) => {
    KrillChat.API.refuseFriendApplication(userID)
      .then(() => {
        enqueueSnackbar(
          intl.get('friends_snackbar_text_reject_application_success'),
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          intl.get('friends_snackbar_text_reject_application_error'),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
      });
  };

  let tableData = [];
  let columns = [];

  if (tableType === 'friends') {
    // tableData = [['Hema', 'hema', 'Online', 'hema']];
    tableData = friendList.map((friend, index) => {
      return [
        friend.nickname,
        friend.userID,
        friendsOnlineStatus[index],
        friend.userID,
      ];
    });
    columns = getFriendsTableColumns(
      openChatWithUser,
      setDeleteFriendConfirmModalOpen,
      setDeleteFriendUserID
    );
  } else if (tableType === 'receivedApplication') {
    // tableData = [['Hema', 'hema', 'this is hema', ['hema', 1]]];
    tableData = recvFriendApplicationList.map((application, index) => {
      return [
        application.fromNickname,
        application.fromUserID,
        application.reqMsg,
        [application.fromUserID, application.handleResult],
      ];
    });
    columns = getReceivedApplicationTableColumns(
      openChatWithUser,
      acceptApplication,
      rejectApplication
    );
  } else if (tableType === 'sentApplication') {
    // tableData = [['Hema', 'hema', 'this is hema', ['hema', 2]]];
    tableData = sentFriendApplicationList.map((application, index) => {
      return [
        application.toNickname,
        application.toUserID,
        application.reqMsg,
        [application.toUserID, application.handleResult],
      ];
    });
    columns = getSentApplicationTableColumns(openChatWithUser);
  } else if (tableType === 'searchUsers') {
    tableData = searchedUserList.map((user, index) => {
      const users_ = friendList.filter(
        (user_) => user_.userID === user.username
      );
      const isFriend = users_.length > 0;
      return [
        getUserFullName(user.firstName, user.lastName, user.preferredLanguage),
        user.username,
        [user.username, isFriend],
      ];
    });
    columns = getSearchedUserTableColumns(openChatWithUser, sendApplication);
  }

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    responsive: 'standard',
    tableBodyMaxHeight: '950px',
    rowsPerPageOptions: [10, 20, 50],
    sortOrder: {
      name: 'order_time',
      direction: 'desc',
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get('friends_text_label_search')}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                <CustomizedTitle
                  tableType={tableType}
                  setTableType={setTableType}
                  recvFriendApplicationList={recvFriendApplicationList}
                  sentFriendApplicationList={sentFriendApplicationList}
                  setSearchedUserList={setSearchedUserList}
                />
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>
        <ConfirmModal
          isOpened={deleteFriendConfirmModalOpen}
          title={intl.get('friends_confirm_modal_title_delete_friend')}
          bodyText={latestDeleteFriendUserID.current}
          onConfirm={() => {
            deleteFriend(latestDeleteFriendUserID.current);
            setDeleteFriendConfirmModalOpen(false);
          }}
          onCancel={() => {
            setDeleteFriendUserID(null);
            setDeleteFriendConfirmModalOpen(false);
          }}
          confirmButtonColor="info"
          confirmButtonText={intl.get(
            'friends_confirm_modal_button_delete_friend_confirm'
          )}
          cancelButtonColor="error"
          cancelButtonText={intl.get(
            'friends_confirm_modal_button_delete_cancel'
          )}></ConfirmModal>
      </Card>
    </Fragment>
  );
};

DashboardContactsFriends.propTypes = propTypes;

export default withRouter(DashboardContactsFriends);
