import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { KrillChatUI, KrillChat, KrillChatEvents } from 'krill';
import KrillChat from 'krill';
import localData from 'shared/utils/localData';
import intl from 'react-intl-universal';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  username: PropTypes.string.isRequired,
  userShowName: PropTypes.string.isRequired,
};

const UserContextMenu = ({ username, userShowName, children }) => {
  const preferredLanguage = localData.get('userPreferredLanguage');

  const [contextMenu, setContextMenu] = React.useState(null);

  const handleOpenMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleStartChat = (username) => {
    const sourceID = username;
    const sessionType = 1;
    KrillChat.UI.Widget.Events.emit(
      KrillChat.Events.TOASSIGNCVE,
      sourceID,
      sessionType
    );
    setContextMenu(null);
  };

  const handleAddFriend = async (username) => {
    const data = await KrillChat.API.addFriend(username);
    setContextMenu(null);
  };

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  return (
    <div onContextMenu={handleOpenMenu} style={{ display: 'inline-block' }}>
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }>
        <MenuItem onClick={() => handleStartChat(username)}>
          {intl.get('shared_user_context_menu_button_send_message')}
        </MenuItem>
        <MenuItem onClick={() => handleAddFriend(username)}>
          {intl.get('shared_user_context_menu_button_add_friend')}
        </MenuItem>
      </Menu>
    </div>
  );
};

UserContextMenu.propTypes = propTypes;

export default UserContextMenu;
