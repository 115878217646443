import React, { useLayoutEffect, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Quill } from 'react-quill';
import 'quill/dist/quill.snow.css';

import { EditorCont } from './Styles';

const propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  getEditor: PropTypes.func,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    // Quill object
    current: PropTypes.object,
  }),
};

const defaultProps = {
  className: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: () => {},
  getEditor: () => {},
};

const CommentTextEditor = ({
  className,
  placeholder,
  defaultValue,
  // we're not really feeding new value to quill instance on each render because it's too
  // expensive, but we're still accepting 'value' prop as alias for defaultValue because
  // other components like <Form.Field> feed their children with data via the 'value' prop
  value: alsoDefaultValue,
  onChange,
  getEditor,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
}) => {
  const $editorContRef = useRef();
  const $editorRef = useRef();
  const initialValueRef = useRef(defaultValue || alsoDefaultValue || '');
  useEffect(() => {
    if (refreshQuote === 0) return;
    let length = commentEditorRef.current.getLength();
    // The editor always has at least a new line character.
    // use `length - 1` if you do not want the new line;
    commentEditorRef.current.insertEmbed(length - 1, 'blockquote', '');
    commentEditorRef.current.insertText(length - 1, quoteAttachmentName);
    commentEditorRef.current.insertText(
      commentEditorRef.current.getLength() - 1,
      '',
      ''
    );
    commentEditorRef.current.setSelection(commentEditorRef.current.getLength());
    commentEditorRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshQuote]);

  useLayoutEffect(() => {
    let quill = new Quill($editorRef.current, { placeholder, ...quillConfig });
    commentEditorRef.current = quill;

    const insertInitialValue = () => {
      quill.clipboard.dangerouslyPasteHTML(0, initialValueRef.current);
      quill.blur();
    };
    const handleContentsChange = () => {
      onChange(getHTMLValue());
    };
    const getHTMLValue = () =>
      $editorContRef.current.querySelector('.ql-editor').innerHTML;

    insertInitialValue();
    getEditor({ getValue: getHTMLValue });

    quill.on('text-change', handleContentsChange);

    return () => {
      quill.off('text-change', handleContentsChange);
      quill = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditorCont className={className} ref={$editorContRef}>
      <div ref={$editorRef} />
    </EditorCont>
  );
};

const quillConfig = {
  theme: 'snow',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ header: [1, 2, 3, 4, false] }],
      ['clean'],
    ],
  },
};

CommentTextEditor.propTypes = propTypes;
CommentTextEditor.defaultProps = defaultProps;

export default CommentTextEditor;
